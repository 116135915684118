<div class="grid">
  <div class="col-12 md:col-4">
    <app-files
      #filesComponent
      [showFilterBar]="true"
      [maxHeight]="490"
      [filesList]="customer.files ?? []"
      [delegatePreview]="delegatePreview"
      context="customers"
      [showUploadBtn]="$can('update')"
      [showRemoveBtn]="$can('delete')"
      [showCustomButton]="$can('update')"
      [showUploadComponent]="$can('update')"
      [(selectedFiles)]="selectedFiles"
      (selectedFilesChange)="handleFilesSelected($event)"
      [selectionMode]="selectionMode"
      (upload)="handleDeviceUpload($event)"
      (fileRemoved)="handleDeviceRemove($event)"
      (preview)="handlePreview($event)"></app-files>
  </div>
  <div
    class="col-12 md:col-8 flex align-items-center justify-content-center max-w-full relative">
    <ng-container [ngSwitch]="true">
      <ng-container *ngSwitchCase="filePreview === null">
        <!-- Display nothing -->
      </ng-container>

      <ng-container *ngSwitchCase="filePreview?.type === 'image'">
        <img
          [src]="filePreview?.file?.previewUrl"
          alt="preview"
          class="max-w-full" />
      </ng-container>

      <ng-container *ngSwitchCase="filePreview?.type === 'pdf'">
        <ngx-doc-viewer
          *ngIf="filePreview?.file?.previewUrl"
          [url]="filePreview!.file.previewUrl!"
          [viewer]="'pdf'"
          style="width: 100%; height: 100vh"></ngx-doc-viewer>
      </ng-container>

      <ng-container *ngSwitchCase="filePreview?.type === 'msg'">
        <app-msg-viewer
          [file]="filePreview!.file"
          (modalHidden)="filePreview = null"></app-msg-viewer>
      </ng-container>
    </ng-container>

    <div
      *ngIf="filePreview"
      class="absolute cursor-pointer"
      role="button"
      [ngStyle]="{
        'top': '1rem',
        'right': filePreview.type === 'pdf' ? '-1.5rem' : '0'
      }"
      (mousedown)="filePreview = null">
      <i class="pi pi-times text-xl"></i>
    </div>
  </div>
</div>
