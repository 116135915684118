<div class="container">
  <img
    #imageElement
    class="profile-img"
    [src]="base64Image ? base64Image : '../../../../../assets/images/logo.png'" />
  <p-button
    *ngIf="isEditable"
    icon="pi pi-pencil"
    [rounded]="true"
    (onClick)="openImageCropper()"
    class="overlay-button"></p-button>
</div>
