import { CustomerDevice } from 'src/app/models/customer/CustomerDevice';

/**
 * Generates the subject string for the ticket form based on the selected category, customer, and devices.
 * The subject string is composed of the category translation, customer name, and device details,
 * separated by slashes. Only non-empty values are included in the subject string.
 * @returns {void}
 */
export function generateSubject(
  category: string,
  customer: string,
  devices: CustomerDevice[]
): string {
  let subject = '';
  // Generate a string for the devices, including the device title and heating engineer name
  const devicesForSubject =
    devices?.length > 0
      ? devices
          .map((device) => {
            const deviceTitle = device.device?.title;
            const heatingEngineer = device.heatingEngineer?.name;

            return `${deviceTitle} (${heatingEngineer})`;
          })
          .join(', ')
      : '';

  // If the form group exists, set the subject field with the generated subject string

  subject = [category, customer, devicesForSubject].filter(Boolean).join(' / ');

  return subject;
}
