import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { User } from 'src/app/models/User';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { environment } from 'src/environments/environment';
import { base64ToFile } from 'src/utils/base64-to-file';

import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends GenericService<User> {
  constructor(
    http: HttpClient,
    messageCenterService: MessageCenterService,
    translate: TranslateService,
    private internalHttp: HttpClient
  ) {
    super(http, messageCenterService, translate);
    this.setContext('users');
  }

  findByEmail(email: string): Observable<User> {
    return this.internalHttp.get<User>(
      `${environment.apiUrl}/${this.context}/findByEmail/${email}`
    );
  }

  reactivateUser(id: number): Observable<User> {
    return this.internalHttp.get<User>(
      `${environment.apiUrl}/${this.context}/reactivateUser/${id}`
    );
  }

  getProfilePicture(id: number): Observable<Blob> {
    return this.internalHttp.get(
      `${environment.apiUrl}/${this.context}/avatar/${id}`,
      {
        responseType: 'blob'
      }
    );
  }

  updateProfilePicture(base64: string): Observable<User> {
    const formData = new FormData();

    formData.append('file', base64ToFile(base64, 'profile-picture.png'));

    return this.internalHttp.post<User>(
      `${environment.apiUrl}/${this.context}/avatar`,
      formData
    );
  }
}
