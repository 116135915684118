<div class="relative flex align-items-center">
  <p-autoComplete
    #autocomplete
    [id]="id"
    [name]="name"
    [placeholder]="placeholder"
    [field]="columnConfiguration.referringKey"
    [suggestions]="filteredOptions"
    [disabled]="isDisabled"
    [delay]="0"
    [minLength]="0"
    [autoHighlight]="true"
    [showEmptyMessage]="false"
    [completeOnFocus]="true"
    [autoOptionFocus]="true"
    class="w-full"
    [style]="{'width':'100%'}"
    [inputStyle]="{'width':'100%'}"
    (completeMethod)="filter($event)"
    (onSelect)="handleOptionSelect($event)"
    (onFocus)="handleFocus()"
    (onBlur)="handleBlur()"
    (onClear)="handleInputChange('')" />
</div>
