/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { ActionClickedResponse } from 'src/app/models/ActionClickedResponse';
import { CellChangedResponse } from 'src/app/models/CellChangedResponse';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { EditMethod } from 'src/app/types/misc/EditMethod';
import { MessageCenterService } from '../../../services/message-center.service';
import { FormComponent } from './form/form.component';

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.scss']
})
export class CrudComponent implements OnInit, OnChanges {
  @Input() tableVisible = true;

  @Input() config!: GenericTableConfiguration;

  @Input() objectsList: any[] = [];

  @Input() sidebarVisible = false;

  @Input() separatePageVisible = false;

  @Input() editMethod!: EditMethod;

  @Input() createMethod!: EditMethod;

  @Input() editObject?: any | null;

  @Input() totalRecords!: number;

  @Output() objectChanged = new EventEmitter<any>();

  @Output() cellChanged = new EventEmitter<CellChangedResponse>();

  @Output() selectionChanged = new EventEmitter<any[]>();

  @Output() rowActionClicked = new EventEmitter<ActionClickedResponse>();

  @Output() sidebarVisibleChange = new EventEmitter<boolean>();

  @Output() separatePageVisibleChange = new EventEmitter<boolean>();

  @Output() tableVisibleChange = new EventEmitter<boolean>();

  @Output() lazyLoadTableEventFired = new EventEmitter<TableLazyLoadEvent>();

  @ViewChild('form') formComponent?: FormComponent;

  @ContentChild('loggingTemplate', { static: true })
  loggingTemplate!: TemplateRef<unknown>;

  constructor(
    private translate: TranslateService,
    private messageCenterService: MessageCenterService
  ) {}

  onObjectChanged(object: any) {
    this.objectChanged.emit(object);
  }

  onCellChanged(object: CellChangedResponse) {
    this.cellChanged.emit(object);
  }

  onSelectionChanged(list: any) {
    this.selectionChanged.emit(list);
  }

  editSubmitDisabled!: boolean;

  createSubmitDisabled!: boolean;

  ngOnInit() {
    this.editSubmitDisabled = this.getEditSubmitDisabled();
    this.createSubmitDisabled = this.getCreateSubmitDisabled();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['editObject']) {
      this.editSubmitDisabled = this.getEditSubmitDisabled();
      this.createSubmitDisabled = this.getCreateSubmitDisabled();
    }
  }

  formChanged(): void {
    this.editSubmitDisabled = this.getEditSubmitDisabled();
    this.createSubmitDisabled = this.getCreateSubmitDisabled();
  }

  async onActionClicked(response: ActionClickedResponse): Promise<void> {
    if (response.action.confirmRequired) {
      await new Promise<boolean>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant('general.confirmDelete.header'),
          this.translate.instant('general.confirmDelete.message'),
          () => {
            this.rowActionClicked.emit(response);
          },
          () => {
            this.messageCenterService.showToast(
              this.translate.instant(
                `${this.config.translationKey}.actions.toasts.${response.action.identifier}.info.summary`
              ),
              this.translate.instant(
                `${this.config.translationKey}.actions.toasts.${response.action.identifier}.info.detail`
              ),
              'info'
            );

            resolve(false);
          }
        );
      });
    } else {
      this.rowActionClicked.emit(response);
    }
  }

  async onSidebarVisibleChange(visible: boolean) {
    if (
      this.editObject &&
      !visible &&
      this.formComponent &&
      this.formComponent &&
      this.formComponent.form &&
      this.formComponent.form.dirty
    ) {
      await new Promise<boolean>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant('general.confirmUnsavedChanges.header'),
          this.translate.instant('general.confirmUnsavedChanges.message'),
          () => {
            this.editObject = null;
            this.sidebarVisible = visible;
            this.sidebarVisibleChange.emit(this.sidebarVisible);
          },
          () => {
            resolve(false);
          }
        );
      });
    } else {
      this.sidebarVisible = visible;
      this.sidebarVisibleChange.emit(this.sidebarVisible);
    }
  }

  async onSeparatePageVisibleChange(visible: boolean) {
    if (
      this.editObject &&
      !visible &&
      this.formComponent &&
      this.formComponent &&
      this.formComponent.form &&
      this.formComponent.form.dirty
    ) {
      await new Promise<boolean>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant('general.confirmUnsavedChanges.header'),
          this.translate.instant('general.confirmUnsavedChanges.message'),
          () => {
            this.editObject = null;
            this.separatePageVisible = visible;
            this.tableVisible = true;
            this.separatePageVisibleChange.emit(this.separatePageVisible);
            this.tableVisibleChange.emit(true);
          },
          () => {
            resolve(false);
          }
        );
      });
    } else {
      this.separatePageVisible = visible;
      this.separatePageVisibleChange.emit(this.separatePageVisible);
      this.tableVisibleChange.emit(true);
      this.tableVisible = true;
    }
  }

  onSubmit(): void {
    if (
      this.formComponent &&
      this.formComponent.form &&
      this.formComponent.form.valid
    ) {
      this.config.columns.forEach((col) => {
        if (col.formControl) {
          this.editObject[col.field] = this.formComponent?.form.get(
            col.field
          )?.value;
        }
      });
      this.formComponent.form.markAsUntouched();
      this.formComponent.form.markAsPristine();
      this.objectChanged.emit(this.editObject);
    }
  }

  getEditSubmitDisabled(): boolean {
    const form = this.formComponent?.form;
    if (form) {
      return !form.valid;
    }

    return true;
  }

  getCreateSubmitDisabled(): boolean {
    const form = this.formComponent?.form;
    if (form) {
      return !form || !form.valid;
    }

    return true;
  }

  displayInSidebar(): boolean {
    return (
      (this.editMethod === 'sidebar' || this.createMethod === 'sidebar') &&
      this.editObject &&
      this.sidebarVisible
    );
  }

  displayInSeparatePage(): boolean {
    return (
      (this.editMethod === 'page' || this.createMethod === 'page') &&
      this.editObject &&
      this.separatePageVisible
    );
  }

  onLazyLoadTableEventFired(event: TableLazyLoadEvent): void {
    this.lazyLoadTableEventFired.emit(event);
  }
}
