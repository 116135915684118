<p-sidebar
  [visible]="isVisible"
  position="right"
  styleClass="w-32rem"
  (visibleChange)="onSidebarVisibleChange($event)">
  <ng-template pTemplate="header">
    <div class="bg-primary">
      <h4 class="mb-0 ml-4 text-white">
        {{ ('general.form.createSubject') | translate: {subject:
        (config.translationKey + ".form.title" | translate)} }}
      </h4>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="pt-4 ml-4">
      <app-form
        #form
        [isInSeparatePage]="false"
        [config]="config"
        (formChanged)="formChanged()"
        [editObject]="editObject"></app-form>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div>
      <div class="flex flex-wrap gap-2 justify-content-between mt-2">
        <button
          pButton
          pRipple
          type="button"
          [label]="'general.form.cancel' | translate"
          (click)="onSidebarVisibleChange(false)"
          class="flex-auto p-button-outlined"></button>
        <button
          pButton
          pRipple
          [label]="'general.form.save' | translate"
          class="flex-auto"
          [disabled]="createSubmitDisabled"
          (click)="onSubmit()"></button>
      </div>
    </div>
  </ng-template>
</p-sidebar>
