<app-customer-table
  [@fadeAnimation]
  [customers]="customers"
  [countries]="countries"
  [isLoading]="isLoading"
  [showCreateAction]="$can('create')"
  (handleCreateCustomer)="handleCreateCustomer()"
  (handleDeleteCustomer)="handleDeleteCustomer($event)"
  (handleViewCustomer)="handleViewCustomer($event)"
  (handleNewCorrespondence)="handleNewCorrespondence($event)"
  (handleNewTicket)="handleNewTicket($event)"
  (handleUpdateCustomer)="handleUpdateCustomer($event)"
  (handleChangeIsActive)="handleChangeIsActive($event.customer, $event.value)"
  (handleChangeIsBlocked)="handleChangeIsBlocked($event.customer, $event.value)"
  (handleReloadCustomers)="handleReloadCustomers()" />

<!-- Create Sidebar -->
<p-sidebar
  [visible]="isSidebarVisible"
  position="right"
  styleClass="w-32rem scrollbar-gutter-stable"
  (visibleChange)="onSidebarVisibleChange($event)"
  (onHide)="onSidebarVisibleChange(false)">
  <!-- Header | Create Sidebar -->
  <ng-template pTemplate="header">
    <div class="bg-primary">
      <h4 class="mb-0 ml-4 text-white">
        {{ ( 'general.form.createSubject') | translate: {subject:
        ("customerComponent.title" | translate)} }}
      </h4>
    </div>
  </ng-template>
  <!-- Body | Create Sidebar -->
  <ng-template pTemplate="content">
    <div body class="pt-4 ml-4">
      <app-customer-create-form
        #createForm
        [customer]="customerToCreate"
        [countries]="countries"
        [customers]="customers" />
    </div>
  </ng-template>
  <!-- Footer | Create Sidebar -->
  <ng-template pTemplate="footer">
    <div class="flex flex-wrap gap-2 justify-content-between mt-2">
      <button
        pButton
        pRipple
        type="button"
        [label]="'general.form.cancel' | translate"
        (click)="onSidebarVisibleChange(false)"
        class="flex-auto p-button-outlined"></button>
      <button
        pButton
        pRipple
        [label]="'general.form.save' | translate"
        class="flex-auto"
        [disabled]=" createSubmitDisabled"
        (click)="onSubmit()"></button>
    </div>
  </ng-template>
</p-sidebar>

<app-customer-table-action-new-correspondence #tableActionNewCorrespondence />

<app-customer-table-action-new-ticket #tableActionNewTicket />
