type FilterFn<V = unknown, F = string> = (value: V, filter: F) => boolean;

/**
 * Creates a filter function based on the provided filter function.
 *
 * @template Value - The type of the value being filtered.
 * @param {FilterFn<Value>} fn - The filter function to be wrapped.
 * @returns {FilterFn<Value>} - The created filter function.
 */
export const filterFactory =
  <Value = unknown, Filter = string>(
    fn: FilterFn<Value, Filter>
  ): FilterFn<Value, Filter> =>
  // return filter function
  (value, filter) => {
    if (
      filter === undefined ||
      filter === null ||
      (typeof filter === 'string' && filter.trim() === '')
    ) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return fn(value, filter);
  };
