import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-cut-text',
  templateUrl: './cut-text.component.html',
  styleUrl: './cut-text.component.scss'
})
export class CutTextComponent implements AfterContentInit, OnChanges {
  @Input() text: string = '';

  @Input() cutTextClass: string = '';

  @Input() maxWidth: number = 250; // default max width

  @ViewChild('textElement') textElement: ElementRef | null = null;

  isTruncated: boolean = false;

  ngAfterContentInit() {
    this.checkTextWidth();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['text'] || changes['maxWidth']) {
      setTimeout(() => {
        this.checkTextWidth();
      }, 20); // wait till render is done
    }
  }

  checkTextWidth() {
    if (
      this.textElement &&
      this.textElement.nativeElement.scrollWidth >
        this.textElement.nativeElement.clientWidth
    ) {
      this.isTruncated = true;
    } else {
      this.isTruncated = false;
    }
  }
}
