import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReoccurringInterval } from 'src/app/models/ReoccurringInterval';
import { Ticket } from 'src/app/models/Ticket';

@Component({
  selector: 'app-ticket-reoccurring-modal',
  templateUrl: './ticket-reoccurring-modal.component.html',
  styleUrls: ['./ticket-reoccurring-modal.component.scss']
})
export class TicketReoccurringModalComponent implements OnInit, OnChanges {
  @Input() display: boolean = false;

  @Input({ required: true }) ticket!: Ticket;

  @Output() modalClosed = new EventEmitter<boolean>();

  @Output() executionDateChanged = new EventEmitter<Date>();

  loading = true;

  header = '';

  showOnFocus = false;

  newExecutionDate = new Date();

  constructor(private readonly translate: TranslateService) {}

  ngOnInit(): void {
    const title = this.translate.instant(
      'ticketComponent.reoccurringModal.title'
    );
    const interval = this.translate.instant(
      `ticketComponent.reoccurringModal.intervalOptions.${this.ticket.reoccurringInterval}`
    );

    this.header = `${title} - (${interval})`;
  }

  updateExecutionDate(): Date {
    if (!this.ticket.executionDate) {
      return new Date();
    }
    const executionDate = new Date(this.ticket.executionDate);

    switch (this.ticket.reoccurringInterval) {
      case ReoccurringInterval.Annually:
        executionDate.setFullYear(executionDate.getFullYear() + 1);
        break;
      case ReoccurringInterval.Biennially:
        executionDate.setFullYear(executionDate.getFullYear() + 2);
        break;
      case ReoccurringInterval.HalfYearly:
        executionDate.setMonth(executionDate.getMonth() + 6);
        break;
      default:
        break;
    }

    return executionDate;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['display']) {
      if (this.ticket.executionDate) {
        this.ticket.nextExecutionDate = this.updateExecutionDate();
      }
      // prevents the calender from getting auto focused
      setTimeout(() => {
        this.showOnFocus = this.display;
      }, 50);
    }
  }

  onCancel(): void {
    this.display = false;
    this.modalClosed.emit(false);
  }

  onSave(): void {
    this.display = false;
    this.modalClosed.emit(true);
  }

  onExecutionDateChanged(event: Date): void {
    this.executionDateChanged.emit(event);
  }
}
