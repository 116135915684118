import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormChangeDetectionService {
  formArray: FormArray = this.formBuilder.array([]);

  constructor(private formBuilder: FormBuilder) {}

  addFormToFormArray(form: FormGroup) {
    const index = this.findFormIndex(form);
    if (index === -1) {
      this.formArray.push(form);
    }
  }

  getFormArrayChanges(): Observable<FormGroup[]> {
    return this.formArray.valueChanges as Observable<FormGroup[]>;
  }

  findFormIndex(form: FormGroup): number {
    return this.formArray.controls.indexOf(form);
  }

  removeForm(form: FormGroup) {
    const index = this.findFormIndex(form);
    if (index >= 0) {
      this.formArray.removeAt(index);
    }
    if (this.formArray.controls.length === 0) {
      this.formArray.reset();
    }
  }
}
