import { ReoccurringInterval } from 'src/app/models/ReoccurringInterval';
import { TicketCategory, TicketStatus } from 'src/app/models/Ticket';

export const statusChips = [
  TicketStatus.Planning,
  TicketStatus.Open,
  TicketStatus.InProgress,
  TicketStatus.Paused,
  TicketStatus.Cancelled,
  TicketStatus.Done,
  TicketStatus.Goodwill,
  TicketStatus.Complaint,
  TicketStatus.Complained,
  TicketStatus.Settle,
  TicketStatus.Settled,
  TicketStatus.Completed
];

export const categoryChips = [
  TicketCategory.Commissioning,
  TicketCategory.Emergency,
  TicketCategory.Maintenance,
  TicketCategory.SGC,
  TicketCategory.Malfunction,
  TicketCategory.Task
];

export const reoccurring: string[] = [
  ReoccurringInterval.HalfYearly,
  ReoccurringInterval.Annually,
  ReoccurringInterval.Biennially,
  ReoccurringInterval.None
];
