<app-crud
  *ngIf="config"
  [@fadeAnimation]
  [config]="config"
  [objectsList]="filterList"
  [sidebarVisible]="sidebarVisible"
  [separatePageVisible]="separatePageVisible"
  [tableVisible]="tableVisible"
  [editMethod]="editMethod"
  [createMethod]="createMethod"
  [editObject]="editFilter"
  [totalRecords]="totalRecords"
  (sidebarVisibleChange)="sidebarVisibleChange($event)"
  (separatePageVisibleChange)="separatePageVisibleChange($event)"
  (tableVisibleChange)="tableVisibleChange($event)"
  (cellChanged)="onCellChanged($any($event))"
  (objectChanged)="filterChanged($event)"
  (selectionChanged)="onSelectionChanged($event)"
  (rowActionClicked)="rowActionClicked($event)"></app-crud>
