import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, first, Subscription, throwError } from 'rxjs';
import { TicketHeaderComponent } from 'src/app/components/admin/ticket/components/ticket-edit/ticket-header/ticket-header.component';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { Country } from 'src/app/models/Country';
import { Customer } from 'src/app/models/customer/Customer';
import { CustomerContactPerson } from 'src/app/models/customer/CustomerContactPerson';
import { ReoccurringInterval } from 'src/app/models/ReoccurringInterval';
import { Ticket, TicketCategory, TicketCreate } from 'src/app/models/Ticket';
import { User } from 'src/app/models/User';
import { CountryService } from 'src/app/services/api/country.service';
import { CustomerContactPersonService } from 'src/app/services/api/customer/customer-contact-person.service';
import { CustomerService } from 'src/app/services/api/customer/customer.service';
import { TicketService } from 'src/app/services/api/ticket.service';
import { UserService } from 'src/app/services/api/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Severity } from 'src/app/types/misc/Severity';
import { AppAction } from 'src/config/authorization.config';
import { TicketCreateFormComponent } from '../ticket-create-form/ticket-create-form.component';
import { generateSubject } from '../utils/createSubject';
import { TicketReoccurringModalComponent } from './ticket-reoccurring-modal/ticket-reoccurring-modal.component';
import { TicketSections } from 'src/app/models/TicketSections';
import { TicketInformationComponent } from './ticket-information/ticket-information.component';

const DEFAULT_STRING_VALIDATORS = [
  Validators.required,
  Validators.minLength(2),
  Validators.maxLength(255)
];

@Component({
  selector: 'app-ticket-edit',
  templateUrl: './ticket-edit.component.html',
  styleUrls: ['./ticket-edit.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class TicketEditComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @ViewChild('cardBody') cardBody!: ElementRef;

  @ViewChild('ticketHeader') ticketHeader?: TicketHeaderComponent;

  @ViewChild('ticketInformation')
  ticketInformation?: TicketInformationComponent;

  @ViewChild('ticketDetailSection') ticketDetailSection!: ElementRef;

  @ViewChild('ticketInformationSection') ticketInformationSection!: ElementRef;

  @ViewChild('ticketCustomerSection') ticketCustomerSection!: ElementRef;

  @ViewChild('ticketCustomerDevicesSection')
  ticketCustomerDevicesSection!: ElementRef;

  @ViewChild('ticketAttachmentsSection') ticketAttachmentsSection!: ElementRef;

  @ViewChild('ticketAppointmentsSection')
  ticketAppointmentsSection!: ElementRef;

  @ViewChild('ticketFormsSection') ticketFormsSection!: ElementRef;

  @ViewChild(TicketReoccurringModalComponent)
  reoccurringModal!: TicketReoccurringModalComponent;

  @HostListener('scroll', ['$event'])
  onScroll() {
    this.checkScroll();
  }

  formComponent?: TicketCreateFormComponent;

  users: User[] = [];

  customers: Customer[] = [];

  getCustomersIsLoading = true;

  customerContactPersons: CustomerContactPerson[] = [];

  countries: Country[] = [];

  form!: FormGroup;

  subscriptions = new Subscription();

  editTicket!: Ticket;

  editId = 0;

  selectedNavLink = 1;

  isLoading = true;

  showDevices = false;

  showAppointment = false;

  showForm = false;

  isReoccurringTicket = false;

  showReoccurringModal = false;

  selectedExecutionDate: Date | undefined;

  scrollTriggered = false;

  initialScrollTo?: TicketSections;

  constructor(
    private readonly ticketService: TicketService,
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    private readonly messageCenterService: MessageCenterService,
    private readonly customerService: CustomerService,
    private readonly countryService: CountryService,
    private readonly customerContactPersonService: CustomerContactPersonService,
    private location: Location,
    private readonly authService: AuthService
  ) {
    this.getUsers();
    this.getCustomers();
    this.getCountries();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.params.subscribe((params) => {
        this.editId = params['id'];
        const scrollToParam = params['scrollTo'];
        if (scrollToParam) {
          this.initialScrollTo = Number(scrollToParam);
        }
        if (this.editId && this.editId > 0) {
          this.ticketService
            .findById(this.editId)
            .pipe(
              catchError((error) => {
                console.error('Error fetching ticket:', error);
                this.router.navigate(['/tickets']);

                return throwError(() => error);
              }),
              first()
            )
            .subscribe((ticket) => {
              this.editTicket = ticket;
              this.ticketService.changeTicket(this.editTicket);
              if (
                this.editTicket.customerDevices &&
                this.editTicket.customerDevices.length > 0
              ) {
                this.showDevices = true;
              }
              if (
                this.editTicket.appointments &&
                this.editTicket.appointments.length > 0
              ) {
                this.showAppointment = true;
              }
              if (
                this.editTicket.id &&
                this.editTicket.forms &&
                this.editTicket.forms.length > 0
              ) {
                this.showForm = true;
              }
              if (
                this.editTicket.reoccurringInterval !== null &&
                this.editTicket.reoccurringInterval !== ReoccurringInterval.None
              ) {
                this.isReoccurringTicket = true;
              }

              const isTaskCategory =
                this.editTicket.ticketCategoryType === TicketCategory.Task;
              const hasCategoryType = Boolean(
                this.editTicket.ticketCategoryType
              );

              this.showForm = !hasCategoryType || !isTaskCategory;

              // make sure the execution date is a date object
              if (this.editTicket.executionDate) {
                this.editTicket.executionDate =
                  typeof this.editTicket.executionDate === 'string'
                    ? new Date(this.editTicket.executionDate)
                    : this.editTicket.executionDate;
              }

              this.generateForm();
            });
        } else {
          this.ticketService.currentTicket.subscribe((ticket) => {
            if (ticket) {
              this.editTicket = ticket as Ticket;
              this.generateForm();
            } else {
              this.editTicket = {} as Ticket;
              this.generateForm();
            }

            if (
              this.editTicket.customerDevices &&
              this.editTicket.customerDevices.length > 0
            ) {
              this.showDevices = true;
            }

            const isTaskCategory =
              this.editTicket.ticketCategoryType === TicketCategory.Task;
            const hasCategoryType = Boolean(this.editTicket.ticketCategoryType);

            this.showForm = !hasCategoryType || !isTaskCategory;
          });
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    if (this.initialScrollTo) {
      this.triggerScrollToSectionByNumber(this.initialScrollTo);
    }
  }

  /**
   * Triggers scrolling to a specific section by its number.
   * @param {number} section - The section number to scroll to.
   */
  triggerScrollToSectionByNumber(section: number): void {
    if (this.checkSectionsAreReady() && !this.scrollTriggered) {
      this.scrollTriggered = true;
      this.scrollToSection(
        this.getNativeElementForScrollByIndex(section),
        section
      );
    }
  }

  /**
   * Gets the native HTML element for scrolling based on the section index.
   * @param {number} index - The index of the section.
   * @returns {HTMLElement | null} - The native HTML element for the section, or null if the index is invalid.
   */
  getNativeElementForScrollByIndex(index: number): HTMLElement | null {
    switch (index) {
      case TicketSections.DetailSection:
        return this.ticketDetailSection.nativeElement;
      case TicketSections.InformationSection:
        return this.ticketInformationSection.nativeElement;
      case TicketSections.CustomerSection:
        return this.ticketCustomerSection.nativeElement;
      case TicketSections.CustomerDevicesSection:
        return this.ticketCustomerDevicesSection.nativeElement;
      case TicketSections.AttachmentsSection:
        return this.ticketAttachmentsSection.nativeElement;
      case TicketSections.AppointmentsSection:
        return this.ticketAppointmentsSection.nativeElement;
      case TicketSections.FormsSection:
        return this.ticketFormsSection.nativeElement;
      default:
        return null;
    }
  }

  /**
   * Checks if all sections are ready.
   * @returns {boolean} - True if all sections are ready, false otherwise.
   */
  checkSectionsAreReady(): boolean {
    return Boolean(
      this.ticketCustomerDevicesSection &&
        this.ticketFormsSection &&
        this.ticketDetailSection &&
        this.ticketInformationSection &&
        this.ticketCustomerSection &&
        this.ticketAttachmentsSection &&
        this.ticketAppointmentsSection
    );
  }

  /**
   * Triggers scrolling to the forms section.
   */
  triggerScrollToForms(): void {
    if (this.ticketFormsSection && !this.scrollTriggered) {
      this.scrollTriggered = true;
      const { nativeElement } = this.ticketFormsSection;
      this.scrollToSection(nativeElement, 7);
    }
  }

  formChanged(): void {
    this.form.markAsDirty();
    this.generateSubject();
  }

  generateSubject(): void {
    const category = this.editTicket.ticketCategoryType ?? null;
    const categoryTranslation = category
      ? this.translate.instant(`ticketComponent.ticketChips.${category}`)
      : '';
    const customer = this.editTicket.customer?.name ?? '';
    const devices = this.editTicket.customerDevices ?? [];
    const subject = generateSubject(categoryTranslation, customer, devices);

    this.editTicket.subject = subject;
    this.form.get('detailData.subject')?.setValue(subject);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  scrollToSection(el: HTMLElement | null, selectIndex: number) {
    if (!el) {
      return;
    }
    el.scrollIntoView({ behavior: 'smooth' });

    setTimeout(() => {
      this.selectedNavLink = selectIndex;
    }, 500);
  }

  checkScroll() {
    const container = this.cardBody.nativeElement;
    const elements = [
      this.ticketDetailSection.nativeElement,
      this.ticketInformationSection.nativeElement,
      this.ticketCustomerSection.nativeElement,
      this.ticketCustomerDevicesSection.nativeElement,
      this.ticketAttachmentsSection.nativeElement,
      this.ticketAppointmentsSection.nativeElement,
      this.ticketFormsSection.nativeElement
    ];

    const containerRect = container.getBoundingClientRect();
    const containerTop = containerRect.top;
    const containerBottom = containerRect.bottom;

    for (let index = 0; index < elements.length; index++) {
      const rect = elements[index].getBoundingClientRect();
      const elementTop = rect.top;
      const elementBottom = rect.bottom;

      if (
        (elementTop >= containerTop && elementTop <= containerBottom) ||
        (elementBottom >= containerTop && elementBottom <= containerBottom)
      ) {
        this.selectedNavLink = index + 1;
        break;
      }
    }
  }

  public buildTicket(): TicketCreate {
    return {
      subject: this.form?.get('detailData.subject')?.value,
      description: this.form?.get('detailData.description')?.value,
      requestReason: this.form?.get('detailData.requestReason')?.value,
      files: this.editTicket.files,
      ticketCategoryType: this.form?.get('ticketInformationData.ticketCategory')
        ?.value,
      ticketStatusType: this.form?.get('ticketInformationData.ticketStatus')
        ?.value,
      isAppointmentNeeded: this.editTicket.isAppointmentNeeded,
      customer: this.editTicket.customer,
      customerContactPerson: this.editTicket.customerContactPerson,
      customerDevices: this.editTicket.customerDevices,
      editor: this.form?.get('ticketInformationData.editor')?.value,
      customerDocumentIds: this.editTicket.customerDocumentIds,
      forms: this.editTicket.forms,
      reoccurringInterval: this.editTicket.reoccurringInterval,
      executionDate: this.editTicket.executionDate
    };
  }

  async save(): Promise<void> {
    if (Number(this.editId) === 0) {
      const ticket = this.buildTicket();
      this.ticketService
        .createIncludingFiles(ticket)
        .pipe(
          catchError((error) => {
            this.showCrudToast(DataModificationMethod.Create, 'error');

            return throwError(() => error);
          }),
          first()
        )
        .subscribe((newTicket) => {
          this.showCrudToast(
            DataModificationMethod.Create,
            'success',
            newTicket
          );

          this.router.navigate(['/tickets', newTicket.id]);
          this.form.markAsPristine();
          this.syncStatusChips();
        });
    } else {
      let save = true;
      const isReoccurring =
        this.editTicket.reoccurringInterval !== null &&
        this.editTicket.reoccurringInterval !== 'None';

      if (isReoccurring && this.editTicket.ticketStatusType === 'Settled') {
        save = await this.showReoccurringModalOnSave();
      }
      if (save) {
        if (this.selectedExecutionDate) {
          this.editTicket.nextExecutionDate = this.selectedExecutionDate;
        }
        let cancel = true;
        if (this.editTicket.ticketStatusType === 'Cancelled') {
          cancel = await this.cancelTicket(this.editTicket);
        }
        if (cancel) {
          this.ticketService
            .editIncludingFiles(this.editTicket.id, this.editTicket)
            .pipe(
              catchError((error) => {
                this.showCrudToast(DataModificationMethod.Edit, 'error');

                return throwError(() => error);
              }),
              first()
            )
            .subscribe((ticket) => {
              this.showCrudToast(
                DataModificationMethod.Edit,
                'success',
                ticket
              );
              this.ticketService.changeTicket(ticket);
              this.form.markAsPristine();
              this.router.navigate(['/tickets', ticket.id]);
              this.ticketHeader?.reloadLogging();
              this.syncStatusChips();
            });
        }
      }
    }
  }

  syncStatusChips(): void {
    if (this.ticketInformation) {
      this.ticketInformation.getPossibleStatus();
    }
  }

  async showReoccurringModalOnSave(): Promise<boolean> {
    this.showReoccurringModal = true;
    const save = await new Promise<boolean>((resolve) => {
      this.subscriptions.add(
        this.reoccurringModal.modalClosed.subscribe((closed: boolean) => {
          resolve(closed);
        })
      );
    });

    this.showReoccurringModal = false;

    return save;
  }

  executionDateChanged(date: Date): void {
    this.selectedExecutionDate = date;
  }

  cancel() {
    this.closeEditForm();
  }

  getUsers(): void {
    this.userService
      .findAll()
      .pipe(first())
      .subscribe((users) => {
        this.users = users;
      });
  }

  getCustomers(): void {
    this.customerService
      .findAll()
      .pipe(first())
      .subscribe((customers) => {
        this.customers = customers;
        this.getCustomersIsLoading = false;
      });
  }

  getCountries(): void {
    this.countryService
      .findAll()
      .pipe(first())
      .subscribe((countries) => {
        this.countries = countries;
      });
  }

  getCustomerContactPersons(customer: Customer): void {
    this.customerContactPersonService
      .findAll(customer.id)
      .pipe(first())
      .subscribe((customerContactPersons) => {
        this.customerContactPersons = customerContactPersons;

        if (!this.editTicket.customerContactPerson) {
          const mainContact = customerContactPersons.find(
            (person) => person.isMainContact
          );
          if (mainContact) {
            this.editTicket.customerContactPerson = mainContact;
          }
        }
      });
  }

  get isFormDirty(): boolean {
    return this.form?.dirty || false;
  }

  get submitDisabled(): boolean {
    if (this.form) {
      return !this.form || !this.form.valid || !this.form.dirty;
    }

    return true;
  }

  reoccurringInterval(interval: ReoccurringInterval): void {
    this.editTicket.reoccurringInterval = interval;
  }

  async cancelTicket(ticket: Ticket): Promise<boolean> {
    const cancel = await new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant('ticketComponent.cancelModal.header'),
        this.translate.instant('ticketComponent.cancelModal.message', {
          ticketNumber: ticket.ticketNumber
        }),
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
    });

    return cancel;
  }

  async closeEditForm(): Promise<void> {
    if (this.isFormDirty === false) {
      this.returnToOverview();

      return;
    }

    const close = await new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant('general.confirmUnsavedChanges.header'),
        this.translate.instant('general.confirmUnsavedChanges.message'),
        () => {
          resolve(false);
        },
        () => {
          resolve(true);
        }
      );
    });

    if (!close) {
      this.returnToOverview();
    }
  }

  returnToOverview(): void {
    this.location.back();
  }

  customerChanged(customer: Customer): void {
    this.editTicket = {
      ...this.editTicket,
      customer,
      customerDevices: []
    };
    this.form.get('customer')?.setValue(customer);
    this.getCustomerContactPersons(customer);
    this.generateSubject();
  }

  generateForm(): void {
    this.form = this.formBuilder.group({
      headerData: this.formBuilder.group({}),
      detailData: this.formBuilder.group({
        subject: new FormControl(this.editTicket.subject, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        description: new FormControl(this.editTicket.description),
        requestReason: new FormControl(this.editTicket.requestReason)
      }),
      ticketInformationData: this.formBuilder.group({
        ticketCategory: new FormControl(this.editTicket.ticketCategoryType, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        ticketStatus: new FormControl(this.editTicket.ticketStatusType),
        editor: new FormControl(this.editTicket.editor, {
          validators: Validators.required
        })
      }),
      customer: new FormControl(this.editTicket.customer),
      customerContactPerson: new FormControl(
        this.editTicket.customerContactPerson
      ),
      attachmentData: this.formBuilder.group({}),
      deviceData: this.formBuilder.group({}),
      appointmentData: this.formBuilder.group({}),
      formData: this.formBuilder.group({})
    });
    if (this.form) {
      this.form
        ?.get('ticketInformationData.ticketCategory')
        ?.valueChanges.subscribe((val) => {
          if (val !== TicketCategory.Task) {
            // for setting validations
            this.form?.get('customer')?.setValidators(Validators.required);
          }
          if (val === TicketCategory.Task) {
            // for clearing validations
            this.form?.get('customer')?.clearValidators();
          }
          this.form?.get('customer')?.updateValueAndValidity();
        });
    }
    if (this.editTicket.customer) {
      this.getCustomerContactPersons(this.editTicket.customer);
    }

    if (this.$can('update') === false) {
      this.form.disable();
    }

    this.isLoading = false;
  }

  public showCrudToast(
    method: DataModificationMethod | 'isActive' | 'isBlocked',
    severity: Severity,
    ticket?: Ticket
  ): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `ticketComponent.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `ticketComponent.actions.toasts.${method}.${severity}.detail`,
        {
          ticketNumber: ticket ? ticket.ticketNumber : ''
        }
      ),
      severity
    );
  }

  $can(action: AppAction) {
    return this.authService.$can(action, 'Ticket');
  }
}
