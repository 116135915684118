import { Validators } from '@angular/forms';
import { CustomerCorrespondenceType } from 'src/app/models/customer/CustomerCorrespondence';

import { GenericActionConfiguration } from 'src/app/models/GenericActionConfiguration';
import { GenericColumnConfiguration } from 'src/app/models/GenericColumnConfiguration';
import { GenericRowActionConfiguration } from 'src/app/models/GenericRowActionConfiguration';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';

export const customerCorrespondenceTableConfig = () =>
  new GenericTableConfiguration({
    permissionSubject: 'CustomerCorrespondence',
    table: 'correspondence-component',
    fileDownloadContext: 'customer-correspondences',
    showGridLines: false,
    showStripedRows: false,
    translationKey: 'customerCorrespondenceComponent',
    showPdfExportAction: false,
    showCsvExportAction: false,
    showExcelExportAction: false,
    lazyLoad: false,
    showAsCard: false,
    columns: [
      new GenericColumnConfiguration({
        type: 'enum',
        options: Object.values(CustomerCorrespondenceType),
        field: 'type',
        formControl: {
          validators: [Validators.required]
        },
        translatePath:
          'customerCorrespondenceComponent.table.columns.type.enum',
        frozenLeft: true,
        width: 2
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'title',
        formControl: {
          validators: [Validators.required]
        },
        frozenLeft: true
      }),
      new GenericColumnConfiguration({
        type: 'longText',
        field: 'description',
        formControl: {
          validators: []
        },
        cutOffWithTooltip: 350
      }),
      new GenericColumnConfiguration({
        type: 'count',
        field: 'count',
        isCreateField: false,
        isEditField: false,
        isFilterable: false,
        isSortable: false,
        referringKey: 'files',
        formControl: {
          validators: []
        },
        width: 2
      }),
      new GenericColumnConfiguration({
        type: 'files',
        field: 'files',
        fileUploadOptions: {
          iconsOnly: true,
          showUploadButton: false,
          showCancelButton: false
        },
        isTableField: false,
        isCreateField: true,
        isEditField: true,
        formControl: {
          validators: []
        }
      }),
      new GenericColumnConfiguration({
        type: 'user',
        field: 'createdBy',
        isCreateField: false,
        isEditField: false,
        isTableField: true,
        matchMode: 'user',
        appendGlobalFilterFields: ['createdBy.firstname', 'createdBy.lastname'],
        width: 2
      }),
      new GenericColumnConfiguration({
        type: 'date',
        field: 'createdAt',
        formControl: {
          validators: []
        },
        isTableField: true,
        isCreateField: false,
        isEditField: false,
        matchMode: 'date',
        width: 2
      })
    ],
    rowActionWidthRem: 5,
    rowActions: [
      new GenericRowActionConfiguration({
        icon: 'pi-pencil',
        tooltipTranslateKey: 'general.form.edit',
        identifier: 'edit',
        iconOnly: false,
        permissionAction: 'update',
        severity: 'success'
      }),
      new GenericRowActionConfiguration({
        icon: 'pi-trash',
        tooltipTranslateKey: 'general.form.delete',
        identifier: 'delete',
        permissionAction: 'delete',
        iconOnly: false,
        confirmRequired: true,
        severity: 'danger'
      })
    ],
    topRightAction: new GenericActionConfiguration({
      identifier: 'create',
      iconOnly: true,
      icon: 'pi-plus-circle',
      label: [
        'general.form.createSubject',
        'customerCorrespondenceComponent.form.title'
      ],
      permissionAction: 'create'
    })
  });
