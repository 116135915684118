// file-size.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  // Define constant for kilobyte
  private readonly KILOBYTE = 1024;

  transform(sizeInBytes: number): string {
    // If the size is 0, return '0 Bytes'
    if (sizeInBytes === 0) {
      return '0 Bytes';
    }

    // Define the units for file size
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    // Calculate the exponent for the size unit
    const exponent = Math.floor(
      Math.log(sizeInBytes) / Math.log(this.KILOBYTE)
    );

    // Calculate the size in the appropriate unit
    // eslint-disable-next-line prefer-exponentiation-operator
    const size = sizeInBytes / Math.pow(this.KILOBYTE, exponent);

    // Return the size with 2 decimal places and the appropriate unit
    return `${parseFloat(size.toFixed(2))} ${sizes[exponent]}`;
  }
}
