<p-dialog
  [draggable]="false"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [(visible)]="msgModalVisible"
  (onHide)="hide()"
  [modal]="true"
  [style]="{ width: '75%' }">
  <!-- Dialog Header -->
  <ng-template pTemplate="header">
    <div
      class="font-bold"
      *ngIf="msgData && file.mimetype !== 'message/rfc822' && (htmlData || plainData)">
      {{msgData.conversationTopic}}
    </div>
    <div
      class="font-bold"
      *ngIf="msgData && file.mimetype === 'message/rfc822' && (htmlData || plainData)">
      {{msgData.subject}}
    </div>
  </ng-template>

  <app-msg-viewer #msgViewer [file]="file" />
</p-dialog>
