/* eslint-disable no-void */

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FilterService, PrimeNGConfig } from 'primeng/api';
import { first, lastValueFrom, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LayoutService } from './layout/service/app.layout.service';
import { FormChangeDetectionService } from './services/form-change-detection.service';

import * as moment from 'moment';
import { CustomerService } from 'src/app/services/api/customer/customer.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { dateFilter } from 'src/app/utils/filter/date.filter';
import { userFilter } from 'src/app/utils/filter/user.filter';

import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'HiVE';

  private formArraySubscription: Subscription = new Subscription();

  private userSubscription: Subscription | null = null;

  private hasUnsavedChanges = false;

  constructor(
    private formChangeDetectionService: FormChangeDetectionService,
    private translate: TranslateService,
    private primengConfig: PrimeNGConfig,
    private layoutService: LayoutService,
    private authService: AuthService,
    private readonly customerService: CustomerService,
    private readonly filterService: FilterService
  ) {
    const SENTRY_ENABLED =
      environment.env === 'production' || environment.env === 'staging';

    Sentry.init({
      dsn: environment.sentryDsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      environment: environment.env,
      enabled: SENTRY_ENABLED
    });

    const { defaultLanguage } = environment;
    translate.setDefaultLang(defaultLanguage);

    let currentLanguage = localStorage.getItem('language');

    if (!currentLanguage) {
      currentLanguage = defaultLanguage;
      localStorage.setItem('language', defaultLanguage);
    }
    translate.use(currentLanguage);

    this.translate.onLangChange.subscribe((event) => {
      moment.locale(event.lang);
    });

    this.translate
      .get('primeng')
      .subscribe((res) => this.primengConfig.setTranslation(res));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const config = JSON.parse(localStorage.getItem('config')!);
    if (config) {
      this.layoutService.config = config;
    } else {
      this.layoutService.config = {
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'static',
        colorScheme: 'dim',
        theme: 'indigo',
        menuTheme: 'colorScheme',
        scale: 14
      };
    }
  }

  ngOnInit(): void {
    this.authService
      .init()
      .pipe(first())
      .subscribe((user) => {
        // if user is logged out do nothing
        if (user === null) {
          return;
        }

        // NOTE: this is a workaround and should be removed in the future (only the timeout)
        setTimeout(async () => {
          const isUserStillLoggedIn = await lastValueFrom(
            this.authService.isLoggedIn()
          );

          if (!isUserStillLoggedIn) {
            return;
          }

          // prefetch customers because they have a lot of data
          this.customerService
            .findAll()
            .pipe(first())
            .subscribe(
              (/* they need a subscription to be executed, otherwise*/) => {
                // they get cached in the service
              }
            );

          // timeout to prevent blocking other requests
        }, 4000);
      });

    this.formArraySubscription = this.formChangeDetectionService
      .getFormArrayChanges()
      .subscribe((changes: FormGroup[]) => {
        if (changes && changes.length > 0) {
          this.hasUnsavedChanges = true;
        } else {
          this.hasUnsavedChanges = false;
        }
      });

    this.filterService.register('user', userFilter);
    this.filterService.register('date', dateFilter);

    this.userSubscription = this.authService.user$.subscribe((user) => {
      if (user === null) {
        return;
      }

      this.translate.setDefaultLang(user.locale); // this also sets moment.js locale
      moment.tz.setDefault(user.timezone);
    });
  }

  ngOnDestroy() {
    this.formArraySubscription.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  @HostListener('window:close', ['$event'])
  handleWindowClose(event: Event) {
    if (this.hasUnsavedChanges) {
      event.preventDefault();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  handleWindowReload(event: Event) {
    if (environment.production === false) {
      return;
    }

    if (this.hasUnsavedChanges) {
      event.preventDefault();
    }
  }
}
