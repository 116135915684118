import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CustomerCorrespondence } from 'src/app/models/customer/CustomerCorrespondence';

import { GenericService } from 'src/app/services/api/generic.service';
import { MessageCenterService } from 'src/app/services/message-center.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerCorrespondenceService extends GenericService<CustomerCorrespondence> {
  constructor(
    http: HttpClient,
    messageCenterService: MessageCenterService,
    translate: TranslateService
  ) {
    super(http, messageCenterService, translate);
    this.setContext('customer-correspondences');
  }

  override lazyLoad(): never {
    throw new Error('Method not implemented.');
  }
}
