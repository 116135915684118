import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { filter, map, of, switchMap } from 'rxjs';

import { AuthService } from 'src/app/services/auth/auth.service';

export const loginGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.authInitialized$.pipe(
    filter((initialized) => initialized !== 'uninitialized'), // Wait until auth is initialized
    switchMap((initialized) =>
      initialized === 'error' ? of(null) : authService.isLoggedIn()
    ), // Check if the user is logged in
    map((isAuthenticated) => {
      if (isAuthenticated === null) {
        router.navigate(['/error']);

        return true;
      }

      if (isAuthenticated) {
        router.navigate(['/']);

        return false;
      }

      return true;
    })
  );
};
