import { Validators } from '@angular/forms';
import { GenericActionConfiguration } from 'src/app/models/GenericActionConfiguration';
import { GenericColumnConfiguration } from 'src/app/models/GenericColumnConfiguration';
import { GenericRowActionConfiguration } from 'src/app/models/GenericRowActionConfiguration';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { UserRole } from 'src/app/models/User';

// eslint-disable-next-line max-lines-per-function, require-jsdoc
function createUserManagementTableConfig(
  roles: UserRole[],
  defaultRole: UserRole
): GenericTableConfiguration {
  return new GenericTableConfiguration({
    permissionSubject: 'User',
    table: 'User',
    translationKey: 'userManagementComponent',
    showPdfExportAction: true,
    showCsvExportAction: true,
    showExcelExportAction: true,
    lazyLoad: true,
    columns: [
      new GenericColumnConfiguration({
        type: 'boolean',
        frozenLeft: true,
        field: 'hasPassword',
        isFilterable: true,
        isSortable: false,
        formSeparatePageIndex: 6,
        booleanStyle: 'icon',
        formControl: {},
        isCreateField: false,
        isEditField: false,
        width: 3
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'email',
        isFilterable: true,
        frozenLeft: true,
        formSeparatePageIndex: 4,
        formControl: {
          validators: [Validators.required, Validators.email]
        }
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'username',
        isFilterable: true,
        formSeparatePageIndex: 3,
        formControl: {}
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'firstname',
        formSeparatePageIndex: 1,
        isFilterable: true,
        isSortable: false,
        formControl: {
          validators: [Validators.required]
        }
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'lastname',
        isFilterable: true,
        formSeparatePageIndex: 2,
        formControl: {
          validators: [Validators.required]
        }
      }),

      new GenericColumnConfiguration({
        type: 'enum',
        field: 'role',
        options: roles,
        formSeparatePageIndex: 5,
        defaultValue: defaultRole,
        translatePath: 'userManagementComponent.table.columns.role.roles',
        formControl: {
          validators: [Validators.required]
        }
      }),
      new GenericColumnConfiguration({
        type: 'boolean',
        field: 'active',
        isFilterable: true,
        formSeparatePageIndex: 6,
        booleanStyle: 'switch',
        permissionAction: 'update',
        formControl: {},
        isCreateField: false
      }),
      new GenericColumnConfiguration({
        type: 'boolean',
        field: 'hasAppAccess',
        isFilterable: true,
        permissionAction: 'update',
        formSeparatePageIndex: 7,
        booleanStyle: 'switch',
        formControl: {},
        isCreateField: false
      })
    ],
    rowActions: [
      new GenericRowActionConfiguration({
        icon: 'pi-pencil',
        tooltipTranslateKey: 'userManagementComponent.actions.tooltips.edit',
        identifier: 'edit',
        permissionAction: 'update',
        iconOnly: false,
        severity: 'success'
      }),
      new GenericRowActionConfiguration({
        icon: 'pi-trash',
        tooltipTranslateKey: 'userManagementComponent.actions.tooltips.delete',
        identifier: 'delete',
        permissionAction: 'delete',
        iconOnly: false,

        severity: 'danger',
        confirmRequired: true
      }),
      new GenericRowActionConfiguration({
        icon: 'pi-send',
        tooltipTranslateKey: 'userManagementComponent.actions.tooltips.send',
        identifier: 'sendInvitation',
        iconOnly: false
      }),
      new GenericRowActionConfiguration({
        icon: 'pi-link',
        tooltipTranslateKey: 'userManagementComponent.actions.tooltips.link',
        identifier: 'copyLink',
        iconOnly: false
      })
    ],
    topRightAction: new GenericActionConfiguration({
      identifier: 'create',
      permissionAction: 'create',
      iconOnly: false,
      icon: 'pi-plus-circle',
      label: [
        'general.form.createSubject',
        'userManagementComponent.form.title'
      ]
    })
  });
}

export { createUserManagementTableConfig };
