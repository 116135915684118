import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { FilterMetadata } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription, first } from 'rxjs';
import { FilterComponent } from 'src/app/components/misc/generic-table/filter/filter.component';
import { FileDto } from 'src/app/models/FileDto';
import { TableFilter } from 'src/app/models/TableFilter';
import { Customer } from 'src/app/models/customer/Customer';

import {
  CommissionType,
  CustomerDevice
} from 'src/app/models/customer/CustomerDevice';
import { DeviceService } from 'src/app/services/api/device.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AppAction } from 'src/config/authorization.config';
import { environment } from 'src/environments/environment';

type Option = {
  value: string;
  label: string;
};

type VirtualTableCustomerDevice = Omit<
  CustomerDevice,
  'isGasSeparatorInstalled'
> & {
  isGasSeparatorInstalled: boolean | null;

  virtual: {
    manufacturer: string;
    device: string;
    serialNumbers: string;
    commissionDate: string;
    commissionType: string;
    heatingEngineer: string;
    controlUnit: string;
    isGasSeparatorInstalled: string;
    heatingEngineerIsBlocked: boolean;
  };
};

@Component({
  selector: 'app-customer-device-table',
  templateUrl: './customer-device-table.component.html',
  styleUrls: ['./customer-device-table.component.scss']
})
export class CustomerDeviceTableComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild('tableElement') public tableElement?: Table;

  @ViewChild('appFilter') appFilter?: FilterComponent;

  @Input({ required: true }) customer!: Customer;

  @Input({ required: true }) customerDevices: CustomerDevice[] = [];

  @Input() showEditIcon = true;

  @Input() showCreateButton = true;

  @Input() showDeleteIcon = true;

  @Input() selectionEnabled = false;

  @Input() selectedDevices: CustomerDevice[] = [];

  @Input() isTicketView = false;

  @Output() selectedDevicesChange = new EventEmitter<CustomerDevice[]>();

  @Output() handleCreateCustomerDevice = new EventEmitter<void>();

  @Output() handleUpdateCustomerDevice = new EventEmitter<CustomerDevice>();

  @Output() handleViewCustomerDevice = new EventEmitter<CustomerDevice>();

  @Output() handleDeleteCustomerDevice = new EventEmitter<CustomerDevice>();

  @Output() handleChangeIsActive = new EventEmitter<{
    value: boolean;
    customerDevice: CustomerDevice;
  }>();

  @Output() changeCustomerDevices = new EventEmitter<CustomerDevice[]>();

  virtualCustomerDevices: VirtualTableCustomerDevice[] = [];

  tableConfig = environment.tableConfiguration;

  displayFilterOverlay = false;

  isFilterApplied = false;

  activeFilter: number | null = null;

  filterSubscription: Subscription | null = null;

  commissionTypeOptions: Option[] = [];

  globalSearchValue = '';

  constructor(
    private readonly translate: TranslateService,
    private readonly authService: AuthService,
    private readonly deviceService: DeviceService
  ) {
    this.commissionTypeOptions = Object.values(CommissionType).map((x) => ({
      value: x,
      label: translate.instant(
        `customerDeviceComponent.table.columns.commissionType.enum.${x}`
      )
    }));
  }

  ngOnInit(): void {
    this.updateVirtualCustomerDevices();
    const localFilters = localStorage.getItem('customer-device-table-memory');
    if (localFilters) {
      const { filters } = JSON.parse(localFilters);

      if (filters?.global) {
        this.globalSearchValue = filters.global.value;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.tableElement) {
      this.filterSubscription = this.tableElement.onFilter.subscribe(
        (event) => {
          if (!event.filters) {
            this.isFilterApplied = false;

            return;
          }

          this.isFilterApplied = Object.keys(event.filters).some((x) => {
            if (x === 'global') {
              return false;
            }

            const filter = this.tableElement?.filters[x] as FilterMetadata;

            return (
              filter.value !== null &&
              filter.value !== undefined &&
              filter.value !== ''
            );
          });
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerDevices'] && changes['customerDevices'].currentValue) {
      this.updateVirtualCustomerDevices();
    }
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'CustomerDevice');
  }

  filterApplied(filter: TableFilter): void {
    this.activeFilter = filter.id;

    if (this.tableElement) {
      this.tableElement.filters = structuredClone(
        filter.filterData
      ) as Table['filters'];
      this.updateVirtualCustomerDevices();
      this.tableElement._filter();
    }
  }

  clearFilters(): void {
    if (this.tableElement) {
      this.tableElement.clearFilterValues();
      this.tableElement.reset();
      localStorage.removeItem('customer-device-table-memory');
    }

    this.activeFilter = null;
  }

  saveFilterClicked(filterName: string): void {
    if (!this.tableElement) {
      throw new Error('Table element not found');
    }

    const tableFilter = new TableFilter();

    tableFilter.filterData = structuredClone(this.tableElement?.filters);
    tableFilter.table = 'customer-device';
    tableFilter.filterName = filterName;

    this.appFilter?.createFilter(tableFilter);
  }

  updateVirtualCustomerDevices(): void {
    this.virtualCustomerDevices = this.customerDevices.map((customerDevice) => {
      const commissionDate = customerDevice.commissionDate
        ? moment(customerDevice.commissionDate).format('DD.MM.YYYY')
        : '';

      let isGasSeparatorInstalled: boolean | null = null;
      let hasGasSeparatorInstalledTranslation = '';

      if (customerDevice.device?.hasGasSeparatorCheck === true) {
        if (customerDevice.isGasSeparatorInstalled === true) {
          isGasSeparatorInstalled = true;
        } else {
          isGasSeparatorInstalled = false;
        }

        hasGasSeparatorInstalledTranslation = this.translate.instant(
          `general.${isGasSeparatorInstalled ? 'yes' : 'no'}`
        );
      }

      return {
        ...customerDevice,
        isGasSeparatorInstalled,

        virtual: {
          manufacturer: customerDevice.device?.manufacturer?.name ?? '-',
          device: customerDevice.device?.title || '<Unknown>',
          commissionType: this.translate.instant(
            `customerDeviceComponent.table.columns.commissionType.enum.${customerDevice.commissionType}`
          ),

          isGasSeparatorInstalled: hasGasSeparatorInstalledTranslation,

          commissionDate,
          controlUnit: customerDevice.controlUnit?.name ?? '-',
          heatingEngineer: customerDevice.heatingEngineer?.name || '<Unknown>',
          heatingEngineerIsBlocked:
            customerDevice.heatingEngineer?.isBlocked || false,
          serialNumbers: [
            customerDevice.internalDeviceSerialNumber,
            customerDevice.externalDeviceSerialNumber
          ]
            .filter(Boolean)
            .join('; ')
        }
      } satisfies VirtualTableCustomerDevice;
    });
  }

  handleDeviceUpload(files: File[], customerDevice: CustomerDevice): void {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });

    this.deviceService
      .uploadFiles(customerDevice.id, formData)
      .pipe(first())
      .subscribe((updatedDevice) => {
        const updatedArray = structuredClone(this.customerDevices);

        const index = updatedArray.findIndex((x) => x.id === updatedDevice.id);

        updatedArray[index].device = updatedDevice;

        this.changeCustomerDevices.emit(updatedArray);
      });
  }

  handleDeviceRemove(file: FileDto, customerDevice: CustomerDevice): void {
    this.deviceService
      .deleteFile(customerDevice.id, file.id)
      .pipe(first())
      .subscribe((updatedDevice) => {
        const updatedArray = structuredClone(this.customerDevices);

        const index = updatedArray.findIndex((x) => x.id === updatedDevice.id);

        updatedArray[index].device = updatedDevice;

        this.changeCustomerDevices.emit(updatedArray);
      });
  }

  createCustomerDevice() {
    this.handleCreateCustomerDevice.emit();
  }

  updateCustomerDevice(customerDevice: CustomerDevice) {
    this.handleUpdateCustomerDevice.emit(customerDevice);
  }

  viewCustomerDevice(customerDevice: CustomerDevice) {
    this.handleViewCustomerDevice.emit(customerDevice);
  }

  deleteCustomerDevice(customerDevice: CustomerDevice) {
    this.handleDeleteCustomerDevice.emit(customerDevice);
  }

  changeIsActive(value: boolean, customerDevice: CustomerDevice) {
    this.handleChangeIsActive.emit({ value, customerDevice });
  }
}
