import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FileDto } from 'src/app/models/FileDto';

@Pipe({
  name: 'fileFilter'
})
export class FileFilterPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(files: FileDto[], searchTerm: string): FileDto[] {
    if (!files || !searchTerm) {
      return files;
    }

    const lowerSearchTerm = searchTerm.toLowerCase();

    return files.filter((file) => {
      const createdAt = this.datePipe.transform(
        file.createdAt,
        'dd.MM.yyyy HH:mm:ss'
      );

      if (file.createdBy) {
        return (
          file.originalname.toLowerCase().includes(lowerSearchTerm) ||
          (createdAt && createdAt.includes(lowerSearchTerm)) ||
          (file.createdBy.firstname &&
            file.createdBy.firstname.toLowerCase().includes(lowerSearchTerm)) ||
          (file.createdBy.lastname &&
            file.createdBy.lastname.toLowerCase().includes(lowerSearchTerm))
        );
      }

      return (
        file.originalname.toLowerCase().includes(lowerSearchTerm) ||
        (createdAt && createdAt.includes(lowerSearchTerm))
      );
    });
  }
}
