import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GenericColumnConfiguration } from 'src/app/models/GenericColumnConfiguration';

@Component({
  selector: 'app-generic-column-header',
  templateUrl: './generic-column-header.component.html',
  styleUrls: ['./generic-column-header.component.scss']
})
export class GenericColumnHeaderComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() column!: GenericColumnConfiguration;

  @Input() translationKey!: string;

  constructor(private translate: TranslateService) {}
}
