/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppSubject } from 'src/config/authorization.config';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
  model: MenuItem[] = [];

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.generateMenu();
  }

  public generateMenu(): void {
    const $can = (subject: AppSubject) =>
      this.authService.$can('read', subject);

    const menu: MenuItem[] = [];

    menu.push({
      label: 'menu.dashboards.title',
      icon: 'pi pi-home',
      items: [
        {
          label: 'menu.dashboards.children.dashboard',
          icon: 'pi pi-fw pi-chart-bar',
          routerLink: ['/dashboard']
        }
      ]
    });

    const management = {
      label: 'menu.management.title',
      icon: 'pi pi-home',
      items: [] as MenuItem[]
    } as const;

    if ($can('Customer')) {
      management.items.push({
        label: 'menu.management.children.customers',
        icon: 'pi pi-fw pi-building',
        routerLink: ['/customers'],
        routerLinkActiveOptions: {
          paths: 'subset',
          queryParams: 'ignored',
          matrixParams: 'ignored',
          fragment: 'ignored'
        }
      });
    }

    if ($can('HeatingEngineer')) {
      management.items.push({
        label: 'menu.management.children.heatingEngineers',
        icon: 'pi pi-fw pi-wrench',
        routerLink: ['/heating-engineers']
      });
    }

    if ($can('Device')) {
      management.items.push({
        label: 'menu.management.children.devices',
        icon: 'pi pi-fw pi-shopping-cart',
        routerLink: ['/devices']
      });
    }

    if ($can('User')) {
      management.items.push({
        label: 'menu.management.children.userManagement.children.user',
        icon: 'pi pi-fw pi-user',
        routerLink: ['/users']
      });
    }

    menu.push(management);

    const assignments = {
      label: 'menu.assignments.title',
      icon: 'pi pi-home',
      items: [] as MenuItem[]
    } as const;

    menu.push(assignments);

    assignments.items.push({
      label: 'menu.assignments.children.tickets',
      icon: 'pi pi-fw pi-ticket',
      routerLink: ['/tickets']
    });

    assignments.items.push({
      label: 'menu.assignments.children.calendar',
      icon: 'pi pi-fw pi-calendar',
      routerLink: ['/calendar']
    });

    assignments.items.push({
      label: 'menu.assignments.children.myDay',
      icon: 'pi pi-fw pi-clock',
      routerLink: ['/my-day']
    });

    this.model = menu;
  }
}
