<p-sidebar
  [visible]="sidebarVisible"
  position="right"
  styleClass="w-32rem"
  (visibleChange)="onVisibleChange($event)"
  (onHide)="onVisibleChange($event)">
  <ng-template pTemplate="header"
    ><ng-content select="[header]"></ng-content
  ></ng-template>
  <ng-template pTemplate="content"
    ><ng-content select="[body]"></ng-content
  ></ng-template>
  <ng-template pTemplate="footer"
    ><ng-content select="[footer]"></ng-content
  ></ng-template>
</p-sidebar>
