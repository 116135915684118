<div class="ticket-summary-container" [ngClass]="colorScheme">
  <div
    class="ticket-summary-header"
    [style]="{backgroundColor: backgroundColor, color: '#fff'}">
    <span [class]="'pi '+icon"></span
    ><span class="ml-2"
      >{{'ticketComponent.ticketChips.'+ticket.ticketCategoryType |
      translate}}</span
    >
  </div>
  <div class="ticket-summary-body">
    <div class="ticket-number text-sm">
      <span>{{ ticket.ticketNumber }}</span>
    </div>
    <div class="font-bold cut-text pb-1">{{ ticket.subject }}</div>
    <div class="ticket-description pt-2 pb-2">{{ticket.description}}</div>
    <div *ngIf="ticket.customer" class="customer-container">
      <div>
        <span class="text-primary"> {{ ticket.customer.name}} </span>
      </div>
      <div>
        <span> {{ ticket.customer.customerNumber}} </span>
      </div>
    </div>
  </div>
</div>
