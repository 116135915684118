<label [htmlFor]="'filtername'">
  {{ "filterComponent.table.columns.filterName.title" | translate}}
</label>
<div class="pt-2 pb-4">
  <input
    type="text"
    pInputText
    class="w-full"
    [id]="'filtername'"
    [(ngModel)]="filterName" />
</div>

<button
  pButton
  pRipple
  [label]="'general.form.save' | translate"
  class="flex-auto"
  [disabled]="filterName.length === 0"
  (click)="onSubmit()"></button>
