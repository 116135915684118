import { FilterMetadata } from 'primeng/api';
import { User } from './User';

export class TableFilter {
  id!: number;

  filterData!: FilterMetadata;

  table!: string;

  filterName!: string;

  createdAt!: Date;

  createdBy!: User;
}
