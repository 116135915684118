import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Ticket } from 'src/app/models/Ticket';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit {
  @Input() formGroupName!: string;

  @Input() ticket!: Ticket;

  @Output() ticketChange = new EventEmitter<Ticket>();

  form!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  onTicketChanged(): void {
    this.ticket.subject = this.form.get('subject')?.value;
    this.ticket.description = this.form.get('description')?.value;
    this.ticket.requestReason = this.form.get('requestReason')?.value;
    this.ticketChange.emit(this.ticket);
  }
}
