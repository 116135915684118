import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ControlUnit } from 'src/app/models/ControlUnit';
import { Device } from 'src/app/models/Device';
import { DeviceManufacturer } from 'src/app/models/DeviceManufacturer';
import { HeatingEngineer } from 'src/app/models/HeatingEngineer';
import {
  CommissionType,
  CustomerDeviceCreate,
  CustomerDeviceUpdate,
  CustomerEditInterface,
  DuplicateSerialNumberInfoDuplicate
} from 'src/app/models/customer/CustomerDevice';
import { DeviceManufacturerService } from 'src/app/services/api/device-manufacturer.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormChangeDetectionService } from 'src/app/services/form-change-detection.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { AppAction } from 'src/config/authorization.config';

type Option<T = string> = {
  value: T;
  label: string;
};

const DEFAULT_DEVICE_MANUFACTURER = 'IDM';
const DEFAULT_CONTROL_UNIT = 'Navi 2.0';

@Component({
  selector: 'app-customer-device-form',
  templateUrl: './customer-device-form.component.html',
  styleUrls: ['./customer-device-form.component.scss']
})
export class CustomerDeviceFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input({ required: true }) customerDevice!: CustomerEditInterface;

  @Input({ required: true }) deviceManufacturers: DeviceManufacturer[] = [];

  @Input({ required: true }) devices: Device[] = [];

  @Input({ required: true }) heatingEngineers: HeatingEngineer[] = [];

  @Output() formChanged = new EventEmitter<void>();

  formGroup: FormGroup | null = null;

  deviceOptions: Device[] = [];

  heatingEngineerOptions: HeatingEngineer[] = [];

  formSubscription: Subscription | null = null;

  selectedDeviceManufacturer: DeviceManufacturer | null = null;

  controlUnitOptions: ControlUnit[] | null = null;

  commissionTypeOptions: Option[] = [];

  gasSeparatorInstalledOptions: Option<boolean>[] = [];

  selectedDevice: Device | null = null;

  constructor(
    private readonly authService: AuthService,
    private readonly formChangeDetectionService: FormChangeDetectionService,
    public readonly translate: TranslateService,
    public readonly messageCenterService: MessageCenterService,
    public readonly deviceManufacturerService: DeviceManufacturerService
  ) {
    this.commissionTypeOptions = Object.values(CommissionType).map((x) => ({
      value: x,
      label: translate.instant(
        `customerDeviceComponent.table.columns.commissionType.enum.${x}`
      )
    }));

    this.gasSeparatorInstalledOptions = [
      {
        value: true,
        label: translate.instant('general.yes')
      },
      {
        value: false,
        label: translate.instant('general.no')
      }
    ];
  }

  ngOnInit(): void {
    this.selectedDevice =
      this.devices.find(
        (device) => device.id === this.customerDevice.deviceId
      ) ?? null;

    this.formGroup = new FormGroup({
      deviceId: new FormControl(this.customerDevice.deviceId, {
        validators: [Validators.required]
      }),

      heatingEngineerId: new FormControl(
        this.customerDevice.heatingEngineerId,
        {
          validators: [Validators.required]
        }
      ),

      isDeliveredByNothaft: new FormControl(
        this.customerDevice.isDeliveredByNothaft,
        {
          validators: []
        }
      ),

      commissionType: new FormControl(this.customerDevice.commissionType, {
        validators: [Validators.required]
      }),

      commissionDate: new FormControl(
        this.customerDevice.commissionDate
          ? new Date(this.customerDevice.commissionDate)
          : undefined,
        {
          validators: []
        }
      ),

      isActive: new FormControl(this.customerDevice.isActive, {
        validators: []
      }),

      note: new FormControl(this.customerDevice.note, {
        validators: []
      }),

      controlUnitId: new FormControl(this.customerDevice.controlUnitId),

      internalDeviceSerialNumber: new FormControl(
        this.customerDevice.internalDeviceSerialNumber,
        {
          validators: []
        }
      ),

      externalDeviceSerialNumber: new FormControl(
        this.customerDevice.externalDeviceSerialNumber,
        {
          validators: []
        }
      ),

      isGasSeparatorInstalled: new FormControl(
        this.customerDevice.isGasSeparatorInstalled,
        {
          validators: []
        }
      )
    });

    if (this.$can('update') === false) {
      this.formGroup.disable();
    }

    this.formChangeDetectionService.addFormToFormArray(this.formGroup);

    this.formSubscription = this.formGroup.valueChanges.subscribe((changes) => {
      if (changes.deviceId) {
        this.selectedDevice =
          this.devices.find((device) => device.id === changes.deviceId) ?? null;

        const value = this.formGroup?.get('isGasSeparatorInstalled')?.value;

        if (!this.selectedDevice && value !== false) {
          this.formGroup?.get('isGasSeparatorInstalled')?.setValue(false);
        }

        if (
          this.selectedDevice?.hasGasSeparatorCheck !== true &&
          value !== false
        ) {
          this.formGroup?.get('isGasSeparatorInstalled')?.setValue(false);
        }
      }

      if (changes) {
        this.formChanged.emit();
      }
    });

    const preSelectedManufacturer = this.customerDevice.device?.manufacturer;

    if (preSelectedManufacturer) {
      this.selectedDeviceManufacturer = preSelectedManufacturer;
    } else {
      this.selectedDeviceManufacturer =
        this.deviceManufacturers.find(
          (deviceManufacturer) =>
            deviceManufacturer.name === DEFAULT_DEVICE_MANUFACTURER
        ) ?? null;
    }

    if (this.selectedDeviceManufacturer) {
      this.updateControlUnitField(this.selectedDeviceManufacturer);
    } else {
      this.formGroup.get('deviceId')?.disable();
    }

    this.setDeviceOptions(this.devices);
    this.setHeatingEngineerOptions(this.heatingEngineers);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['devices']) {
      this.setDeviceOptions(this.devices);
    }

    if (changes['customerDevice']) {
      this.formGroup?.patchValue(this.customerDevice);
    }

    if (changes['heatingEngineers']) {
      this.setHeatingEngineerOptions(this.heatingEngineers);
    }
  }

  ngOnDestroy(): void {
    if (this.formGroup) {
      this.formChangeDetectionService.removeForm(this.formGroup);
    }

    this.formSubscription?.unsubscribe();
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'CustomerDevice');
  }

  setHeatingEngineerOptions(heatingEngineers: HeatingEngineer[]): void {
    const options = heatingEngineers.filter(
      (heatingEngineer) => heatingEngineer.isActive
    );

    // if the currently selected heating engineer is inactive, add it to the options
    if (this.customerDevice.heatingEngineerId) {
      const index = options.findIndex(
        (heatingEngineer) =>
          heatingEngineer.id === this.customerDevice.heatingEngineerId
      );

      // if selected heating engineer is not in the list of active heating engineers
      if (index === -1) {
        const inactiveCurrentlySelectedHeatingEngineer = heatingEngineers.find(
          (heatingEngineer) =>
            heatingEngineer.id === this.customerDevice.heatingEngineerId
        );

        if (inactiveCurrentlySelectedHeatingEngineer) {
          options.unshift(inactiveCurrentlySelectedHeatingEngineer);
        }
      }
    }

    this.heatingEngineerOptions = options;
  }

  setDeviceOptions(devices: Device[]): void {
    // add all active devices to the options
    const options = devices
      .filter((device) => device.status)
      .filter((x) => x.manufacturerId === this.selectedDeviceManufacturer?.id);

    const deviceId = this.formGroup?.get('deviceId')?.value;

    // if the currently selected device is inactive, add it to the options
    if (deviceId) {
      const index = options.findIndex((device) => device.id === deviceId);

      // if selected device is not in the list of active devices
      if (index === -1) {
        const inactiveCurrentlySelectedDevice = devices.find(
          (device) => device.id === deviceId
        );

        if (inactiveCurrentlySelectedDevice) {
          options.unshift(inactiveCurrentlySelectedDevice);
        }
      }
    }

    this.deviceOptions = options;
  }

  public buildCustomerDevice():
    | CustomerDeviceCreate
    | CustomerDeviceUpdate
    | null {
    return {
      customerId: this.customerDevice.customerId,

      deviceId: this.formGroup?.get('deviceId')?.value,
      heatingEngineerId: this.formGroup?.get('heatingEngineerId')?.value,
      commissionType: this.formGroup?.get('commissionType')?.value,
      isDeliveredByNothaft: this.formGroup?.get('isDeliveredByNothaft')?.value,
      controlUnitId: this.formGroup?.get('controlUnitId')?.value,
      commissionDate: this.formGroup?.get('commissionDate')?.value,
      isActive: this.formGroup?.get('isActive')?.value,
      note: this.formGroup?.get('note')?.value,
      isGasSeparatorInstalled: this.formGroup?.get('isGasSeparatorInstalled')
        ?.value,

      internalDeviceSerialNumber:
        this.formGroup?.get('internalDeviceSerialNumber')?.value?.trim() ||
        null,

      externalDeviceSerialNumber:
        this.formGroup?.get('externalDeviceSerialNumber')?.value?.trim() || null
    };
  }

  onChangeDeviceManufacturer(
    deviceManufacturer?: DeviceManufacturer | null | undefined
  ): void {
    this.formGroup?.get('deviceId')?.reset();

    if (deviceManufacturer) {
      this.formGroup?.get('deviceId')?.enable();
    } else {
      this.formGroup?.get('deviceId')?.disable();
    }

    this.setDeviceOptions(this.devices);

    this.updateControlUnitField(deviceManufacturer);
  }

  updateControlUnitField(
    deviceManufacturer: DeviceManufacturer | null | undefined
  ): void {
    if (deviceManufacturer) {
      this.deviceManufacturerService
        .findAllControlUnits(deviceManufacturer.id)
        .subscribe((controlUnits) => {
          if (controlUnits.length === 0) {
            this.formGroup?.get('controlUnitId')?.clearValidators();
            this.formGroup?.get('controlUnitId')?.setValue(null);
            this.controlUnitOptions = null;

            return;
          }

          this.formGroup
            ?.get('controlUnitId')
            ?.setValidators([Validators.required]);

          if (controlUnits.length === 1) {
            this.formGroup?.get('controlUnitId')?.setValue(controlUnits[0].id);
          } else {
            const defaultControlUnit = controlUnits.find(
              (controlUnit) => controlUnit.name === DEFAULT_CONTROL_UNIT
            );

            if (defaultControlUnit) {
              this.formGroup
                ?.get('controlUnitId')
                ?.setValue(defaultControlUnit.id);
            }
          }

          this.controlUnitOptions = controlUnits;
        });
    } else {
      this.formGroup?.get('controlUnitId')?.clearValidators();
      this.formGroup?.get('controlUnitId')?.setValue(null);
      this.controlUnitOptions = null;
    }
  }

  public getCurrentManufacturerId() {
    return this.selectedDeviceManufacturer?.id;
  }

  public alertDuplicateSerialNumber(
    serialNumber: string,
    info: DuplicateSerialNumberInfoDuplicate,
    type: 'internal' | 'external'
  ): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        'customerDeviceComponent.duplicateSerialNumber.single.header'
      ),
      this.translate.instant(
        'customerDeviceComponent.duplicateSerialNumber.single.message',
        {
          serialNumber,
          customer: `${info.customer.name} (${info.customer.customerNumber})`
        }
      ),
      'error'
    );

    this.formGroup
      ?.get(
        type === 'internal'
          ? 'internalDeviceSerialNumber'
          : 'externalDeviceSerialNumber'
      )
      ?.setErrors({
        error: true
      });
  }

  public alertBothDuplicateSerialNumber(
    info: DuplicateSerialNumberInfoDuplicate,
    internal: string,
    external: string
  ): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        'customerDeviceComponent.duplicateSerialNumber.both.header'
      ),
      this.translate.instant(
        'customerDeviceComponent.duplicateSerialNumber.both.message',
        {
          internal,
          external,
          customer: `${info.customer.name} (${info.customer.customerNumber})`
        }
      ),
      'error'
    );

    this.formGroup?.get('internalDeviceSerialNumber')?.setErrors({
      error: true
    });

    this.formGroup?.get('externalDeviceSerialNumber')?.setErrors({
      error: true
    });
  }

  public alertInternalSerialNumberIsExternalSerialNumber(): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        'customerDeviceComponent.duplicateSerialNumber.internalIsExternal.header'
      ),
      this.translate.instant(
        'customerDeviceComponent.duplicateSerialNumber.internalIsExternal.message'
      ),
      'error'
    );

    this.formGroup?.get('internalDeviceSerialNumber')?.setErrors({
      error: true
    });

    this.formGroup?.get('externalDeviceSerialNumber')?.setErrors({
      error: true
    });
  }
}
