<div role="button" *ngIf="loggingView" class="logging-view-preview-container">
  <div class="logging-view-preview">
    <div class="logging-view-preview-row">
      <span>
        {{ 'logging.createdAtDate' | translate:{date: loggingView.createdAt |
        date: 'dd.MM.yyyy HH:mm'} }}
      </span>
      <span>|</span>
      <span>
        {{ 'logging.byUser' | translate:{user: loggingView.createdByCredentials
        ?? 'logging.unknown' | translate} }}
      </span>
    </div>

    <div class="logging-view-preview-row">
      <span>
        {{ 'logging.updateAtDate' | translate:{date: loggingView.updatedAt |
        date: 'dd.MM.yyyy HH:mm'} }}
      </span>
      <span>|</span>
      <span>
        {{ 'logging.byUser' | translate:{user: loggingView.updatedByCredentials
        ?? 'logging.unknown' | translate} }}
      </span>
    </div>
  </div>

  <i
    class="pi pi-history text-xl"
    pBadge
    [value]="loggingView.loggingEntries.length.toString()"
    (mousedown)="isVisible = true"></i>
</div>

<div *ngIf="!loggingView" class="logging-loading-container">
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</div>

<p-dialog
  header="Historie - {{ loggingView?.entityContext }}"
  [modal]="true"
  [(visible)]="isVisible"
  [draggable]="false"
  [dismissableMask]="true"
  [style]="{ width: '90vw', maxWidth: '1300px' }">
  <p-table
    [value]="filteredEntries"
    [tableStyle]="{ 'min-width': '50rem' }"
    [expandedRowKeys]="expandedRows"
    [paginator]="true"
    [rows]="tableConfig.rowsPerPageOptions[0]"
    [rowsPerPageOptions]="tableConfig.rowsPerPageOptions"
    dataKey="id">
    <ng-template pTemplate="caption">
      <div class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="updateSearchTerm($any($event.target).value)"
          [placeholder]="'general.globalSearchPlaceholder' | translate" />
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 5rem"></th>
        <th>{{ 'logging.information' | translate }}</th>
        <th pSortableColumn="timestamp">
          {{ 'logging.time' | translate }}<p-sortIcon field="timestamp" />
        </th>
        <th pSortableColumn="responsibleUserId">
          {{ 'logging.user' | translate }}<p-sortIcon
            field="responsibleUserId" />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-log let-expanded="expanded">
      <tr>
        <td>
          <p-button
            *ngIf="log.attributes.length > 0"
            type="button"
            pRipple
            [pRowToggler]="log"
            [text]="true"
            [rounded]="true"
            [plain]="true"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            size="small"
            styleClass="p-0 w-2rem h-2rem" />
        </td>
        <td>
          <app-search-highlight
            [search]="searchTerm"
            [content]="getActionDisplay(log)" />
        </td>
        <td>
          <span title="{{log.timestamp | date: 'dd.MM.YYYY HH:mm:ss'}}">
            <app-search-highlight
              [search]="searchTerm"
              [content]="getTimestamp(log.timestamp)"
          /></span>
        </td>
        <td>
          <div class="flex align-items-center gap-2">
            <div>
              <app-user-avatar
                *ngIf="log.responsibleUser"
                [user]="log.responsibleUser" />
            </div>

            <div class="flex-1">
              <ng-container *ngIf="log.responsibleUser">
                <app-search-highlight
                  [search]="searchTerm"
                  content="{{log.responsibleUser.firstname}} {{log.responsibleUser.lastname }}" />
              </ng-container>
              <ng-container *ngIf="!log.responsibleUser">
                {{ log.responsibleUserCredentials }}
              </ng-container>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-log>
      <tr *ngIf="log.attributes.length !== 0">
        <td colspan="5">
          <div class="p-3">
            <p-table [value]="log.attributes" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="attributeKey">
                    {{"logging.object" | translate}}
                    <p-sortIcon field="attributeKey" />
                  </th>
                  <th>{{"logging.old" | translate}}</th>
                  <th>{{"logging.new" | translate}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-logAttribute>
                <tr>
                  <td>
                    <app-search-highlight
                      [search]="searchTerm"
                      [content]="getAttributeFieldDisplay(logAttribute.attributeKey)" />
                  </td>
                  <td>
                    <app-search-highlight
                      [search]="searchTerm"
                      [content]="formatLoggingValue('old',logAttribute, log)" />
                  </td>
                  <td>
                    <app-search-highlight
                      [search]="searchTerm"
                      [content]="formatLoggingValue('new', logAttribute, log)" />
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="3" class="text-center">
                    {{ 'table.noResults' | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5" class="text-center">
          {{ 'table.noResults' | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="flex justify-content-end mt-5">
    <p-button
      [label]="'general.form.cancel' | translate"
      [outlined]="true"
      (click)="isVisible = false" />
  </div>
</p-dialog>
