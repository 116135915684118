/* eslint-disable dot-notation */
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs';

import { AuthService } from 'src/app/services/auth/auth.service';
import { PasswordValidationService } from 'src/app/services/utils/password-validation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-newpassword',
  templateUrl: './newpassword.component.html',
  styleUrls: ['./newpassword.component.scss']
})
export class NewPasswordComponent {
  newPasswordForm: FormGroup;

  /**
   * Is needed to control on which direction the login mask is located.
   */
  style = 'right';

  passwordMediumRegEx = '';

  passwordStrongRegEx = '';

  passwordWeakRegEx = '';

  token?: string;

  constructor(
    private formBuilder: FormBuilder,
    private passwordValidationService: PasswordValidationService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      this.token = params['token'];
    });
    this.passwordMediumRegEx = environment.passwordStrength.medium
      ? this.passwordValidationService.getPasswordRegex('medium')
      : '';

    this.passwordStrongRegEx = environment.passwordStrength.strong
      ? this.passwordValidationService.getPasswordRegex('strong')
      : '';

    this.passwordWeakRegEx = environment.passwordStrength.weak
      ? this.passwordValidationService.getPasswordRegex('weak')
      : '';

    const pattern = new RegExp(this.getPasswordPattern().substring(1));

    this.newPasswordForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(pattern) // Use pattern validator with validAt strength regex
        ]
      ],
      password2: [
        '',
        [
          Validators.required,
          this.passwordsMatchValidator.bind(this) // Custom validator for matching passwords
        ]
      ]
    });

    this.style = environment.loggedOutStyle;
  }

  onSubmit() {
    if (this.newPasswordForm && this.newPasswordForm.valid && this.token) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const password = this.newPasswordForm.get('password')?.value;
      this.authService
        .setPassword(password, this.token)
        .pipe(
          catchError((error) => {
            this.authService.showLoginToast('error');
            throw error;
          })
        )
        .subscribe((result) => {
          if (result) {
            this.router.navigate(['/']);
          }
        });
    }
  }

  getPasswordPattern(): string {
    let passwordPattern = '';
    switch (environment.passwordValidAt) {
      case 'weak':
        passwordPattern = this.passwordWeakRegEx;
        break;
      case 'medium':
        passwordPattern = this.passwordMediumRegEx;
        break;
      case 'strong':
        passwordPattern = this.passwordStrongRegEx;
        break;
      default:
        break;
    }

    return passwordPattern;
  }

  passwordsMatchValidator(): { [key: string]: boolean } | null {
    const password = this.newPasswordForm?.controls['password']?.value;
    const password2 = this.newPasswordForm?.controls['password2']?.value;

    if (password && password2 && password !== password2) {
      return { passwordsNotMatch: true };
    }

    return null;
  }
}
