import {
  ExtractCreateModel,
  ExtractUpdateModel
} from 'src/app/utils/types/extract-action-model';

export const CustomerContactPersonType = {
  Owner: 'Owner',
  Tenant: 'Tenant',
  PropertyManager: 'PropertyManager'
} as const;

export type CustomerContactPersonType =
  (typeof CustomerContactPersonType)[keyof typeof CustomerContactPersonType];

export type CustomerContactPerson = {
  readonly id: number;

  type: CustomerContactPersonType;

  firstname: string | null;

  lastname: string;

  phoneNumberMobile: string | null;

  phoneNumberLandline: string | null;

  email: string | null;

  isActive: boolean;

  note: string | null;

  street: string | null;

  postalCode: string | null;

  city: string | null;

  countryId: number | null;

  customerId: number;

  isMainContact: boolean;

  readonly createdAt: Date;

  readonly createdById: number;

  readonly updatedAt: Date;

  readonly updatedById: number;

  readonly deletedAt: Date | null;

  readonly deletedById: number | null;
};

export type CustomerContactPersonCreate =
  ExtractCreateModel<CustomerContactPerson>;

export type CustomerContactPersonUpdate =
  ExtractUpdateModel<CustomerContactPerson>;

export type CustomerContactPersonEditInterface =
  | CustomerContactPersonCreate
  | (CustomerContactPersonUpdate & { id: number });
