import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectButtonModule } from 'primeng/selectbutton';
import { LanguageSwitcherComponent } from './language-switcher.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
@NgModule({
  declarations: [LanguageSwitcherComponent],
  imports: [CommonModule, SelectButtonModule, DropdownModule, FormsModule],
  exports: [LanguageSwitcherComponent]
})
export class LanguageModule {}
