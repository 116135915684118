<div
  *ngIf="!preview"
  class="link w-full flex justify-content-between cursor-pointer mt-2">
  <span class="text-xl font-bold"
    >{{ 'widgets.ticketAll.header' | translate }}</span
  >
  <app-ticket
    *ngIf="!editMode && canCreateTicket"
    [createOnly]="true"
    (ticketCreated)="newTicketCreated()"></app-ticket>
</div>

<div
  *ngIf="tickets && tickets.length > 0"
  appendTo="body"
  echarts
  [options]="options"
  class="chart"></div>
