import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';

import { AuthService } from 'src/app/services/auth/auth.service';

export const roleGuard: CanActivateFn = (route, state) => {
  // TODO: Implement role-based access control

  const authService = inject(AuthService);

  return authService.authInitialized$.pipe(
    filter((initialized) => initialized !== 'uninitialized'), // Wait until auth is initialized
    switchMap(() => authService.user$), // Check if the user is logged in
    map((user) => {
      if (!user) {
        return false;
      }

      if (user.role === 'TechnicalAdmin') {
        return true;
      }

      if (user.role === 'Admin') {
        return true;
      }

      if (
        user.role === 'Spectator' ||
        user.role === 'CustomerService' ||
        user.role === 'Dispatcher'
      ) {
        switch (state.url) {
          case '/users':
            return false;
          default:
            break;
        }

        return true;
      }

      return false;
    })
  );
};
