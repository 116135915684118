<p-dialog
  [draggable]="false"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [modal]="true"
  [style]="{ width: '75%' }"
  [header]="header"
  [(visible)]="display"
  [modal]="true"
  [closable]="false">
  <div class="mb-4">
    <p>{{'ticketComponent.reoccurringModal.description' | translate}}</p>
  </div>
  <div class="flex gap-4 mb-4">
    <div>{{'ticketComponent.reoccurringModal.interval' | translate}}:</div>
    <div class="font-bold">
      {{'ticketComponent.reoccurringModal.intervalOptions.' +
      ticket.reoccurringInterval | translate}}
    </div>
  </div>
  <hr />
  <div class="form-field mb-2 mt-2">
    <div class="mb-4">
      <p>{{'ticketComponent.reoccurringModal.text' | translate}}</p>
    </div>
    <p-calendar
      appendTo="body"
      dateFormat="dd.mm.yy"
      [firstDayOfWeek]="1"
      [iconDisplay]="'input'"
      [showIcon]="true"
      [required]="true"
      [placeholder]="'ticketComponent.attributes.ExecutionDate' | translate"
      inputId="executionDateCalendar"
      [showOnFocus]="showOnFocus"
      [hideOnDateTimeSelect]="false"
      (ngModelChange)="onExecutionDateChanged($event)"
      [(ngModel)]="ticket.nextExecutionDate"></p-calendar>
  </div>
  <p-footer>
    <div class="flex gap-2 justify-content-end">
      <div>
        <button
          pButton
          pRipple
          [label]="'general.form.cancel' | translate"
          class="flex-auto w-full"
          type="button"
          (click)="onCancel()"></button>
      </div>
      <div>
        <button
          pButton
          pRipple
          [label]="'general.form.save' | translate"
          class="flex-auto w-full"
          type="button"
          (click)="onSave()"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
