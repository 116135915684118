import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  of,
  shareReplay,
  tap
} from 'rxjs';

import { LoginCodeDto } from 'src/app/models/LoginCodeDto';
import { LoginDto } from 'src/app/models/LoginDto';
import { ResetLinkDto } from 'src/app/models/ResetLinkDto';
import { User } from 'src/app/models/User';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { JwtLoginResponse } from 'src/app/types/JwtLoginResponse';
import { Severity } from 'src/app/types/misc/Severity';
import {
  AppAbility,
  AppAction,
  AppSubject,
  abilityFactory,
  cookieKeys
} from 'src/config/authorization.config';
import { environment } from 'src/environments/environment';

type AuthInitializeStatus = 'initialized' | 'uninitialized' | 'error';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // user subject to store the current user
  private userSubject = new BehaviorSubject<User | null>(null);

  // observable to subscribe to the user
  public user$ = this.userSubject.asObservable();

  // ability to store the user's permissions
  private ability: AppAbility | null = null;

  // subject to store the initialization state of the authentication service
  private authInitializeStatusSubject =
    new BehaviorSubject<AuthInitializeStatus>('uninitialized');

  // observable to subscribe to the initialization state of the authentication service
  public authInitialized$ = this.authInitializeStatusSubject.asObservable();

  private refreshSubject: Observable<JwtLoginResponse | null> | null = null;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private translate: TranslateService,
    private messageCenterService: MessageCenterService
  ) {}

  // ###########################################################################
  //                               HTTP REQUESTS
  // ###########################################################################

  /**
   * Initializes the authentication service by making a GET request to retrieve the current user's information.
   * The retrieved user object is then passed to the setUser method.
   */
  public init(): Observable<User | null> {
    const refreshToken = this.cookieService.get(cookieKeys.refreshToken);

    if (this.isTokenValid(refreshToken) === false) {
      this.authInitializeStatusSubject.next('initialized');

      this.setUser(null);
      this.deleteCookies();

      return of(null);
    }

    const accessToken = this.cookieService.get(cookieKeys.accessToken);

    if (this.isTokenValid(accessToken) === false) {
      return this.refresh().pipe(
        tap((response) => {
          this.authInitializeStatusSubject.next('initialized');

          return response;
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            // only auto logout if the status is 401
            this.setUser(null);
            this.deleteCookies();

            this.authInitializeStatusSubject.next('initialized');
          } else {
            this.authInitializeStatusSubject.next('error');
          }

          return of(null);
        }),
        map((response) => {
          if (!response || response instanceof Error) {
            return null;
          }

          return response.user;
        })
      );
    }

    return this.http.get<User>(`${environment.apiUrl}/auth/me`).pipe(
      tap((user) => {
        if (!user || user instanceof Error) {
          return user;
        }

        this.setUser(user);
        this.authInitializeStatusSubject.next('initialized');

        return user;
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // only auto logout if the status is 401
          this.setUser(null);
          this.deleteCookies();
        } else {
          this.authInitializeStatusSubject.next('error');
        }

        return of(null);
      })
    );
  }

  /**
   * Logs in the user with the provided credentials.
   * @param credentials - The login credentials.
   * @returns An observable that emits a JwtLoginResponse object.
   * @throws Throws an error if an error occurs during the login process.
   */
  public login(credentials: LoginDto): Observable<JwtLoginResponse> {
    return this.http
      .post<JwtLoginResponse>(`${environment.apiUrl}/auth/login`, credentials)
      .pipe(
        tap((response) => {
          if (!response || response instanceof Error) {
            return response;
          }

          this.setUser(response.user);
          this.setCookies(response);

          return response;
        }),
        catchError((error: unknown) => {
          throw error;
        })
      );
  }

  /**
   * Refreshes the user's JWT token.
   * @returns An observable that emits a JwtLoginResponse object.
   */
  public refresh(): Observable<JwtLoginResponse | null> {
    if (this.refreshSubject === null) {
      const refreshToken = this.cookieService.get(cookieKeys.refreshToken);

      if (this.isTokenValid(refreshToken) === false) {
        return of(null);
      }

      this.refreshSubject = this.http
        .post<JwtLoginResponse>(`${environment.apiUrl}/auth/refresh-token`, {
          refreshToken
        })
        .pipe(
          shareReplay(1),
          tap((response) => {
            if (!response || response instanceof Error) {
              return response;
            }

            this.setUser(response.user);
            this.setCookies(response);

            this.refreshSubject = null;

            return response;
          }),
          catchError(() => of(null))
        );
    }

    return this.refreshSubject;
  }

  /**
   * Sends a login code request to the server and returns an observable that emits a JwtLoginResponse.
   *
   * @param credentials - The login code credentials.
   * @returns An observable that emits a JwtLoginResponse.
   * @throws Throws an error if an error occurs during the login code request.
   */
  public loginCode(credentials: LoginCodeDto): Observable<JwtLoginResponse> {
    return this.http
      .post<JwtLoginResponse>(
        `${environment.apiUrl}/auth/logincode`,
        credentials
      )
      .pipe(
        tap((response) => {
          if (!response || response instanceof Error) {
            return response;
          }

          this.setUser(response.user);
          this.setCookies(response);

          return response;
        }),
        catchError((error: unknown) => {
          throw error;
        })
      );
  }

  /**
   * Generates a password reset link for web users.
   *
   * @param email - The email address of the user.
   * @returns An Observable that emits a boolean indicating whether the password reset link was successfully generated.
   */
  public generatePasswordLinkWeb(email: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiUrl}/auth/generate-password-link-web/${email}`
    );
  }

  /**
   * Generates a password reset link for the specified email.
   *
   * @param email - The email address for which to generate the password reset link.
   * @returns An observable that emits a ResetLinkDto object containing the generated password reset link.
   */
  public generatePasswordLink(email: string): Observable<ResetLinkDto> {
    return this.http.get<ResetLinkDto>(
      `${environment.apiUrl}/auth/generate-password/link/${email}`
    );
  }

  /**
   * Sets the password for a user using the provided token.
   *
   * @param password - The new password for the user.
   * @param token - The token used to verify the user's identity.
   */
  public setPassword(
    password: string,
    token: string
  ): Observable<JwtLoginResponse> {
    return this.http
      .post<JwtLoginResponse>(`${environment.apiUrl}/auth/set-password`, {
        password,
        token
      })
      .pipe(
        tap((response) => {
          if (!response || response instanceof Error) {
            return response;
          }

          this.setUser(response.user);
          this.setCookies(response);

          return response;
        }),
        catchError((error: unknown) => {
          throw error;
        })
      );
  }

  /**
   * Resets the password for a user using the provided token.
   *
   * @param password - The new password for the user.
   * @param token - The token used to verify the user's identity.
   */
  public generateCodeForLogin(email: string): Observable<boolean> {
    return this.http
      .get<boolean>(
        `${environment.apiUrl}/auth/generate-code/for-login/${email}`
      )
      .pipe(catchError(() => of(false)));
  }

  /**
   * Resets the password for a user using the provided token.
   *
   * @param password - The new password for the user.
   * @param token - The token used to verify the user's identity.
   */
  public getBlockedAtByEmail(email: string): Observable<Date> {
    return this.http.get<Date>(
      `${environment.apiUrl}/auth/getBlockedAtByEmail/${email}`
    );
  }

  // ###########################################################################
  //                               AUTHENTICATION
  // ###########################################################################

  /**
   * Logs out the user.
   * Deletes cookies, sets the user to null, shows a logout toast message, and navigates to the login page.
   */
  public logout(): void {
    this.deleteCookies();
    this.setUser(null);

    this.showLogoutToast('info');
    this.router.navigate(['/login']);
  }

  /**
   * Checks if the user is currently logged in.
   * @returns True if the user is logged in, false otherwise.
   */
  public isLoggedIn(): Observable<boolean> {
    return this.user$.pipe(
      map((user) => Boolean(user)) // Return true if user is not null
    );
  }

  /**
   * Retrieves the currently logged in user.
   * @returns The currently logged in user.
   */
  public getLoggedInUser(): User | null {
    return this.userSubject.getValue();
  }

  // ###########################################################################
  //                        AUTORISATION / PERMISSIONS
  // ###########################################################################

  /**
   * Checks if the user has the ability to perform a specific action on a subject.
   * @param action - The action to be performed.
   * @param subject - The subject on which the action is performed.
   * @returns A boolean indicating whether the user has the ability to perform the action on the subject.
   */
  public $can(action: AppAction | AppAction[], subject: AppSubject): boolean {
    if (!this.ability) {
      return false;
    }

    if (Array.isArray(action)) {
      return action.every((a) => this.ability?.can(a, subject));
    }

    return this.ability.can(action, subject);
  }

  // ###########################################################################
  //                               JWT TOKENs
  // ###########################################################################

  private isTokenValid(token: string | null | undefined): boolean {
    if (!token) {
      return false;
    }

    const decodedToken = jwtDecode<JwtPayload>(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp && decodedToken.exp > currentTime) {
      return true;
    }

    return false;
  }

  // ###########################################################################
  //                               USER METHODS
  // ###########################################################################

  public setUser(user: User | null) {
    this.userSubject.next(user);
    this.ability = abilityFactory(user);
  }

  // ###########################################################################
  //                               COOKIES
  // ###########################################################################

  private setCookies(response: JwtLoginResponse) {
    const decodedToken = jwtDecode<JwtPayload>(response.accessToken);
    const decodedRefreshTokenTest = jwtDecode<JwtPayload>(
      response.refreshToken
    );

    const expirationDateAccessToken = new Date(Number(decodedToken.exp) * 1000);
    const expirationDateRefreshToken = new Date(
      Number(decodedRefreshTokenTest.exp) * 1000
    );

    this.cookieService.set(cookieKeys.accessToken, response.accessToken, {
      sameSite: 'Lax',
      path: '/',
      expires: expirationDateAccessToken,
      domain: `.${environment.domain}`
    });
    this.cookieService.set(cookieKeys.refreshToken, response.refreshToken, {
      sameSite: 'Lax',
      path: '/',
      expires: expirationDateRefreshToken,
      domain: `.${environment.domain}`
    });
  }

  private deleteCookies() {
    this.cookieService.delete(
      cookieKeys.accessToken,
      '/',
      `.${environment.domain}`,
      undefined,
      'Lax'
    );
    this.cookieService.delete(
      cookieKeys.refreshToken,
      '/',
      `.${environment.domain}`,
      undefined,
      'Lax'
    );
  }

  // ###########################################################################
  //                               TOASTS
  // ###########################################################################

  public showLogoutToast(severity: Severity): void {
    this.messageCenterService.showToast(
      this.translate.instant(`general.logout.toasts.${severity}.summary`),
      this.translate.instant(`general.logout.toasts.${severity}.detail`),
      severity
    );
  }

  public showLoginToast(severity: Severity): void {
    this.messageCenterService.showToast(
      this.translate.instant(`loginComponent.toasts.${severity}.summary`),
      this.translate.instant(`loginComponent.toasts.${severity}.detail`),
      severity
    );
  }
}
