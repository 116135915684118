import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { LoginCodeDto } from 'src/app/models/LoginCodeDto';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { JwtLoginResponse } from 'src/app/types/JwtLoginResponse';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent {
  verificationForm: FormGroup;

  verificationCodeArray: number[] = [];

  email!: string;

  loginSubscription: Subscription = new Subscription();

  focusIndex = 0;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private messageCenterService: MessageCenterService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.email = window.history.state.email;
    if (!this.email) {
      this.router.navigate(['/']);
    }
    const numberOfDigits = environment.verificationCodeLength;

    this.verificationForm = this.formBuilder.group({});
    for (let index = 0; index < numberOfDigits; index++) {
      this.verificationForm.addControl(
        index.toString(),
        this.formBuilder.control(null, Validators.required)
      );
      this.verificationCodeArray.push(index);
    }

    this.verificationForm.setValidators(this.validateAllFields);
  }

  onSubmit() {
    if (this.verificationForm.valid) {
      let code = '';
      for (let index = 0; index < this.verificationCodeArray.length; index++) {
        const controlName = index.toString();
        const controlValue = this.verificationForm.get(controlName)?.value;
        if (controlValue !== null && controlValue !== '') {
          code += controlValue;
        } else {
          return;
        }
      }
      if (code.length === environment.verificationCodeLength) {
        const loginDto = new LoginCodeDto(this.email, code);

        this.loginSubscription = this.authService
          .loginCode(loginDto)
          .subscribe({
            next: (response) => this.login(response),
            error: () => this.authService.showLoginToast('error')
          });
      } else {
        this.authService.showLoginToast('error');
      }
    }
  }

  login(response: JwtLoginResponse): void {
    this.router.navigate(['/']);
    this.authService.showLoginToast(response.user ? 'success' : 'error');
  }

  private validateAllFields(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const formKeys = Object.keys(control.value);
    for (const key of formKeys) {
      if (control.get(key)?.value === null || control.get(key)?.value === '') {
        return { incomplete: true };
      }
    }

    return null;
  }

  raiseFocusIndex(): void {
    this.focusIndex += 1;
  }
}
