<div
  class="layout-sidebar"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()">
  <a [routerLink]="['/']" class="app-logo">
    <div class="flex side-nav-logo">
      <img
        class="hive-logo"
        src="../../assets/images/logo.png"
        [alt]="'general.appName' | translate" />
      <img
        class="hive-logo-dark"
        src="../../assets/images/logo-dark.png"
        [alt]="'general.appName' | translate" />

      <div class="logo-line"></div>
    </div>

    <button
      class="layout-sidebar-anchor p-link"
      type="button"
      (click)="anchor()"></button>
  </a>

  <div class="layout-menu-container">
    <app-menu></app-menu>
  </div>
</div>
