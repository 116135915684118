import { Country } from 'src/app/models/Country';

export class HeatingEngineer {
  id!: number;

  heatingEngineerNumber!: string;

  name!: string;

  street?: string;

  mobilePhoneNumber?: string;

  landlinePhoneNumber?: string;

  email?: string;

  city?: string;

  postalCode?: string;

  countryId?: number;

  country?: Country;

  note?: string;

  isActive!: boolean;

  isBlocked!: boolean;

  constructor(config?: Partial<HeatingEngineer>) {
    Object.assign(this, config);
  }
}
