import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  RendererFactory2
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FileDto } from 'src/app/models/FileDto';
import { MsgAttachment } from 'src/app/models/MsgAttachment';
import { MsgData } from 'src/app/models/MsgData';
import { FileService } from 'src/app/services/file.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { FileDownloader } from 'src/app/utils/other/download-helper';

@Component({
  selector: 'app-msg-viewer',
  templateUrl: './msg-viewer.component.html',
  styleUrls: ['./msg-viewer.component.scss']
})
export class MsgViewerComponent implements OnInit, OnDestroy {
  @Input() file!: FileDto;

  actualAttachments: MsgAttachment[] = [];

  htmlData?: SafeHtml;

  loading = false;

  msgData!: MsgData;

  plainData?: string;

  msgSubscriptions = new Subscription();

  private fileDownloader: FileDownloader;

  constructor(
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    private rendererFactory: RendererFactory2,
    private messageCenterService: MessageCenterService,
    private translate: TranslateService
  ) {
    this.fileDownloader = new FileDownloader(this.rendererFactory);
  }

  ngOnInit(): void {
    this.loading = true;

    if (this.file.mimetype === 'message/rfc822') {
      this.msgSubscriptions.add(
        this.fileService.parseEml(this.file.id).subscribe({
          next: (data) => {
            if (data) {
              this.processEmlData(data);
            }
            this.loading = false;
          },
          error: (error) => {
            this.messageCenterService.showToast(
              this.translate.instant('general.msgParsingErrorTitle'),
              this.translate.instant('general.msgParsingErrorDescription'),
              'error'
            );
            console.error('Error parsing message:', error);
            this.loading = false;
          }
        })
      );
    } else {
      this.msgSubscriptions.add(
        this.fileService.parseMsg(this.file.id).subscribe({
          next: (data) => {
            if (data) {
              this.processMsgData(data);
            }
            this.loading = false;
          },
          error: (error) => {
            this.messageCenterService.showToast(
              this.translate.instant('general.msgParsingErrorTitle'),
              this.translate.instant('general.msgParsingErrorDescription'),
              'error'
            );
            console.error('Error parsing message:', error);
            this.loading = false;
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.msgSubscriptions.unsubscribe();
  }

  /**
   * Downloads the provided attachment.
   * @param {MsgAttachment} attachment The attachment to download.
   * @returns {void}
   */
  downloadAttachment(attachment: MsgAttachment): void {
    const byteArray = new Uint8Array(Object.values(attachment.content));

    if (attachment.content) {
      this.fileDownloader.downloadFile(
        byteArray,
        attachment.fileName,
        attachment.attachMimeTag
      );
    }
  }

  /**
   * Processes the provided MsgData.
   * @param {MsgData} data The MsgData to process.
   * @returns {void}
   */
  private processMsgData(data: MsgData): void {
    this.msgData = data;
    if (data.headers.includes('Content-Type: text/plain; charset=UTF-8')) {
      this.plainData = data.html;
    } else {
      this.processAttachments(data);
      data.html = this.removeFooterClass(data.html);
      this.htmlData = this.sanitizer.bypassSecurityTrustHtml(data.html);
    }
  }

  /**
   * Processes the provided MsgData (EML).
   * @param {MsgData} data The MsgData (EML) to process.
   * @returns {void}
   */
  private processEmlData(data: MsgData): void {
    this.msgData = data;
    this.processAttachments(data);
    data.html = this.removeFooterClass(data.html);
    this.htmlData = this.sanitizer.bypassSecurityTrustHtml(data.html);
  }

  /**
   * Processes the attachments included in provided MsgData.
   * @param {MsgData} data The MsgData to process.
   * @returns {void}
   */
  private processAttachments(data: MsgData): void {
    data.attachments.forEach((attachment) => {
      const byteArray = new Uint8Array(Object.values(attachment.content));
      const blob = new Blob([byteArray], { type: attachment.attachMimeTag });
      const imageUrl = URL.createObjectURL(blob);

      if (attachment.pidContentId === '') {
        this.actualAttachments.push(attachment);
      } else {
        data.html = this.replaceContentIdWithImageUrl(
          data.html,
          `cid:${attachment.pidContentId}`,
          imageUrl
        );
      }
    });
  }

  /**
   * Replaces all occurrences of a pidContentId with an image URL in a given HTML string.
   *
   * @param {string} html - The original HTML string.
   * @param {string} pidContentId - The string / old image/file-reference to replace.
   * @param {string} imageUrl - The image URL to replace the pidContentId with.
   * @returns {string} - The manipulated HTML string with all pidContentId's replaced by the image URL.
   */
  private replaceContentIdWithImageUrl(
    html: string,
    pidContentId: string,
    imageUrl: string
  ): string {
    let manipulatedHtml = html;
    while (manipulatedHtml.includes(pidContentId)) {
      manipulatedHtml = manipulatedHtml.replace(pidContentId, imageUrl);
    }

    return manipulatedHtml;
  }

  /**
   * Removes the 'footer' class from a given HTML string.
   * This is necessary because the 'footer' class is often defined as absolute positioned.
   * This could cause the footer to overlap the content in the modal.
   *
   * @param {string} html - The original HTML string.
   * @returns {string} - The manipulated HTML string with the 'footer' class removed.
   */
  private removeFooterClass(html: string): string {
    return html.replace('class="footer"', '');
  }
}
