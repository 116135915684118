import { HiveForm } from 'src/app/models/HiveForm';

export const isFormCompleted = (form: HiveForm): boolean => {
  if (form.installationIdmLuftFormId !== null) {
    return true;
  }

  if (form.installationIdmSwFormId !== null) {
    return true;
  }

  if (form.serviceReportFormId !== null) {
    return true;
  }

  if (form.sgcMaintenanceIdmFormId !== null) {
    return true;
  }

  return false;
};
