import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TabView } from 'primeng/tabview';
import { catchError, first, pipe, Subscription, throwError } from 'rxjs';
import { CustomerEditBaseDataComponent } from 'src/app/components/admin/customer/views/components/customer-edit-base-data/customer-edit-base-data.component';
import { LoggingComponent } from 'src/app/components/misc/logging/logging.component';

import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { Country } from 'src/app/models/Country';
import { Customer, CustomerUpdate } from 'src/app/models/customer/Customer';
import { CustomerContactPerson } from 'src/app/models/customer/CustomerContactPerson';
import { CustomerCorrespondence } from 'src/app/models/customer/CustomerCorrespondence';
import { CustomerDevice } from 'src/app/models/customer/CustomerDevice';
import { DeviceManufacturer } from 'src/app/models/DeviceManufacturer';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { Ticket } from 'src/app/models/Ticket';
import { CountryService } from 'src/app/services/api/country.service';
import { CustomerContactPersonService } from 'src/app/services/api/customer/customer-contact-person.service';
import { CustomerCorrespondenceService } from 'src/app/services/api/customer/customer-correspondence.service';
import { CustomerDeviceService } from 'src/app/services/api/customer/customer-device.service';
import { CustomerService } from 'src/app/services/api/customer/customer.service';
import { DeviceManufacturerService } from 'src/app/services/api/device-manufacturer.service';
import { TicketService } from 'src/app/services/api/ticket.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Severity } from 'src/app/types/misc/Severity';
import {
  displayLoggingContext,
  LoggingFormatter,
  LoggingFormatterBuilder
} from 'src/app/utils/logging';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit, OnDestroy {
  @ViewChild('loggingComponent') loggingComponent!: LoggingComponent;

  @ViewChild('baseDataComponent')
  baseDataComponent?: CustomerEditBaseDataComponent;

  @ViewChild('tabView')
  tabViewComponent!: TabView;

  customer: Customer | null = null;

  countries: Country[] = [];

  deviceManufacturers: DeviceManufacturer[] = [];

  correspondences: CustomerCorrespondence[] = [];

  devices: CustomerDevice[] = [];

  customerContactPersons: CustomerContactPerson[] = [];

  tickets: Ticket[] = [];

  config = new GenericTableConfiguration({
    translationKey: 'customerComponent'
  });

  activeTabIndex = 0;

  private routeParamSub: Subscription | null = null;

  private routeQuerySub: Subscription | null = null;

  loggingFormatter: LoggingFormatter | null = null;

  loadedTabs = new Map<number, boolean>();

  constructor(
    private readonly countryService: CountryService,
    private readonly customerService: CustomerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly messageCenterService: MessageCenterService,
    private readonly customerCorrespondenceService: CustomerCorrespondenceService,
    private readonly customerDevicesService: CustomerDeviceService,
    private readonly customerContactPersonService: CustomerContactPersonService,
    private readonly ticketService: TicketService,
    private readonly deviceManufacturerService: DeviceManufacturerService
  ) {}

  ngOnInit(): void {
    this.routeParamSub = this.route.params.subscribe((params) => {
      this.customerService
        .findById(params['id'])
        .pipe(
          catchError((error) => {
            console.error('Error fetching customer:', error);
            this.router.navigate(['/customers']);

            return throwError(() => error);
          }),
          first()
        )
        .subscribe((customer) => {
          this.customer = customer;

          this.customerCorrespondenceService
            .findAll({
              params: { customer: customer.id.toString() }
            })
            .pipe(first())
            .subscribe((correspondences) => {
              this.correspondences = correspondences;
            });

          this.customerDevicesService
            .findAll(customer.id)
            .pipe(first())
            .subscribe((devices) => {
              this.devices = devices;
            });
          this.customerContactPersonService
            .findAll(customer.id)
            .pipe(first())
            .subscribe((customerContactPerson) => {
              this.customerContactPersons = customerContactPerson;
            });
          this.ticketService
            .findAllByCustomerId(this.customer.id)
            .pipe(first())
            .subscribe((tickets) => {
              this.tickets = tickets;
            });
        });
    });

    this.routeQuerySub = this.route.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.activeTabIndex = parseInt(params['tab'], 10);
        this.loadedTabs.set(this.activeTabIndex, true);
      }
    });

    this.deviceManufacturerService
      .findAll()
      .pipe(first())
      .subscribe((deviceManufacturers) => {
        this.deviceManufacturers = deviceManufacturers;
      });

    this.countryService
      .findAll()
      .pipe(first())
      .subscribe((countries) => {
        this.countries = countries;
      });

    this.loggingFormatter = LoggingFormatterBuilder.new<Customer>()
      .add('facilityAddressType', (value) =>
        this.translate.instant(`customerComponent.facilityAddressType.${value}`)
      )
      .add('billingAddressId', displayLoggingContext)
      .add('facilityAddressId', displayLoggingContext)
      .build();

    this.loadedTabs.set(this.activeTabIndex, true);
  }

  revalidateCustomerCache(): void {
    if (!this.customer) {
      return;
    }

    this.customerService
      .findById(this.customer.id)
      .pipe(first())
      .subscribe((customer) => {
        this.customer = customer;
      });
  }

  changeCustomer(customer: Customer): void {
    this.customer = customer;
    this.loggingComponent.fetchLoggingView();
  }

  updateCustomer(updatePayload: CustomerUpdate): void {
    if (!this.customer) {
      throw new Error('Customer is not set');
    }

    this.customerService
      .update(this.customer.id, updatePayload)
      .pipe(
        catchError((error) => {
          this.showCrudToast(
            DataModificationMethod.Create,
            'error',
            this.customer || updatePayload
          );

          return throwError(() => error);
        }),
        pipe(first())
      )
      .subscribe((customer) => {
        this.customer = customer;
        this.showCrudToast(DataModificationMethod.Edit, 'success', customer);

        this.loggingComponent.fetchLoggingView();

        this.baseDataComponent?.formGroup?.reset(customer, {
          emitEvent: false
        });
      });
  }

  ngOnDestroy() {
    this.routeParamSub?.unsubscribe();
    this.routeQuerySub?.unsubscribe();
  }

  private showCrudToast(
    method: DataModificationMethod,
    severity: Severity,
    customer: Customer | CustomerUpdate
  ): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `customerComponent.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `customerComponent.actions.toasts.${method}.${severity}.detail`,
        {
          name: customer.name,
          customerNumber: customer.customerNumber
        }
      ),
      severity
    );
  }

  preventClosingBaseData(tabIndex: number) {
    const nextTab = () => {
      this.activeTabIndex = tabIndex;
      this.loadedTabs.set(tabIndex, true);

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { tab: tabIndex },
        queryParamsHandling: 'merge'
      });

      this.baseDataComponent?.formGroup?.reset(this.customer, {
        emitEvent: false
      });
    };

    // set time out to await next tick
    setTimeout(() => {
      if (this.baseDataComponent?.formGroup?.dirty) {
        this.activeTabIndex = 0;
        this.loadedTabs.set(0, true);

        this.messageCenterService.confirm(
          this.translate.instant('general.confirmUnsavedChanges.header'),
          this.translate.instant('general.confirmUnsavedChanges.message'),
          nextTab,
          () => {}
        );

        return;
      }

      nextTab();
    }, 0);
  }
}
