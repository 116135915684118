/* eslint-disable max-lines-per-function */
import { Validators } from '@angular/forms';
import { DeviceManufacturer } from 'src/app/models/DeviceManufacturer';
import { GenericActionConfiguration } from 'src/app/models/GenericActionConfiguration';
import { GenericColumnConfiguration } from 'src/app/models/GenericColumnConfiguration';
import { GenericRowActionConfiguration } from 'src/app/models/GenericRowActionConfiguration';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';

// eslint-disable-next-line require-jsdoc
function deviceTableConfig(
  manufacturers: DeviceManufacturer[]
): GenericTableConfiguration {
  return new GenericTableConfiguration({
    table: 'device',
    permissionSubject: 'Device',
    fileDownloadContext: 'devices',
    showGridLines: false,
    showStripedRows: false,
    translationKey: 'deviceComponent',
    showPdfExportAction: false,
    showCsvExportAction: false,
    showExcelExportAction: false,
    lazyLoad: true,
    defaultSortField: 'customerDeviceCount',
    defaultSortOrder: -1,
    columns: [
      new GenericColumnConfiguration<DeviceManufacturer | null>({
        type: 'autocomplete',
        field: 'manufacturer',
        isCreateField: true,
        options: manufacturers,
        referringKey: 'name',
        formControl: {
          validators: [Validators.required]
        },
        formatter: (value) => value?.name
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'title',
        isClickable: true,
        isCreateField: true,
        formControl: {
          validators: [Validators.required]
        }
      }),
      new GenericColumnConfiguration({
        type: 'boolean',
        field: 'status',
        isFilterable: true,
        booleanStyle: 'switch',
        permissionAction: 'update',
        formControl: {},
        defaultValue: true,
        isCreateField: true
      }),
      new GenericColumnConfiguration({
        type: 'files',
        field: 'files',
        fileUploadOptions: {
          titleTranslationKey: 'deviceComponent.form.fileUpload.title',
          subtitleTranslationKey: 'deviceComponent.form.fileUpload.subtitle',
          chooseButtonTranslationKey:
            'deviceComponent.form.fileUpload.chooseButton',
          uploadButtonTranslationKey:
            'deviceComponent.form.fileUpload.uploadButton',
          cancelButtonTranslationKey:
            'deviceComponent.form.fileUpload.cancelButton',
          iconsOnly: true,
          showUploadButton: false,
          showCancelButton: false
        },
        isTableField: false,
        isCreateField: true,
        isEditField: true,
        formControl: {
          validators: []
        }
      }),
      new GenericColumnConfiguration({
        type: 'count',
        field: 'count',
        isCreateField: false,
        isEditField: false,
        isFilterable: false,
        isSortable: true,
        referringKey: 'files',
        formControl: {
          validators: []
        },
        permissionAction: 'update'
      }),
      new GenericColumnConfiguration({
        type: 'number',
        field: 'customerDeviceCount',
        isCreateField: false,
        isEditField: false,
        isTableField: true,
        isSortable: true,
        isFilterable: false
      })
    ],
    rowActionWidthRem: 5,
    rowActions: [
      new GenericRowActionConfiguration({
        icon: 'pi-pencil',
        tooltipTranslateKey: 'general.form.edit',
        identifier: 'edit',
        iconOnly: false,
        permissionAction: 'update',
        severity: 'success'
      }),
      new GenericRowActionConfiguration({
        icon: 'pi-trash',
        tooltipTranslateKey: 'general.form.delete',
        identifier: 'delete',
        iconOnly: false,
        confirmRequired: true,
        permissionAction: 'delete',
        severity: 'danger'
      })
    ],
    topRightAction: new GenericActionConfiguration({
      identifier: 'create',
      iconOnly: true,
      icon: 'pi-plus-circle',
      label: ['general.form.createSubject', 'deviceComponent.form.title'],
      permissionAction: 'create'
    })
  });
}

export { deviceTableConfig };
