import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { catchError, Observable, switchMap } from 'rxjs';

import { AuthService } from 'src/app/services/auth/auth.service';
import { cookieKeys } from 'src/config/authorization.config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const accessToken = this.cookieService.get(cookieKeys.accessToken);

    const manipulatedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return next.handle(manipulatedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.authService.refresh().pipe(
            switchMap((jwtResponse) => {
              if (!jwtResponse) {
                throw error;
              }

              const newRequest = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${jwtResponse.accessToken}`
                }
              });

              return next.handle(newRequest);
            })
          );
        }

        throw error;
      })
    );
  }
}
