import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Widget } from 'src/app/models/Widget';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserWidgetService {
  constructor(private internalHttp: HttpClient) {}

  createUserDashboard(widgets: Widget[]): Observable<Widget[]> {
    return this.internalHttp.post<Widget[]>(
      `${environment.apiUrl}/user-widgets/create-user-dashboard`,
      widgets
    );
  }

  getUserDashboard(): Observable<Widget[]> {
    return this.internalHttp.get<Widget[]>(
      `${environment.apiUrl}/user-widgets/get-user-dashboard`
    );
  }
}
