/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  standalone: true,
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
  imports: [
    ImageCropperComponent,
    CommonModule,
    InputTextModule,
    FormsModule,
    FileUploadModule,
    TranslateModule,
    ProgressSpinnerModule
  ]
})
export class CropperComponent implements OnInit {
  imageChangedEvent: any = '';

  croppedImage: any = '';

  result: any;

  image: any;

  base64Image?: string;

  imageCroppedChanged = false;

  fileSizeInMB = 0;

  cropperIsReady = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    private messageCenterService: MessageCenterService
  ) {}

  ngOnInit(): void {
    this.base64Image = this.config.data.base64;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.cropperIsReady = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageCroppedChanged = true;
    this.calculateFileSize();
  }

  imageLoaded() {
    // image loaded
  }

  cropperReady() {
    this.cropperIsReady = true;
  }

  loadImageFailed() {
    // show message
  }

  calculateFileSize() {
    if (this.croppedImage) {
      // Base64-String bereinigen (Metadaten entfernen)
      const base64Length = this.croppedImage.split(',')[1].length;

      // Dateigröße in Bytes berechnen
      const sizeInBytes = base64Length * (3 / 4) - this.paddingCount();

      const sizeInMB = sizeInBytes / (1024 * 1024);

      // Bytes in Megabytes umrechnen (1 MB = 1024 * 1024 Bytes)
      this.fileSizeInMB = parseFloat(sizeInMB.toFixed(2));
    }
  }

  paddingCount(): number {
    if (this.croppedImage && this.croppedImage.endsWith('==')) {
      return 2;
    }
    if (this.croppedImage && this.croppedImage.endsWith('=')) {
      return 1;
    }

    return 0;
  }

  closeWithResult() {
    this.ref.close(this.croppedImage);
  }

  async closeWithoutResult() {
    if (this.croppedImage) {
      await new Promise<boolean>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant('general.confirmUnsavedChanges.header'),
          this.translate.instant('general.confirmUnsavedChanges.message'),
          () => {
            this.ref.close();
          },
          () => {
            resolve(false);
          }
        );
      });
    }
    this.ref.close();
  }
}
