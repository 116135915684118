import * as moment from 'moment';

/**
 * Converts a timestamp string to a human-readable display format.
 * If the timestamp is within the current week, it returns a relative time (e.g., "2 days ago").
 * Otherwise, it formats the timestamp as "day of the week.month.year hour:minute:second".
 *
 * @param {string} timestamp - The timestamp to be converted, in a format recognized by moment.js.
 * @returns {string} The formatted timestamp as a string.
 */
export function getTimestampDisplay(timestamp: string): string {
  const now = new Date();
  const date = moment(timestamp);

  if (date.isSame(now, 'week')) {
    return date.fromNow();
  }

  return date.format('DD.MM.YYYY HH:mm:ss');
}
