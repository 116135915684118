import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-filter-create',
  templateUrl: './filter-create.component.html',
  styleUrls: ['./filter-create.component.scss']
})
export class FilterCreateComponent {
  filterName = '';

  @Output() saveFilterClicked = new EventEmitter<string>();

  onSubmit() {
    const newFilterName = this.filterName;
    this.filterName = '';
    if (newFilterName.length > 0) {
      this.saveFilterClicked.emit(newFilterName);
    }
  }
}
