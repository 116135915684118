<div>
  <span class="text-primary"
    >{{'ticketComponent.editForm.sections.customerContactPerson.inner' |
    translate | uppercase}}</span
  >
  <span
    class="pi pi-window-maximize text-primary cursor-pointer ml-4"
    (click)="navigateToCustomerContactPersons()"></span>

  <span
    class="pi pi-arrow-right-arrow-left text-primary cursor-pointer ml-4"
    (click)="chooseCustomerContactPersonClicked()"></span>
</div>
<div *ngIf="ticket.customer">
  <div
    class="flex align-items-center mt-4"
    *ngIf="!selectedCustomerContactPerson">
    <span
      (mousedown)="chooseCustomerContactPersonClicked()"
      class="pi pi-plus-circle text-primary cursor-pointer text-2xl mr-2"></span>
    <span
      >{{'general.form.chooseSubject'| translate: {subject:
      ('ticketComponent.attributes.customerContactPerson' | translate)} }}</span
    >
  </div>

  <div *ngIf="selectedCustomerContactPerson">
    <div class="grid mt-2">
      <div class="col-12 md:col-6 lg:col-4">
        <!-- CustomerContactPerson Type | Field -->
        <div class="form-field">
          <label htmlFor="type" class="block mb-2">
            {{
            "ticketComponent.editForm.sections.customerContactPerson.labels.type"
            | translate}}
          </label>
          <input
            [disabled]="true"
            id="type"
            pInputText
            type="text"
            class="w-full block"
            [value]="translateType(selectedCustomerContactPerson.type)" />
        </div>
        <!-- CustomerContactPerson Type  | Field -->
      </div>

      <div class="col-12 md:col-6 lg:col-4">
        <!-- CustomerContactPerson Name | Field -->
        <div class="form-field">
          <label htmlFor="name" class="block mb-2">
            {{
            "ticketComponent.editForm.sections.customerContactPerson.labels.name"
            | translate}}
          </label>
          <input
            [disabled]="true"
            id="name"
            pInputText
            type="text"
            class="w-full block"
            [value]="(selectedCustomerContactPerson.firstname ? selectedCustomerContactPerson.firstname + ' ' : '') + selectedCustomerContactPerson.lastname" />
        </div>
        <!-- CustomerContactPerson Name | Field -->
      </div>

      <div class="col-12 md:col-6 lg:col-4">
        <!-- CustomerContactPerson address | Field -->
        <div class="form-field">
          <label htmlFor="address" class="block mb-2">
            {{
            "ticketComponent.editForm.sections.customerContactPerson.labels.address"
            | translate}}
          </label>
          <input
            [disabled]="true"
            id="address"
            pInputText
            type="text"
            class="w-full block"
            tooltipPosition="top"
            [pTooltip]="formatCustomerContactAddress(selectedCustomerContactPerson)"
            [value]="formatCustomerContactAddress(selectedCustomerContactPerson)" />
        </div>
        <!-- CustomerContactPerson address | Field -->
      </div>
    </div>
  </div>

  <div *ngIf="selectedCustomerContactPerson">
    <div class="grid mt-2">
      <div class="col-12 md:col-6 lg:col-4">
        <!-- CustomerContactPerson phoneNumberMobile | Field -->
        <div class="form-field">
          <div class="flex gap-2">
            <label htmlFor="phoneNumberMobile" class="block mb-2">
              {{
              "ticketComponent.editForm.sections.customerContactPerson.labels.phoneNumberMobile"
              | translate}}
            </label>
            <a
              class="link"
              [ngClass]="{'white-space-nowrap': true}"
              href="tel:{{selectedCustomerContactPerson.phoneNumberMobile}}"
              ><span
                class="pi pi-phone"
                [pTooltip]="(selectedCustomerContactPerson.firstname ? selectedCustomerContactPerson.firstname + ' ' : '') +
                selectedCustomerContactPerson.lastname + ' ' +
                ('ticketComponent.editForm.sections.customerContactPerson.labels.callNowMobile' | translate)"></span>
            </a>
          </div>
          <input
            [disabled]="true"
            id="phoneNumberMobile"
            pInputText
            type="text"
            class="w-full block"
            [value]="selectedCustomerContactPerson.phoneNumberMobile" />
        </div>
        <!-- CustomerContactPerson phoneNumberMobile  | Field -->
      </div>

      <div class="col-12 md:col-6 lg:col-4">
        <!-- CustomerContactPerson phoneNumberLandline | Field -->
        <div class="form-field">
          <div class="flex gap-2">
            <label htmlFor="phoneNumberLandline" class="block mb-2">
              {{
              "ticketComponent.editForm.sections.customerContactPerson.labels.phoneNumberLandline"
              | translate}}
            </label>
            <a
              class="link"
              [ngClass]="{'white-space-nowrap': true}"
              href="tel:{{selectedCustomerContactPerson.phoneNumberLandline}}"
              ><span
                class="pi pi-phone"
                [pTooltip]="(selectedCustomerContactPerson.firstname ? selectedCustomerContactPerson.firstname + ' ' : '') +
                selectedCustomerContactPerson.lastname + ' ' +
                ('ticketComponent.editForm.sections.customerContactPerson.labels.callNowLandline' | translate)"></span>
            </a>
          </div>

          <input
            [disabled]="true"
            id="phoneNumberLandline"
            pInputText
            type="text"
            class="w-full block"
            [value]="selectedCustomerContactPerson.phoneNumberLandline" />
        </div>
        <!-- CustomerContactPerson Name | Field -->
      </div>

      <div class="col-12 md:col-6 lg:col-4">
        <!-- CustomerContactPerson email | Field -->
        <div class="form-field">
          <div class="flex gap-2">
            <label htmlFor="email" class="block mb-2">
              {{
              "ticketComponent.editForm.sections.customerContactPerson.labels.email"
              | translate}}
            </label>
            <a
              class="link"
              [ngClass]="{'white-space-nowrap': true}"
              href="mailto:{{selectedCustomerContactPerson.email}}"
              ><span
                class="pi pi-envelope"
                [pTooltip]="
                (selectedCustomerContactPerson.firstname ? selectedCustomerContactPerson.firstname + ' ' : '') +
                selectedCustomerContactPerson.lastname + ' ' +
                ('ticketComponent.editForm.sections.customerContactPerson.labels.mailNow' | translate)"></span>
            </a>
          </div>
          <input
            [disabled]="true"
            id="email"
            pInputText
            type="text"
            class="w-full block"
            tooltipPosition="top"
            [value]="selectedCustomerContactPerson.email" />
        </div>
        <!-- CustomerContactPerson email | Field -->
      </div>
    </div>
  </div>
</div>

<p-dialog
  [draggable]="false"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [(visible)]="customerContactPersonModalVisible"
  (onHide)="hideCustomerContactPersonModal()"
  [modal]="true"
  [style]="{ width: '75%' }">
  <!-- Dialog Header -->
  <ng-template pTemplate="header">
    <h2>
      {{'general.form.chooseSubject'| translate: {subject:
      ('ticketComponent.attributes.customerContactPerson' | translate)} }}
    </h2>
  </ng-template>

  <app-customer-contact-person-table
    *ngIf="ticket.customer"
    [showChooseAction]="true"
    [showCreateAction]="false"
    [showDeleteAction]="false"
    [showEditAction]="false"
    (handleCustomerContactPersonChosen)="onCustomerContactPersonSelectionChanged($event)"
    [customer]="ticket.customer"
    [customerContactPersons]="customerContactPersons"
    [countries]="countries"></app-customer-contact-person-table>

  <ng-template pTemplate="footer">
    <div class="text-left">
      <button
        pButton
        pRipple
        type="button"
        [label]="'general.form.cancel' | translate"
        (click)="hideCustomerContactPersonModal()"
        class="flex-auto p-button-outlined"></button>
    </div>
  </ng-template>
</p-dialog>
