import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { MessageCenterService } from 'src/app/services/message-center.service';
import { getHttpErrorTranslationKeys } from 'src/app/utils/errors/http-error';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor(
    private http: HttpClient,
    private messageCenterService: MessageCenterService
  ) {}

  downloadFormPdf(id: number): Observable<ArrayBuffer> {
    return this.http
      .get<ArrayBuffer>(`${environment.apiUrl}/forms/pdf/${id}/download`, {
        responseType: 'arraybuffer' as 'json'
      })
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('An error occurred:', error.error);

    const translation = getHttpErrorTranslationKeys(error);

    this.messageCenterService.showToast(
      translation.summary,
      translation.detail,
      'error'
    );

    return throwError(() => error);
  }
}
