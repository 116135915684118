import { getBackgroundColor, getIcon } from 'src/utils/ticket-helper';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Ticket } from 'src/app/models/Ticket';
import { LocalStorageService } from 'src/app/services/local-storage.service';

export type HeaderStyle = {
  background: string;
  color: string;
};

@Component({
  selector: 'app-ticket-summary',
  templateUrl: './ticket-summary.component.html',
  styleUrl: './ticket-summary.component.scss'
})
export class TicketSummaryComponent implements OnInit, OnDestroy {
  @Input({ required: true }) ticket!: Ticket;

  style!: HeaderStyle;

  colorScheme: 'dim' | 'light' | 'dark' = 'light';

  localStorageSubscription = new Subscription();

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.localStorageSubscription =
      this.localStorageService.itemValue.subscribe((value: string) => {
        if (value) {
          const { colorScheme } = JSON.parse(value);
          if (colorScheme) {
            this.colorScheme = colorScheme;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.localStorageSubscription.unsubscribe();
  }

  get backgroundColor(): string {
    return getBackgroundColor(this.ticket);
  }

  get icon(): string {
    return getIcon(this.ticket);
  }
}
