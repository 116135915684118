<div class="flex align-items-center">
  <span *ngIf="column.showTableHeader">
    {{ column.customLabel ? column.customLabel : translationKey +
    ".table.columns." + column.field + ".title" | translate }}
  </span>

  <p-sortIcon
    *ngIf="column.isSortable"
    [field]="column.scaffoldedField ? column.scaffoldedField : column.field"></p-sortIcon>
</div>
