import { Component } from '@angular/core';
import { Direction } from 'src/app/types/misc/Direction';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logged-out-layout',
  templateUrl: './logged-out-layout.component.html',
  styleUrls: ['./logged-out-layout.component.scss']
})
export class LoggedOutLayoutComponent {
  /**
   * Is needed to control on which direction the login mask is located.
   */
  style: Direction = 'right';

  constructor() {
    this.style = environment.loggedOutStyle;
  }
}
