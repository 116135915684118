import { GenericActionConfiguration } from './GenericActionConfiguration';
import { GenericRowActionConfiguration } from './GenericRowActionConfiguration';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class ActionClickedResponse {
  objectList?: any[];

  object?: any;

  action!: GenericActionConfiguration | GenericRowActionConfiguration;
}
