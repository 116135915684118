import { filterFactory } from 'src/app/utils/filter/filter.factory';

export const userFilter = filterFactory((value, filter) => {
  if (
    value &&
    typeof value === 'object' &&
    'firstname' in value &&
    'lastname' in value
  ) {
    return `${value.firstname} ${value.lastname}`.includes(filter);
  }

  return String(value).includes(filter);
});
