/**
 * Converts a base64 string to a File object.
 *
 * @param base64 - The base64 string to convert.
 * @param filename - The name of the file.
 * @returns The converted File object.
 */
export const base64ToFile = (base64: string, filename: string): File => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/);
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const mimetype = mime ? mime[1] : 'application/octet-stream';

  return new File([u8arr], filename, {
    type: mimetype
  });
};
