<app-logged-out-layout>
  <ng-container class="header-content">
    <div class="mb-4">
      <div class="text-900 text-xl font-bold mb-2">
        {{'forgetPasswordComponent.title' | translate }}
      </div>
      <span class="text-600 font-medium">
        {{'forgetPasswordComponent.subtitle' | translate }}</span
      >
    </div>
  </ng-container>

  <ng-container class="body-content">
    <form [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column">
        <span class="p-input-icon-left w-full mb-4">
          <i class="pi pi-envelope"></i>
          <input
            id="email"
            type="text"
            pInputText
            class="w-full"
            [placeholder]="'general.form.enterSubject' | translate: {subject: ('forgetPasswordComponent.form.email.placeholder' | translate)}"
            formControlName="email" />
        </span>
        <div class="flex flex-wrap gap-2 justify-content-between">
          <button
            pButton
            pRipple
            type="button"
            [label]="'general.form.cancel' | translate"
            class="flex-auto p-button-outlined"
            [routerLink]="['/login']"></button>
          <button
            pButton
            pRipple
            [label]="'general.form.submit' | translate"
            class="flex-auto"
            type="submit"
            [disabled]="!forgetPasswordForm.valid"></button>
        </div>
      </div>
    </form>
  </ng-container>
</app-logged-out-layout>
