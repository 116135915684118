import { Observable } from 'rxjs';

/**
 * Converts a Blob object to a base64 string.
 *
 * @param blob - The Blob object to convert.
 * @returns An Observable that emits the base64 string.
 */
export const blobToBase64 = (blob: Blob): Observable<string> =>
  new Observable((observer) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      observer.next(reader.result as string);
      observer.complete();
    };
  });
