import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from 'src/app/models/customer/Customer';

@Component({
  selector: 'app-customer-status-badge',
  templateUrl: './customer-status-badge.component.html',
  styleUrl: './customer-status-badge.component.scss'
})
export class CustomerStatusBadgeComponent {
  @Input({ required: true }) customer!: Customer;

  @Input() disabled = false;

  @Input() clickable = true;

  @Output() changeIsBlocked = new EventEmitter<boolean>();

  constructor(private readonly router: Router) {}

  handleOnMouseDown() {
    if (this.disabled || !this.clickable) {
      return;
    }

    this.changeIsBlocked.emit(!this.customer.isBlocked);
  }

  navigateToCustomer(customerId: number) {
    if (this.disabled || !this.clickable) {
      return;
    }

    this.router.navigate(['/customers', customerId]);
  }
}
