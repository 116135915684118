import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { HeatingEngineer } from 'src/app/models/HeatingEngineer';
import { MessageCenterService } from 'src/app/services/message-center.service';

import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class HeatingEngineerService extends GenericService<HeatingEngineer> {
  constructor(
    http: HttpClient,
    messageCenterService: MessageCenterService,
    translate: TranslateService
  ) {
    super(http, messageCenterService, translate);
    this.setContext('heating-engineers');
  }
}
