import { Component, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { customerCorrespondenceTableConfig } from 'src/app/components/admin/customer/views/components/customer-edit-correspondence/customer-edit-correspondences.config';
import { FormComponent } from 'src/app/components/templates/crud/form/form.component';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { Customer } from 'src/app/models/customer/Customer';
import { CustomerCorrespondence } from 'src/app/models/customer/CustomerCorrespondence';
import { CustomerCorrespondenceService } from 'src/app/services/api/customer/customer-correspondence.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Severity } from 'src/app/types/misc/Severity';

@Component({
  selector: 'app-customer-table-action-new-correspondence',
  templateUrl: './customer-table-action-new-correspondence.component.html',
  styleUrls: ['./customer-table-action-new-correspondence.component.scss']
})
export class CustomerTableActionNewCorrespondenceComponent
  implements OnDestroy
{
  @ViewChild('form') formComponent?: FormComponent;

  isVisible = false;

  customer: Customer | null = null;

  config = customerCorrespondenceTableConfig();

  editObject = new CustomerCorrespondence();

  createSubmitDisabled = true;

  createSubscription: Subscription | null = null;

  constructor(
    private readonly translate: TranslateService,
    private readonly messageCenterService: MessageCenterService,

    private readonly customerCorrespondenceService: CustomerCorrespondenceService
  ) {}

  ngOnDestroy(): void {
    this.createSubscription?.unsubscribe();
  }

  public setVisible(isVisible: Customer | false): void {
    if (typeof isVisible === 'object') {
      this.customer = isVisible;
      this.isVisible = true;
      this.editObject = new CustomerCorrespondence({
        customerId: this.customer.id
      });
    } else {
      this.isVisible = false;
      this.customer = null;
    }
  }

  async onSidebarVisibleChange(isVisible: boolean) {
    if (isVisible === true) {
      this.isVisible = true;

      return;
    }

    if (this.formComponent?.form?.dirty === true) {
      await new Promise<void>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant('general.confirmUnsavedChanges.header'),
          this.translate.instant('general.confirmUnsavedChanges.message'),
          () => {
            this.setVisible(false);
            resolve();
          },
          () => {
            resolve();
            this.isVisible = !isVisible;
          }
        );
      });

      return;
    }

    this.isVisible = isVisible;
  }

  onSubmit() {
    if (
      this.formComponent &&
      this.formComponent.form &&
      this.formComponent.form.valid
    ) {
      this.config.columns.forEach((col) => {
        if (col.formControl && this.editObject) {
          // @ts-expect-error Should be fine probably
          this.editObject[col.field] = this.formComponent?.form.get(
            col.field
          )?.value;
        }
      });
      this.formComponent.form.markAsUntouched();
      this.formComponent.form.markAsPristine();

      this.createCustomerCorrespondence(this.editObject);
    }
  }

  createCustomerCorrespondence(
    customerCorrespondence: CustomerCorrespondence
  ): void {
    this.createSubscription = this.customerCorrespondenceService
      .createIncludingFiles(customerCorrespondence)
      .subscribe((newCc) => {
        if (newCc) {
          if (this.isVisible) {
            this.isVisible = !this.isVisible;
          }

          this.showCrudToast(DataModificationMethod.Create, 'success', newCc);
        } else {
          this.showCrudToast(
            DataModificationMethod.Create,
            'error',
            customerCorrespondence
          );
        }
      });
  }

  formChanged(): void {
    this.createSubmitDisabled = this.getCreateSubmitDisabled();
  }

  getCreateSubmitDisabled(): boolean {
    const form = this.formComponent?.form;
    if (form) {
      return !form || !form.valid;
    }

    return true;
  }

  showCrudToast(
    method: DataModificationMethod,
    severity: Severity,
    cc: CustomerCorrespondence
  ): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `customerCorrespondenceComponent.actions.toasts.${method}.${severity}.summary`,
        { title: cc.title }
      ),
      this.translate.instant(
        `customerCorrespondenceComponent.actions.toasts.${method}.${severity}.detail`,
        { title: cc.title }
      ),
      severity
    );
  }

  showToast(severity: Severity, method: string): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `customerCorrespondenceComponent.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `customerCorrespondenceComponent.actions.toasts.${method}.${severity}.detail`
      ),
      severity
    );
  }
}
