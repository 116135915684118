import { Component, Input } from '@angular/core';
import * as sanitizeHtml from 'sanitize-html';

const RE_REG_EXP_CHAR = /[\\^$.*+?()[\]{}|]/g;

const RE_HAS_REG_EXP_CHAR = RegExp(RE_REG_EXP_CHAR.source);

@Component({
  selector: 'app-search-highlight',
  templateUrl: './search-highlight.component.html',
  styleUrls: ['./search-highlight.component.scss']
})
export class SearchHighlightComponent {
  @Input({ required: true }) content!: string;

  @Input() search: string | null = null;

  get highlightedContent(): string {
    if (!this.search || !this.content) {
      return this.content;
    }

    const highlightClasses = 'text-highlight';

    const searchRegex = new RegExp(`(${this.escapeRegExp(this.search)})`, 'ig');

    const highlightedText = this.content.replace(
      searchRegex,
      `<span class="${highlightClasses}">$1</span>`
    );

    return sanitizeHtml(highlightedText, {
      allowedClasses: {
        span: [highlightClasses]
      }
    });
  }

  private escapeRegExp(string: string): string {
    return string && RE_HAS_REG_EXP_CHAR.test(string)
      ? string.replace(RE_REG_EXP_CHAR, '\\$&')
      : string || '';
  }
}
