<app-logged-out-layout>
  <ng-container class="header-content">
    <div class="text-900 text-xl font-bold mb-2">
      {{ 'loginComponent.title' | translate }}
    </div>
    <span class="text-600 font-medium"
      >{{ 'loginComponent.subtitle' | translate }}</span
    >
  </ng-container>

  <ng-container class="body-content">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column">
        <span class="p-input-icon-left w-full mb-4">
          <i class="pi pi-envelope"></i>
          <input
            id="email"
            type="text"
            pInputText
            class="w-full"
            [placeholder]="'general.form.enterSubject' | translate: {subject: ('loginComponent.form.email.placeholder' | translate)}"
            formControlName="email" />
        </span>
        <span
          class="p-input-icon-left w-full mb-4"
          *ngIf="loginMethod === 'standard'">
          <i class="pi pi-lock"></i>
          <input
            id="password"
            type="password"
            pInputText
            class="w-full"
            [placeholder]="'general.form.enterSubject' | translate: {subject: ('loginComponent.form.password.placeholder' | translate)}"
            formControlName="password" />
        </span>

        <div class="mb-4 flex flex-wrap gap-3">
          <a
            [routerLink]="['/forgot-password']"
            class="text-600 cursor-pointer hover:text-primary cursor-pointer ml-auto transition-colors transition-duration-300"
            >{{ 'loginComponent.reset' | translate }}</a
          >
        </div>
        <button
          pButton
          pRipple
          [label]="'loginComponent.title' | translate"
          class="w-full"
          type="submit"></button>
      </div>
    </form>
  </ng-container>
</app-logged-out-layout>
