import { FileDto } from 'src/app/models/FileDto';

export const CustomerCorrespondenceType = {
  Email: 'Email',
  Phone: 'Phone',
  Note: 'Note',
  Other: 'Other'
} as const;

export type CustomerCorrespondenceType =
  (typeof CustomerCorrespondenceType)[keyof typeof CustomerCorrespondenceType];

export class CustomerCorrespondence {
  readonly id?: number;

  title!: string;

  type!: CustomerCorrespondenceType;

  description?: string | undefined;

  customerId!: number;

  files?: FileDto[] = [];

  readonly createdAt?: string;

  createdById?: number;

  readonly updatedAt?: string;

  readonly updatedById?: number;

  readonly deletedAt?: string | null;

  readonly deletedById?: number | null;

  constructor(config?: Partial<CustomerCorrespondence>) {
    Object.assign(this, config);
  }
}
