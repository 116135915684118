/* eslint-disable max-lines */

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { Subscription } from 'rxjs';
import { heatingEngineerTableConfig } from 'src/app/components/admin/heating-engineer/heating-engineer.config';
import { LoggingComponent } from 'src/app/components/misc/logging/logging.component';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { ActionClickedResponse } from 'src/app/models/ActionClickedResponse';
import { CellChangedResponse } from 'src/app/models/CellChangedResponse';
import { Country } from 'src/app/models/Country';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { HeatingEngineer } from 'src/app/models/HeatingEngineer';
import { LazyListDto } from 'src/app/models/LazyListDto';
import { CountryService } from 'src/app/services/api/country.service';
import { HeatingEngineerService } from 'src/app/services/api/heating-engineer.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { DataService } from 'src/app/services/utils/data.service';
import { EditMethod } from 'src/app/types/misc/EditMethod';
import { Severity } from 'src/app/types/misc/Severity';

@Component({
  selector: 'app-heating-engineer',
  templateUrl: './heating-engineer.component.html',
  styleUrls: ['./heating-engineer.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class HeatingEngineerComponent implements OnInit, OnDestroy {
  config!: GenericTableConfiguration;

  heatingEngineerList: HeatingEngineer[] = [];

  totalRecords!: number;

  editHeatingEngineer?: HeatingEngineer | null;

  editMethod: EditMethod = 'sidebar';

  createMethod: EditMethod = 'sidebar';

  sidebarVisible = false;

  separatePageVisible = false;

  tableVisible = true;

  heatingEngineerSubscription: Subscription = new Subscription();

  totalRecordsSubscription: Subscription = new Subscription();

  lazyLoadTableEvent?: TableLazyLoadEvent;

  countries: Country[] = [];

  countriesSubscription: Subscription | null = null;

  @ViewChild('loggingComponent') loggingComponent!: LoggingComponent;

  constructor(
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService,
    private readonly heatingEngineerService: HeatingEngineerService,
    private readonly dataService: DataService<HeatingEngineer>,
    private readonly countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.countriesSubscription = this.countryService
      .findAll()
      .subscribe((countries) => {
        if (countries) {
          this.countries = countries;
          this.countries.forEach((country: Country) => {
            country.label = this.translate.instant(`countries.${country.key}`);
          });
          this.config = heatingEngineerTableConfig(this.countries);
          if (!this.config.lazyLoad) {
            this.heatingEngineerSubscription = this.heatingEngineerService
              .findAll()
              .subscribe((heatingEngineers: HeatingEngineer[]) => {
                this.heatingEngineerList = heatingEngineers;
              });
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.heatingEngineerSubscription) {
      this.heatingEngineerSubscription.unsubscribe();
    }
    if (this.totalRecordsSubscription) {
      this.totalRecordsSubscription.unsubscribe();
    }
  }

  lazyLoadTableEventFired(event: TableLazyLoadEvent): void {
    if (this.config.lazyLoad) {
      this.lazyLoadTableEvent = event;
      this.heatingEngineerSubscription = this.heatingEngineerService
        .lazyLoad(event)
        .subscribe(
          (lazyHeatingEngineerListDto: LazyListDto<HeatingEngineer>) => {
            this.heatingEngineerList = lazyHeatingEngineerListDto.objects;
            this.totalRecords = lazyHeatingEngineerListDto.totalRecords;
          }
        );
    }
  }

  heatingEngineerChanged(heatingEngineer: HeatingEngineer): void {
    if (heatingEngineer.country && heatingEngineer.country.id) {
      heatingEngineer.countryId = heatingEngineer.country.id;
    }

    if (heatingEngineer.id) {
      this.updateHeatingEngineer(heatingEngineer.id, heatingEngineer);
    } else {
      this.createHeatingEngineer(heatingEngineer);
    }
  }

  createHeatingEngineer(heatingEngineer: HeatingEngineer): void {
    if (heatingEngineer.country && heatingEngineer.country.id) {
      heatingEngineer.countryId = heatingEngineer.country.id;
    }

    this.heatingEngineerService
      .create(heatingEngineer)
      .subscribe((newHeatingEngineer) => {
        if (newHeatingEngineer) {
          this.heatingEngineerList = this.dataService.modifyList(
            this.heatingEngineerList,
            newHeatingEngineer,
            DataModificationMethod.Create
          );
          if (this.sidebarVisible) {
            this.sidebarVisible = !this.sidebarVisible;
          }
          this.showCrudToast(
            DataModificationMethod.Create,
            'success',
            newHeatingEngineer
          );
        } else {
          this.showCrudToast(
            DataModificationMethod.Create,
            'error',
            heatingEngineer
          );
        }
      });
  }

  onCellChanged(response: CellChangedResponse): void {
    const heatingEngineer = new HeatingEngineer(response.object);

    if (heatingEngineer.id) {
      this.updateHeatingEngineer(heatingEngineer.id, heatingEngineer);
    }
  }

  updateHeatingEngineer(id: number, heatingEngineer: HeatingEngineer): void {
    this.heatingEngineerService
      .edit(id, heatingEngineer)
      .subscribe((updatedHeatingEngineer) => {
        if (updatedHeatingEngineer) {
          this.heatingEngineerList = this.dataService.modifyList(
            this.heatingEngineerList,
            updatedHeatingEngineer,
            DataModificationMethod.Edit
          );
          if (this.sidebarVisible) {
            this.sidebarVisible = !this.sidebarVisible;
          }
          this.reloadData();
          this.loggingComponent?.fetchLoggingView();

          this.showCrudToast(
            DataModificationMethod.Edit,
            'success',
            updatedHeatingEngineer
          );
        } else {
          this.showCrudToast(
            DataModificationMethod.Edit,
            'error',
            heatingEngineer
          );
        }
      });
  }

  rowActionClicked(response: ActionClickedResponse): void {
    switch (response.action.identifier) {
      case 'create':
        this.create();
        break;
      case 'edit':
        this.edit(response.object);
        break;
      case 'delete':
        this.delete(response.object);
        break;
      case 'columnClicked':
        this.edit(response.object);
        break;
      default:
        console.warn('Unknown action:', response);
        break;
    }
  }

  edit(data: HeatingEngineer): void {
    if (this.editMethod === 'sidebar') {
      this.sidebarVisible = this.editMethod === 'sidebar';
    } else {
      this.separatePageVisible = this.editMethod === 'page';
      this.tableVisible = false;
    }
    this.editHeatingEngineer = data;
  }

  create(): void {
    if (this.createMethod === 'sidebar') {
      this.sidebarVisible = this.createMethod === 'sidebar';
    } else {
      this.separatePageVisible = this.createMethod === 'page';
      this.tableVisible = false;
    }
    this.editHeatingEngineer = new HeatingEngineer();
  }

  delete(data: HeatingEngineer): void {
    if (data.id) {
      let severity: 'error' | 'success' = 'error';
      this.heatingEngineerService
        .delete(data.id)
        .subscribe((deletedHeatingEngineer: HeatingEngineer) => {
          if (deletedHeatingEngineer) {
            severity = 'success';
            this.heatingEngineerList = this.dataService.modifyList(
              this.heatingEngineerList,
              data,
              DataModificationMethod.Delete
            );
            this.reloadData();
          }
          this.showCrudToast(
            DataModificationMethod.Delete,
            severity,
            deletedHeatingEngineer
          );
        });
    }
  }

  sidebarVisibleChange(visible: boolean): void {
    this.sidebarVisible = visible;
    if (this.editHeatingEngineer && !this.sidebarVisible) {
      this.editHeatingEngineer = null;
    }
  }

  separatePageVisibleChange(visible: boolean): void {
    this.separatePageVisible = visible;
    if (this.editHeatingEngineer && !this.separatePageVisible) {
      this.editHeatingEngineer = null;
    }
  }

  tableVisibleChange(visible: boolean): void {
    this.tableVisible = visible;
  }

  reloadData(): void {
    if (this.config.lazyLoad && this.lazyLoadTableEvent) {
      this.lazyLoadTableEventFired(this.lazyLoadTableEvent);
    }
  }

  showCrudToast(
    method: DataModificationMethod,
    severity: Severity,
    heatingEngineer: HeatingEngineer
  ): void {
    // ToDo: add keys
    this.messageCenterService.showToast(
      this.translate.instant(
        `heatingEngineerComponent.actions.toasts.${method}.${severity}.summary`,
        {
          name: heatingEngineer.name,
          heatingEngineerNumber: heatingEngineer.heatingEngineerNumber
        }
      ),
      this.translate.instant(
        `heatingEngineerComponent.actions.toasts.${method}.${severity}.detail`,
        {
          name: heatingEngineer.name,
          heatingEngineerNumber: heatingEngineer.heatingEngineerNumber
        }
      ),
      severity
    );
  }
}
