<form autocomplete="off" [formGroup]="form">
  <div class="flex gap-4">
    <div class="form-field mb-2 w-4">
      <!-- TICKET-CATEGORY -->
      <label htmlFor="subject" class="block">
        {{ "ticketComponent.attributes.ticketCategory" | translate}}*
      </label>
      <p-dropdown
        appendTo="body"
        formControlName="ticketCategory"
        [options]="categoryChips"
        optionLabel="label"
        class="w-full mt-2 block"
        (onChange)="onTicketChanged()"
        [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.ticketCategory' | translate)}">
        <ng-template let-item pTemplate="item">
          <div class="inline-block vertical-align-middle">
            <app-ticket-chip [label]="item"></app-ticket-chip>
          </div>
        </ng-template>
        <ng-template let-selectedItem pTemplate="selectedItem">
          <div class="inline-flex align-items-center gap-2 px-1">
            <app-ticket-chip [label]="selectedItem"></app-ticket-chip>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="form-field mb-2 w-4">
      <!-- TICKET-STATUS -->
      <label htmlFor="subject" class="block">
        {{ "ticketComponent.attributes.ticketStatus" | translate}}*
      </label>
      <p-dropdown
        appendTo="body"
        formControlName="ticketStatus"
        [options]="statusChips"
        optionLabel="label"
        class="w-full mt-2 block"
        (onChange)="onTicketChanged()"
        [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.ticketStatus' | translate)}">
        <ng-template let-item pTemplate="item">
          <div class="inline-block vertical-align-middle">
            <app-ticket-chip [label]="item"></app-ticket-chip>
          </div>
        </ng-template>
        <ng-template let-selectedItem pTemplate="selectedItem">
          <div class="inline-flex align-items-center gap-2 px-1">
            <app-ticket-chip [label]="selectedItem"></app-ticket-chip>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <!-- EDITOR -->
    <div class="form-field mb-2 w-4">
      <label htmlFor="subject" class="block mb-2">
        {{ "ticketComponent.attributes.editor" | translate}}*
      </label>
      <p-dropdown
        *ngIf="editors"
        appendTo="body"
        formControlName="editor"
        [options]="editors"
        dataKey="id"
        optionLabel="filterOptionLabel"
        [filter]="true"
        class="w-full mt-2 block"
        (onChange)="editorChanged($event)"
        [placeholder]="'general.form.chooseSubject'| translate:
      {subject: ('ticketComponent.attributes.editor' | translate)}">
        <ng-template let-item pTemplate="item">
          <div
            class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap">
            <app-user-avatar [user]="item" size="normal" />
            <span>{{item.firstname}} {{item.lastname}}</span>
          </div>
        </ng-template>
        <ng-template let-selectedItem pTemplate="selectedItem">
          <div
            *ngIf="selectedItem"
            class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap">
            <app-user-avatar [user]="selectedItem" size="normal" />
            <span>{{selectedItem.firstname}} {{selectedItem.lastname}}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
</form>
<div class="flex gap-4">
  <div class="form-field mb-2 mt-2 w-4 flex gap-2">
    <p-inputSwitch
      [(ngModel)]="showDevices"
      [disabled]="!ticket.customer"
      (ngModelChange)="onShowDevicesChange()"
      [disabled]="disabled"
      class="mt-2 block">
    </p-inputSwitch>
    <label htmlFor="subject" class="block mb-2 align-content-end">
      {{ "ticketComponent.attributes.showDevice" | translate}}
    </label>
  </div>
  <div class="form-field mb-2 mt-2 w-4 flex gap-2">
    <p-inputSwitch
      [(ngModel)]="ticket.isAppointmentNeeded"
      (ngModelChange)="onIsAppointmentNeededChange()"
      [disabled]="disabled"
      class="mt-2 block">
    </p-inputSwitch>
    <label htmlFor="subject" class="block mb-2 align-content-end">
      {{ "ticketComponent.attributes.planAppointment" | translate}}
    </label>
  </div>
  <div class="form-field mb-2 mt-2 w-4 flex gap-2">
    <p-inputSwitch
      [(ngModel)]="showForm"
      (ngModelChange)="onShowFormChange()"
      [disabled]="disabled"
      class="mt-2 block">
    </p-inputSwitch>
    <label htmlFor="subject" class="block mb-2 align-content-end">
      {{ "ticketComponent.attributes.fillForm" | translate}}
    </label>
  </div>
</div>

<div class="flex gap-4" *ngIf="showReoccurring">
  <div class="form-field mb-2 w-4">
    <label id="reoccurring-label" htmlFor="subject" class="block mt-1 mb-4">
      {{ "ticketComponent.attributes.reoccurringTicket" | translate}}
    </label>
    <p-inputSwitch
      [(ngModel)]="isReoccurringTicket"
      (ngModelChange)="onIsReoccurringTicketChange()"
      [disabled]="disabled"
      class="mt-2 block">
    </p-inputSwitch>
  </div>
  <div class="form-field mb-2 mt-2 w-4" *ngIf="isReoccurringTicket">
    <label htmlFor="subject" class="block mb-2 align-content-end">
      {{ "ticketComponent.attributes.interval" | translate}}
    </label>
    <p-dropdown
      appendTo="body"
      [options]="reoccurring"
      [(ngModel)]="selectedReoccurring"
      optionLabel="label"
      class="w-full mt-2 block"
      (onChange)="onIsReoccurringTicketChange()"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.interval' | translate)}">
      <ng-template let-item pTemplate="item">
        <div class="inline-block vertical-align-middle">
          {{'ticketComponent.attributes.intervalOptions.'+item | translate}}
        </div>
      </ng-template>
      <ng-template let-selectedItem pTemplate="selectedItem">
        <div class="inline-flex align-items-center gap-2 px-1">
          {{'ticketComponent.attributes.intervalOptions.'+selectedItem |
          translate}}
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="form-field mb-2 mt-2 w-4" *ngIf="isReoccurringTicket">
    <label htmlFor="subject" class="block mb-2 align-content-end">
      {{ "ticketComponent.attributes.ExecutionDate" | translate}}
    </label>
    <p-calendar
      [firstDayOfWeek]="1"
      appendTo="body"
      [style]="{'width':'100%'}"
      [placeholder]="'ticketComponent.attributes.ExecutionDate' | translate"
      [readonlyInput]="true"
      [(ngModel)]="ticket.executionDate"
      (ngModelChange)="onExecutionDateChanged($event)"
      [dateFormat]="'dd.mm.yy'"></p-calendar>
  </div>
</div>
