import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';

import { Country } from 'src/app/models/Country';
import {
  Customer,
  CustomerCreate,
  FacilityAddressType
} from 'src/app/models/customer/Customer';
import { CustomerService } from 'src/app/services/api/customer/customer.service';
import { MessageCenterService } from 'src/app/services/message-center.service';

type Option = {
  value: string;
  label: string;
};

const DEFAULT_STRING_VALIDATORS = [
  Validators.required,
  Validators.minLength(2),
  Validators.maxLength(255)
];

@Component({
  selector: 'app-customer-create-form',
  templateUrl: './customer-create-form.component.html',
  styleUrls: ['./customer-create-form.component.scss']
})
export class CustomerCreateFormComponent implements OnInit {
  @Input({ required: true }) customer!: CustomerCreate;

  @Input({ required: true }) customers!: Customer[];

  @Input({ required: true }) countries: Country[] = [];

  formGroup: FormGroup | null = null;

  isBillingAddressDifferent = false;

  facilityAddressTypeOptions: Option[] = [];

  constructor(
    private readonly translate: TranslateService,
    private readonly messageCenterService: MessageCenterService,
    private readonly customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(this.customer.name, {
        validators: DEFAULT_STRING_VALIDATORS
      }),
      customerNumber: new FormControl(
        this.translate.instant('general.loading'),
        {
          validators: DEFAULT_STRING_VALIDATORS
        }
      ),
      company: new FormControl(this.customer.company),

      billingAddress: new FormGroup({
        street: new FormControl(this.customer.billingAddress.street, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        city: new FormControl(this.customer.billingAddress.city, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        postalCode: new FormControl(this.customer.billingAddress.postalCode, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        countryId: new FormControl(this.customer.billingAddress.countryId, {
          validators: [Validators.required]
        })
      }),
      billingAddressEmail: new FormControl(this.customer.billingAddressEmail, {
        validators: [Validators.email]
      }),
      billingRecipient: new FormControl(this.customer.billingRecipient),

      facilityAddress: new FormGroup({
        street: new FormControl(this.customer.facilityAddress.street, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        city: new FormControl(this.customer.facilityAddress.city, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        postalCode: new FormControl(this.customer.facilityAddress.postalCode, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        countryId: new FormControl(this.customer.facilityAddress.countryId, {
          validators: [Validators.required]
        })
      }),

      facilityAddressType: new FormControl(this.customer.facilityAddressType, {
        validators: DEFAULT_STRING_VALIDATORS
      }),
      contactPerson: new FormGroup({
        firstname: new FormControl(this.customer.contactPerson.firstname, {
          validators: [Validators.maxLength(255)]
        }),
        lastname: new FormControl(this.customer.contactPerson.lastname, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        phoneNumberMobile: new FormControl(
          this.customer.contactPerson.phoneNumberMobile
        ),
        phoneNumberLandline: new FormControl(
          this.customer.contactPerson.phoneNumberLandline
        ),
        email: new FormControl(this.customer.contactPerson.email, {
          validators: [Validators.email]
        })
      })
    });

    this.formGroup.get('customerNumber')?.disable();

    this.facilityAddressTypeOptions = Object.entries(FacilityAddressType).map(
      ([key, value]) => ({
        value: key,
        label: this.translate.instant(
          `customerComponent.facilityAddressType.${value}`
        )
      })
    );

    this.customerService
      .getNextCustomerNumber()
      .pipe(first())
      .subscribe(({ customerNumber }) => {
        this.customer.customerNumber = customerNumber;
        this.formGroup?.get('customerNumber')?.setValue(customerNumber);
      });

    this.onChangeIsBillingAddressDifferent(false);
  }

  onChangeIsBillingAddressDifferent(value: boolean): void {
    if (!this.formGroup) {
      return;
    }

    // @ts-expect-error Its not properly typed but the values are there
    const controls = this.formGroup.controls.billingAddress.controls as Record<
      string,
      AbstractControl
    >;

    const controlsBilling =
      // @ts-expect-error Its not properly typed but the values are there
      this.formGroup.controls.facilityAddress.controls as Record<
        string,
        AbstractControl
      >;

    if (value === false) {
      controls['street'].clearValidators();
      controls['city'].clearValidators();
      controls['postalCode'].clearValidators();
      controls['countryId'].clearValidators();
    } else {
      controls['street'].setValidators(DEFAULT_STRING_VALIDATORS);
      controls['city'].setValidators(DEFAULT_STRING_VALIDATORS);
      controls['postalCode'].setValidators(DEFAULT_STRING_VALIDATORS);
      controls['countryId'].setValidators(Validators.required);

      controls['street'].setValue(controlsBilling['street'].value);
      controls['city'].setValue(controlsBilling['city'].value);
      controls['postalCode'].setValue(controlsBilling['postalCode'].value);
      controls['countryId'].setValue(controlsBilling['countryId'].value);
    }

    controls['street'].updateValueAndValidity();
    controls['city'].updateValueAndValidity();
    controls['postalCode'].updateValueAndValidity();
    controls['countryId'].updateValueAndValidity();
  }

  get selectedBillingAddressCountry(): Country | undefined {
    if (!this.formGroup) {
      return undefined;
    }

    const countryId = this.formGroup.get('billingAddress.countryId')?.value;

    if (!countryId) {
      return undefined;
    }

    return this.countries.find((country) => country.id === countryId);
  }

  get selectedFacilityAddressCountry(): Country | undefined {
    if (!this.formGroup) {
      return undefined;
    }

    const countryId = this.formGroup.get('facilityAddress.countryId')?.value;

    if (!countryId) {
      return undefined;
    }

    return this.countries.find((country) => country.id === countryId);
  }

  public buildCustomer(): CustomerCreate {
    const facilityAddress = {
      street: this.formGroup?.get('facilityAddress.street')?.value,
      city: this.formGroup?.get('facilityAddress.city')?.value,
      postalCode: this.formGroup?.get('facilityAddress.postalCode')?.value,
      countryId: this.formGroup?.get('facilityAddress.countryId')?.value
    };
    const contactPerson = {
      firstname: this.formGroup?.get('contactPerson.firstname')?.value,
      lastname: this.formGroup?.get('contactPerson.lastname')?.value,
      phoneNumberMobile: this.formGroup?.get('contactPerson.phoneNumberMobile')
        ?.value,
      phoneNumberLandline: this.formGroup?.get(
        'contactPerson.phoneNumberLandline'
      )?.value,
      email: this.formGroup?.get('contactPerson.email')?.value
    };

    return {
      name: this.formGroup?.get('name')?.value,
      customerNumber: this.formGroup?.get('customerNumber')?.value,
      company: this.formGroup?.get('company')?.value,

      contactPerson,

      facilityAddress,
      facilityAddressType: this.formGroup?.get('facilityAddressType')?.value,

      billingAddressEmail: this.formGroup?.get('billingAddressEmail')?.value,
      billingRecipient: this.formGroup?.get('billingRecipient')?.value,
      billingAddress: this.isBillingAddressDifferent
        ? {
            street: this.formGroup?.get('billingAddress.street')?.value,
            city: this.formGroup?.get('billingAddress.city')?.value,
            postalCode: this.formGroup?.get('billingAddress.postalCode')?.value,
            countryId: this.formGroup?.get('billingAddress.countryId')?.value
          }
        : { ...facilityAddress }
    };
  }
}
