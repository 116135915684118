/* eslint-disable max-lines */

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { first, Subscription } from 'rxjs';

import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { ActionClickedResponse } from 'src/app/models/ActionClickedResponse';
import { CellChangedResponse } from 'src/app/models/CellChangedResponse';
import { Device } from 'src/app/models/Device';
import { DeviceManufacturer } from 'src/app/models/DeviceManufacturer';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { LazyListDto } from 'src/app/models/LazyListDto';
import { DeviceManufacturerService } from 'src/app/services/api/device-manufacturer.service';
import { DeviceService } from 'src/app/services/api/device.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { DataService } from 'src/app/services/utils/data.service';
import { EditMethod } from 'src/app/types/misc/EditMethod';
import { Severity } from 'src/app/types/misc/Severity';
import {
  displayLoggingContext,
  LoggingFormatter,
  LoggingFormatterBuilder
} from 'src/app/utils/logging';

import { deviceTableConfig } from './device.config';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class DeviceComponent implements OnInit, OnDestroy {
  config!: GenericTableConfiguration;

  deviceList: Device[] = [];

  manufacturers: DeviceManufacturer[] = [];

  totalRecords!: number;

  editDevice?: Device | null;

  editMethod: EditMethod = 'sidebar';

  createMethod: EditMethod = 'sidebar';

  sidebarVisible = false;

  separatePageVisible = false;

  tableVisible = true;

  deviceSubscription: Subscription = new Subscription();

  totalRecordsSubscription: Subscription = new Subscription();

  lazyLoadTableEvent?: TableLazyLoadEvent;

  loggingFormatter: LoggingFormatter | null = null;

  constructor(
    private messageCenterService: MessageCenterService,
    private translate: TranslateService,
    private deviceService: DeviceService,
    private dataService: DataService<Device>,
    private deviceManufacturerService: DeviceManufacturerService
  ) {
    this.config = deviceTableConfig(this.manufacturers);
  }

  ngOnInit(): void {
    if (!this.config.lazyLoad) {
      this.deviceSubscription = this.deviceService
        .findAll()
        .subscribe((devices: Device[]) => {
          this.deviceList = devices;
        });
    }

    this.deviceManufacturerService
      .findAll()
      .pipe(first())
      .subscribe((manufacturers: DeviceManufacturer[]) => {
        this.manufacturers = manufacturers;
        this.config = deviceTableConfig(this.manufacturers);
      });

    this.loggingFormatter = LoggingFormatterBuilder.new<{
      [K in keyof Device]: unknown;
    }>()
      .add('manufacturerId', displayLoggingContext)
      .build();
  }

  ngOnDestroy(): void {
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
    if (this.totalRecordsSubscription) {
      this.totalRecordsSubscription.unsubscribe();
    }
  }

  lazyLoadTableEventFired(event: TableLazyLoadEvent): void {
    if (this.config.lazyLoad) {
      this.lazyLoadTableEvent = event;
      this.deviceSubscription = this.deviceService
        .lazyLoad(event)
        .subscribe((lazyDeviceListDto: LazyListDto<Device>) => {
          this.deviceList = lazyDeviceListDto.objects;
          this.totalRecords = lazyDeviceListDto.totalRecords;
        });
    }
  }

  deviceChanged(device: Device): void {
    if (device.id) {
      this.updateDevice(device.id, device);
    } else {
      this.createDevice(device);
    }
  }

  createDevice(device: Device): void {
    this.deviceService.createIncludingFiles(device).subscribe((newDevice) => {
      if (newDevice) {
        this.reloadData();
        if (this.sidebarVisible) {
          this.sidebarVisible = !this.sidebarVisible;
        }
        this.showCrudToast(
          DataModificationMethod.Create,
          'success',
          newDevice.title
        );
      } else {
        this.showCrudToast(
          DataModificationMethod.Create,
          'error',
          device.title
        );
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCellChanged(response: CellChangedResponse): void {
    const device = new Device(response.object);

    if (device.id) {
      this.updateDevice(device.id, device);
    }
  }

  updateDevice(id: number, device: Device): void {
    if (device.files && device.files.length > 0) {
      device.files.forEach((file) => {
        if (file.createdBy) {
          Reflect.deleteProperty(file, 'createdBy');
        }
      });
    }
    this.deviceService
      .editIncludingFiles(id, device)
      .subscribe((updatedDevice) => {
        if (updatedDevice) {
          this.deviceList = this.dataService.modifyList(
            this.deviceList,
            updatedDevice,
            DataModificationMethod.Edit
          );
          if (this.sidebarVisible) {
            this.sidebarVisible = !this.sidebarVisible;
          }
          this.showCrudToast(
            DataModificationMethod.Edit,
            'success',
            updatedDevice.title
          );
          this.reloadData();
        } else {
          this.showCrudToast(
            DataModificationMethod.Edit,
            'error',
            device.title
          );
        }
      });
  }

  rowActionClicked(response: ActionClickedResponse): void {
    switch (response.action.identifier) {
      case 'create':
        this.create();
        break;
      case 'edit':
        this.edit(response.object);
        break;
      case 'delete':
        this.delete(response.object);
        break;
      case 'columnClicked':
        this.edit(response.object);
        break;
      default:
        break;
    }
  }

  edit(data: Device): void {
    if (this.editMethod === 'sidebar') {
      this.sidebarVisible = this.editMethod === 'sidebar';
    } else {
      this.separatePageVisible = this.editMethod === 'page';
      this.tableVisible = false;
    }
    this.editDevice = data;
  }

  create(): void {
    if (this.createMethod === 'sidebar') {
      this.sidebarVisible = this.createMethod === 'sidebar';
    } else {
      this.separatePageVisible = this.createMethod === 'page';
      this.tableVisible = false;
    }
    this.editDevice = new Device();
  }

  delete(data: Device): void {
    if (data.id) {
      let severity: 'error' | 'success' = 'error';
      this.deviceService.delete(data.id).subscribe((deletedDevice: Device) => {
        if (deletedDevice) {
          severity = 'success';
          this.deviceList = this.dataService.modifyList(
            this.deviceList,
            data,
            DataModificationMethod.Delete
          );
          this.reloadData();
        }
        this.showCrudToast(DataModificationMethod.Delete, severity, data.title);
      });
    }
  }

  sidebarVisibleChange(visible: boolean): void {
    this.sidebarVisible = visible;
    if (this.editDevice && !this.sidebarVisible) {
      this.editDevice = null;
    }
  }

  separatePageVisibleChange(visible: boolean): void {
    this.separatePageVisible = visible;
    if (this.editDevice && !this.separatePageVisible) {
      this.editDevice = null;
    }
  }

  tableVisibleChange(visible: boolean): void {
    this.tableVisible = visible;
  }

  reloadData(): void {
    if (this.config.lazyLoad && this.lazyLoadTableEvent) {
      this.lazyLoadTableEventFired(this.lazyLoadTableEvent);
    }
  }

  showCrudToast(
    method: DataModificationMethod,
    severity: Severity,
    deviceTitle: string | undefined
  ): void {
    const translateParam = deviceTitle ? deviceTitle : '';
    this.messageCenterService.showToast(
      this.translate.instant(
        `deviceComponent.actions.toasts.${method}.${severity}.summary`,
        { deviceTitle: translateParam }
      ),
      this.translate.instant(
        `deviceComponent.actions.toasts.${method}.${severity}.detail`,
        { deviceTitle: translateParam }
      ),
      severity
    );
  }
}
