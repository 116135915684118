/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FileDto } from 'src/app/models/FileDto';
import { GenericColumnConfiguration } from 'src/app/models/GenericColumnConfiguration';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { FormChangeDetectionService } from 'src/app/services/form-change-detection.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  @ViewChild('form') formElement!: NgForm;

  @Input() config!: GenericTableConfiguration;

  @Input() editObject: any;

  @Input() isInSeparatePage!: boolean;

  @Output() formChanged = new EventEmitter<boolean>();

  @Output() fileRemovedEmitter = new EventEmitter<File | FileDto>();

  form: FormGroup = new FormGroup({});

  formSubscription: Subscription = new Subscription();

  highestSlotIndex = 0;

  previousNumbersArray: number[] = [];

  constructor(
    private formChangeDetectionService: FormChangeDetectionService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.highestSlotIndex = Math.max(
      ...this.config.columns.map((col) => col.formSeparatePageIndex || 0)
    );

    this.previousNumbersArray = Array.from(
      { length: this.highestSlotIndex },
      // eslint-disable-next-line id-length
      (_, i) => i + 1
    );

    this.config.columns.forEach((col) => {
      if (col.defaultValue && this.editObject[col.field] === undefined) {
        this.editObject[col.field] = col.defaultValue;
      }
      if (
        col.formControl &&
        ((col.isCreateField && !this.editObject.id) ||
          (col.isEditField && this.editObject.id))
      ) {
        if (
          (col.type === 'dropdown' || col.type === 'enum') &&
          this.editObject[col.field]
        ) {
          const formControl = new FormControl(
            this.editObject[col.field].value
              ? this.editObject[col.field].value
              : this.editObject[col.field],
            col.formControl.validators
          );

          this.form.setControl(col.field, formControl);
        } else {
          const formControl = new FormControl(
            this.editObject[col.field],
            col.formControl.validators
          );
          this.form.setControl(col.field, formControl);
        }
      }
    });
    this.initializeFormSubscription();
  }

  ngOnDestroy(): void {
    this.formChangeDetectionService.removeForm(this.form);
    this.formSubscription.unsubscribe();
  }

  initializeFormSubscription(): void {
    this.formSubscription = this.form.valueChanges.subscribe((changes) => {
      if (changes) {
        this.formChangeDetectionService.addFormToFormArray(this.form);
        this.formChanged.emit(true);
      }
    });
  }

  isNumberInArrayAttribute(numberToCheck: number): boolean {
    const foundObject = this.config.columns.find(
      (col) => col.formSeparatePageIndex === numberToCheck
    );
    if (foundObject) {
      if (this.editObject.id && foundObject.isEditField) {
        if (foundObject.isEditField) {
          return true;
        }
      } else if (!this.editObject.id && foundObject.isCreateField) {
        return true;
      }
    }

    return false;
  }

  fileRemoved(file: FileDto | File): void {
    if (file) {
      this.formChanged.emit(true);
      this.fileRemovedEmitter.emit(file);
    }
  }

  filesChosen(files: File[], field: string): void {
    if (this.editObject[field] instanceof Array) {
      files.forEach((file) => {
        if (
          !this.editObject[field].find(
            (fileToFind: { name: string }) => fileToFind.name === file.name
          )
        ) {
          this.editObject[field].push(file);
        }
      });
      this.form.get(field)?.setValue(this.editObject[field]);
    } else {
      this.editObject[field] = files;
      this.form.get(field)?.setValue(files);
    }
    this.formChanged.emit(true);
  }

  isRequired(column: GenericColumnConfiguration<unknown>): boolean {
    const control = this.form?.get(column.field);

    return control?.hasValidator(Validators.required) ?? false;
  }
}
