import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';

import { ProfileComponent } from 'src/app/components/auth/profile/profile.component';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth/auth.service';

import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-profilemenu',
  templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent implements OnInit, OnDestroy {
  user: User | null = null;

  subscriptions = new Subscription();

  constructor(
    public layoutService: LayoutService,
    private authService: AuthService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.authService.user$.subscribe((user) => {
        if (user) {
          this.user = user;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get userCredentials(): string {
    if (!this.user) {
      return '';
    }

    return `${this.user.firstname} ${this.user.lastname}`;
  }

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.profileSidebarVisible = _val;
  }

  handleLogout(): void {
    this.visible = false;
    this.authService.logout();
  }

  viewUserProfile(): void {
    this.visible = false;

    if (!this.user) {
      return;
    }

    this.dialogService.open(ProfileComponent, {
      header: '',
      modal: true,
      width: '40%',
      data: {
        username: this.user.username
      }
    });
  }
}
