import { Ticket, TicketCategory, TicketStatus } from 'src/app/models/Ticket';
import { User } from 'src/app/models/User';

/**
 * Gets the possible ticket status types according to the task workflow.
 *
 * @param {Ticket} ticket - The ticket object.
 * @param {boolean} isCurrentUser - A boolean indicating if the current user is the ticket creator.
 * @returns {TicketStatus[]} - An array of possible ticket status types.
 */
export function getPossibleTicketStatusTypesAccordingToTaskWorkflow(
  ticket: Ticket,
  isCurrentUser: boolean
): TicketStatus[] {
  // Add the current status to the possible statuses so it's always visible
  const retVal: TicketStatus[] = [ticket.ticketStatusType];
  if (ticket.ticketStatusType === TicketStatus.Open && ticket.ticketNumber) {
    retVal.push(TicketStatus.InProgress);
    if (isCurrentUser) {
      retVal.push(TicketStatus.Cancelled);
    }
  }

  if (ticket.ticketStatusType === TicketStatus.InProgress) {
    retVal.push(TicketStatus.Done);
    if (isCurrentUser) {
      retVal.push(TicketStatus.Cancelled);
    }
  }

  if (ticket.ticketStatusType === TicketStatus.Done) {
    retVal.push(TicketStatus.InProgress);
    if (isCurrentUser) {
      retVal.push(TicketStatus.Completed);
    }
  }

  return retVal;
}

/**
 * Gets the possible ticket status types according to the commissioning workflow.
 *
 * @param {Ticket} ticket - The ticket object.
 * @returns {TicketStatus[]} - An array of possible ticket status types.
 */
export function getPossibleTicketStatusTypesAccordingToCommissioningWorkflow(
  ticket: Ticket
): TicketStatus[] {
  // Add the current status to the possible statuses so it's always visible
  const retVal: TicketStatus[] = [ticket.ticketStatusType];

  if (ticket.ticketStatusType === TicketStatus.Open && ticket.ticketNumber) {
    retVal.push(TicketStatus.InProgress);
    retVal.push(TicketStatus.Cancelled);
  }

  if (ticket.ticketStatusType === TicketStatus.InProgress) {
    retVal.push(TicketStatus.Done);
    retVal.push(TicketStatus.Cancelled);
    retVal.push(TicketStatus.Paused);
  }

  if (ticket.ticketStatusType === TicketStatus.Paused) {
    retVal.push(TicketStatus.InProgress);
  }

  if (ticket.ticketStatusType === TicketStatus.Done) {
    retVal.push(TicketStatus.Settled);
  }

  if (ticket.ticketStatusType === TicketStatus.Settled) {
    retVal.push(TicketStatus.Done);
    retVal.push(TicketStatus.Completed);
  }

  return retVal;
}

/**
 * Gets the possible ticket status types according to the emergency or malfunction workflow.
 *
 * @param {Ticket} ticket - The ticket object.
 * @returns {TicketStatus[]} - An array of possible ticket status types.
 */
export function getPossibleTicketStatusTypesAccordingToEmergencyOrMalfunctionWorkflow(
  ticket: Ticket
): TicketStatus[] {
  // Add the current status to the possible statuses so it's always visible
  const retVal: TicketStatus[] = [ticket.ticketStatusType];

  if (ticket.ticketStatusType === TicketStatus.Open && ticket.ticketNumber) {
    retVal.push(TicketStatus.InProgress);
    retVal.push(TicketStatus.Cancelled);
  }

  if (ticket.ticketStatusType === TicketStatus.InProgress) {
    retVal.push(TicketStatus.Done);
    retVal.push(TicketStatus.Cancelled);
    retVal.push(TicketStatus.Paused);
  }

  if (ticket.ticketStatusType === TicketStatus.Paused) {
    retVal.push(TicketStatus.InProgress);
  }

  if (ticket.ticketStatusType === TicketStatus.Done) {
    retVal.push(TicketStatus.Complaint);
    retVal.push(TicketStatus.Goodwill);
    retVal.push(TicketStatus.Settle);
  }

  if (ticket.ticketStatusType === TicketStatus.Complaint) {
    retVal.push(TicketStatus.Complained);
    retVal.push(TicketStatus.Goodwill);
    retVal.push(TicketStatus.Settle);
  }

  if (ticket.ticketStatusType === TicketStatus.Goodwill) {
    retVal.push(TicketStatus.Settled);
    retVal.push(TicketStatus.Complaint);
    retVal.push(TicketStatus.Settle);
  }

  if (ticket.ticketStatusType === TicketStatus.Settle) {
    retVal.push(TicketStatus.Settled);
    retVal.push(TicketStatus.Complaint);
    retVal.push(TicketStatus.Goodwill);
  }

  if (
    ticket.ticketStatusType === TicketStatus.Settled ||
    ticket.ticketStatusType === TicketStatus.Complained
  ) {
    retVal.push(TicketStatus.Completed);
  }

  return retVal;
}

/**
 * Gets the possible ticket status types according to the sgc or maintenance workflow.
 *
 * @param {Ticket} ticket - The ticket object.
 * @returns {TicketStatus[]} - An array of possible ticket status types.
 */
export function getPossibleTicketStatusTypesAccordingToSgcOrMaintenanceWorkflow(
  ticket: Ticket
): TicketStatus[] {
  // Add the current status to the possible statuses so it's always visible
  const retVal: TicketStatus[] = [ticket.ticketStatusType];

  if (ticket.ticketStatusType === TicketStatus.Planning) {
    retVal.push(TicketStatus.Open);
  }

  if (ticket.ticketStatusType === TicketStatus.Open && ticket.ticketNumber) {
    retVal.push(TicketStatus.InProgress);
    retVal.push(TicketStatus.Cancelled);
  }

  if (ticket.ticketStatusType === TicketStatus.Open && !ticket.ticketNumber) {
    retVal.push(TicketStatus.Planning);
  }

  if (ticket.ticketStatusType === TicketStatus.InProgress) {
    retVal.push(TicketStatus.Done);
    retVal.push(TicketStatus.Cancelled);
    retVal.push(TicketStatus.Paused);
  }

  if (ticket.ticketStatusType === TicketStatus.Done) {
    retVal.push(TicketStatus.Settled);
  }

  if (ticket.ticketStatusType === TicketStatus.Settled) {
    retVal.push(TicketStatus.Completed);
  }

  return retVal;
}

/**
 * Gets the possible ticket status types according to the workflow.
 *
 * @param {Ticket} ticket - The ticket object.
 * @param {User | null} currentUser - The current user object or null.
 * @returns {TicketStatus[]} - An array of possible ticket status types.
 */
export function getPossibleTicketStatusTypesAccordingToWorkflow(
  ticket: Ticket,
  currentUser: User | null
): TicketStatus[] {
  const isCurrentUser = ticket.createdById === currentUser?.id;

  if (ticket.ticketCategoryType === TicketCategory.Task) {
    return getPossibleTicketStatusTypesAccordingToTaskWorkflow(
      ticket,
      isCurrentUser
    );
  }

  if (ticket.ticketCategoryType === TicketCategory.Commissioning) {
    return getPossibleTicketStatusTypesAccordingToCommissioningWorkflow(ticket);
  }

  if (
    ticket.ticketCategoryType === TicketCategory.Emergency ||
    ticket.ticketCategoryType === TicketCategory.Malfunction
  ) {
    return getPossibleTicketStatusTypesAccordingToEmergencyOrMalfunctionWorkflow(
      ticket
    );
  }

  if (
    ticket.ticketCategoryType === TicketCategory.SGC ||
    ticket.ticketCategoryType === TicketCategory.Maintenance
  ) {
    return getPossibleTicketStatusTypesAccordingToSgcOrMaintenanceWorkflow(
      ticket
    );
  }

  return [TicketStatus.Open];
}
