import { Pipe, PipeTransform } from '@angular/core';
import { FileDto } from 'src/app/models/FileDto';
import { User } from 'src/app/models/User';

@Pipe({
  name: 'createdByFilter'
})
export class CreatedByFilterPipe implements PipeTransform {
  transform(files: FileDto[], createdBy: User): FileDto[] {
    if (!files || !createdBy) {
      return files;
    }

    return files.filter((file) => {
      const fullName =
        `${file.createdBy?.firstname} ${file.createdBy?.lastname}`.toLowerCase();

      if (createdBy && createdBy.firstname && createdBy.lastname) {
        return (
          fullName.includes(createdBy.firstname.toLowerCase()) ||
          fullName.includes(createdBy.lastname.toLowerCase())
        );
      }
      if (createdBy && createdBy.firstname && !createdBy.lastname) {
        return fullName.includes(createdBy.firstname.toLowerCase());
      }
      if (createdBy && !createdBy.firstname && createdBy.lastname) {
        return fullName.includes(createdBy.lastname.toLowerCase());
      }

      return false;
    });
  }
}
