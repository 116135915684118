<app-generic-table
  [config]="config"
  [objectList]="correspondences"
  (actionClicked)="handleTableActionClicked($event)" />

<p-sidebar
  [visible]="isSidebarVisible"
  position="right"
  styleClass="w-32rem"
  (visibleChange)="onSidebarVisibleChange($event)">
  <ng-template pTemplate="header">
    <div class="bg-primary">
      <h4 class="mb-0 ml-4 text-white">
        {{ (editObject.id ? 'general.form.editSubject' :
        'general.form.createSubject') | translate: {subject:
        (config.translationKey + ".form.title" | translate)} }}
      </h4>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="pt-4 ml-4">
      <app-form
        #form
        [isInSeparatePage]="false"
        [config]="config"
        (formChanged)="formChanged()"
        (fileRemovedEmitter)="fileRemoved($event)"
        [editObject]="editObject"></app-form>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div>
      <app-logging
        #loggingComponent
        *ngIf="editObject && editObject.id"
        [model]="'CustomerCorrespondence'"
        [identifier]="editObject.id"
        [config]="config"
        [formatter]="loggingFormatter" />

      <div class="flex flex-wrap gap-2 justify-content-between mt-2">
        <button
          pButton
          pRipple
          type="button"
          [label]="'general.form.cancel' | translate"
          (click)="onSidebarVisibleChange(false)"
          class="flex-auto p-button-outlined"></button>
        <button
          pButton
          pRipple
          [label]="'general.form.save' | translate"
          class="flex-auto"
          [disabled]="editObject.id ? editSubmitDisabled : createSubmitDisabled"
          (click)="onSubmit()"></button>
      </div>
    </div>
  </ng-template>
</p-sidebar>
