import { Component } from '@angular/core';
import { Customer } from 'src/app/models/customer/Customer';

@Component({
  selector: 'app-customer-table-action-new-ticket',
  templateUrl: './customer-table-action-new-ticket.component.html',
  styleUrls: ['./customer-table-action-new-ticket.component.scss']
})
export class CustomerTableActionNewTicketComponent {
  isVisible = false;

  customer: Customer | null = null;

  triggerCreate = false;

  public setVisible(isVisible: Customer | false): void {
    this.isVisible = typeof isVisible === 'object';
    this.customer = typeof isVisible === 'object' ? isVisible : null;

    this.triggerCreate = !this.triggerCreate;
  }

  newTicketCreatedOrCancelled(): void {
    this.customer = null;
    this.isVisible = false;
    this.triggerCreate = false;
  }
}
