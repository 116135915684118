<div
  [ngClass]="size === 'small' ? 'small-widget' : size === 'medium' ? 'medium-widget' : 'large-widget'"
  class="card">
  <div class="widget-wrapper-header">
    <p-checkbox
      [(ngModel)]="widgetSelected"
      [binary]="true"
      (ngModelChange)="selectionStateChanged()" />
  </div>
  <ng-content></ng-content>
  <div class="widget-wrapper-footer">{{footerTitle}}</div>
</div>
