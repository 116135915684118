import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Appointment, MyDayView } from 'src/app/models/Appointment';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { environment } from 'src/environments/environment';

import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends GenericService<Appointment> {
  constructor(
    http: HttpClient,
    messageCenterService: MessageCenterService,
    translate: TranslateService,
    private internalHttp: HttpClient
  ) {
    super(http, messageCenterService, translate);
    this.setContext('appointments');
  }

  findAllByDateRangeAndUser(
    startDate: Date,
    endDate: Date,
    userIds: number[]
  ): Observable<Appointment[]> {
    return this.internalHttp.post<Appointment[]>(
      `${environment.apiUrl}/appointments/find-all-by-date-range-and-user`,
      {
        startDate,
        endDate,
        userIds
      }
    );
  }

  getMyDayView(): Observable<MyDayView> {
    return this.internalHttp.get<MyDayView>(
      `${environment.apiUrl}/appointments/my-day-view`
    );
  }
}
