import { Component, Input } from '@angular/core';
import { Ticket } from 'src/app/models/Ticket';
import { Customer } from 'src/app/models/customer/Customer';

@Component({
  selector: 'app-customer-edit-tickets',
  templateUrl: './customer-edit-tickets.component.html',
  styleUrls: ['./customer-edit-tickets.component.scss']
})
export class CustomerEditTicketsComponent {
  @Input({ required: true }) customer!: Customer;

  @Input() tickets: Ticket[] = [];
}
