import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Severity } from 'src/app/types/misc/Severity';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgetPasswordForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private messageCenterService: MessageCenterService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    if (this.forgetPasswordForm && this.forgetPasswordForm.valid) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const email = this.forgetPasswordForm.get('email')?.value;
      this.authService.generatePasswordLinkWeb(email).subscribe((response) => {
        if (response) {
          this.router.navigate(['/login']);
          this.showForgotPasswordToast('success');
        }
      });
    }
  }

  showForgotPasswordToast(severity: Severity): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `forgetPasswordComponent.toasts.${severity}.summary`
      ),
      this.translate.instant(
        `forgetPasswordComponent.toasts.${severity}.detail`
      ),
      severity
    );
  }
}
