import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Ticket } from 'src/app/models/Ticket';
import { Widget, WidgetComponent } from 'src/app/models/Widget';

@Component({
  selector: 'app-widget-chooser',
  templateUrl: './widget-chooser.component.html',
  styleUrl: './widget-chooser.component.scss'
})
export class WidgetChooserComponent {
  @Input() dashboard: Widget[] = [];

  @Input() tickets: Ticket[] = [];

  @Output() selectedWidgetHasChanged = new EventEmitter<WidgetComponent>();

  selectedWidget: string = '';

  WidgetComponent = WidgetComponent;

  /**
   * Emits an event when the selected widget changes.
   *
   * @param {WidgetComponent} widget - The name of the selected widget.
   * @returns {void}
   */
  widgetSelectedChange(widget: WidgetComponent): void {
    this.selectedWidgetHasChanged.emit(widget);
  }
}
