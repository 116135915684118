<app-logged-out-layout>
  <ng-container class="header-content">
    <div class="text-900 text-2xl font-bold mb-2 text-primary">
      {{ 'loginComponent.title' | translate }}
    </div>
    <span class="text-600 font-medium"
      >{{ 'verificationComponent.subtitle' | translate }}</span
    >
  </ng-container>

  <ng-container class="body-content">
    <form [formGroup]="verificationForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column">
        <div
          class="flex justify-content-between w-full align-items-center mb-4 gap-3">
          <ng-container
            *ngFor="let val of verificationCodeArray; let i = index">
            <p-inputNumber
              [formControlName]="i"
              inputStyleClass="w-3rem text-center"
              pAutoFocus
              [autofocus]="focusIndex === i"
              (onInput)="raiseFocusIndex()"
              [maxlength]="1"></p-inputNumber>
          </ng-container>
        </div>

        <div class="flex flex-wrap gap-2 justify-content-between">
          <button
            pButton
            pRipple
            type="button"
            [label]="'general.form.cancel' | translate"
            class="flex-auto p-button-outlined"
            [routerLink]="['/login']"></button>
          <button
            pButton
            pRipple
            [label]="'general.login' | translate"
            class="flex-auto"
            type="submit"
            [disabled]="!verificationForm.valid"></button>
        </div>
      </div>
    </form>
  </ng-container>
</app-logged-out-layout>
