<app-customer-device-table
  [customer]="customer"
  [customerDevices]="customerDevices"
  [(selectedDevices)]="selectedDevices"
  [selectionEnabled]="selectionEnabled"
  (selectedDevicesChange)="selectedDevicesChange.emit($event)"
  (handleCreateCustomerDevice)="handleCreateCustomerDevice()"
  (handleDeleteCustomerDevice)="handleDeleteCustomerDevice($event)"
  (handleViewCustomerDevice)="handleViewCustomerDevice($event)"
  (handleUpdateCustomerDevice)="handleUpdateCustomerDevice($event)"
  (handleChangeIsActive)="handleChangeIsActive($event.customerDevice, $event.value)"
  (changeCustomerDevices)="changeCustomerDevices.emit($event)" />

<!-- Create Sidebar -->
<p-sidebar
  [visible]="isSidebarVisible"
  position="right"
  appendTo="body"
  styleClass="w-32rem scrollbar-gutter-stable"
  (visibleChange)="onSidebarVisibleChange($event)"
  (onHide)="onSidebarVisibleChange(false)">
  <!-- Header | Create Sidebar -->
  <ng-template pTemplate="header">
    <div class="bg-primary">
      <h4 class="mb-0 ml-4 text-white">
        {{ "customerDeviceComponent.newDevice" | translate }}
      </h4>
    </div>
  </ng-template>
  <!-- Body | Create Sidebar -->
  <ng-template pTemplate="content">
    <div body class="pt-4 ml-4">
      <app-customer-device-form
        #form
        *ngIf="customerDeviceToEdit"
        [customerDevice]="customerDeviceToEdit"
        [heatingEngineers]="heatingEngineers"
        [devices]="devices"
        [deviceManufacturers]="deviceManufacturers"
        (formChanged)="onChangeForm()" />
    </div>
  </ng-template>
  <!-- Footer | Create Sidebar -->
  <ng-template pTemplate="footer">
    <ng-container>
      <app-logging
        *ngIf="loggingIdentifier !== null"
        #loggingComponent
        [model]="'CustomerDevice'"
        [identifier]="loggingIdentifier"
        [config]="config"
        [formatter]="loggingFormatter" />
    </ng-container>

    <div class="flex flex-wrap gap-2 justify-content-between mt-2">
      <button
        pButton
        pRipple
        type="button"
        [label]="'general.form.cancel' | translate"
        (click)="onSidebarVisibleChange(false)"
        class="flex-auto p-button-outlined"></button>
      <button
        *ngIf="$can('create')"
        pButton
        pRipple
        [label]="'general.form.save' | translate"
        class="flex-auto"
        [disabled]="isFormDisabled"
        (click)="onSubmit()"></button>
    </div>
  </ng-template>
</p-sidebar>
