<div *ngIf="customer" class="flex gap-3 align-items-center">
  <div
    role="button"
    class="flex align-items-center justify-content-center"
    [ngStyle]="{
      'background-color': customer.isBlocked ? '#FF0000' : '#7AAA00',
      'border-radius': '1.5rem',
      width: '1.5rem',
      height: '1.5rem',
      color: '#f1f1f1'
    }"
    [ngClass]="{
      'cursor-pointer': !disabled && clickable
    }"
    (mousedown)="handleOnMouseDown()">
    <i *ngIf="!customer.isBlocked" class="pi pi-check text-xs"></i>
    <i
      *ngIf="customer.isBlocked"
      class="pi pi-times text-xs"
      [ngStyle]="{
        marginTop: '0.12rem'
      }">
    </i>
  </div>

  <div
    class="flex flex-column white-space-nowrap gap-1 select-none"
    [ngClass]="{
      'cursor-pointer': !disabled && clickable
    }"
    (click)="navigateToCustomer(customer.id)">
    <app-cut-text
      cutTextClass="font-semibold"
      [text]="customer.name"
      [maxWidth]="350" />
    <span>{{customer.customerNumber}}</span>
  </div>
</div>
