<div *ngIf="customer !== null" class="card card-extension">
  <div class="flex align-items-start justify-content-between">
    <div class="flex align-items-center gap-3">
      <app-customer-avatar
        [customer]="customer"
        [ngStyle]="{
          'height.px': 42,
          'width.px': 42,
        }"
        class="block" />

      <div>
        <div class="flex gap-3">
          <h1 class="mb-0 font-medium text-4xl">{{customer.name}}</h1>

          <div class="flex gap-2 align-items-center">
            <p-tag
              *ngIf="customer.isActive"
              severity="success"
              [value]="'customerComponent.isActive' | translate"
              [rounded]="true"
              [style]="{ minWidth: '5rem', borderRadius: '9999px', height: '21px' }" />
            <p-tag
              *ngIf="!customer.isActive"
              severity="secondary"
              [value]="'customerComponent.isInactive' | translate"
              [rounded]="true"
              [style]="{ minWidth: '5rem', borderRadius: '9999px', height: '21px' }" />
            <p-tag
              *ngIf="customer.isBlocked"
              severity="danger"
              [value]="'customerComponent.isBlocked' | translate"
              [rounded]="true"
              [style]="{ minWidth: '5rem', borderRadius: '9999px', height: '21px' }" />
          </div>
        </div>

        <span>{{customer.customerNumber}}</span>
      </div>
    </div>

    <div class="flex flex-column align-items-end gap-3">
      <div class="flex align-items-end">
        <a
          routerLink="/customers"
          class="p-button p-button-outlined"
          style="min-width: 12rem">
          {{'general.back' | translate}}
        </a>
      </div>

      <app-logging
        #loggingComponent
        [identifier]="customer.id"
        [model]="'Customer'"
        [config]="config"
        [formatter]="loggingFormatter" />
    </div>
  </div>

  <p-tabView
    #tabView
    (activeIndexChange)="preventClosingBaseData($event)"
    [(activeIndex)]="activeTabIndex">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2">
          <i class="pi pi-building" style="font-size: 1.25rem"></i>

          <span class="font-bold white-space-nowrap m-0">
            {{ 'customerComponent.tabs.baseData' | translate }}
          </span>

          <p-badge [value]="'?'" class="opacity-0 max-w-0" />
        </div>
      </ng-template>

      <app-customer-edit-base-data
        #baseDataComponent
        *ngIf="loadedTabs.get(0)"
        [customer]="customer"
        [countries]="countries"
        (handleUpdateCustomer)="updateCustomer($event)" />
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2">
          <i
            class="pi pi-id-card"
            style="font-size: 1.25rem; margin-top: 2px"></i>

          <span class="font-bold white-space-nowrap m-0">
            {{ 'customerComponent.tabs.contactPerson' | translate }}
          </span>

          <p-badge
            [value]="customerContactPersons.length.toString()"
            [style.filter]="activeTabIndex !== 1 ? 'grayscale(100%)' : ''" />
        </div>
      </ng-template>

      <app-customer-edit-contact-person
        *ngIf="loadedTabs.get(1)"
        [customer]="customer"
        [customerContactPersons]="customerContactPersons"
        [countries]="countries"
        (changeCustomerContactPersons)="customerContactPersons = $event; revalidateCustomerCache()" />
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2">
          <i
            class="pi pi-shopping-cart"
            style="font-size: 1.25rem; margin-top: 2px"></i>

          <span class="font-bold white-space-nowrap m-0">
            {{ 'customerComponent.tabs.devices' | translate }}
          </span>

          <p-badge
            [value]="devices.length.toString()"
            [style.filter]="activeTabIndex !== 2 ? 'grayscale(100%)' : ''" />
        </div>
      </ng-template>

      <app-customer-edit-devices
        *ngIf="loadedTabs.get(2)"
        [customer]="customer"
        [customerDevices]="devices"
        [deviceManufacturers]="deviceManufacturers"
        (changeCustomerDevices)="devices = $event; revalidateCustomerCache()" />
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2">
          <i
            class="pi pi-check-square"
            style="font-size: 1.25rem; margin-top: 2px"></i>

          <span class="font-bold white-space-nowrap m-0">
            {{ 'customerComponent.tabs.tickets' | translate }}
          </span>

          <p-badge
            [value]="tickets.length.toString()"
            [style.filter]="activeTabIndex !== 3? 'grayscale(100%)' : ''" />
        </div>
      </ng-template>

      <app-customer-edit-tickets
        *ngIf="loadedTabs.get(3)"
        [tickets]="tickets"
        [customer]="customer" />
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2">
          <i
            class="pi pi-comments"
            style="font-size: 1.25rem; margin-top: 2px"></i>

          <span class="font-bold white-space-nowrap m-0">
            {{ 'customerComponent.tabs.correspondence' | translate }}
          </span>

          <p-badge
            [value]="correspondences.length.toString()"
            [style.filter]="activeTabIndex !== 4 ? 'grayscale(100%)' : ''" />
        </div>
      </ng-template>

      <app-customer-edit-correspondence
        *ngIf="loadedTabs.get(4)"
        [customer]="customer"
        [correspondences]="correspondences"
        (correspondencesChange)="correspondences = $event" />
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2">
          <i class="pi pi-file" style="font-size: 1.25rem; margin-top: 2px"></i>

          <span class="font-bold white-space-nowrap m-0">
            {{ 'customerComponent.tabs.documents' | translate }}
          </span>

          <p-badge
            [value]="customer.files?.length?.toString() || '0'"
            [style.filter]="activeTabIndex !== 5 ? 'grayscale(100%)' : ''" />
        </div>
      </ng-template>

      <app-customer-edit-documents
        *ngIf="loadedTabs.get(5)"
        [customer]="customer"
        (changeCustomer)="changeCustomer($event)" />
    </p-tabPanel>
  </p-tabView>
</div>

<div
  *ngIf="customer === null"
  class="flex justify-content-center align-items-center w-full"
  [ngStyle]="{minHeight: '70vh'}">
  <p-progressSpinner ariaLabel="loading" />
</div>
