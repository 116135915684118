import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, throwError } from 'rxjs';

import { getHttpErrorTranslationKeys } from 'src/app/utils/errors/http-error';
import { environment } from 'src/environments/environment';

import {
  CustomerContactPerson,
  CustomerContactPersonCreate,
  CustomerContactPersonUpdate
} from 'src/app/models/customer/CustomerContactPerson';
import { MessageCenterService } from 'src/app/services/message-center.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerContactPersonService {
  constructor(
    private readonly http: HttpClient,
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService
  ) {}

  public create(
    customerContactPerson: CustomerContactPersonCreate
  ): Observable<CustomerContactPerson> {
    return this.http.post<CustomerContactPerson>(
      `${environment.apiUrl}/customers-contact-persons`,
      customerContactPerson
    );
  }

  public findById(id: number): Observable<CustomerContactPerson> {
    return this.http
      .get<CustomerContactPerson>(
        `${environment.apiUrl}/customers-contact-persons/${id}`
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  public findAll(customer?: number): Observable<CustomerContactPerson[]> {
    const params = customer ? { customer: customer.toString() } : undefined;

    return this.http
      .get<CustomerContactPerson[]>(
        `${environment.apiUrl}/customers-contact-persons`,
        {
          params
        }
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  public update(
    id: number,
    customerContactPerson: CustomerContactPersonUpdate
  ): Observable<CustomerContactPerson> {
    return this.http
      .patch<CustomerContactPerson>(
        `${environment.apiUrl}/customers-contact-persons/${id}`,
        customerContactPerson
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  public delete(id: number): Observable<CustomerContactPerson> {
    return this.http
      .delete<CustomerContactPerson>(
        `${environment.apiUrl}/customers-contact-persons/${id}`
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    const errorKeys = getHttpErrorTranslationKeys(error);

    this.messageCenterService.showToast(
      this.translate.instant(errorKeys.summary, { status: error.status }),
      this.translate.instant(errorKeys.detail),
      'error'
    );

    return throwError(() => error);
  }
}
