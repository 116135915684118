import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/User';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.scss']
})
export class UserProfileViewComponent {
  @Input({ required: true }) user!: User;

  get userAvatarUrl(): string {
    const baseUrl = environment.apiUrl;

    return `${baseUrl}/users/avatar/${this.user.id}`;
  }

  get userAvatarLabel(): string {
    return `${this.user.firstname?.charAt(0).toUpperCase()}${this.user.lastname?.charAt(0).toUpperCase()}`;
  }

  get hasAvatar(): boolean {
    return Boolean(this.user.profilePictureId);
  }
}
