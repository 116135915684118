import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';

import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent implements OnInit, OnDestroy {
  @ViewChild('menubutton') menuButton: ElementRef | null = null;

  readonly showHeader = environment.header;

  user: User | null = null;

  userSubscription: Subscription | null = null;

  constructor(
    public layoutService: LayoutService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.authService.user$.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }

  get initials(): string {
    if (!this.user) {
      return '';
    }

    return `${this.user.firstname?.charAt(0)}${this.user.lastname?.charAt(0)}`;
  }

  get imgUrl(): string | undefined {
    if (!this.user) {
      return undefined;
    }

    return `${environment.apiUrl}/users/avatar/${this.user.id}`;
  }
}
