import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';

import { LoadingService } from 'src/app/services/utils/loading.service';
import { environment } from 'src/environments/environment';

// Since the customers are loaded in advance and the request takes a relatively long time, the request is excluded
const BLOCKED_REQUEST = [`${environment.apiUrl}/customers`];

const BLOCKED_PATH = ['/customers'];

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  // Counter to keep track of the number of active HTTP requests
  private activeRequestsCount = 0;

  constructor(private loadingService: LoadingService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      BLOCKED_REQUEST.includes(request.url) ||
      BLOCKED_PATH.some((path) => window.location.pathname.startsWith(path))
    ) {
      return next.handle(request);
    }

    // Show the loading indicator if there are no active requests
    if (this.activeRequestsCount === 0) {
      this.loadingService.show();
    }

    // Increment the counter for each outgoing request
    this.activeRequestsCount++;

    return next.handle(request).pipe(
      finalize(() => {
        // Decrement the counter when a request completes or fails
        this.activeRequestsCount--;

        // Hide the loading indicator only when there are no active requests
        if (this.activeRequestsCount === 0) {
          this.loadingService.hide();
        }
      })
    );
  }
}
