import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, throwError } from 'rxjs';

import { getHttpErrorTranslationKeys } from 'src/app/utils/errors/http-error';
import { environment } from 'src/environments/environment';

import {
  CustomerDevice,
  CustomerDeviceCreate,
  CustomerDeviceUpdate,
  DuplicateSerialNumberInfo
} from 'src/app/models/customer/CustomerDevice';
import { MessageCenterService } from 'src/app/services/message-center.service';

/**
 * Service for managing customer devices.
 */
@Injectable({
  providedIn: 'root'
})
export class CustomerDeviceService {
  constructor(
    private readonly http: HttpClient,
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService
  ) {}

  /**
   * Creates a new customer device.
   * @param customerDevice The customer device to create.
   * @returns An observable of the created customer device.
   */
  public create(
    customerDevice: CustomerDeviceCreate
  ): Observable<CustomerDevice> {
    return this.http.post<CustomerDevice>(
      `${environment.apiUrl}/customer-devices`,
      customerDevice
    );
  }

  /**
   * Finds a customer device by its ID.
   * @param id The ID of the customer device to find.
   * @returns An observable of the found customer device.
   */
  public findById(id: number): Observable<CustomerDevice> {
    return this.http
      .get<CustomerDevice>(`${environment.apiUrl}/customer-devices/${id}`)
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * Finds all customer devices.
   * @param customer Optional. The ID of the customer to filter by.
   * @returns An observable of an array of customer devices.
   */
  public findAll(customer?: number): Observable<CustomerDevice[]> {
    const params = customer ? { customer: customer.toString() } : undefined;

    return this.http
      .get<CustomerDevice[]>(`${environment.apiUrl}/customer-devices`, {
        params
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * Updates a customer device.
   * @param id The ID of the customer device to update.
   * @param customerDevice The updated customer device.
   * @returns An observable of the updated customer device.
   */
  public update(
    id: number,
    customerDevice: CustomerDeviceUpdate
  ): Observable<CustomerDevice> {
    return this.http.patch<CustomerDevice>(
      `${environment.apiUrl}/customer-devices/${id}`,
      customerDevice
    );
  }

  /**
   * Deletes a customer device.
   * @param id The ID of the customer device to delete.
   * @returns An observable of the deleted customer device.
   */
  public delete(id: number): Observable<CustomerDevice> {
    return this.http.delete<CustomerDevice>(
      `${environment.apiUrl}/customer-devices/${id}`
    );
  }

  /**
   * Finds a customer device by its internal serial number.
   * @param manufacturerId The ID of the manufacturer to search for.
   * @param serialNumber The internal serial number to search for.
   * @returns An observable of the found customer device.
   */
  public findDuplicateSerialNumber(
    manufacturerId: number,
    serialNumber: string,
    ignoreId?: number
  ): Observable<DuplicateSerialNumberInfo> {
    const params: Record<string, string> = {
      'manufacturer-id': manufacturerId.toString(),
      'serial-number': serialNumber
    };

    if (ignoreId) {
      params['ignore-id'] = ignoreId.toString();
    }

    return this.http.get<DuplicateSerialNumberInfo>(
      `${environment.apiUrl}/customer-devices/is-serial-number-duplicate`,
      {
        params
      }
    );
  }

  /**
   * Handles HTTP error responses.
   * @param error The HTTP error response.
   * @returns An observable that throws the error.
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    const errorKeys = getHttpErrorTranslationKeys(error);

    this.messageCenterService.showToast(
      this.translate.instant(errorKeys.summary, { status: error.status }),
      this.translate.instant(errorKeys.detail),
      'error'
    );

    return throwError(() => error);
  }
}
