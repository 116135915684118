import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import {
  FilePreviewEvent,
  FilesComponent
} from 'src/app/components/misc/file-upload/files/files.component';
import { FileDto } from 'src/app/models/FileDto';
import { Customer } from 'src/app/models/customer/Customer';
import { CustomerService } from 'src/app/services/api/customer/customer.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { AppAction } from 'src/config/authorization.config';

type FileSelectionMode = 'multiple' | 'none';

@Component({
  selector: 'app-customer-edit-documents',
  templateUrl: './customer-edit-documents.component.html',
  styleUrls: ['./customer-edit-documents.component.scss']
})
export class CustomerEditDocumentsComponent {
  @Input({ required: true }) customer!: Customer;

  @Input() selectedFiles: FileDto[] = [];

  @Input() selectionMode: FileSelectionMode = 'none';

  @Input() delegatePreview = true;

  @Output() selectedFilesChange = new EventEmitter<FileDto[]>();

  @Output() changeCustomer = new EventEmitter<Customer>();

  @ViewChild('filesComponent') filesComponent?: FilesComponent | undefined;

  filePreview: FilePreviewEvent | null = null;

  constructor(
    private readonly customerService: CustomerService,
    private readonly authService: AuthService,
    private readonly translate: TranslateService,
    private readonly messageCenterService: MessageCenterService
  ) {}

  handleDeviceUpload(files: File[]): void {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });

    this.customerService
      .uploadFiles(this.customer.id, formData)
      .pipe(first())
      .subscribe((updatedCustomer) => {
        files.forEach((file) => {
          this.messageCenterService.showToast(
            this.translate.instant(
              `filesComponent.actions.toasts.create.success.summary`
            ),
            this.translate.instant(
              `filesComponent.actions.toasts.create.success.detail`,
              { name: file.name }
            ),
            'success'
          );
        });

        this.changeCustomer.emit(updatedCustomer);
      });
  }

  handleDeviceRemove(file: FileDto): void {
    const translationContext = {
      name: file.originalname
    };

    const executeDelete = () => {
      this.customerService
        .deleteFile(this.customer.id, file.id)
        .pipe(first())
        .subscribe((updatedCustomer) => {
          this.filePreview = null;

          this.changeCustomer.emit(updatedCustomer);
        });

      this.messageCenterService.showToast(
        this.translate.instant(
          `filesComponent.actions.toasts.delete.success.summary`
        ),
        this.translate.instant(
          `filesComponent.actions.toasts.delete.success.detail`,
          translationContext
        ),
        'success'
      );
    };

    this.messageCenterService.confirm(
      this.translate.instant(
        'filesComponent.actions.toasts.delete.confirm.header',
        translationContext
      ),
      this.translate.instant(
        'filesComponent.actions.toasts.delete.confirm.message',
        translationContext
      ),
      executeDelete,
      () => {
        this.messageCenterService.showToast(
          this.translate.instant(
            `filesComponent.actions.toasts.delete.info.summary`
          ),
          this.translate.instant(
            `filesComponent.actions.toasts.delete.info.detail`,
            translationContext
          ),
          'info'
        );
        // the file component removes the file from the list
        // so if the user cancels the delete, we need to add it back
        file.deletedAt = undefined;
        this.filesComponent?.remount();
      }
    );
  }

  handlePreview(event: FilePreviewEvent) {
    this.filePreview = event;
  }

  handleFilesSelected(files: FileDto[]): void {
    this.selectedFilesChange.emit(files);
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'Customer');
  }
}
