import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterMetadata } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

import { TableFilter } from 'src/app/models/TableFilter';
import { Customer } from 'src/app/models/customer/Customer';
import {
  CustomerContactPerson,
  CustomerContactPersonType
} from 'src/app/models/customer/CustomerContactPerson';

import { AuthService } from 'src/app/services/auth/auth.service';

import { AppAction } from 'src/config/authorization.config';
import { environment } from 'src/environments/environment';

import { FilterComponent } from 'src/app/components/misc/generic-table/filter/filter.component';
import { Country } from 'src/app/models/Country';

type VirtualTableCustomerContactPerson = CustomerContactPerson & {
  virtual: {
    type: string;
    name: string;
    address: string;
  };
};
@Component({
  selector: 'app-customer-contact-person-table',
  templateUrl: './customer-contact-person-table.component.html',
  styleUrls: ['./customer-contact-person-table.component.scss']
})
export class CustomerContactPersonTableComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild('tableElement') public tableElement?: Table;

  @ViewChild('appFilter') appFilter?: FilterComponent;

  @Input({ required: true }) customer!: Customer;

  @Input({ required: true }) customerContactPersons: CustomerContactPerson[] =
    [];

  @Input({ required: true }) countries: Country[] = [];

  @Input() showEditAction = true;

  @Input() showDeleteAction = true;

  @Input() showCorrespondenceAction = true;

  @Input() showTicketAction = true;

  @Input() showCreateAction = true;

  @Input() showChooseAction = false;

  @Output() handleCreateCustomerContactPerson = new EventEmitter<void>();

  @Output() handleUpdateCustomerContactPerson =
    new EventEmitter<CustomerContactPerson>();

  @Output() handleViewCustomerContactPerson =
    new EventEmitter<CustomerContactPerson>();

  @Output() handleDeleteCustomerContactPerson =
    new EventEmitter<CustomerContactPerson>();

  @Output() handleChangeIsMainContact = new EventEmitter<{
    value: boolean;
    customerContactPerson: CustomerContactPerson;
  }>();

  @Output() handleChangeIsActive = new EventEmitter<{
    value: boolean;
    customerContactPerson: CustomerContactPerson;
  }>();

  @Output() changeCustomerContactPersons = new EventEmitter<
    CustomerContactPerson[]
  >();

  @Output() handleCustomerContactPersonChosen =
    new EventEmitter<CustomerContactPerson>();

  virtualCustomerContactPerson: VirtualTableCustomerContactPerson[] = [];

  tableConfig = environment.tableConfiguration;

  typeOptions = Object.values(CustomerContactPersonType);

  displayFilterOverlay = false;

  isFilterApplied = false;

  activeFilter: number | null = null;

  filterSubscription: Subscription | null = null;

  globalSearchValue = '';

  constructor(
    private readonly translate: TranslateService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.updateVirtualCustomerContactPersons();
    const localFilters = localStorage.getItem(
      'customer-contact-person-table-memory'
    );
    if (localFilters) {
      const { filters } = JSON.parse(localFilters);

      if (filters?.global) {
        this.globalSearchValue = filters.global.value;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.tableElement) {
      this.filterSubscription = this.tableElement.onFilter.subscribe(
        (event) => {
          if (!event.filters) {
            this.isFilterApplied = false;

            return;
          }

          this.isFilterApplied = Object.keys(event.filters).some((x) => {
            if (x === 'global') {
              return false;
            }

            const filter = this.tableElement?.filters[x] as FilterMetadata;

            return (
              filter.value !== null &&
              filter.value !== undefined &&
              filter.value !== ''
            );
          });
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['customerContactPersons'] &&
      changes['customerContactPersons'].currentValue
    ) {
      this.updateVirtualCustomerContactPersons();
    }
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'CustomerContactPerson');
  }

  filterApplied(filter: TableFilter): void {
    this.activeFilter = filter.id;

    if (this.tableElement) {
      this.tableElement.filters = structuredClone(
        filter.filterData
      ) as Table['filters'];
      this.updateVirtualCustomerContactPersons();
      this.tableElement._filter();
    }
  }

  clearFilters(): void {
    if (this.tableElement) {
      this.tableElement.clearFilterValues();
      this.tableElement.reset();
      localStorage.removeItem('customer-contact-person-table-memory');
    }

    this.activeFilter = null;
  }

  saveFilterClicked(filterName: string): void {
    if (!this.tableElement) {
      throw new Error('Table element not found');
    }

    const tableFilter = new TableFilter();

    tableFilter.filterData = structuredClone(this.tableElement?.filters);
    tableFilter.table = 'customer-contact-person';
    tableFilter.filterName = filterName;

    this.appFilter?.createFilter(tableFilter);
  }

  updateVirtualCustomerContactPersons(): void {
    this.virtualCustomerContactPerson = this.customerContactPersons.map(
      (customerContactPerson) =>
        ({
          ...customerContactPerson,
          virtual: {
            type: this.translateType(customerContactPerson.type),
            name:
              customerContactPerson.firstname === null
                ? customerContactPerson.lastname
                : `${customerContactPerson.firstname} ${customerContactPerson.lastname}`,
            address: this.formatAddress(
              customerContactPerson.street,
              customerContactPerson.postalCode,
              customerContactPerson.city,
              customerContactPerson.countryId
            )
          }
        }) satisfies VirtualTableCustomerContactPerson
    );
  }

  translateType(type: string): string {
    return this.translate.instant(
      `customerContactPersonComponent.attributes.type.enum.${type}`
    );
  }

  formatAddress(
    street: string | null,
    postalCode: string | null,
    city: string | null,
    countryId: number | null
  ): string {
    const country = this.countries.find((c) => c.id === countryId);

    // Construct each part of the address
    const formattedStreet = street ?? '';
    const formattedPostalCode = postalCode ?? '';
    const formattedCity = city ?? '';
    const formattedCountry = country
      ? this.translate.instant(`countries.${country.key}`)
      : '';

    // Check if all parts are empty
    if (
      !formattedStreet &&
      !formattedPostalCode &&
      !formattedCity &&
      !formattedCountry
    ) {
      return '-';
    }

    // Combine parts with conditional commas
    let address = '';

    if (formattedStreet) {
      address += formattedStreet;
      if (formattedPostalCode || formattedCity || formattedCountry) {
        address += ', ';
      }
    }

    if (formattedPostalCode) {
      address += formattedPostalCode;
      if (formattedCity || formattedCountry) {
        address += ' ';
      }
    }

    if (formattedCity) {
      address += formattedCity;
      if (formattedCountry) {
        address += ', ';
      }
    }

    if (formattedCountry) {
      address += formattedCountry;
    }

    return address;
  }

  createCustomerContactPerson() {
    this.handleCreateCustomerContactPerson.emit();
  }

  updateCustomerContactPerson(customerContactPerson: CustomerContactPerson) {
    this.handleUpdateCustomerContactPerson.emit(customerContactPerson);
  }

  viewCustomerContactPerson(customerContactPerson: CustomerContactPerson) {
    this.handleViewCustomerContactPerson.emit(customerContactPerson);
  }

  deleteCustomerContactPerson(customerContactPerson: CustomerContactPerson) {
    if (!customerContactPerson.isMainContact) {
      this.handleDeleteCustomerContactPerson.emit(customerContactPerson);
    }
  }

  changeIsMainContact(
    value: boolean,
    customerContactPerson: CustomerContactPerson
  ) {
    if (!value) {
      return;
    }
    this.handleChangeIsMainContact.emit({ value, customerContactPerson });
  }

  changeIsActive(value: boolean, customerContactPerson: CustomerContactPerson) {
    if (customerContactPerson.isMainContact) {
      return;
    }
    this.handleChangeIsActive.emit({ value, customerContactPerson });
  }

  chooseCustomerContactPerson(customerContactPerson: CustomerContactPerson) {
    this.handleCustomerContactPersonChosen.emit(customerContactPerson);
  }
}
