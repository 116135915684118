import { FileDto } from 'src/app/models/FileDto';
import { Address } from 'src/app/models/customer/Address';
import { CustomerContactPerson } from 'src/app/models/customer/CustomerContactPerson';
import { CustomerDevice } from 'src/app/models/customer/CustomerDevice';
import {
  ExtractCreateModel,
  ExtractUpdateModel
} from 'src/app/utils/types/extract-action-model';

export const FacilityAddressType = {
  EFH: 'EFH',
  MFH: 'MFH',
  Business: 'Business',
  Unknown: 'Unknown'
} as const;

export type FacilityAddressType =
  (typeof FacilityAddressType)[keyof typeof FacilityAddressType];

export type Customer = {
  readonly id: number;

  name: string;
  customerNumber: string;
  company: string | null;

  isActive: boolean;
  isBlocked: boolean;

  billingAddress: Address;
  billingAddressId: number;
  billingAddressEmail: string | null;
  billingRecipient: string | null;

  facilityAddress: Address;
  facilityAddressId: number;
  facilityAddressType: FacilityAddressType;

  customerDeviceCount?: number | undefined;
  customerTicketCount?: number | undefined;

  contactPersons: CustomerContactPerson[];

  files?: FileDto[];

  customerDevices?: CustomerDevice[];

  readonly createdAt: Date;
  readonly createdById: number;

  readonly updatedAt: Date;
  readonly updatedById: number;

  readonly deletedAt: Date | null;
  readonly deletedById: number | null;
};

export type CustomerCreate = Omit<
  ExtractCreateModel<Customer>,
  | 'isActive'
  | 'isBlocked'
  | 'contactPersons'
  | 'billingAddressId'
  | 'facilityAddressId'
> & {
  contactPerson: {
    firstname: string;
    lastname: string;
    phoneNumberMobile: string | null;
    phoneNumberLandline: string | null;
    email: string | null;
  };
};

export type CustomerUpdate = ExtractUpdateModel<Customer>;
