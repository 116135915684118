import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() sidebarVisible = false;

  @Output() sidebarVisibleChange = new EventEmitter<boolean>();

  onVisibleChange(visible: boolean) {
    this.sidebarVisibleChange.emit(visible);
  }
}
