import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs';
import { CropperComponent } from 'src/app/components/misc/cropper/cropper.component';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit, OnDestroy {
  ref!: DynamicDialogRef;

  @Input() base64Image?: string | null = null;

  @Input() isEditable = true;

  @Output() profilePictureChanged = new EventEmitter<string>();

  constructor(
    private dialogService: DialogService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    if (this.base64Image === null) {
      this.fileService
        .getFileAsBase64('assets/images/logo.png')
        .pipe(first())
        .subscribe({
          next: (data) => {
            this.base64Image = data;
          },
          error: (error) => {
            console.error('Error loading file:', error);
          }
        });
    }
  }

  openImageCropper() {
    this.ref = this.dialogService.open(CropperComponent, {
      header: '',
      modal: true,
      width: '40%',
      data: {
        base64: this.base64Image
      }
    });

    this.ref.onClose.subscribe((result: string) => {
      if (result) {
        this.base64Image = result;
        this.profilePictureChanged.emit(this.base64Image);
      }
    });
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }
}
