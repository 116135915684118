import { Injectable } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Severity } from '../types/misc/Severity';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MessageCenterService {
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {}

  showToast(summary: string, detail: string, severity: Severity) {
    this.messageService.add({
      severity,
      summary,
      detail
    });
  }

  confirm(
    header: string,
    message: string,
    accept: () => void,
    reject: () => void,
    acceptLabel?: string,
    rejectLabel?: string
  ): void {
    this.confirmationService.confirm({
      header,
      message,
      accept,
      reject,
      acceptLabel:
        acceptLabel ?? this.translate.instant('general.confirm.acceptLabel'),
      rejectLabel:
        rejectLabel ?? this.translate.instant('general.confirm.rejectLabel')
    });
  }
}
