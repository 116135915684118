import { User } from 'src/app/models/User';

export const LoggingModel = {
  User: 'User',
  Customer: 'Customer',
  CustomerContactPerson: 'CustomerContactPerson',
  CustomerCorrespondence: 'CustomerCorrespondence',
  CustomerDevice: 'CustomerDevice',
  HeatingEngineer: 'HeatingEngineer',
  ControlUnit: 'ControlUnit',
  DeviceManufacturer: 'DeviceManufacturer',
  Device: 'Device',
  Ticket: 'Ticket',
  Appointment: 'Appointment',
  Form: 'Form'
} as const;

export type LoggingModel = (typeof LoggingModel)[keyof typeof LoggingModel];

export const LogAttributeType = {
  BigInt: 'BigInt',
  Boolean: 'Boolean',
  String: 'String',
  Number: 'Number',
  DateTime: 'DateTime',
  Null: 'Null',
  Undefined: 'Undefined',
  JSON: 'JSON'
} as const;

export type LogAttributeType =
  (typeof LogAttributeType)[keyof typeof LogAttributeType];

export const LogAction = {
  Create: 'Create',
  Update: 'Update',
  Delete: 'Delete',
  FileUpload: 'FileUpload',
  FileDelete: 'FileDelete',

  Text: 'Text'
} as const;

export type LogAction = (typeof LogAction)[keyof typeof LogAction];

export type LoggingAttribute = {
  id: number;

  attributeKey: string;

  newValue: string;
  newValueType: LogAttributeType;
  newContext: string | null;

  oldValue: string;
  oldValueType: LogAttributeType;
  oldContext: string | null;
};

export type Logging = {
  id: number;

  identifier: number;
  model: string;
  action: LogAction;

  timestamp: string;

  responsibleUserId: number | null;
  responsibleUser: User | null;
  responsibleUserCredentials: string;

  context: string | null;

  attributes: LoggingAttribute[];
};

export type LoggingView = {
  entityContext: string;

  loggingEntries: Logging[];

  createdAt: Date;
  createdById: number | null;
  createdByCredentials: string | null;

  updatedAt: Date;
  updatedById: number | null;
  updatedByCredentials: string | null;
};
