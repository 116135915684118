import { TranslateService } from '@ngx-translate/core';
import { Country } from 'src/app/models/Country';

/**
 * Formats an address based on the provided parts.
 *
 * @param {string | null} street - The street of the address.
 * @param {string | null} postalCode - The postal code of the address.
 * @param {string | null} city - The city of the address.
 * @param {number | null} countryId - The country id of the address.
 * @param {Country[]} countries - The list of countries.
 * @param {TranslateService} translate - The translation service.
 * @returns {string} - The formatted address.
 */
export function formatAddress(
  street: string | null,
  postalCode: string | null,
  city: string | null,
  countryId: number | null,
  countries: Country[],
  translate: TranslateService
): string {
  const country = countries.find((c) => c.id === countryId);

  // Construct each part of the address
  const formattedStreet = street ?? '';
  const formattedPostalCode = postalCode ?? '';
  const formattedCity = city ?? '';
  const formattedCountry = country
    ? translate.instant(`countries.${country.key}`)
    : '';

  // Check if all parts are empty
  if (
    !formattedStreet &&
    !formattedPostalCode &&
    !formattedCity &&
    !formattedCountry
  ) {
    return '-';
  }

  // Combine parts with conditional commas
  let address = '';

  if (formattedStreet) {
    address += formattedStreet;
    if (formattedPostalCode || formattedCity || formattedCountry) {
      address += ', ';
    }
  }

  if (formattedPostalCode) {
    address += formattedPostalCode;
    if (formattedCity || formattedCountry) {
      address += ' ';
    }
  }

  if (formattedCity) {
    address += formattedCity;
    if (formattedCountry) {
      address += ', ';
    }
  }

  if (formattedCountry) {
    address += formattedCountry;
  }

  return address;
}
