<form
  #form
  *ngIf="formGroup !== null && customerContactPerson"
  [formGroup]="formGroup"
  class="form-fields">
  <!-- Type Field | From -->
  <div class="form-field mb-2">
    <label for="type" class="block mb-2">
      {{ "customerContactPersonComponent.attributes.type.title" | translate }}*
    </label>
    <p-dropdown
      formControlName="type"
      id="type"
      styleClass="w-full"
      [options]="typeOptions"
      [placeholder]="'general.form.enterSubject' | translate: { subject: ('customerContactPersonComponent.attributes.type.title' | translate) }">
      <ng-template let-option pTemplate="item">
        {{ ("customerContactPersonComponent.attributes.type.enum." + option) |
        translate }}
      </ng-template>
      <ng-template let-value pTemplate="selectedItem">
        <span *ngIf="value !== undefined">
          {{ ("customerContactPersonComponent.attributes.type.enum." + value) |
          translate }}
        </span>
      </ng-template>
    </p-dropdown>
  </div>
  <!-- Type Field | From -->

  <!-- Firstname Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="firstname" class="block">
      {{ "customerContactPersonComponent.attributes.firstname" | translate}}
    </label>
    <input
      id="firstname"
      pInputText
      formControlName="firstname"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
{subject: ('customerContactPersonComponent.attributes.firstname' | translate)}"
      [value]="customerContactPerson.firstname" />
  </div>
  <!-- Firstname Field | From -->

  <!-- Lastname Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="lastname" class="block">
      {{ "customerContactPersonComponent.attributes.lastname" | translate}}*
    </label>
    <input
      id="lastname"
      pInputText
      formControlName="lastname"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
    {subject: ('customerContactPersonComponent.attributes.lastname' | translate)}"
      [value]="customerContactPerson.lastname" />
  </div>
  <!-- Lastname Field | From -->

  <!-- PhoneNumberMobile Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="phoneNumberMobile" class="block">
      {{ "customerContactPersonComponent.attributes.phoneNumberMobile" |
      translate}}
    </label>
    <input
      id="phoneNumberMobile"
      pInputText
      formControlName="phoneNumberMobile"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('customerContactPersonComponent.attributes.phoneNumberMobile' | translate)}"
      [value]="customerContactPerson.phoneNumberMobile" />
  </div>
  <!-- PhoneNumberMobile Field | From -->

  <!-- PhoneNumberLandline Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="phoneNumberLandline" class="block">
      {{ "customerContactPersonComponent.attributes.phoneNumberLandline" |
      translate}}
    </label>
    <input
      id="phoneNumberLandline"
      pInputText
      formControlName="phoneNumberLandline"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('customerContactPersonComponent.attributes.phoneNumberLandline' | translate)}"
      [value]="customerContactPerson.phoneNumberLandline" />
  </div>
  <!-- PhoneNumberLandline Field | From -->

  <!-- Email Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="email" class="block">
      {{ "customerContactPersonComponent.attributes.email" | translate}}
    </label>
    <input
      id="email"
      pInputText
      formControlName="email"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
            {subject: ('customerContactPersonComponent.attributes.email' | translate)}"
      [value]="customerContactPerson.email" />
  </div>
  <!-- Email Field | From -->

  <!-- Street Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="street" class="block">
      {{ "customerContactPersonComponent.attributes.street" | translate}}
    </label>
    <input
      id="street"
      pInputText
      formControlName="street"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
            {subject: ('customerContactPersonComponent.attributes.street' | translate)}"
      [value]="customerContactPerson.street" />
  </div>
  <!-- Street Field | From -->

  <!-- PostalCode | From -->
  <div class="form-field mb-2">
    <label htmlFor="postalCode" class="block">
      {{ "customerContactPersonComponent.attributes.postalCode" | translate}}
    </label>
    <input
      id="postalCode"
      pInputText
      formControlName="postalCode"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
                {subject: ('customerContactPersonComponent.attributes.postalCode' | translate)}"
      [value]="customerContactPerson.postalCode" />
  </div>
  <!-- PostalCode | From -->

  <!-- City Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="city" class="block">
      {{ "customerContactPersonComponent.attributes.city" | translate}}
    </label>
    <input
      id="city"
      pInputText
      formControlName="city"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
                    {subject: ('customerContactPersonComponent.attributes.city' | translate)}"
      [value]="customerContactPerson.city" />
  </div>
  <!-- City Field | From -->

  <!-- Country Field | From -->
  <div class="form-field mb-2">
    <label for="country" class="block mb-2">
      {{ "customerComponent.attributes.address.country" | translate }}
    </label>
    <p-dropdown
      formControlName="countryId"
      optionLabel="key"
      optionValue="id"
      dataKey="id"
      id="country"
      styleClass="w-full"
      [options]="countries"
      [placeholder]="'general.form.enterSubject' | translate: { subject: ('customerComponent.attributes.address.country' | translate) }">
      <ng-template let-country pTemplate="item">
        {{ ("countries." + country.key) | translate }}
      </ng-template>
      <ng-template let-country pTemplate="selectedItem">
        <span *ngIf="country !== undefined">
          {{ ("countries." + country.key) | translate }}
        </span>
      </ng-template>
    </p-dropdown>
  </div>
  <!-- Country Field | From -->

  <!-- Is Main Contact Field | From -->
  <div class="form-field mb-2 pb-2 pt-2 flex align-items-center gap-2">
    <p-inputSwitch id="isMainContact" formControlName="isMainContact" />
    <label htmlFor="isMainContact" class="block">
      {{ "customerContactPersonComponent.attributes.isMainContact" | translate}}
    </label>
  </div>
  <!-- Is Main Contact Field | From -->

  <!-- Is Active Field | From -->
  <div class="form-field mb-2 pb-2 pt-2 flex align-items-center gap-2">
    <p-inputSwitch id="isActive" formControlName="isActive" />
    <label htmlFor="isActive" class="block">
      {{ "customerContactPersonComponent.attributes.isActive" | translate}}
    </label>
  </div>
  <!-- Is Active Field | From -->

  <!-- Note Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="note" class="block mb-2">
      {{ "customerContactPersonComponent.attributes.note" | translate}}
    </label>
    <textarea
      id="note"
      rows="5"
      cols="30"
      type="text"
      pInputText
      formControlName="note"
      class="w-full block"
      [placeholder]="'general.form.enterSubject'| translate:
    {subject: ('customerContactPersonComponent.attributes.note' | translate)}"
      [value]="customerContactPerson.note"></textarea>
  </div>
  <!-- Note Field | From -->
</form>
