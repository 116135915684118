import { Renderer2, RendererFactory2 } from '@angular/core';

/**
 * Helper class for downloading files.
 */
export class FileDownloader {
  private renderer: Renderer2;

  /**
   * Constructs a new FileDownloader instance.
   * @param rendererFactory - The renderer factory used to create the renderer.
   */
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Creates a blob URL for the given data and file type.
   * @param data - The data to be converted into a blob.
   * @param fileType - The type of the file.
   * @returns The created blob URL.
   */
  public createBlobUrl(data: ArrayBuffer, fileType: string): string {
    const blob = new Blob([data], { type: fileType });

    return URL.createObjectURL(blob);
  }

  /**
   * Revokes the given blob URL.
   * @param url - The blob URL to be revoked.
   */
  public revokeBlobUrl(url: string): void {
    URL.revokeObjectURL(url);
  }

  /**
   * Downloads a file with the given data, filename, and file type.
   * @param data - The data to be downloaded.
   * @param filename - The name of the file.
   * @param fileType - The type of the file.
   */
  public downloadFile(
    data: ArrayBuffer,
    filename: string,
    fileType: string
  ): void {
    try {
      const url = this.createBlobUrl(data, fileType);

      const link = this.renderer.createElement('a');
      this.renderer.setProperty(link, 'href', url);
      this.renderer.setProperty(link, 'download', filename);

      link.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }
}
