import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  GridsterConfig,
  GridsterItem,
  GridsterItemComponentInterface
} from 'angular-gridster2';
import { first, Subscription } from 'rxjs';
import { Widget, WidgetComponent } from 'src/app/models/Widget';

import { animate, style, transition, trigger } from '@angular/animations';
import { Ticket } from 'src/app/models/Ticket';
import { TicketService } from 'src/app/services/api/ticket.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AppAction } from 'src/config/authorization.config';
import { UserWidgetService } from 'src/app/services/api/user-widget.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  animations: [
    trigger('fadeAnimation', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Output() itemResizedEvent = new EventEmitter<{
    item: Widget;
    itemComponent: GridsterItemComponentInterface;
  }>();

  options: GridsterConfig;

  dashboard: Widget[] = [];

  editMode = false;

  showGridster = false;

  widgetChooserModalVisible = false;

  tickets?: Ticket[];

  myTickets?: Ticket[];

  lastWidgetIdClicked?: number;

  subscriptions = new Subscription();

  colorScheme: 'dim' | 'light' | 'dark' = 'light';

  constructor(
    private readonly ticketService: TicketService,
    private readonly userWidgetService: UserWidgetService,
    private readonly localStorageService: LocalStorageService,
    private readonly authService: AuthService
  ) {
    // Bind the methods to the current instance to ensure the correct `this` context
    this.itemResize = this.itemResize.bind(this);
    this.itemChange = this.itemChange.bind(this);
    this.addWidget = this.addWidget.bind(this);

    this.options = {
      itemChangeCallback: this.itemChange,
      itemResizeCallback: this.itemResize,
      emptyCellClickCallback: this.addWidget,
      displayGrid: 'onDrag&Resize',
      gridType: 'scrollVertical',
      rowHeightRatio: 0.2,
      enableEmptyCellClick: true,
      pushItems: true,
      draggable: {
        enabled: this.editMode
      },
      resizable: {
        enabled: this.editMode
      }
    };
  }

  ngOnInit(): void {
    this.localStorageService.itemValue.subscribe((value: string) => {
      if (value) {
        const { colorScheme } = JSON.parse(value);
        if (colorScheme) {
          this.colorScheme = colorScheme;
        }
      }
    });
    this.getTickets();
    this.getWidgets();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Fetches the user's dashboard widgets using the userWidgetService and assigns them to the dashboard property.
   * Subscribes to the userWidgetService and ensures only the first emitted value is processed.
   * Sets the showGridster flag to true after the widgets are fetched.
   *
   * @returns {void}
   */
  getWidgets(): void {
    this.subscriptions.add(
      this.userWidgetService.getUserDashboard().subscribe((widgets) => {
        this.dashboard = widgets;
        this.showGridster = true;
      })
    );
  }

  /**
   * Fetches all tickets using the ticketService and assigns them to the tickets property.
   * Subscribes to the ticketService and ensures only the first emitted value is processed.
   *
   * @returns {void}
   */
  getTickets(): void {
    this.subscriptions.add(
      this.ticketService.findAll().subscribe((tickets) => {
        this.tickets = tickets;

        const userMe = this.authService.getLoggedInUser();

        if (userMe) {
          this.myTickets = tickets.filter(
            (ticket) => ticket.editedById === userMe.id
          );
        }
      })
    );
  }

  /**
   * Handles the change event of a Gridster item.
   * Finds the corresponding widget in the dashboard and emits an itemResizedEvent.
   *
   * @param {GridsterItem} item - The Gridster item that was changed.
   * @param {GridsterItemComponentInterface} itemComponent - The Gridster item component interface.
   *
   * @returns {void}
   */
  itemChange(
    item: GridsterItem,
    itemComponent: GridsterItemComponentInterface
  ): void {
    const changedComponent = this.dashboard.find(
      (d) => d.item === item
    ) as Widget;

    if (changedComponent) {
      this.itemResizedEvent.emit({ item: changedComponent, itemComponent });
    }
  }

  /**
   * Handles the resize event of a Gridster item.
   * Finds the corresponding widget in the dashboard and emits an itemResizedEvent.
   *
   * @param {GridsterItem} item - The Gridster item that was resized.
   * @param {GridsterItemComponentInterface} itemComponent - The Gridster item component interface.
   *
   * @returns {void}
   */
  itemResize(
    item: GridsterItem,
    itemComponent: GridsterItemComponentInterface
  ): void {
    const resizedComponent = this.dashboard.find(
      (d) => d.item === item
    ) as Widget;
    if (resizedComponent) {
      this.itemResizedEvent.emit({ item: resizedComponent, itemComponent });
    }
  }

  /**
   * Toggles the edit mode of the dashboard.
   * Enables or disables the draggable and resizable options based on the edit mode.
   * Temporarily hides and shows the Gridster to apply changes.
   *
   * @returns {void}
   */
  toggleEditMode(): void {
    this.editMode = !this.editMode;
    this.showGridster = false;
    if (this.options.draggable && this.options.resizable) {
      this.options.draggable.enabled = this.editMode;
      this.options.resizable.enabled = this.editMode;
    }
    setTimeout(() => {
      this.showGridster = true;
    }, 0);
  }

  /**
   * Triggers the optionsChanged function if it exists in the options API.
   *
   * @returns {void}
   */
  changedOptions(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  /**
   * Saves the current state of the dashboard by calling the userWidgetService.
   * Sets the dashboard to the returned value and disables edit mode.
   *
   * @returns {void}
   */
  saveDashboard(): void {
    this.dashboard = this.dashboard.filter(
      (d) => d.component !== 'app-widget-empty'
    );
    this.subscriptions.add(
      this.userWidgetService
        .createUserDashboard(this.dashboard)
        .pipe(first())
        .subscribe((dashboard) => {
          this.dashboard = dashboard;
          this.toggleEditMode();
        })
    );
  }

  /**
   * Adds a new widget to the dashboard.
   *
   * @param {MouseEvent} event - The mouse event that triggered the addition.
   * @param {GridsterItem} item - The gridster item to be added to the dashboard.
   *
   * @returns {void}
   */
  addWidget(event: MouseEvent, item: GridsterItem): void {
    if (this.dashboard && this.editMode) {
      this.dashboard.push({
        id: Math.floor(Math.random() * 1000000) + 1,
        component: WidgetComponent.Empty,
        item
      });
    }
  }

  /**
   * Removes a widget from the dashboard.
   *
   * @param {Widget} widget - The widget to be removed from the dashboard.
   *
   * @returns {void}
   */
  removeWidget(widget: Widget): void {
    if (widget.id) {
      const index = this.dashboard.findIndex((d) => d.id === widget.id);
      if (index > -1) {
        this.dashboard.splice(index, 1);
      }
    }
  }

  /**
   * Shows the widget chooser modal and sets the ID of the last clicked widget.
   *
   * @param {number} widgetIdClicked - The ID of the widget that was clicked.
   *
   * @returns {void}
   */
  showWidgetChooserModal(widgetIdClicked: number): void {
    this.lastWidgetIdClicked = widgetIdClicked;
    this.widgetChooserModalVisible = true;
  }

  /**
   * Hides the widget chooser modal.
   *
   * @returns {void}
   */
  hideWidgetChooserModal(): void {
    this.widgetChooserModalVisible = false;
  }

  /**
   * Updates the component of the widget in the dashboard based on the last clicked widget ID.
   *
   * @param {WidgetComponent} widget - The new widget component to be set.
   *
   * @returns {void}
   */
  selectedWidgetsChange(widget: WidgetComponent): void {
    const index = this.dashboard.findIndex(
      (d) => d.id === this.lastWidgetIdClicked
    );
    if (index > -1) {
      this.dashboard[index].component = widget;
    }
    this.hideWidgetChooserModal();
  }

  ticketCreated(): void {
    this.getTickets();
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'UserWidget');
  }
}
