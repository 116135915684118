<div class="flex">
  <h6 class="text-primary">
    {{'ticketComponent.editForm.sections.appointment.title' | translate}}
  </h6>
  <span
    class="pi pi-window-maximize text-primary cursor-pointer ml-4"
    (click)="navigateToCalendar()"></span>
</div>
<ng-container *ngIf="ticket && ticket.appointments">
  <p-table
    [value]="ticket.appointments"
    [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header">
      <tr>
        <th>
          <label htmlFor="start" class="block mb-2">
            {{ "ticketComponent.editForm.sections.appointment.labels.start" |
            translate}}
          </label>
        </th>
        <th>
          <label htmlFor="end" class="block mb-2">
            {{ "ticketComponent.editForm.sections.appointment.labels.end" |
            translate}}
          </label>
        </th>
        <th>
          <label htmlFor="technician" class="block mb-2">
            {{ "ticketComponent.editForm.sections.appointment.labels.technician"
            | translate}}
          </label>
        </th>
        <th>
          <label htmlFor="otherEditors" class="block mb-2">
            {{
            "ticketComponent.editForm.sections.appointment.labels.otherEditors"
            | translate}}
          </label>
        </th>
        <th>
          <label htmlFor="notes" class="block mb-2">
            {{ "ticketComponent.editForm.sections.appointment.labels.notes" |
            translate}}
          </label>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-appointment>
      <tr
        [ngClass]="{'deleted' : appointment.deletedAt, 'done': isPastAppointment(appointment.end)}">
        <td>{{ appointment.start | date:'dd.MM.YYYY HH:mm:ss' }}</td>
        <td>{{ appointment.end | date:'dd.MM.YYYY HH:mm:ss' }}</td>
        <td>
          <div
            *ngIf="appointment.technician"
            class="flex align-items-center gap-2">
            <app-user-avatar [user]="appointment.technician" size="normal" />
            <span
              >{{appointment.technician.firstname}}
              {{appointment.technician.lastname}}</span
            >
          </div>
        </td>

        <td>
          <div
            *ngIf="appointment.otherTechnicians && appointment.otherTechnicians.length === 1 && appointment.otherTechnicians[0]"
            class="flex align-items-center gap-2">
            <app-user-avatar
              [user]="appointment.otherTechnicians[0]"
              size="normal" />
            <span
              >{{appointment.otherTechnicians[0].firstname}}
              {{appointment.otherTechnicians[0].lastname}}</span
            >
          </div>
          <div
            *ngIf="appointment.otherTechnicians && appointment.otherTechnicians.length > 1"
            class="flex align-items-center gap-2">
            @for (editor of appointment.otherTechnicians; track $index) {
            <app-user-avatar [user]="editor" size="normal" />
            }
          </div>
        </td>
        <td>
          <div class="cut-text" [pTooltip]="appointment.notes">
            {{appointment.notes}}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
