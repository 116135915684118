/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { GenericColumnConfiguration } from 'src/app/models/GenericColumnConfiguration';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { AppAction } from 'src/config/authorization.config';

@Component({
  selector: 'app-generic-column-body',
  templateUrl: './generic-column-body.component.html',
  styleUrls: ['./generic-column-body.component.scss']
})
export class GenericColumnBodyComponent implements OnInit {
  @Input() column!: GenericColumnConfiguration;

  @Input() config!: GenericTableConfiguration;

  // This is a hack to always use the formatter function
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ alias: 'value' }) _value: any;

  @Input() object: any;

  @Input() field: any;

  @Output() switchValueChanged = new EventEmitter<any>();

  @Output() columnClickedEvent = new EventEmitter<any>();

  checked?: boolean;

  constructor(
    private messageCenterService: MessageCenterService,
    private translate: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.checked = this.value;
  }

  // This is a hack to always use the formatter function
  // TODO: This should be properly implemented in the future
  get value(): any {
    return this.column.formatter(this._value);
  }

  async onSwitchValueChanged(event: InputSwitchChangeEvent | any) {
    if (this.column.confirmCellAction) {
      const confirmed = await new Promise<boolean>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant(
            `${this.config.translationKey}.table.columns.${this.column.field}.cellConfirm.header`
          ),
          this.translate.instant(
            `${this.config.translationKey}.table.columns.${this.column.field}.cellConfirm.message`
          ),
          () => {
            resolve(true);
          },
          () => {
            const result = 'info';
            const toastPath = `${this.config.translationKey}.table.columns.${this.column.field}.toasts.${result}`;

            this.messageCenterService.showToast(
              this.translate.instant(`${toastPath}.summary`),
              this.translate.instant(`${toastPath}.detail`),
              result
            );
            resolve(false);
          }
        );
      });

      if (confirmed) {
        this.object[this.field] = event;
        this.switchValueChanged.emit(this.object);
      } else {
        this.checked = !event;
      }
    } else {
      this.object[this.field] = event;
      this.switchValueChanged.emit(this.object);
    }
  }

  valueChangedWithoutConfirm(event: InputSwitchChangeEvent | any) {
    if (this.column.referringKey) {
      if (event instanceof Array) {
        this.object[this.column.referringKey] = [
          this.object[this.column.referringKey],
          ...event
        ];
      } else {
        this.object[this.column.referringKey] = event;
      }
    }

    this.switchValueChanged.emit(this.object);
  }

  $can(action: AppAction | AppAction[] | undefined): boolean {
    const subject = this.config.permissionSubject;

    if (!subject || !action) {
      return true;
    }

    return this.authService.$can(action, subject);
  }

  columnClicked() {
    if (this.column.isClickable) {
      this.columnClickedEvent.emit(this.object);
    }
  }
}
