/* eslint-disable no-bitwise */

import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Draggable } from '@fullcalendar/interaction';
import { TranslateService } from '@ngx-translate/core';
import { first, Subscription } from 'rxjs';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { Ticket, TicketCategory, TicketStatus } from 'src/app/models/Ticket';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/api/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Severity } from 'src/app/types/misc/Severity';
import { AppAction } from 'src/config/authorization.config';
import { CalendarComponent } from './calendar/calendar.component';
import {
  categoryChips,
  statusChips
} from '../ticket/components/utils/getTicketInfo';
import { TicketService } from 'src/app/services/api/ticket.service';

@Component({
  selector: 'app-calendar-overview',
  templateUrl: './calendar-overview.component.html',
  styleUrl: './calendar-overview.component.scss',
  animations: [
    trigger('fadeAnimation', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class CalendarOverviewComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @ViewChild('calendar') calendar!: ElementRef;

  @ViewChild(CalendarComponent) calendarComponent!: CalendarComponent;

  private isViewInitialized = false;

  leftPanelExpanded = true;

  rightPanelExpanded = true;

  calendarViewChange = false;

  tickets: Ticket[] = [];

  filteredTickets: Ticket[] = [];

  ticketsForAppointment: Ticket[] = [];

  users: User[] = [];

  usersCopy: User[] = [];

  selectedUsers: User[] = [];

  selectedFilterUsers: User[] = [];

  selectedTicket?: Ticket;

  subscriptions = new Subscription();

  categories: string[] = [];

  searchTerm = '';

  selectedStatuses: TicketStatus[] = [];

  userIdsToSave: number[] = [];

  selectedCategories: TicketCategory[] = [];

  categoryChips = categoryChips;

  statusChips = statusChips;

  calendarSelectLimit = 9999;

  constructor(
    private readonly ticketService: TicketService,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    const loggedInUser = this.authService.getLoggedInUser();
    if (loggedInUser && loggedInUser.role === 'CustomerService') {
      this.calendarSelectLimit = 5;
    }

    this.getTickets();
    this.getUsers();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewChecked(): void {
    if (
      !this.isViewInitialized &&
      this.filteredTickets &&
      this.filteredTickets.length > 0
    ) {
      this.isViewInitialized = true;
      this.filteredTickets.forEach((ticket) => this.initDraggable(ticket));
    }
  }

  getTickets(): void {
    this.subscriptions.add(
      this.ticketService
        .findAll()
        .pipe(first())
        .subscribe((tickets) => {
          const ticketsForAppointments = tickets.filter(
            (ticket) =>
              this.isAppointmentNeeded(ticket) &&
              ticket.ticketStatusType !== TicketStatus.Cancelled &&
              ticket.ticketStatusType !== TicketStatus.Completed
          );
          this.tickets = ticketsForAppointments;
          this.filteredTickets = this.tickets;

          this.ticketsForAppointment = tickets.filter(
            (ticket) =>
              ticket.isAppointmentNeeded &&
              ticket.ticketStatusType !== TicketStatus.Cancelled &&
              ticket.ticketStatusType !== TicketStatus.Completed
          );
          setTimeout(() => {
            this.calendarViewChange = false;

            if (this.rightPanelExpanded) {
              this.filteredTickets.forEach((ticket) =>
                this.initDraggable(ticket)
              );
            }
          }, 70);
        })
    );
  }

  isAppointmentNeeded(ticket: Ticket): boolean {
    if (!ticket.isAppointmentNeeded) {
      return false;
    }
    const { appointments } = ticket;
    const hasAppointments = appointments && appointments.length > 0;

    if (hasAppointments) {
      const hasActiveAppointments = appointments.some(
        (appointment) => appointment.deletedAt === null
      );
      if (hasActiveAppointments) {
        return false;
      }
    }

    return true;
  }

  getUsers(): void {
    this.subscriptions.add(
      this.userService
        .findAll()
        .pipe(first())
        .subscribe((users) => {
          this.users = users;
          this.users = this.users
            .filter((user) => user.active && user.deletedAt === null)
            .map((user) => ({
              ...user,
              fullName: user.firstname
                ? `${user.firstname} ${user.lastname}`
                : user.lastname
            }));

          this.getCalendarUsersToLocalStorage();
          this.loadUserOrderFromLocalStorage();
          this.usersCopy = this.users.map((user) => ({ ...user }));
        })
    );
  }

  /**
   * Initializes a draggable element for the given ticket.
   * Finds the HTML element corresponding to the ticket and makes it draggable.
   *
   * @param {Ticket} ticket - The ticket for which to initialize the draggable element.
   * @returns {void}
   */
  initDraggable(ticket: Ticket): void {
    // Get the HTML element by ticket ID
    const element = document.getElementById(`ticket-${ticket.id}`);

    // If the element exists, make it draggable
    if (element) {
      // eslint-disable-next-line no-new
      new Draggable(element, {
        eventData: {
          title: ticket.subject,
          description: ticket.description,
          duration: '00:30',
          ticket
        }
      });
    }
  }

  /**
   * Handles the change in calendar view when a side panel is toggled.
   * Expands or collapses the left or right panel based on the side parameter.
   * Initializes draggable tickets if the right panel is expanded.
   *
   * @param {'left' | 'right'} side - The side panel that was toggled.
   * @returns {void}
   */
  calendarViewChanged(side: 'left' | 'right'): void {
    // Indicate that the calendar view is changing
    this.calendarViewChange = true;

    // Toggle the expansion state of the left or right panel
    if (side === 'left') {
      this.leftPanelExpanded = !this.leftPanelExpanded;
    } else {
      this.rightPanelExpanded = !this.rightPanelExpanded;
    }

    // After a short delay, reset the calendar view change indicator
    setTimeout(() => {
      this.calendarViewChange = false;

      // If the right panel is expanded, (re)initialize draggable tickets
      if (this.rightPanelExpanded) {
        this.filteredTickets.forEach((ticket) => this.initDraggable(ticket));
      }
    }, 10);
  }

  /**
   * Sets the selected ticket.
   *
   * @param {Ticket} ticket - The ticket to be selected.
   * @returns {void}
   */
  ticketSelected(ticket: Ticket): void {
    if (this.$can('create') === false) {
      return;
    }

    this.selectedTicket = ticket;
  }

  /**
   * Resets the selected ticket to undefined.
   *
   * @returns {void}
   */
  resetTicket(): void {
    this.selectedTicket = undefined;
  }

  /**
   * Handles the deletion of an appointment.
   * This method is called when an appointment is deleted and it refreshes the list of tickets and reinits the ticket draggables
   *
   * @returns {void}
   */
  reInitTickets(): void {
    this.getTickets();

    setTimeout(() => {
      this.calendarViewChange = false;

      // If the right panel is expanded, (re)initialize draggable tickets
      if (this.rightPanelExpanded) {
        this.filteredTickets.forEach((ticket) => this.initDraggable(ticket));
      }
    }, 10);
  }

  /**
   * Removes a ticket from the tickets and filteredTickets arrays based on the ticket ID.
   *
   * @param {number} ticketId - The ID of the ticket to be removed.
   * @returns {void}
   */
  removeTicketFromList(ticketId: number): void {
    this.tickets = this.tickets.filter((ticket) => ticket.id !== ticketId);
    this.filteredTickets = this.filteredTickets.filter(
      (ticket) => ticket.id !== ticketId
    );
  }

  /**
   * Filters the tickets by the given statuses and updates the filteredTickets array.
   *
   * @param {TicketStatus[]} statuses - The statuses to filter the tickets by.
   * @returns {void}
   */
  filterByStatus(statuses: TicketStatus[]): void {
    this.selectedStatuses = statuses;
    this.filterTickets();
  }

  /**
   * Filters the tickets by the given categories and updates the filteredTickets array.
   *
   * @param {TicketCategory[]} categories - The categories to filter the tickets by.
   * @returns {void}
   */
  filterByCategory(categories: TicketCategory[]): void {
    this.selectedCategories = categories;
    this.filterTickets();
  }

  filterByUser(users: User[]): void {
    this.selectedFilterUsers = users;
    this.filterTickets();
  }

  /**
   * Filters the tickets based on selected statuses, categories, and a search term.
   * Updates the filteredTickets array with tickets that match the selected criteria.
   *
   * @returns {void}
   */
  filterTickets(): void {
    this.filteredTickets = this.tickets.filter((ticket) => {
      // Check if the ticket matches the selected statuses
      const matchesStatus =
        this.selectedStatuses.length === 0 ||
        this.selectedStatuses.includes(ticket.ticketStatusType);

      // Check if the ticket matches the selected categories
      const matchesCategories =
        this.selectedCategories.length === 0 ||
        this.selectedCategories.includes(ticket.ticketCategoryType);

      let matchesUsers;
      if (ticket.editor) {
        matchesUsers =
          this.selectedFilterUsers.length === 0 ||
          this.selectedFilterUsers.some(
            (user) => user.id === ticket.editor?.id
          );
      }

      // Check if the ticket matches the search term in subject, description, or ticket number
      const matchesSearchTerm = this.searchTerm
        ? ticket.subject
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          ticket.description
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          `${ticket.ticketNumber}`.includes(this.searchTerm.toLowerCase())
        : true;

      // Return true if the ticket matches all the selected criteria
      return (
        matchesStatus && matchesCategories && matchesSearchTerm && matchesUsers
      );
    });

    // Since the cdr (for whatever reason) does not work here, a little hack to reinit the dragables
    this.calendarViewChanged('right');
    setTimeout(() => {
      this.calendarViewChanged('right');
    }, 0);
  }

  /**
   * Toggles the selection of a user. If the user is already selected, it removes the user from the selectedUsers array.
   * If the user is not selected, it adds the user to the selectedUsers array.
   *
   * @param {User} user - The user object to be selected or deselected.
   * @returns {void}
   */
  userSelected(user: User): void {
    if (
      this.selectedUsers.some((selectedUser) => selectedUser.id === user.id)
    ) {
      this.selectedUsers = this.selectedUsers.filter(
        (selectedUser) => selectedUser.id !== user.id
      );
    } else {
      this.selectedUsers = [...this.selectedUsers, user];
    }

    this.setCalendarUsersToLocalStorage();
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'Appointment');
  }

  $canUser(action: AppAction): boolean {
    return this.authService.$can(action, 'User');
  }

  /**
   * Updates the color of a user in the users array and optionally adds the user ID to the userIdsToSave array.
   * If the user ID is not already in the userIdsToSave array, it is added.
   *
   * @param {User} user - The user object with the updated color.
   * @param {boolean} [addToSelected=true] - Whether to add the user ID to the userIdsToSave array. Defaults to true.
   * @returns {void}
   */
  colorChanged(user: User, addToSelected: boolean = true): void {
    this.users = this.users.map((userToUpdate) =>
      userToUpdate === user
        ? { ...userToUpdate, color: user.color }
        : userToUpdate
    );
    if (user.id && addToSelected) {
      if (!this.userIdsToSave.includes(user.id)) {
        this.userIdsToSave.push(user.id);
      }
    }
  }

  /**
   * Resets the color of a user to its original value.
   * Finds the user in the users and usersCopy arrays, and sets the user's color to the original color.
   * Removes the user ID from the userIdsToSave array if it exists.
   * Calls the colorChanged method to update the color change status.
   *
   * @param {User} user - The user object whose color needs to be reset.
   * @returns {void}
   */
  resetUserColor(user: User): void {
    const userIndex = this.users.findIndex(
      (userToUpdate) => userToUpdate.id === user.id
    );
    const userCopyIndex = this.usersCopy.findIndex(
      (userCopyToUpdate) => userCopyToUpdate.id === user.id
    );

    if (userIndex !== -1 && userCopyIndex !== -1) {
      this.users[userIndex].color = this.usersCopy[userCopyIndex].color;
    }
    if (user.id) {
      this.userIdsToSave = this.userIdsToSave.filter((id) => id !== user.id);
    }
    this.colorChanged(this.users[userIndex], false);
  }

  /**
   * Updates the color of a user and synchronizes the changes with the server.
   * If the update is successful, it updates the user in the local users array and usersCopy array,
   * and removes the user ID from the userIdsToSave array. If the update fails, it resets the user's color.
   *
   * @param {User} user - The user object containing the updated color and other user details.
   * @returns {void}
   */
  updateUserColor(user: User): void {
    if (user.id) {
      this.userService.edit(user.id, user).subscribe({
        next: () => {
          const userIndex = this.users.findIndex(
            (userToUpdate) => userToUpdate.id === user.id
          );
          const userCopyIndex = this.usersCopy.findIndex(
            (userCopyToUpdate) => userCopyToUpdate.id === user.id
          );
          if (userIndex !== -1 && userCopyIndex !== -1) {
            this.users[userIndex] = user;
            this.usersCopy[userCopyIndex] = user;
            this.userIdsToSave = this.userIdsToSave.filter(
              (id) => id !== user.id
            );
            this.showCrudToast(
              DataModificationMethod.Edit,
              'success',
              user.username
            );
          }
        },
        error: () => {
          this.resetUserColor(user);
          this.showCrudToast(
            DataModificationMethod.Edit,
            'error',
            user.username
          );
        }
      });
    }
  }

  showCrudToast(
    method: DataModificationMethod,
    severity: Severity,
    username: string | undefined
  ): void {
    const translateParam = username ? username : '';
    this.messageCenterService.showToast(
      this.translate.instant(
        `userManagementComponent.actions.toasts.${method}.${severity}.summary`,
        { username: translateParam }
      ),
      this.translate.instant(
        `userManagementComponent.actions.toasts.${method}.${severity}.detail`,
        { username: translateParam }
      ),
      severity
    );
  }

  /**
   * Determines the appropriate text color (black or white) based on the given background color.
   *
   * @param {string} backgroundColor - The background color in hex format (e.g., '#RRGGBB').
   * @returns {string} The text color in hex format ('#FFFFFF' for white or '#000000' for black).
   */
  getTextColorBasedOnBackground(backgroundColor: string | undefined): string {
    if (!backgroundColor) {
      return '#000000';
    }
    // Convert hex color to RGB
    const hex = backgroundColor.replace('#', '');
    const red = parseInt(hex.substring(0, 2), 16);
    const green = parseInt(hex.substring(2, 4), 16);
    const blue = parseInt(hex.substring(4, 6), 16);

    // Calculate relative luminance
    // eslint-disable-next-line no-mixed-operators
    const luminance = 0.2126 * red + 0.7152 * green + 0.0722 * blue;

    // Return white for dark backgrounds and black for light backgrounds
    return luminance < 140 ? '#FFFFFF' : '#000000';
  }

  /**
   * Handles the drop event for drag-and-drop functionality.
   * Moves an item in the tickets array from the previous index to the current index.
   *
   * @param {CdkDragDrop<string[]>} event - The drag-and-drop event containing the previous and current indices.
   * @returns {void}
   */
  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.tickets, event.previousIndex, event.currentIndex);
  }

  /**
   * Handles the drop event for drag-and-drop functionality.
   * Moves an item in the users array from the previous index to the current index.
   *
   * @param {CdkDragDrop<string[]>} event - The drag-and-drop event containing the previous and current indices.
   * @returns {void}
   */
  dropUser(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.users, event.previousIndex, event.currentIndex);
    this.saveUserOrderToLocalStorage();
  }

  /**
   * Saves the current order of users to local storage.
   *
   * @returns {void}
   */
  saveUserOrderToLocalStorage(): void {
    localStorage.setItem(
      'user-order',
      JSON.stringify(this.users.map((user) => user.id))
    );
  }

  /**
   * Loads the order of users from local storage and updates the users array.
   *
   * @returns {void}
   */
  loadUserOrderFromLocalStorage(): void {
    const storedOrder = localStorage.getItem('user-order');
    if (storedOrder) {
      const userOrder = JSON.parse(storedOrder);
      // Sort users based on the stored order
      this.users.sort((a, b) => {
        const indexA = userOrder.indexOf(a.id);
        const indexB = userOrder.indexOf(b.id);
        // If a user is not found in the stored order, place them at the end
        if (indexA === -1) {
          return 1;
        }
        if (indexB === -1) {
          return -1;
        }

        return indexA - indexB;
      });
    }
  }

  /**
   * Getter to determine if a user checkbox should be disabled.
   *
   * @returns {Function} A function that takes a user and returns a boolean indicating if the checkbox should be disabled.
   */
  get isUserCheckboxDisabled(): (user: User) => boolean {
    return (user: User) => {
      if (user.id) {
        const hasValidId = Boolean(user.id);
        const isUserInSaveList = this.userIdsToSave.includes(user.id);
        const isUserLimitReached =
          this.selectedUsers.length >= this.calendarSelectLimit;
        const isUserNotSelected = !this.isUserSelected(user);

        return (
          (hasValidId && isUserInSaveList) ||
          (isUserLimitReached && isUserNotSelected)
        );
      }

      return false;
    };
  }

  /**
   * Converts a hex color code to an RGBA color string with a fixed alpha value.
   *
   * @param {string} hex - The hex color code (e.g., "#RRGGBB").
   * @returns {string} The RGBA color string with 50% opacity (e.g., "rgba(255, 0, 0, 0.5)").
   */
  getCheckBoxShadowColor(hex: string): string {
    const bigint = parseInt(hex.slice(1), 16);
    const red = (bigint >> 16) & 255;
    const green = (bigint >> 8) & 255;
    const blue = bigint & 255;

    return `rgba(${red}, ${green}, ${blue}, ${0.5})`;
  }

  setCalendarUsersToLocalStorage(): void {
    localStorage.setItem(
      'selected-calendar-users',
      // only add the necessary data to identify (fpr better security and performance)
      JSON.stringify(this.selectedUsers.map((x) => x.id))
    );
  }

  getCalendarUsersToLocalStorage(): void {
    const users = localStorage.getItem('selected-calendar-users');

    if (users) {
      const selectedUsers: User[] = [];

      const ids = JSON.parse(users) as number[];

      ids.forEach((id) => {
        // remove potential deleted users
        const user = this.users.find((x) => x.id === id);
        if (user) {
          selectedUsers.push(user);
        }
      });

      this.selectedUsers = selectedUsers;

      // also update the local storage with the remaining users
      this.setCalendarUsersToLocalStorage();
    }
  }

  isUserSelected(user: User): boolean {
    return this.selectedUsers.some(
      (selectedUser) => selectedUser.id === user.id
    );
  }
}
