<ng-container *ngIf="user && !isLoading && profileForm" class="px-8">
  <form [formGroup]="profileForm">
    <div class="text-center">
      <div>
        <app-profile-image
          *ngIf="profilePictureBase64 !== undefined"
          [base64Image]="profilePictureBase64"
          (profilePictureChanged)="profilePictureChanged($event)"></app-profile-image>
      </div>
      <div>
        <h4>{{user.firstname}} {{user.lastname}}</h4>
      </div>
      <div>
        <span
          (click)="changePassword()"
          class="password-reset-link text-primary">
          <span>
            <i class="pi pi-key mr-2"></i>
          </span>
          <span>{{'profile.changePassword' | translate}}</span>
        </span>
      </div>
    </div>

    <hr />

    <div class="flex flex-column gap-1 pb-2">
      <small for="email">{{'profile.email' | translate}}*</small>
      <input pInputText id="email" name="email" [value]="user.email" disabled />
    </div>

    <div class="flex flex-column gap-1 pb-2">
      <small for="role">{{'profile.role' | translate}}*</small>
      <input
        *ngIf="user.role"
        pInputText
        id="role"
        name="role"
        disabled
        [value]="('userManagementComponent.table.columns.role.roles.' + user.role )| translate" />
    </div>

    <div class="flex flex-column gap-1 pb-2">
      <small for="username">{{'profile.username' | translate}}</small>
      <input
        pInputText
        id="username"
        name="username"
        [formControlName]="'username'" />
    </div>

    <div class="flex flex-column gap-1 pb-2">
      <small for="firstname">{{'profile.firstname' | translate}}*</small>
      <input
        pInputText
        id="firstname"
        name="firstname"
        [formControlName]="'firstname'" />
    </div>

    <div class="flex flex-column gap-1 pb-2">
      <small for="lastname">{{'profile.lastname' | translate}}*</small>
      <input
        pInputText
        id="lastname"
        name="lastname"
        [formControlName]="'lastname'" />
    </div>

    <div class="flex justify-content-between pt-4" *ngIf="profileForm.dirty">
      <button
        pButton
        pRipple
        [label]="'general.form.cancel' | translate"
        (click)="closeWindow()"
        class="p-button-outlined p-1"></button>
      <button
        pButton
        pRipple
        [disabled]="!profileForm.valid"
        [label]="'general.form.save' | translate"
        (click)="save()"></button>
    </div>
  </form>
</ng-container>

<div class="text-center" *ngIf="isLoading">
  <p-progressSpinner ariaLabel="loading" />
</div>

<p-dialog
  [header]="'profile.changePassword' | translate"
  [modal]="true"
  [(visible)]="passwordChangeVisible"
  (onHide)="resetForm()"
  [draggable]="false"
  [style]="{ width: '30rem' }">
  <form [formGroup]="newPasswordForm" (ngSubmit)="onPasswordChange()">
    <div class="flex flex-column gap-1 pb-2">
      <small for="password">{{'profile.password' | translate}}*</small>

      <p-password
        id="password"
        formControlName="password"
        [feedback]="true"
        [toggleMask]="true"
        [appendTo]="'body'"
        inputStyleClass="w-full"
        [strongRegex]="passwordStrongRegEx"
        [mediumRegex]="passwordMediumRegEx"
        styleClass="w-full p-password p-component p-inputwrapper p-input-icon-right">
        <ng-template pTemplate="header">
          <app-password-guidelines
            [showHeader]="true"></app-password-guidelines>
        </ng-template>
        <ng-template pTemplate="footer">
          <app-password-guidelines
            [showFooter]="true"></app-password-guidelines>
        </ng-template>
      </p-password>
    </div>
    <hr />
    <div class="flex flex-column gap-1 pb-2">
      <small for="password2">{{'profile.password2' | translate}}*</small>
      <p-password
        id="password2"
        formControlName="password2"
        [feedback]="true"
        [toggleMask]="true"
        inputStyleClass="w-full"
        [appendTo]="'body'"
        [strongRegex]="passwordStrongRegEx"
        [mediumRegex]="passwordMediumRegEx"
        styleClass="w-full p-password p-component p-inputwrapper p-input-icon-right">
        <ng-template pTemplate="header">
          <app-password-guidelines
            [showHeader]="true"></app-password-guidelines>
        </ng-template>
        <ng-template pTemplate="footer">
          <app-password-guidelines
            [showFooter]="true"></app-password-guidelines>
        </ng-template>
      </p-password>
    </div>
    <div class="flex flex-column gap-1 pb-2">
      <small for="password3">{{'profile.password3' | translate}}*</small>
      <p-password
        id="password3"
        formControlName="password3"
        [feedback]="true"
        [appendTo]="'body'"
        [toggleMask]="true"
        inputStyleClass="w-full"
        [strongRegex]="passwordStrongRegEx"
        [mediumRegex]="passwordMediumRegEx"
        styleClass="w-full p-password p-component p-inputwrapper p-input-icon-right">
        <ng-template pTemplate="header">
          <app-password-guidelines
            [showHeader]="true"></app-password-guidelines>
        </ng-template>
        <ng-template pTemplate="footer">
          <app-password-guidelines
            [showFooter]="true"></app-password-guidelines>
        </ng-template>
      </p-password>
    </div>
    <div class="flex justify-content-between gap-2">
      <div>
        <button
          pButton
          pRipple
          [label]="'general.form.cancel' | translate"
          (click)="passwordChangeVisible = false"
          class="p-button-outlined"></button>
      </div>
      <div>
        <button
          pButton
          pRipple
          [label]="'general.form.save' | translate"
          type="submit"
          [disabled]="!newPasswordForm.valid"></button>
      </div>
    </div>
  </form>
</p-dialog>
