import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, first, throwError } from 'rxjs';
import { CustomerContactPersonFormComponent } from 'src/app/components/admin/customer/views/components/customer-edit-contact-person/components/customer-contact-person-form/customer-contact-person-form.component';
import {
  HandleIsActiveSwitchContext,
  handleSwitchChangeFactory
} from 'src/app/components/admin/customer/views/components/customer-edit-contact-person/utils/handle-switch-change-factory';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { Country } from 'src/app/models/Country';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { Customer } from 'src/app/models/customer/Customer';
import {
  CustomerContactPerson,
  CustomerContactPersonCreate,
  CustomerContactPersonEditInterface,
  CustomerContactPersonUpdate
} from 'src/app/models/customer/CustomerContactPerson';
import { CustomerContactPersonService } from 'src/app/services/api/customer/customer-contact-person.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Severity } from 'src/app/types/misc/Severity';
import {
  displayLoggingContext,
  LoggingFormatter,
  LoggingFormatterBuilder
} from 'src/app/utils/logging';
import { AppAction } from 'src/config/authorization.config';

const handleChangeIsActive = handleSwitchChangeFactory(
  HandleIsActiveSwitchContext
);
@Component({
  selector: 'app-customer-edit-contact-person',
  templateUrl: './customer-edit-contact-person.component.html',
  styleUrls: ['./customer-edit-contact-person.component.scss']
})
export class CustomerEditContactPersonComponent implements OnInit {
  @ViewChild('form')
  form?: CustomerContactPersonFormComponent;

  @Input({ required: true }) customer!: Customer;

  @Input({ required: true }) customerContactPersons: CustomerContactPerson[] =
    [];

  @Input({ required: true }) countries: Country[] = [];

  @Output() changeCustomerContactPersons = new EventEmitter<
    CustomerContactPerson[]
  >();

  isFormDisabled = true;

  isFormDirty = false;

  isSidebarVisible = false;

  customerContactPersonToEdit: CustomerContactPersonEditInterface | null = null;

  loggingFormatter: LoggingFormatter | null = null;

  config = new GenericTableConfiguration({
    translationKey: 'customerContactPersonComponent'
  });

  constructor(
    public readonly translate: TranslateService,
    public readonly messageCenterService: MessageCenterService,
    public readonly customerContactPersonService: CustomerContactPersonService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.onChangeForm();

    this.loggingFormatter = LoggingFormatterBuilder.new<CustomerContactPerson>()
      .add('countryId', displayLoggingContext)
      .add('type', (value) =>
        this.translate.instant(
          `customerContactPersonComponent.attributes.type.enum.${value}`
        )
      )
      .build();
  }

  onSubmit(): void {
    if (
      this.customerContactPersonToEdit === null ||
      !this.form ||
      this.isFormDisabled
    ) {
      return;
    }

    const customerContactPerson = this.form.buildCustomerContactPerson();

    if (!customerContactPerson) {
      return;
    }

    if ('id' in this.customerContactPersonToEdit) {
      this.updateCustomerContactPerson(
        this.customerContactPersonToEdit.id,
        customerContactPerson as CustomerContactPersonUpdate // we can safely assert this type because we checked if 'id' is in this.customerContactPersonToEdit
      );

      return;
    }

    // We can safely assert this type because we checked if 'id' is in this.customerContactPersonToEdit
    this.createCustomerContactPerson(
      customerContactPerson as CustomerContactPersonCreate
    );
  }

  onChangeForm() {
    this.isFormDisabled =
      (!this.form?.formGroup?.valid || this.form?.formGroup?.disabled) ?? true;
    this.isFormDirty = this.form?.formGroup?.dirty ?? false;
  }

  get loggingIdentifier() {
    if (!this.customerContactPersonToEdit) {
      return null;
    }

    if ('id' in this.customerContactPersonToEdit) {
      return this.customerContactPersonToEdit.id;
    }

    return null;
  }

  createCustomerContactPerson(
    customerContactPerson: CustomerContactPersonCreate
  ): void {
    this.customerContactPersonService
      .create(customerContactPerson)
      .pipe(
        catchError((error) => {
          this.showCrudToast(
            DataModificationMethod.Create,
            'error',
            customerContactPerson
          );

          return throwError(() => error);
        }),
        first() // Automatically unsubscribe after first value
      )
      .subscribe((createdCustomerContactPerson) => {
        this.showCrudToast(
          DataModificationMethod.Create,
          'success',
          createdCustomerContactPerson
        );

        const newCustomerContactPersons = [
          ...this.customerContactPersons.map((contactPerson) => {
            if (createdCustomerContactPerson.isMainContact) {
              contactPerson.isMainContact = false;
            }

            return contactPerson;
          }),
          createdCustomerContactPerson
        ];

        newCustomerContactPersons.sort((a, b) => {
          const nameA = `${a.firstname} ${a.lastname}`.toLowerCase();
          const nameB = `${b.firstname} ${b.lastname}`.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });

        this.changeCustomerContactPersons.emit(newCustomerContactPersons);

        this.customerContactPersonToEdit = null;
        this.form?.formGroup?.reset();
        this.isSidebarVisible = false;
      });
  }

  updateCustomerContactPerson(
    id: number,
    customerContactPerson: CustomerContactPersonUpdate
  ): void {
    this.customerContactPersonService
      .update(id, customerContactPerson)
      .pipe(
        catchError((error) => {
          this.showCrudToast(
            DataModificationMethod.Edit,
            'error',
            customerContactPerson
          );

          return throwError(() => error);
        }),
        first() // Automatically unsubscribe after first value
      )
      .subscribe((createdCustomerContactPerson) => {
        this.showCrudToast(
          DataModificationMethod.Edit,
          'success',
          createdCustomerContactPerson
        );

        const updatedCustomerContactPersons = this.customerContactPersons.map(
          (c) => {
            if (c.id === createdCustomerContactPerson.id) {
              return createdCustomerContactPerson;
            }

            if (createdCustomerContactPerson.isMainContact) {
              c.isMainContact = false;
            }

            return c;
          }
        );

        this.changeCustomerContactPersons.emit(updatedCustomerContactPersons);

        this.customerContactPersonToEdit = null;
        this.form?.formGroup?.reset();
        this.isSidebarVisible = false;
      });
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'CustomerContactPerson');
  }

  handleCreateCustomerContactPerson(): void {
    this.isSidebarVisible = true;

    this.customerContactPersonToEdit = {
      customerId: this.customer.id,
      firstname: '',
      lastname: '',
      phoneNumberMobile: null,
      phoneNumberLandline: null,
      email: null,
      isActive: true,
      note: null,
      street: null,
      postalCode: null,
      city: null,
      countryId: null,
      type: undefined,
      isMainContact: false
    };
  }

  handleDeleteCustomerContactPerson(
    customerContactPerson: CustomerContactPerson
  ): void {
    const translationContext = {
      name:
        customerContactPerson.firstname === null
          ? customerContactPerson.lastname
          : `${customerContactPerson.firstname} ${customerContactPerson.lastname}`
    } as const;

    const executeDelete = () => {
      this.customerContactPersonService
        .delete(customerContactPerson.id)
        .pipe(
          catchError((error) => {
            this.showCrudToast(
              DataModificationMethod.Delete,
              'error',
              customerContactPerson
            );

            return throwError(() => error);
          }),
          first() // Automatically unsubscribe after first value
        )
        .subscribe((deletedCustomerContactPerson) => {
          this.showCrudToast(
            DataModificationMethod.Delete,
            'success',
            customerContactPerson
          );
          this.changeCustomerContactPersons.emit(
            this.customerContactPersons.filter(
              (c) => c.id !== deletedCustomerContactPerson.id
            )
          );
        });
    };
    this.messageCenterService.confirm(
      this.translate.instant(
        'customerContactPersonComponent.actions.toasts.delete.confirm.header',
        translationContext
      ),
      this.translate.instant(
        'customerContactPersonComponent.actions.toasts.delete.confirm.message',
        translationContext
      ),
      executeDelete,
      () => {
        this.messageCenterService.showToast(
          this.translate.instant(
            `customerContactPersonComponent.actions.toasts.delete.info.summary`
          ),
          this.translate.instant(
            `customerContactPersonComponent.actions.toasts.delete.info.detail`,
            translationContext
          ),
          'info'
        );
      }
    );
  }

  handleUpdateCustomerContactPerson(
    customerContactPerson: CustomerContactPerson
  ): void {
    this.isSidebarVisible = true;

    this.customerContactPersonToEdit = structuredClone(customerContactPerson);
  }

  handleViewCustomerContactPerson(
    customerContactPerson: CustomerContactPerson
  ): void {
    this.isSidebarVisible = true;

    this.customerContactPersonToEdit = structuredClone(customerContactPerson);
  }

  handleChangeIsActive(
    customerContactPerson: CustomerContactPerson,
    value: boolean
  ): void {
    return Reflect.apply(handleChangeIsActive, this, [
      customerContactPerson,
      value
    ]);
  }

  handleChangeIsMainContact(
    customerContactPerson: CustomerContactPerson,
    value: boolean
  ): void {
    const translationContext = {
      name:
        customerContactPerson.firstname === null
          ? customerContactPerson.lastname
          : `${customerContactPerson.firstname} ${customerContactPerson.lastname}`,
      result: this.translate.instant(
        `customerContactPersonComponent.actions.toasts.isMainContact.actions.activated`
      )
    } as const;

    const executeUpdate = () => {
      this.customerContactPersonService
        .update(customerContactPerson.id, {
          // value should always be true here. Save new main contact in database. Backend handles setting all other contacts to false.
          isMainContact: value,
          // new main contact should be active
          isActive: true,
          // we need to provide the customerId to the backend to know which customer contact persons to update (set isMainContact to false)
          customerId: customerContactPerson.customerId
        })
        .pipe(first())
        .subscribe((updatedCustomerContactPerson) => {
          this.showCrudToast(
            'isMainContact',
            'success',
            updatedCustomerContactPerson,
            translationContext
          );

          // emit to display updated table
          this.changeCustomerContactPersons.emit(
            this.customerContactPersons.map((contactPerson) => {
              if (contactPerson.id !== customerContactPerson.id) {
                contactPerson.isMainContact = false;

                return contactPerson;
              }
              contactPerson.isMainContact = true;
              contactPerson.isActive = true;

              return contactPerson;
            })
          );
        });
    };

    // confirm changing main contact
    this.messageCenterService.confirm(
      // header of the confirmation dialog
      this.translate.instant(
        'customerContactPersonComponent.actions.toasts.switch.confirm.header',
        translationContext
      ),

      // message of the confirmation dialog
      this.translate.instant(
        'customerContactPersonComponent.actions.toasts.switch.confirm.message',
        translationContext
      ),

      // execute the update on confirmation
      executeUpdate,

      // change fronted back to previous state on cancel
      () => {
        this.changeCustomerContactPersons.emit(
          this.customerContactPersons.map((contactPerson) => {
            if (contactPerson.id === customerContactPerson.id) {
              contactPerson.isMainContact = false;

              return contactPerson;
            }

            return contactPerson;
          })
        );

        // show toast on cancel
        this.messageCenterService.showToast(
          this.translate.instant(
            `customerContactPersonComponent.actions.toasts.switch.cancel.summary`
          ),
          this.translate.instant(
            `customerContactPersonComponent.actions.toasts.switch.cancel.detail`,
            translationContext
          ),
          'info'
        );
      }
    );
  }

  async onSidebarVisibleChange(value: boolean): Promise<void> {
    if (value === true || this.isFormDirty === false) {
      this.isSidebarVisible = value;

      return;
    }

    const isVisible = await new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant('general.confirmUnsavedChanges.header'),
        this.translate.instant('general.confirmUnsavedChanges.message'),
        () => {
          resolve(false);
          this.form?.formGroup?.reset();
        },
        () => {
          resolve(true);
        }
      );
    });

    this.isSidebarVisible = isVisible;
  }

  public showCrudToast(
    method: DataModificationMethod | 'isActive' | 'isMainContact',
    severity: Severity,
    customerContactPerson:
      | CustomerContactPerson
      | CustomerContactPersonCreate
      | CustomerContactPersonUpdate,
    translationContext?: Record<string, string>
  ): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `customerContactPersonComponent.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `customerContactPersonComponent.actions.toasts.${method}.${severity}.detail`,
        {
          name:
            customerContactPerson.firstname === null
              ? customerContactPerson.lastname
              : `${customerContactPerson.firstname} ${customerContactPerson.lastname}`,
          ...translationContext
        }
      ),
      severity
    );
  }
}
