import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { ControlUnit } from 'src/app/models/ControlUnit';

import { DeviceManufacturer } from 'src/app/models/DeviceManufacturer';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { getHttpErrorTranslationKeys } from 'src/app/utils/errors/http-error';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceManufacturerService {
  private cache: Map<number, ControlUnit[]> = new Map();

  constructor(
    private readonly http: HttpClient,
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService
  ) {}

  public findAll(): Observable<DeviceManufacturer[]> {
    return this.http
      .get<DeviceManufacturer[]>(`${environment.apiUrl}/device-manufacturers`)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public findAllControlUnits(
    manufacturerId: number
  ): Observable<ControlUnit[]> {
    const cachedValue = this.cache.get(manufacturerId);

    if (cachedValue) {
      return new Observable((observer) => {
        observer.next(cachedValue);
        observer.complete();
      });
    }

    return this.http
      .get<ControlUnit[]>(
        `${environment.apiUrl}/device-manufacturers/${manufacturerId}/control-units`
      )
      .pipe(
        tap((controlUnits) => {
          this.cache.set(manufacturerId, controlUnits);
        })
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    const errorKeys = getHttpErrorTranslationKeys(error);

    this.messageCenterService.showToast(
      this.translate.instant(errorKeys.summary, { status: error.status }),
      this.translate.instant(errorKeys.detail),
      'error'
    );

    return throwError(() => error);
  }
}
