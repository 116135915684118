import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { formatAddress } from 'src/app/components/admin/customer/views/components/customer-edit-contact-person/utils/formatAdress';
import { Country } from 'src/app/models/Country';
import { Customer } from 'src/app/models/customer/Customer';
import { CustomerContactPerson } from 'src/app/models/customer/CustomerContactPerson';
import { Ticket } from 'src/app/models/Ticket';

@Component({
  selector: 'app-ticket-contact-person',
  templateUrl: './ticket-contact-person.component.html',
  styleUrls: ['./ticket-contact-person.component.scss']
})
export class TicketContactPersonComponent implements OnInit, OnChanges {
  @Input() formGroupName!: string;

  @Input() ticket!: Ticket;

  @Input() customers: Customer[] = [];

  @Input() customerContactPersons: CustomerContactPerson[] = [];

  @Input() countries: Country[] = [];

  @Output() ticketChange = new EventEmitter<Ticket>();

  form!: FormGroup;

  customerContactPersonModalVisible = false;

  selectedCustomerContactPerson: CustomerContactPerson | null = null;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    if (this.ticket.customerContactPerson) {
      this.selectedCustomerContactPerson = this.ticket.customerContactPerson;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerContactPersons']) {
      const mainContactPerson = this.customerContactPersons.find(
        (person) => person.isMainContact
      );
      if (mainContactPerson && !this.selectedCustomerContactPerson) {
        this.selectedCustomerContactPerson = mainContactPerson;
      }
    }
  }

  onTicketChanged(customerContactPerson: CustomerContactPerson): void {
    this.form.get('customerContactPerson')?.setValue(customerContactPerson);
    this.ticket.customerContactPerson = customerContactPerson;
    this.selectedCustomerContactPerson = customerContactPerson;
    this.ticketChange.emit(this.ticket);
    this.form.markAsDirty();
  }

  formatCustomerContactAddress(
    customerContactPerson: CustomerContactPerson
  ): string {
    return formatAddress(
      customerContactPerson.street,
      customerContactPerson.postalCode,
      customerContactPerson.city,
      customerContactPerson.countryId,
      this.countries,
      this.translate
    );
  }

  translateType(type: string): string {
    return this.translate.instant(
      `customerContactPersonComponent.attributes.type.enum.${type}`
    );
  }

  navigateToCustomerContactPersons(): void {
    if (this.ticket.customer) {
      this.router.navigate([`customers/${this.ticket.customer.id}`], {
        queryParams: { tab: 1 }
      });
    }
  }

  onCustomerContactPersonSelectionChanged(
    customerContactPerson: CustomerContactPerson
  ): void {
    this.hideCustomerContactPersonModal();
    this.onTicketChanged(customerContactPerson);
  }

  chooseCustomerContactPersonClicked(): void {
    this.customerContactPersonModalVisible = true;
  }

  public hideCustomerContactPersonModal(): void {
    this.customerContactPersonModalVisible = false;
  }
}
