<ng-container *ngIf="tableVisible">
  <app-generic-table
    [config]="config"
    [objectList]="objectsList"
    [totalRecords]="totalRecords"
    (objectChanged)="onObjectChanged($event)"
    (cellChanged)="onCellChanged($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (actionClicked)="onActionClicked($event)"
    (lazyLoadTableEventFired)="onLazyLoadTableEventFired($event)"></app-generic-table>
</ng-container>

<ng-container *ngIf="displayInSeparatePage()">
  <div class="card">
    <div class="flex justify-content-between align-items-start">
      <h4 class="mb-6">
        {{ (editObject.id ? 'general.form.editSubject' :
        'general.form.createSubject') | translate: {subject:
        (config.translationKey + ".form.title" | translate)} }}
      </h4>

      <button
        pButton
        pRipple
        style="min-width: 12rem"
        type="button"
        [label]="'general.back' | translate"
        (click)="onSeparatePageVisibleChange(false)"
        class="p-button-outlined"></button>
    </div>

    <app-form
      #form
      [isInSeparatePage]="true"
      [config]="config"
      [editObject]="editObject"
      (formChanged)="formChanged()"
      body></app-form>
    <div
      class="separate-page-button-container mt-4 flex flex-wrap justify-content-between align-items-end w-full">
      <div *ngIf="form" class="flex flex-wrap gap-4 justify-content-between">
        <button
          pButton
          pRipple
          style="min-width: 12rem"
          type="button"
          [label]="'general.form.cancel' | translate"
          (click)="onSeparatePageVisibleChange(false)"
          class="flex-auto p-button-outlined"></button>
        <button
          pButton
          pRipple
          style="min-width: 12rem"
          [label]="'general.form.save' | translate"
          class="flex-auto"
          [disabled]="editObject.id ? editSubmitDisabled : createSubmitDisabled"
          (click)="onSubmit()"></button>
      </div>
      <div *ngIf="!form"></div>

      <ng-container *ngIf="loggingTemplate">
        <ng-container *ngTemplateOutlet="loggingTemplate"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="displayInSidebar()">
  <app-sidebar
    [sidebarVisible]="sidebarVisible"
    (sidebarVisibleChange)="onSidebarVisibleChange($event)">
    <div header class="bg-primary">
      <h4 class="mb-0 ml-4 text-white">
        {{ (editObject.id ? 'general.form.editSubject' :
        'general.form.createSubject') | translate: {subject:
        (config.translationKey + ".form.title" | translate)} }}
      </h4>
    </div>

    <div body class="pt-4 ml-4">
      <app-form
        #form
        [isInSeparatePage]="false"
        [config]="config"
        (formChanged)="formChanged()"
        [editObject]="editObject"></app-form>
    </div>

    <ng-container footer>
      <div>
        <ng-container *ngIf="loggingTemplate">
          <ng-container *ngTemplateOutlet="loggingTemplate"></ng-container>
        </ng-container>

        <div class="flex flex-wrap gap-2 justify-content-between mt-2">
          <button
            pButton
            pRipple
            type="button"
            [label]="'general.form.cancel' | translate"
            (click)="onSidebarVisibleChange(false)"
            class="flex-auto p-button-outlined"></button>
          <button
            pButton
            pRipple
            [label]="'general.form.save' | translate"
            class="flex-auto"
            [disabled]="editObject.id ? editSubmitDisabled : createSubmitDisabled"
            (click)="onSubmit()"></button>
        </div>
      </div>
    </ng-container>
  </app-sidebar>
</ng-container>
