import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { BehaviorSubject, interval, map, Subscription } from 'rxjs';

type DeadlineTextContext = {
  text: string;
  time: string;
  count: number;
};

@Injectable({
  providedIn: 'root'
})
export class FormDeadlineService {
  private deadlineSubjects = new Map<
    number,
    BehaviorSubject<DeadlineTextContext | null>
  >();

  private intervalSubscriptions = new Map<number, Subscription>();

  // Starts a timer for a specific form ID and returns the observable directly
  startTimer(
    deadlineDate: Date | Moment,
    intervalInMilliseconds: number = 1000,
    formId: number
  ): BehaviorSubject<DeadlineTextContext | null> {
    this.stopTimer(formId); // Stop any previous timer for this form

    const deadlineMoment = moment(deadlineDate);
    const deadlineSubject = new BehaviorSubject<DeadlineTextContext | null>(
      null
    );
    this.deadlineSubjects.set(formId, deadlineSubject);

    const intervalSubscription = interval(intervalInMilliseconds)
      .pipe(
        map(() => {
          const now = moment();
          const secondsDiff = deadlineMoment.diff(now, 'seconds');

          return this.getDeadlineText(secondsDiff);
        })
      )
      .subscribe((context: DeadlineTextContext) => {
        deadlineSubject.next(context);
      });

    this.intervalSubscriptions.set(formId, intervalSubscription);

    // Immediately fetch and emit the deadline value
    const now = moment();
    const secondsDiff = deadlineMoment.diff(now, 'seconds');
    const immediateContext = this.getDeadlineText(secondsDiff);
    deadlineSubject.next(immediateContext);

    return deadlineSubject; // Return the observable directly
  }

  // Stops the timer for a specific form ID
  stopTimer(formId: number) {
    const intervalSubscription = this.intervalSubscriptions.get(formId);
    if (intervalSubscription) {
      intervalSubscription.unsubscribe(); // Unsubscribe from the interval
      this.intervalSubscriptions.delete(formId);
    }

    const deadlineSubject = this.deadlineSubjects.get(formId);
    if (deadlineSubject) {
      deadlineSubject.next(null); // Emit null to clear the current value
      deadlineSubject.complete(); // Complete the subject to stop emissions
      this.deadlineSubjects.delete(formId); // Remove the subject
    }
  }

  // Retrieves the deadline observable for a specific form ID
  getDeadline(formId: number) {
    if (!this.deadlineSubjects.has(formId)) {
      this.deadlineSubjects.set(
        formId,
        new BehaviorSubject<DeadlineTextContext | null>(null)
      );
    }

    return this.deadlineSubjects.get(formId)!.asObservable();
  }

  // Gets the deadline text based on the difference
  private getDeadlineText(diff: number): DeadlineTextContext {
    let mode: 'second' | 'minute' | 'hour' | 'day';
    let count: number;

    if (Math.abs(diff) < 60) {
      // Less than 60 seconds, stay in seconds
      mode = 'second';
      count = Math.abs(diff); // No rounding for seconds
    } else if (Math.abs(diff) < 3600) {
      // Less than 60 minutes, switch to minutes
      mode = 'minute';
      count = Math.floor(Math.abs(diff) / 60); // Use floor for minutes
    } else if (Math.abs(diff) < 86400) {
      // Less than 24 hours, switch to hours
      mode = 'hour';
      count = Math.floor(Math.abs(diff) / 3600); // Use floor for hours
    } else {
      // More than 24 hours, switch to days
      mode = 'day';
      count = Math.floor(Math.abs(diff) / 86400); // Use floor for days
    }

    if (diff === 1) {
      return {
        text: `myDayComponent.detailView.forms.deadline.timeUntil.prefix`,
        time: `myDayComponent.detailView.forms.deadline.timeUntil.units.${mode}`,
        count
      };
    }

    if (diff === -1) {
      return {
        text: `myDayComponent.detailView.forms.deadline.timeAgo.prefix`,
        time: `myDayComponent.detailView.forms.deadline.timeAgo.units.${mode}`,
        count
      };
    }
    if (diff < 0) {
      return {
        text: `myDayComponent.detailView.forms.deadline.timeAgo.prefix`,
        time: `myDayComponent.detailView.forms.deadline.timeAgo.units.${mode}s`,
        count
      };
    }

    return {
      text: `myDayComponent.detailView.forms.deadline.timeUntil.prefix`,
      time: `myDayComponent.detailView.forms.deadline.timeUntil.units.${mode}s`,
      count
    };
  }
}
