import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, throwError } from 'rxjs';
import { LoggingModel, LoggingView } from 'src/app/models/Logging';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { getHttpErrorTranslationKeys } from 'src/app/utils/errors/http-error';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  constructor(
    private http: HttpClient,
    private messageCenterService: MessageCenterService,
    private translate: TranslateService
  ) {}

  get(model: LoggingModel, identifier: number): Observable<LoggingView> {
    return this.http
      .get<LoggingView>(
        `${environment.apiUrl}/logging?model=${model}&identifier=${identifier}`
      )
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('An error occurred:', error.error);

    const translation = getHttpErrorTranslationKeys(error);

    this.messageCenterService.showToast(
      translation.summary,
      translation.detail,
      'error'
    );

    return throwError(() => error);
  }
}
