import { Environment } from 'src/environments/type';
import packageJson from '../../package.json';

export const environment: Environment = {
  env: 'staging',
  production: true,
  version: packageJson.version,

  domain: 'nothaft-heiztechnik.de',
  apiUrl: 'https://hive-test-api.nothaft-heiztechnik.de/api',

  sentryDsn:
    'https://ede3d7bc27090d7baf17f034671337c5@o4507922245091328.ingest.de.sentry.io/4508245181792336',

  SENTRY_AUTH_TOKEN:
    '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',

  defaultLanguage: 'de',

  loginMethod: 'standard',

  saveTableFiltersEnabled: true,

  config: {
    showConfig: true,
    showThemes: false,
    showScale: true,
    showMenuType: false,
    showMenuTheme: false,
    showColorScheme: true,
    showInputStyle: false,
    showRippleEffect: false,
    themeName: 'hive',
    initialColorScheme: 'light'
  },

  /**
   * Defines if the header should be displayed as card
   */
  header: true,

  /**
   * Defines if the footer should be displayed
   */
  footer: true,

  /**
   * Login style
   * Used for changing the appearance of the login, newpassword and verification page
   * Possible values: 'center', 'right', 'left'
   */
  loggedOutStyle: 'right',

  /**
   * Specify how many digits the verification code should consist of.
   */
  verificationCodeLength: 6,

  /**
   * Password valid at (specifies the valid password strength)
   * Possible values: 'weak', 'medium', 'strong'
   */
  passwordValidAt: 'strong',

  /**
   * Password guidelines
   * The defined rules are displayed in each password input and applied as specified.
   */
  passwordStrength: {
    weak: {
      minCharacters: 6,
      oneLowerCase: false,
      oneUpperCase: false,
      oneNumeric: false,
      oneSpecial: false
    },
    medium: {
      minCharacters: 8,
      oneLowerCase: true,
      oneUpperCase: true,
      oneNumeric: false,
      oneSpecial: false
    },
    strong: {
      minCharacters: 10,
      oneLowerCase: true,
      oneUpperCase: true,
      oneNumeric: true,
      oneSpecial: true
    }
  },

  /**
   * Configuration for generic tables
   */
  tableConfiguration: {
    rows: 15,
    rowsPerPageOptions: [15, 30, 60],
    showTableHeader: false,
    scrollHeight: window.screen.width <= 1920 ? '63vh' : 'calc(88vh - 160px)'
  },
  deadlineOffset: {
    days: 1,
    minutes: 1
  }
};
