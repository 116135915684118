import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  itemValue = new BehaviorSubject(this.configItem);

  set configItem(value: string) {
    this.itemValue.next(value);
  }

  get configItem() {
    return localStorage.getItem('config') || '';
  }
}
