import { Component, Input } from '@angular/core';

import { Customer } from 'src/app/models/customer/Customer';

@Component({
  selector: 'app-customer-avatar',
  templateUrl: './customer-avatar.component.html',
  styleUrls: ['./customer-avatar.component.scss']
})
export class CustomerAvatarComponent {
  @Input({ required: true }) customer!: Customer;

  get userAvatarLabel(): string {
    return this.customer.name.substring(0, 2).toUpperCase();
  }
}
