import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Country } from 'src/app/models/Country';
import { GenericService } from 'src/app/services/api/generic.service';
import { MessageCenterService } from 'src/app/services/message-center.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends GenericService<Country> {
  constructor(
    http: HttpClient,
    messageCenterService: MessageCenterService,
    translate: TranslateService
  ) {
    super(http, messageCenterService, translate);
    this.setContext('countries');
  }
}
