/* eslint-disable @typescript-eslint/no-explicit-any */

import { AppAction } from 'src/config/authorization.config';
import { BooleanStyle } from '../types/misc/GenericTableTypes/BooleanStyle';
import { ColumnTypes } from '../types/misc/GenericTableTypes/ColumnTypes';
import { FileUploadOptions } from './FileUploadOptions';

type GenericColumnFormatter<T> = (value: T) => unknown;

export class GenericColumnConfiguration<T = any> {
  isSortable?: boolean;

  isFilterable?: boolean;

  type: ColumnTypes = 'string';

  field!: string;

  defaultValue?: NoInfer<T> | undefined;

  formSeparatePageIndex?: number;

  booleanStyle?: BooleanStyle;

  isGlobalFilterField?: boolean;

  customExportHeader?: string;

  formControl: any | undefined;

  options?: any[];

  optionLabel?: string;

  optionLabel2?: string;

  scaffoldedField?: string;

  translatePath?: string;

  confirmCellAction?: boolean;

  isTableField?: boolean;

  isCreateField?: boolean;

  isEditField?: boolean;

  dropDownFilter?: boolean;

  showFilter!: boolean;

  width?: number;

  frozenLeft?: boolean;

  frozenRight?: boolean;

  scaffolded?: boolean;

  customLabel?: string;

  permissionAction?: AppAction | AppAction[] | undefined = undefined;

  // Note: Only works for string type or longText type
  cutOffWithTooltip?: boolean | number; // number represents the max length in px

  // Note: Only works for string type or longText type
  minWidth?: number; // number represents the min width in px

  // Must be an array
  referringKey?: string;

  fileUploadOptions?: FileUploadOptions;

  matchMode = 'contains';

  appendGlobalFilterFields: string[] = [];

  forceSingleLine?: boolean = false;

  formatter: GenericColumnFormatter<T>;

  isClickable?: boolean = false;

  showTableHeader?: boolean = true;

  booleanIsOpposite?: boolean = false;

  constructor(config?: Partial<GenericColumnConfiguration<T>>) {
    Object.assign(this, config);

    this.isSortable = this.isSortable ?? true;

    this.isFilterable = this.isFilterable ?? true;

    this.booleanStyle = this.booleanStyle ?? 'text';

    this.isGlobalFilterField = this.isGlobalFilterField ?? true;

    this.confirmCellAction = this.confirmCellAction ?? true;

    this.isTableField = this.isTableField ?? true;

    this.isCreateField = this.isCreateField ?? true;

    this.isEditField = this.isEditField ?? true;

    this.dropDownFilter = this.dropDownFilter ?? false;

    this.frozenLeft = this.frozenLeft ?? false;

    this.frozenRight = this.frozenRight ?? false;

    this.scaffolded = this.scaffolded ?? false;

    this.cutOffWithTooltip = this.cutOffWithTooltip ?? false;

    this.formatter = config?.formatter ?? ((value: T) => value);
  }
}
