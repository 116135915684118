import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FilterMetadata } from 'primeng/api';
import { Table } from 'primeng/table';
import { first, lastValueFrom, map, Observable, Subscription } from 'rxjs';
import { FilterComponent } from 'src/app/components/misc/generic-table/filter/filter.component';
import { Country } from 'src/app/models/Country';
import { TableFilter } from 'src/app/models/TableFilter';
import { Ticket } from 'src/app/models/Ticket';
import { User } from 'src/app/models/User';
import { Address } from 'src/app/models/customer/Address';
import { Customer } from 'src/app/models/customer/Customer';
import { getTimestampDisplay } from 'src/app/utils/other/date.utils';
import { environment } from 'src/environments/environment';
import { categoryChips, statusChips } from '../utils/getTicketInfo';
import { HiveForm } from 'src/app/models/HiveForm';
import { PdfService } from '../../../../../services/api/pdf.service';
import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver-es';
import { CustomerDevice } from 'src/app/models/customer/CustomerDevice';

type VirtualTableTicket = Ticket & {
  virtual: {
    facilityAddress: string;
    customer: string;
    editor: string;
    createdBy: string;
    ticketCategoryType: string;
    ticketStatusType: string;
    isReoccurringTicket: string;
    virtualSearch: {
      heatingEngineerSearch: string;
    };
    virtualData: {
      heatingEngineers: {
        id: number;
        name: string;
        deviceSerialNumbers: string;
      }[];
    };
  };
};

@Component({
  selector: 'app-ticket-table',
  templateUrl: './ticket-table.component.html',
  styleUrls: ['./ticket-table.component.scss']
})
export class TicketTableComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @ViewChild('tableElement') public tableElement?: Table;

  @ViewChild('appFilter') appFilter?: FilterComponent;

  @Input({ required: true }) customers: Customer[] = [];

  @Input({ required: true }) countries: Country[] = [];

  @Input({ required: true }) tickets: Ticket[] = [];

  @Input() showCreateButton: boolean = true;

  @Input() showUpdateButton: boolean = true;

  @Input() showCustomerColumn = true;

  @Input() standaloneCard = true;

  @Output() handleCreateTicket = new EventEmitter<void>();

  @Output() handleUpdateTicket = new EventEmitter<Ticket>();

  tableConfig = environment.tableConfiguration;

  displayFilterOverlay = false;

  isFilterApplied = false;

  activeFilter: number | null = null;

  filterSubscription: Subscription | null = null;

  virtualTickets: VirtualTableTicket[] = [];

  subscriptions = new Subscription();

  categoryChips = categoryChips;

  statusChips = statusChips;

  createdByList: User[] = [];

  editorList: User[] = [];

  uniqueCreatedByIds = new Set();

  uniqueEditorIds = new Set();

  isLoading = true;

  isFileModalVisible = false;

  fileToView: string | null = null;

  fileCache: Map<number, string> = new Map();

  ticketToView: Ticket | null = null;

  formsToView: HiveForm[] = [];

  formIndex = 0;

  loading = false;

  globalSearchValue = '';

  constructor(
    private readonly translate: TranslateService,
    private readonly pdfService: PdfService
  ) {}

  ngOnInit(): void {
    this.tickets.forEach((ticket) => {
      if (
        ticket.createdBy &&
        !this.uniqueCreatedByIds.has(ticket.createdBy.id)
      ) {
        this.uniqueCreatedByIds.add(ticket.createdBy.id);
        this.createdByList.push(ticket.createdBy);
      }

      if (ticket.editor && !this.uniqueEditorIds.has(ticket.editor.id)) {
        this.uniqueEditorIds.add(ticket.editor.id);
        this.editorList.push(ticket.editor);
      }
    });

    const localFilters = localStorage.getItem('ticket-table-memory');
    if (localFilters) {
      const { filters } = JSON.parse(localFilters);

      if (filters?.global) {
        this.globalSearchValue = filters.global.value;
      }
    }

    this.updateVirtualTickets();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tickets'] && changes['tickets'].currentValue) {
      this.updateVirtualTickets();
    }
  }

  getTimestampDisplay(date: string): string {
    return getTimestampDisplay(date);
  }

  editTicket(ticket: Ticket): void {
    this.handleUpdateTicket.emit(ticket);
  }

  createTicket(): void {
    this.handleCreateTicket.emit();
  }

  ngAfterViewInit(): void {
    if (this.tableElement) {
      this.filterSubscription = this.tableElement.onFilter.subscribe(
        (event) => {
          if (!event.filters) {
            this.isFilterApplied = false;

            return;
          }

          this.isFilterApplied = Object.keys(event.filters).some((x) => {
            if (x === 'global') {
              return false;
            }

            const filter = this.tableElement?.filters[x] as FilterMetadata;

            return (
              filter.value !== null &&
              filter.value !== undefined &&
              filter.value !== ''
            );
          });
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  filterApplied(filter: TableFilter): void {
    this.activeFilter = filter.id;

    if (this.tableElement) {
      this.tableElement.filters = structuredClone(
        filter.filterData
      ) as Table['filters'];
      this.tableElement._filter();
    }
  }

  clearFilters(): void {
    if (this.tableElement) {
      this.tableElement.clearFilterValues();
      this.tableElement.reset();
      localStorage.removeItem('ticket-table-memory');
    }

    this.activeFilter = null;
  }

  saveFilterClicked(filterName: string): void {
    if (!this.tableElement) {
      throw new Error('Table element not found');
    }

    const tableFilter = new TableFilter();

    tableFilter.filterData = structuredClone(this.tableElement?.filters);
    tableFilter.table = 'ticket';
    tableFilter.filterName = filterName;

    this.appFilter?.createFilter(tableFilter);
  }

  formatAddress(address: Address): string {
    const country = this.countries.find((c) => c.id === address.countryId);

    if (country) {
      const countryTranslation = this.translate.instant(
        `countries.${country.key}`
      );

      return `${address.street}, ${address.postalCode} ${address.city}, ${countryTranslation}`;
    }

    return `${address.street}, ${address.postalCode} ${address.city}`;
  }

  updateVirtualTickets(): void {
    this.virtualTickets = this.tickets.map((ticket) => {
      const facilityAddress = ticket.customer
        ? this.formatAddress(ticket.customer.facilityAddress)
        : '';

      const facilityAddressSearch = `${this.translate.instant(
        `customerComponent.facilityAddressType.${ticket.customer?.facilityAddressType}`
      )} ${facilityAddress}`;

      const heatingEngineers =
        ticket.customerDevices?.reduce(
          (arr, customerDevice) => {
            if (!customerDevice.isActive) {
              return arr;
            }

            const index = arr.findIndex(
              (heatingEngineer) =>
                heatingEngineer.id === customerDevice.heatingEngineerId
            );

            if (index === -1) {
              arr.push({
                id: customerDevice.heatingEngineerId,
                name: customerDevice.heatingEngineer?.name ?? '',
                deviceSerialNumbers:
                  this.displaySerialNumber(customerDevice) ?? ''
              });

              return arr;
            }

            const serialNumber = this.displaySerialNumber(customerDevice);

            if (serialNumber) {
              arr[index].deviceSerialNumbers += `; ${serialNumber}`;
            }

            return arr;
          },
          [] as VirtualTableTicket['virtual']['virtualData']['heatingEngineers']
        ) ?? [];

      return {
        ...ticket,
        virtual: {
          facilityAddress: facilityAddressSearch,
          customer: ticket.customer
            ? `${ticket.customer.name} ${ticket.customer.customerNumber}`
            : '',
          ticketStatusType: this.translate.instant(
            `ticketComponent.ticketChips.${ticket.ticketStatusType}`
          ),
          ticketCategoryType: this.translate.instant(
            `ticketComponent.ticketChips.${ticket.ticketCategoryType}`
          ),
          editor: ticket.editor
            ? `${ticket.editor.firstname} ${ticket.editor.lastname}`
            : '',
          createdBy: ticket.createdBy
            ? `${ticket.createdBy.firstname} ${ticket.createdBy.lastname}`
            : '',
          isReoccurringTicket:
            ticket.reoccurringInterval === 'None'
              ? this.translate.instant('general.no')
              : this.translate.instant('general.yes'),
          virtualSearch: {
            heatingEngineerSearch: heatingEngineers
              .map((x) => x.name)
              .join('; ')
          },
          virtualData: {
            heatingEngineers
          }
        }
      } satisfies VirtualTableTicket;
    });

    this.isLoading = false;
  }

  displaySerialNumber(customerDevice: CustomerDevice): string | undefined {
    let serialNumber = customerDevice.internalDeviceSerialNumber ?? '';

    if (customerDevice.externalDeviceSerialNumber) {
      if (serialNumber) {
        serialNumber += '; ';
      }

      serialNumber += customerDevice.externalDeviceSerialNumber;
    }

    return serialNumber === '' ? undefined : serialNumber;
  }

  /**
   * Checks if any form in the ticket has a non-null form ID which indicates that the form has been created and can be viewed or downloaded.
   *
   * @param {Ticket} ticket - The ticket object containing the forms.
   * @returns {boolean} - Returns true if any form has a non-null form ID, otherwise false.
   */
  showPdfActions(ticket: Ticket): boolean {
    if (ticket.forms && ticket.forms.length > 0) {
      // Check if any form has a non-null form ID which indicates that the form has been created
      return ticket.forms.some(
        (form) =>
          form.installationIdmLuftFormId !== null ||
          form.installationIdmSwFormId !== null ||
          form.sgcMaintenanceIdmFormId !== null ||
          form.serviceReportFormId !== null
      );
    }

    return false;
  }

  /**
   * Downloads the PDF(s) for the given ticket. If the ticket has only one form, it downloads a single PDF.
   * If the ticket has multiple forms, it downloads multiple PDFs.
   *
   * @param {Ticket} ticket - The ticket object containing the forms to be downloaded.
   */
  download(ticket: Ticket): void {
    if (ticket.forms && ticket.forms.length === 1) {
      this.downloadSinglePdf(ticket.forms[0], ticket);
    }
    if (ticket.forms && ticket.forms.length > 1) {
      this.downloadMultiplePdfs(ticket);
    }
  }

  /**
   * Downloads all forms related to a ticket as a zip file containing PDFs.
   *
   * @param {Ticket} ticket - The ticket object containing form and customer details.
   * @returns {void}
   */
  downloadMultiplePdfs(ticket: Ticket): void {
    if (!ticket.forms || ticket.forms.length === 0) {
      return;
    }

    this.formsToView = ticket.forms.filter(
      (form) =>
        form.installationIdmLuftFormId !== null ||
        form.installationIdmSwFormId !== null ||
        form.sgcMaintenanceIdmFormId !== null ||
        form.serviceReportFormId !== null
    );

    const zip = new JSZip();
    const zipFilename = `forms_ticket_${ticket.ticketNumber}.zip`;

    const downloadPromises = this.formsToView.map((form) => {
      if (!form.id) {
        return Promise.resolve();
      }

      return lastValueFrom(this.fetchPdf(form.id))
        .then((url) => {
          if (url) {
            return fetch(url)
              .then((response) => response.blob())
              .then((blob) => {
                const fileBlob = new Blob([blob], { type: 'application/pdf' });
                zip.file(this.getFileName(ticket, form), fileBlob);
              });
          }

          return Promise.resolve();
        })
        .catch((error) => {
          console.error(
            `Failed to download PDF for form ID ${form.id}:`,
            error
          );

          return Promise.resolve();
        });
    });

    Promise.all(downloadPromises).then(() => {
      zip
        .generateAsync({ type: 'blob' })
        .then((content) => {
          saveAs(content, zipFilename);
        })
        .catch((error) => {
          console.error('Failed to generate zip file:', error);
        });
    });
  }

  /**
   * Downloads the PDF of the given form
   */
  downloadSinglePdf(form: HiveForm, ticket: Ticket): void {
    if (!form.id) {
      return;
    }

    // downloadUrl function to download the PDF
    const downloadUrl = (url: string) => {
      const a = document.createElement('a');
      a.href = url;
      a.download = this.getFileName(ticket, form);
      a.click();
    };

    // Download the PDF and cache the URL
    this.fetchPdf(form.id).subscribe((url: string) => {
      downloadUrl(url);
    });
  }

  /**
   * Returns the filename for the PDF based on the provided ticket and form.
   *
   * @param {Ticket} ticket - The ticket object containing details of the ticket.
   * @param {HiveForm} form - The form object containing details of the form.
   * @returns {string} - The formatted filename.
   */
  private getFileName(ticket: Ticket, form: HiveForm): string {
    const ticketType = this.translate.instant(
      `ticketComponent.ticketChips.${ticket.ticketCategoryType}`
    );
    const manufacturer = form.customerDevice?.device?.manufacturer?.name || '';
    const customerName = ticket.customer?.name || '';
    const workDate =
      moment(form.appointments?.[0]?.end).format('DDMMYYYY') ||
      moment(new Date()).format('DDMMYYYY');

    return `${workDate}_${ticketType}_${manufacturer}_${customerName}_${form.type}_${form.id}_ticket_${ticket.ticketNumber}.pdf`;
  }

  /**
   * Opens the PDF viewer for the given form
   *
   * @param {Ticket} ticket - The ticket object containing forms.
   * @returns {void}
   */
  viewPdfs(ticket: Ticket): void {
    this.ticketToView = ticket;

    if (!this.showPdfActions(ticket)) {
      return;
    }

    // Show the modal
    this.isFileModalVisible = true;
    if (ticket.forms) {
      const firstForm = ticket.forms[this.formIndex];
      // Download the PDF and cache the URL
      if (firstForm && firstForm.id) {
        this.fetchPdf(firstForm.id).subscribe((url: string) => {
          this.fileToView = url;
          this.loading = false;
        });
      }
    }
  }

  /**
   * Determines whether to show viewer arrows based on the number of forms in the ticket.
   * Filters the forms in the ticket to include only those with non-null form IDs.
   *
   * @returns {boolean} - Returns true if there are more than one form with non-null form IDs, otherwise false.
   */
  showViewerArrows(): boolean {
    if (
      this.ticketToView &&
      this.ticketToView.forms &&
      this.ticketToView.forms.length > 1
    ) {
      this.formsToView = this.ticketToView.forms.filter(
        (form) =>
          form.installationIdmLuftFormId !== null ||
          form.installationIdmSwFormId !== null ||
          form.sgcMaintenanceIdmFormId !== null ||
          form.serviceReportFormId !== null
      );

      return true;
    }

    return false;
  }

  /**
   * Hides the PDF viewer modal.
   * Resets the fileToView to null, formIndex to 0, and sets isFileModalVisible to false.
   *
   * @returns {void}
   */
  hidePdfViewer(): void {
    if (this.fileToView) {
      this.fileToView = null;
    }
    this.formIndex = 0;
    this.isFileModalVisible = false;
  }

  /**
   * Fetches the PDF for the given form ID. If the PDF is cached, it returns the cached URL.
   * Otherwise, it downloads the PDF, caches it, and returns the URL.
   *
   * @param {number} formId - The ID of the form to fetch the PDF for.
   * @returns {Observable<string>} - An observable that emits the URL of the fetched PDF.
   */
  private fetchPdf(formId: number): Observable<string> {
    const cached = this.fileCache.get(formId);

    if (cached) {
      return new Observable((observer) => {
        observer.next(cached);
        observer.complete();
      });
    }

    return this.pdfService.downloadFormPdf(formId).pipe(
      first(),
      map((data: ArrayBuffer) => {
        const blob = new Blob([data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        this.fileCache.set(formId, url);

        return url;
      })
    );
  }

  /**
   * Displays the previous PDF in the list of forms.
   * Decrements the formIndex and calls viewPdfs to display the previous form.
   *
   * @returns {void}
   */
  viewPreviousPdf(): void {
    this.loading = true;
    this.formIndex -= 1;
    this.viewPdfs(this.ticketToView as Ticket);
  }

  /**
   * Displays the next PDF in the list of forms.
   * Increments the formIndex and calls viewPdfs to display the next form.
   *
   * @returns {void}
   */
  viewNextPdf(): void {
    this.loading = true;
    this.formIndex += 1;
    this.viewPdfs(this.ticketToView as Ticket);
  }
}
