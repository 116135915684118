import { ReturnOnClickTypes } from '../types/misc/GenericTableTypes/ReturnOnClickTypes';
import { GenericRowActionConfiguration } from './GenericRowActionConfiguration';

export class GenericActionConfiguration extends GenericRowActionConfiguration {
  returnType!: ReturnOnClickTypes;

  label?: string[];

  constructor(config?: Partial<GenericActionConfiguration>) {
    super(config);
  }
}
