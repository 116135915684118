<form
  #form
  *ngIf="formGroup !== null && customer"
  [formGroup]="formGroup"
  class="form-fields">
  <!-- First Row -->
  <div class="grid mt-2">
    <div class="col-12 md:col-6 lg:col-4">
      <!-- Customer Number | Field -->
      <div class="form-field">
        <label htmlFor="customerNumber" class="block mb-2">
          {{ "customerComponent.attributes.customerNumber" | translate}}*
        </label>
        <input
          id="customerNumber"
          pInputText
          formControlName="customerNumber"
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('customerComponent.attributes.customerNumber' | translate)}"
          [value]="customer.customerNumber" />
      </div>
      <!-- Customer Number | Field -->
    </div>

    <div class="col-12 md:col-6 lg:col-4">
      <!-- Name | Field -->
      <div class="form-field">
        <label htmlFor="name" class="block mb-2">
          {{ "customerComponent.attributes.name" | translate}}*
        </label>
        <input
          id="name"
          pInputText
          formControlName="name"
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('customerComponent.attributes.name' | translate)}"
          [value]="customer.name" />
      </div>
      <!-- Name | Field -->
    </div>

    <div class="col-12 md:col-6 lg:col-4">
      <!-- Company | Field -->
      <div class="form-field">
        <label htmlFor="company" class="block mb-2">
          {{ "customerComponent.attributes.company" | translate}}
        </label>
        <input
          id="company"
          pInputText
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('customerComponent.attributes.company' | translate)}"
          formControlName="company"
          [value]="customer.company" />
      </div>
      <!-- Company | Field -->
    </div>
  </div>
  <!-- First Row -->

  <!-- Second Row -->
  <div class="grid">
    <div class="col-12 md:col-6 lg:col-4">
      <!-- Is Active | Field -->
      <div
        class="form-field flex align-items-center gap-2"
        [ngStyle]="{marginTop: '12px'}">
        <p-inputSwitch
          id="isActive"
          formControlName="isActive"
          (onChange)="onChangeIsActive($event)" />
        <label
          htmlFor="isActive"
          class="line-height-1"
          [ngStyle]="{marginBottom: '4px'}">
          {{ "customerComponent.attributes.isActive" | translate}}
        </label>
      </div>
      <!-- Is Active | Field -->
    </div>

    <div class="col-12 md:col-6 lg:col-4">
      <!-- Is Blocked | Field -->
      <div
        class="form-field flex align-items-center gap-2"
        [ngStyle]="{marginTop: '12px'}">
        <p-inputSwitch
          id="isBlocked"
          formControlName="isBlocked"
          (onChange)="onChangeIsBlocked($event)" />
        <label
          htmlFor="isBlocked"
          class="line-height-1"
          [ngStyle]="{marginBottom: '4px'}">
          {{ "customerComponent.attributes.isBlocked" | translate}}
        </label>
      </div>
      <!-- Is Blocked | Field -->
    </div>
  </div>
  <!-- Second Row -->

  <h6 class="uppercase text-primary font-normal">
    {{ 'customerComponent.form.subtiles.facilityAddress' | translate}}
  </h6>

  <div class="grid">
    <div class="col-12 md:col-3">
      <!-- Facility Address Type | Field -->
      <div class="form-field">
        <label htmlFor="facilityAddressType" class="block mb-2">
          {{ "customerComponent.attributes.facilityAddressType" | translate}}*
        </label>
        <p-dropdown
          id="facilityAddressType"
          formControlName="facilityAddressType"
          styleClass="w-full"
          optionLabel="label"
          optionValue="value"
          dataKey="value"
          [options]="facilityAddressTypeOptions"
          [placeholder]="'general.form.enterSubject'| translate:
            {subject: ('customerComponent.attributes.facilityAddressType' | translate)}">
        </p-dropdown>
      </div>
      <!-- Facility Address Type | Field -->
    </div>
  </div>

  <div class="grid" formGroupName="facilityAddress">
    <div class="col-12 md:col-6 lg:col-3">
      <!-- Street | Billing Address | Field -->
      <div class="form-field">
        <label htmlFor="facilityAddress.street" class="block mb-2">
          {{ "customerComponent.attributes.address.street" | translate}}*
        </label>
        <input
          id="facilityAddress.street"
          pInputText
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('customerComponent.attributes.address.street' | translate)}"
          formControlName="street"
          [value]="customer.facilityAddress.street" />
      </div>
      <!-- Street | Billing Address | Field -->
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <!-- Postal Code | Billing Address | Field -->
      <div class="form-field">
        <label htmlFor="facilityAddress.postalCode" class="block mb-2">
          {{ "customerComponent.attributes.address.postalCode" | translate}}*
        </label>
        <input
          id="facilityAddress.postalCode"
          pInputText
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('customerComponent.attributes.address.postalCode' | translate)}"
          formControlName="postalCode"
          [value]="customer.facilityAddress.postalCode" />
      </div>
      <!-- Postal Code | Billing Address | Field -->
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <!-- City | Billing Address | Field -->
      <div class="form-field">
        <label htmlFor="facilityAddress.city" class="block mb-2">
          {{ "customerComponent.attributes.address.city" | translate}}*
        </label>
        <input
          id="facilityAddress.city"
          pInputText
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('customerComponent.attributes.address.city' | translate)}"
          formControlName="city"
          [value]="customer.facilityAddress.city" />
      </div>
      <!-- City | Billing Address | Field -->
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <!-- Country ID | Billing Address | Field -->
      <div class="form-field">
        <label htmlFor="facilityAddress.country" class="block mb-2">
          {{ "customerComponent.attributes.address.country" | translate}}*
        </label>
        <p-dropdown
          formControlName="countryId"
          optionLabel="key"
          optionValue="id"
          dataKey="id"
          id="facilityAddress.country"
          styleClass="w-full"
          [options]="countries"
          [placeholder]="'general.form.enterSubject'| translate:
          {subject: ('customerComponent.attributes.address.country' | translate)}">
          <ng-template let-country pTemplate="item">
            {{ ("countries."+ country.key) | translate }}
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <span *ngIf="selectedFacilityAddressCountry !== undefined">
              {{ ("countries."+ selectedFacilityAddressCountry.key) | translate
              }}
            </span>
          </ng-template>
        </p-dropdown>
      </div>
      <!-- Country ID | Billing Address | Field -->
    </div>
  </div>

  <div class="flex align-items-center gap-2 mt-3 mb-3">
    <p-inputSwitch
      id="isBillingAddressDifferent"
      [(ngModel)]="isBillingAddressDifferent"
      [ngModelOptions]="{standalone: true}"
      (ngModelChange)="onChangeUseFacilityAddressAsBillingAddress($event)"
      [disabled]="!$can('update')" />
    <label
      htmlFor="isBillingAddressDifferent"
      class="line-height-1"
      [ngStyle]="{marginBottom: '4px'}">
      {{ 'customerComponent.form.isBillingAddressDifferent' | translate}}
    </label>
  </div>

  <h6
    *ngIf="isBillingAddressDifferent === true"
    class="uppercase text-primary font-normal">
    {{ 'customerComponent.form.subtiles.billingAddress' | translate}}
  </h6>

  <div class="grid" *ngIf="isBillingAddressDifferent === true">
    <div class="col-12 md:col-6 lg:col-3">
      <!-- Billing billingRecipient | From -->
      <div class="form-field">
        <label htmlFor="billingRecipient" class="block mb-2">
          {{ "customerComponent.attributes.billingRecipient" | translate}}
        </label>
        <input
          id="billingRecipient"
          pInputText
          formControlName="billingRecipient"
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
            {subject: ('customerComponent.attributes.billingRecipient' | translate)}"
          [value]="customer.billingRecipient" />
      </div>
      <!-- Customer billingRecipient | From -->
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <!-- Billing Address Email | From -->
      <div class="form-field">
        <label htmlFor="billingAddressEmail" class="block mb-2">
          {{ "customerComponent.attributes.billingAddressEmail" | translate}}*
        </label>
        <input
          id="billingAddressEmail"
          pInputText
          formControlName="billingAddressEmail"
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
            {subject: ('customerComponent.attributes.billingAddressEmail' | translate)}"
          [value]="customer.billingAddressEmail" />
      </div>
      <!-- Customer Number Field | From -->
    </div>
  </div>

  <div
    *ngIf="isBillingAddressDifferent === true"
    formGroupName="billingAddress"
    class="grid">
    <div class="col-12 md:col-6 lg:col-3">
      <!-- Street | Billing Address | Field -->
      <div class="form-field">
        <label htmlFor="billingAddress.street" class="block mb-2">
          {{ "customerComponent.attributes.address.street" | translate}}*
        </label>
        <input
          id="billingAddress.street"
          pInputText
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
    {subject: ('customerComponent.attributes.address.street' | translate)}"
          formControlName="street"
          [value]="customer.billingAddress.street" />
      </div>
      <!-- Street | Billing Address | Field -->
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <!-- Postal Code | Billing Address | Field -->
      <div class="form-field">
        <label htmlFor="billingAddress.postalCode" class="block mb-2">
          {{ "customerComponent.attributes.address.postalCode" | translate}}*
        </label>
        <input
          id="billingAddress.postalCode"
          pInputText
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
    {subject: ('customerComponent.attributes.address.postalCode' | translate)}"
          formControlName="postalCode"
          [value]="customer.billingAddress.postalCode" />
      </div>
      <!-- Postal Code | Billing Address | Field -->
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <!-- City | Billing Address | Field -->
      <div class="form-field">
        <label htmlFor="billingAddress.city" class="block mb-2">
          {{ "customerComponent.attributes.address.city" | translate}}*
        </label>
        <input
          id="billingAddress.city"
          pInputText
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
    {subject: ('customerComponent.attributes.address.city' | translate)}"
          formControlName="city"
          [value]="customer.billingAddress.city" />
      </div>
      <!-- City | Billing Address | Field -->
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <!-- Country ID | Billing Address | Field -->
      <div class="form-field">
        <label htmlFor="billingAddress.country" class="block mb-2">
          {{ "customerComponent.attributes.address.country" | translate}}*
        </label>
        <p-dropdown
          formControlName="countryId"
          optionLabel="key"
          optionValue="id"
          dataKey="id"
          id="billingAddress.country"
          styleClass="w-full"
          [options]="countries"
          [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('customerComponent.attributes.address.country' | translate)}">
          <ng-template let-country pTemplate="item">
            {{ ("countries."+ country.key) | translate }}
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <span *ngIf="selectedBillingAddressCountry !== undefined">
              {{ ("countries."+ selectedBillingAddressCountry.key) | translate
              }}
            </span>
          </ng-template>
        </p-dropdown>
      </div>
      <!-- Country ID | Billing Address | Field -->
    </div>
  </div>

  <div class="flex">
    <div class="mt-4 flex gap-3 flex-wrap">
      <a
        routerLink="/customers"
        class="p-button p-button-outlined block"
        style="min-width: 12rem">
        {{'general.form.cancel' | translate}}
      </a>

      <button
        *ngIf="$can('update')"
        pButton
        pRipple
        style="min-width: 12rem"
        [label]="'general.form.save' | translate"
        class="flex-auto"
        [disabled]="isSubmitDisabled"
        (click)="onSubmit()"></button>
    </div>
  </div>
</form>
