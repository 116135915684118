<div
  [ngClass]="{ 'cursor-pointer':column.isClickable }"
  (click)="columnClicked()">
  <ng-container *ngIf="column.type === 'string'">
    <span *ngIf="column.cutOffWithTooltip">
      <span
        class="cut-text"
        [pTooltip]="(column.translatePath+'.'+value )| translate "
        *ngIf="column.translatePath && column.optionLabel && column.optionLabel ==='key' && column.translatePath === 'countries'">
        {{column.translatePath+"."+value | translate }}
      </span>
      <span
        [pTooltip]="value"
        class="cut-text"
        [ngStyle]="{
      'max-width.px': column.cutOffWithTooltip === true ? 500 : column.cutOffWithTooltip,
      'min-width.px': column.minWidth,
    }">
        {{value}}
      </span>
    </span>
    <span *ngIf="!column.cutOffWithTooltip">
      <span
        [ngClass]="{'white-space-nowrap': column.forceSingleLine}"
        *ngIf="column.translatePath && column.optionLabel && column.optionLabel ==='key' && column.translatePath === 'countries'">
        {{column.translatePath+"."+value | translate }}
      </span>
      <span [ngClass]="{'white-space-nowrap': column.forceSingleLine}">
        {{value}}
      </span>
    </span>
  </ng-container>

  <ng-container *ngIf="column.type === 'longText'">
    <span *ngIf="column.cutOffWithTooltip">
      <span
        class="cut-text"
        [pTooltip]="(column.translatePath+'.'+value )| translate "
        *ngIf="column.translatePath && column.optionLabel && column.optionLabel ==='key' && column.translatePath === 'countries'">
        {{column.translatePath+"."+value | translate }}
      </span>
      <span
        [pTooltip]="value"
        class="cut-text"
        [ngStyle]="{
      'max-width.px': column.cutOffWithTooltip === true ? 200 : column.cutOffWithTooltip,
      'min-width.px': column.minWidth,
    }">
        {{value}}
      </span>
    </span>
    <span
      *ngIf="!column.cutOffWithTooltip"
      [ngClass]="{'white-space-nowrap': column.forceSingleLine}">
      {{value}}
    </span>
  </ng-container>

  <ng-container *ngIf="column.type === 'email'">
    <a
      class="link"
      [ngClass]="{'white-space-nowrap': column.forceSingleLine}"
      href="mailto:{{value}}">
      {{value}}
    </a>
  </ng-container>
  <ng-container *ngIf="column.type === 'telephone'">
    <a
      class="link"
      [ngClass]="{'white-space-nowrap': column.forceSingleLine}"
      href="tel:{{value}}">
      {{value}}
    </a>
  </ng-container>
  <ng-container *ngIf="column.type === 'enum'">
    <span
      *ngIf="column.translatePath && column.optionLabel && column.optionLabel ==='key' && column.translatePath === 'countries'">
      {{column.translatePath+"."+value | translate }}
    </span>
    <span *ngIf="column.translatePath && value ">
      {{column.translatePath+"."+value | translate }}
    </span>
  </ng-container>
  <ng-container *ngIf="column.type === 'dropdown'">
    <span
      *ngIf="column.translatePath && column.optionLabel && value && value[column.optionLabel]">
      {{column.translatePath+"."+value[column.optionLabel] | translate }}
    </span>
    <span
      *ngIf="!column.translatePath && column.optionLabel && !column.optionLabel2 && !column.scaffolded && !column.scaffoldedField">
      {{value[column.optionLabel] ? value[column.optionLabel] : value }}
    </span>
    <span
      *ngIf="!column.translatePath && column.optionLabel && column.optionLabel2 && !column.scaffolded && !column.scaffoldedField">
      {{value[column.optionLabel]&&value[column.optionLabel2] ?
      value[column.optionLabel] + ' ' + value[column.optionLabel2] : value }}
    </span>
    <span
      *ngIf="!column.translatePath && column.optionLabel && column.scaffolded && column.scaffoldedField">
      {{value[column.scaffoldedField][column.optionLabel] ?
      value[column.scaffoldedField][column.optionLabel] : value }}
    </span>
    <span *ngIf="!column.translatePath && !column.optionLabel">
      {{value.label }}
    </span>
  </ng-container>
  <ng-container *ngIf="column.type === 'number'">
    {{ value | number }}
  </ng-container>
  <ng-container *ngIf="column.type === 'currency'">
    {{ value | currency }}
  </ng-container>
  <ng-container *ngIf="column.type === 'date'">
    <span [ngClass]="{'white-space-nowrap': column.forceSingleLine}">
      {{ value | date:'dd.MM.yyyy' }}
    </span>
  </ng-container>
  <ng-container *ngIf="column.type === 'boolean'">
    <ng-container
      *ngIf="column.booleanStyle === 'icon' && column.booleanIsOpposite">
      <div
        class="flex align-items-center justify-content-center"
        [ngStyle]="{
      'background-color': value ? '#FF0000' : '#7AAA00',
      'border-radius': '1.5rem',
      width: '1.5rem',
      height: '1.5rem',
      color: '#f1f1f1'
    }">
        <i *ngIf="!value" class="pi pi-check text-xs"></i>
        <i
          *ngIf="value"
          class="pi pi-times text-xs"
          [ngStyle]="{
        marginTop: '0.12rem'
      }">
        </i>
      </div>
    </ng-container>
    <ng-container
      *ngIf="column.booleanStyle === 'icon' && !column.booleanIsOpposite">
      <div
        class="flex align-items-center justify-content-center"
        [ngStyle]="{
      'background-color': value ? '#7AAA00' : '#FF0000',
      'border-radius': '1.5rem',
      width: '1.5rem',
      height: '1.5rem',
      color: '#f1f1f1'
    }">
        <i *ngIf="value" class="pi pi-check text-xs"></i>
        <i
          *ngIf="!value"
          class="pi pi-times text-xs"
          [ngStyle]="{
        marginTop: '0.12rem'
      }">
        </i>
      </div>
    </ng-container>
    <ng-container *ngIf="column.booleanStyle === 'switch'">
      <ng-container *ngIf="$can(column.permissionAction)">
        <p-inputSwitch
          [(ngModel)]="checked"
          (ngModelChange)="onSwitchValueChanged($event)"></p-inputSwitch>
      </ng-container>
      <ng-container *ngIf="!$can(column.permissionAction)">
        <i [ngClass]="value ? 'pi pi-check' : 'pi pi-times'"></i>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="column.booleanStyle === 'text'">
      {{ value ? 'Yes' : 'No' }}
    </ng-container>
    <ng-container *ngIf="!column.booleanStyle">
      {{ value ? 'Yes' : 'No' }}
    </ng-container>
  </ng-container>

  <!-- TODO: This could be refactored in a switch case -->
  <ng-container
    *ngIf="column.type !== 'string' && column.type !== 'number' && column.type !== 'currency' && column.type !== 'date' && column.type !== 'boolean'  && column.type !== 'decimal'  && column.type !== 'dropdown' && column.type !== 'enum' && column.type !== 'email' && column.type !== 'telephone' && column.type !== 'count' && column.type !== 'user' && column.type !== 'longText' && column.type !== 'autocomplete'">
    {{ value }}
  </ng-container>

  <ng-container
    *ngIf="column.type === 'count' && column.referringKey && config.fileDownloadContext">
    <ng-container *ngIf="object[column.referringKey]">
      <div class="link" (click)="op.toggle($event)">
        <span
          [pTooltip]="'general.show' | translate"
          tooltipPosition="top"
          *ngIf="object[column.referringKey].length && object[column.referringKey].length > 0"
          >{{object[column.referringKey].length}}</span
        >
      </div>
      <p-overlayPanel #op [dismissable]="false">
        <div class="w-full text-right">
          <span class="pi pi-times link" (click)="op.hide()"></span>
        </div>
        <app-files
          [showUploadComponent]="true"
          [showFilterBar]="true"
          [filesList]="object[column.referringKey]"
          [context]="config.fileDownloadContext"
          (upload)="valueChangedWithoutConfirm($event)"
          (fileRemoved)="onSwitchValueChanged($event)"
          [showUploadBtn]="$can(column.permissionAction)"
          [showRemoveBtn]="$can(column.permissionAction)"
          [showCustomButton]="$can(column.permissionAction)"
          [showUploadComponent]="$can(column.permissionAction)"></app-files>
      </p-overlayPanel>
    </ng-container>
    <ng-container
      *ngIf="!object[column.referringKey] || object[column.referringKey] && object[column.referringKey].length === 0">
      0
    </ng-container>
  </ng-container>

  <ng-container *ngIf="column.type === 'user'">
    <div class="flex align-items-center gap-2">
      <app-user-avatar [user]="value" />
      <span>{{value.firstname}} {{value.lastname}}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="column.type === 'autocomplete'">
    {{value}}
  </ng-container>
</div>
