import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordValidationService {
  constructor() {}

  getPasswordRegex(strength: 'weak' | 'medium' | 'strong'): string {
    let regex = `^`;

    const strengthSettings = environment.passwordStrength[strength];

    // Minimum number of characters from the environment
    regex += `(?=.{${strengthSettings.minCharacters},})`;

    // Lowercase letters, if enabled in the environment
    if (strengthSettings.oneLowerCase) {
      regex += `(?=.*[a-z])`;
    }

    // Uppercase letters, if enabled in the environment
    if (strengthSettings.oneUpperCase) {
      regex += `(?=.*[A-Z])`;
    }

    // Numeric digits, if enabled in the environment
    if (strengthSettings.oneNumeric) {
      regex += `(?=.*[0-9])`;
    }

    // Special characters, if enabled in the environment
    if (strengthSettings.oneSpecial) {
      regex += `(?=.*[\\W_])`;
    }

    return regex;
  }
}
