<head>
  <title>{{'general.appName' | translate}}</title>
  <meta name="description" content="Generated by create next app" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <link rel="icon" href="/favicon.ico" />
</head>
<div class="text-center pt-8 select-none">
  <div class="flex p-3 logo align-items-center justify-content-center gap-4">
    <img
      class="hive-logo"
      src="../../../../assets/images/logo.png"
      [alt]="'general.appName' | translate" />
    <img
      class="hive-logo-dark"
      src="../../../../assets/images/logo-dark.png"
      [alt]="'general.appName' | translate" />
  </div>
  <div class="p-3">
    <h3 class="welcome-text">
      {{'general.welcome' | translate}} {{credentials}}
    </h3>
  </div>
</div>
