<app-crud
  *ngIf="config"
  [@fadeAnimation]
  [config]="config"
  [objectsList]="heatingEngineerList"
  [sidebarVisible]="sidebarVisible"
  [separatePageVisible]="separatePageVisible"
  [tableVisible]="tableVisible"
  [editMethod]="editMethod"
  [createMethod]="createMethod"
  [editObject]="editHeatingEngineer"
  [totalRecords]="totalRecords"
  (sidebarVisibleChange)="sidebarVisibleChange($event)"
  (separatePageVisibleChange)="separatePageVisibleChange($event)"
  (tableVisibleChange)="tableVisibleChange($event)"
  (cellChanged)="onCellChanged($any($event))"
  (objectChanged)="heatingEngineerChanged($event)"
  (rowActionClicked)="rowActionClicked($event)"
  (lazyLoadTableEventFired)="lazyLoadTableEventFired($event)">
  <ng-template #loggingTemplate>
    <app-logging
      #loggingComponent
      *ngIf="editHeatingEngineer && editHeatingEngineer.id"
      [model]="'HeatingEngineer'"
      [identifier]="editHeatingEngineer.id"
      [config]="config"></app-logging
  ></ng-template>
</app-crud>
