import { Pipe, PipeTransform } from '@angular/core';
import { FileDto } from 'src/app/models/FileDto';

@Pipe({
  name: 'createdAtFilter'
})
export class CreatedAtFilterPipe implements PipeTransform {
  constructor() {}

  transform(files: FileDto[], rangeDates: Date[]): FileDto[] {
    if (!files || !rangeDates || rangeDates.length !== 2) {
      return files;
    }

    const [startDate, endDate] = rangeDates;

    // Normalize the startDate and endDate to the start and end of the day
    const normalizedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const normalizedEndDate = new Date(endDate.setHours(23, 59, 59, 999));

    return files.filter((file) => {
      const fileCreationDate = new Date(file.createdAt);
      const normalizedFileCreationDate = new Date(
        fileCreationDate.setHours(0, 0, 0, 0)
      );

      return (
        normalizedFileCreationDate >= normalizedStartDate &&
        normalizedFileCreationDate <= normalizedEndDate
      );
    });
  }
}
