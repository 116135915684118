<button
  pButton
  pRipple
  [pTooltip]="tooltip"
  [tooltipPosition]="tooltipPosition"
  (click)="clicked()"
  [outlined]="true"
  [raised]="true"
  [rounded]="true"
  size="small"
  style="
    width: 2rem !important;
    height: 2rem !important;
    padding: 1rem !important;
  "
  [severity]="severity"
  [icon]="icon"></button>
