import { Pipe, PipeTransform } from '@angular/core';
import { FileDto } from 'src/app/models/FileDto';

@Pipe({
  name: 'filenameFilter'
})
export class FileNameFilterPipe implements PipeTransform {
  transform(files: FileDto[], searchTerm: string): FileDto[] {
    if (!files || !searchTerm) {
      return files;
    }

    const lowerSearchTerm = searchTerm.toLowerCase();

    return files.filter((file) =>
      file.originalname.toLowerCase().includes(lowerSearchTerm)
    );
  }
}
