import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { first, Subscription } from 'rxjs';
import {
  FilePreviewEvent,
  FilesComponent
} from 'src/app/components/misc/file-upload/files/files.component';
import { FileDto } from 'src/app/models/FileDto';
import { Ticket } from 'src/app/models/Ticket';
import { Customer } from 'src/app/models/customer/Customer';
import { TicketService } from 'src/app/services/api/ticket.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { AppAction } from 'src/config/authorization.config';

@Component({
  selector: 'app-ticket-attachments',
  templateUrl: './ticket-attachments.component.html',
  styleUrls: ['./ticket-attachments.component.scss']
})
export class TicketAttachmentsComponent implements OnInit, OnDestroy {
  @Input() formGroupName!: string;

  @Input() ticket!: Ticket;

  @Output() changeCustomer = new EventEmitter<Customer>();

  @ViewChild('filesComponent') filesComponent?: FilesComponent | undefined;

  filePreview: FilePreviewEvent | null = null;

  @Output() ticketChange = new EventEmitter<Ticket>();

  form!: FormGroup;

  files: FileDto[] = [];

  selectedCustomerFiles: FileDto[] = [];

  ticketId = 0;

  customerDocumentsModalVisible = false;

  hasCustomerDocuments = false;

  subscriptions = new Subscription();

  constructor(
    private rootFormGroup: FormGroupDirective,
    private readonly ticketService: TicketService,
    private readonly authService: AuthService,
    private readonly translate: TranslateService,
    private readonly messageCenterService: MessageCenterService
  ) {}

  ngOnInit(): void {
    this.ticketId = Number(this.ticket.id);
    if (isNaN(this.ticketId)) {
      this.ticketId = 0;
    }

    this.files = this.ticket.files as FileDto[];
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.subscriptions.add(
      this.ticketService.currentTicket.subscribe((ticket) => {
        if (ticket) {
          this.files = ticket.files as FileDto[];
          this.ticketId = Number(this.ticket.id);
          if (isNaN(this.ticketId)) {
            this.ticketId = 0;
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleDeviceUpload(files: File[]): void {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });

    if (this.ticket.customer) {
      this.ticketService
        .uploadFiles(this.ticket.id, formData)
        .pipe(first())
        .subscribe((updatedTicket: Ticket) => {
          files.forEach((file) => {
            this.messageCenterService.showToast(
              this.translate.instant(
                `filesComponent.actions.toasts.create.success.summary`
              ),
              this.translate.instant(
                `filesComponent.actions.toasts.create.success.detail`,
                { name: file.name }
              ),
              'success'
            );
          });
          this.files = updatedTicket.files as FileDto[];
          this.ticketChange.emit(updatedTicket);
        });
    }
  }

  handleFileChoose(files: File[]) {
    this.form.markAsDirty();
    this.ticket.files = files;
    this.ticketChange.emit(this.ticket);
  }

  handleFileRemove(file: FileDto): void {
    const translationContext = {
      name: file.originalname
    };
    if (this.ticket) {
      const executeDelete = () => {
        if (this.ticket) {
          this.ticketService
            .deleteFile(this.ticket.id, file.id)
            .pipe(first())
            .subscribe((updatedTicket: Ticket) => {
              this.filePreview = null;

              this.ticketChange.emit(updatedTicket);
            });

          this.messageCenterService.showToast(
            this.translate.instant(
              `filesComponent.actions.toasts.delete.success.summary`
            ),
            this.translate.instant(
              `filesComponent.actions.toasts.delete.success.detail`,
              translationContext
            ),
            'success'
          );
        }
      };

      this.messageCenterService.confirm(
        this.translate.instant(
          'filesComponent.actions.toasts.delete.confirm.header',
          translationContext
        ),
        this.translate.instant(
          'filesComponent.actions.toasts.delete.confirm.message',
          translationContext
        ),
        executeDelete,
        () => {
          this.messageCenterService.showToast(
            this.translate.instant(
              `filesComponent.actions.toasts.delete.info.summary`
            ),
            this.translate.instant(
              `filesComponent.actions.toasts.delete.info.detail`,
              translationContext
            ),
            'info'
          );
          // the file component removes the file from the list
          // so if the user cancels the delete, we need to add it back
          file.deletedAt = undefined;
          this.filesComponent?.remount();
        }
      );
    }
  }

  handlePreview(event: FilePreviewEvent) {
    this.filePreview = event;
  }

  handleCustomerDocumentsLinkClicked(): void {
    this.customerDocumentsModalVisible = true;
  }

  /**
   * Hides the customer modal.
   * @returns {void}
   */
  public hideCustomerModal(): void {
    if (!this.hasCustomerDocuments) {
      this.selectedCustomerFiles = [];
    }
    this.ticket.customerDocumentIds = this.selectedCustomerFiles.map(
      (file) => file.id
    );
    this.ticketChange.emit(this.ticket);
    this.form.markAsDirty();
    this.customerDocumentsModalVisible = false;
  }

  takeCustomerDocuments(): void {
    this.hasCustomerDocuments = true;
    this.hideCustomerModal();
    this.files = [...this.files];
  }

  fileSelectionChanged(files: FileDto[]): void {
    this.selectedCustomerFiles = files;
    this.selectedCustomerFiles.forEach((file) => {
      if (!this.files.includes(file)) {
        this.files.push(file);
      }
    });
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'Ticket');
  }

  fileRemoved(fileToRemove: FileDto): void {
    this.selectedCustomerFiles = this.selectedCustomerFiles.filter(
      (file) => file.id !== fileToRemove.id
    );

    this.ticket.customer?.files?.forEach((file) => {
      if (file.id === fileToRemove.id) {
        fileToRemove.selected = false;
      }
    });
    this.ticket.customerDocumentIds = this.selectedCustomerFiles.map(
      (file) => file.id
    );
  }

  dismissPreview(): void {
    this.filePreview = null;
  }
}
