import { Injectable } from '@angular/core';
import { DataModificationMethod } from '../../enums/DataModificationMethod';

@Injectable({
  providedIn: 'root'
})
export class DataService<T> {
  modifyList(itemList: T[], item: T, method: DataModificationMethod): T[] {
    const editedItemList = this.modifyListInternal(itemList, item, method);

    return editedItemList;
  }

  private modifyListInternal(
    list: T[],
    item: T,
    method: DataModificationMethod
  ): T[] {
    switch (method) {
      case DataModificationMethod.Delete:
        return list.filter((existingItem) => existingItem !== item);

      case DataModificationMethod.Edit:
        return list.map((existingItem) =>
          existingItem === item ? item : existingItem
        );

      case DataModificationMethod.Create:
        return [...list, item];

      default:
        return list;
    }
  }
}
