<form
  #form
  *ngIf="formGroup !== null && customer"
  [formGroup]="formGroup"
  class="form-fields">
  <!-- Customer Number Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="customerNumber" class="block">
      {{ "customerComponent.attributes.customerNumber" | translate}}*
    </label>
    <input
      id="customerNumber"
      pInputText
      formControlName="customerNumber"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('customerComponent.attributes.customerNumber' | translate)}"
      [value]="customer.customerNumber" />
  </div>
  <!-- Customer Number Field | From -->

  <!-- Name Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="name" class="block">
      {{ "customerComponent.attributes.name" | translate}}*
    </label>
    <input
      id="name"
      pInputText
      formControlName="name"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
{subject: ('customerComponent.attributes.name' | translate)}"
      [value]="customer.name" />
  </div>
  <!-- Name Field | From -->

  <!-- Company Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="company" class="block">
      {{ "customerComponent.attributes.company" | translate}}
    </label>
    <input
      id="company"
      pInputText
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
{subject: ('customerComponent.attributes.company' | translate)}"
      formControlName="company"
      [value]="customer.company" />
  </div>
  <!-- Company Field | From -->

  <h6 class="uppercase text-primary font-normal">
    {{ 'customerComponent.form.subtiles.contact' | translate}}
  </h6>

  <!-- Contact Person Field | From -->
  <div formGroupName="contactPerson">
    <!-- Firstname Field | From -->
    <div class="form-field mb-2">
      <label htmlFor="firstname" class="block">
        {{ "customerContactPersonComponent.attributes.firstname" | translate}}
      </label>
      <input
        id="firstname"
        pInputText
        formControlName="firstname"
        type="text"
        class="w-full mt-2 block"
        [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('customerContactPersonComponent.attributes.firstname' | translate)}"
        [value]="customer.contactPerson.firstname" />
    </div>
    <!-- Firstname Field | From -->

    <!-- Lastname Field | From -->
    <div class="form-field mb-2">
      <label htmlFor="lastname" class="block">
        {{ "customerContactPersonComponent.attributes.lastname" | translate}}*
      </label>
      <input
        id="lastname"
        pInputText
        formControlName="lastname"
        type="text"
        class="w-full mt-2 block"
        [placeholder]="'general.form.enterSubject'| translate:
    {subject: ('customerContactPersonComponent.attributes.lastname' | translate)}"
        [value]="customer.contactPerson.lastname" />
    </div>
    <!-- Lastname Field | From -->

    <!-- PhoneNumberMobile Field | From -->
    <div class="form-field mb-2">
      <label htmlFor="phoneNumberMobile" class="block">
        {{ "customerContactPersonComponent.attributes.phoneNumberMobile" |
        translate}}
      </label>
      <input
        id="phoneNumberMobile"
        pInputText
        formControlName="phoneNumberMobile"
        type="text"
        class="w-full mt-2 block"
        [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('customerContactPersonComponent.attributes.phoneNumberMobile' | translate)}"
        [value]="customer.contactPerson.phoneNumberMobile" />
    </div>
    <!-- PhoneNumberMobile Field | From -->

    <!-- PhoneNumberLandline Field | From -->
    <div class="form-field mb-2">
      <label htmlFor="phoneNumberLandline" class="block">
        {{ "customerContactPersonComponent.attributes.phoneNumberLandline" |
        translate}}
      </label>
      <input
        id="phoneNumberLandline"
        pInputText
        formControlName="phoneNumberLandline"
        type="text"
        class="w-full mt-2 block"
        [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('customerContactPersonComponent.attributes.phoneNumberLandline' | translate)}"
        [value]="customer.contactPerson.phoneNumberLandline" />
    </div>
    <!-- PhoneNumberLandline Field | From -->

    <!-- Email Field | From -->
    <div class="form-field mb-2">
      <label htmlFor="email" class="block">
        {{ "customerContactPersonComponent.attributes.email" | translate}}
      </label>
      <input
        id="email"
        pInputText
        formControlName="email"
        type="text"
        class="w-full mt-2 block"
        [placeholder]="'general.form.enterSubject'| translate:
            {subject: ('customerContactPersonComponent.attributes.email' | translate)}"
        [value]="customer.contactPerson.email" />
    </div>
    <!-- Email Field | From -->
  </div>
  <!-- Contact Person Field | From -->

  <h6 class="uppercase text-primary font-normal">
    {{ 'customerComponent.form.subtiles.facilityAddress' | translate}}
  </h6>

  <!-- Facility Address Type Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="facilityAddressType" class="block mb-2">
      {{ "customerComponent.attributes.facilityAddressType" | translate}}*
    </label>
    <p-dropdown
      id="facilityAddressType"
      formControlName="facilityAddressType"
      styleClass="w-full"
      optionLabel="label"
      optionValue="value"
      dataKey="value"
      [options]="facilityAddressTypeOptions"
      [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('customerComponent.attributes.facilityAddressType' | translate)}">
    </p-dropdown>
  </div>
  <!-- Facility Address Type Field | From -->

  <div formGroupName="facilityAddress">
    <!-- Facility Address Street | From -->
    <div class="form-field mb-2">
      <label htmlFor="facilityAddress.street" class="block">
        {{ "customerComponent.attributes.address.street" | translate}}*
      </label>
      <input
        id="facilityAddress.street"
        pInputText
        type="text"
        class="w-full mt-2 block"
        [placeholder]="'general.form.enterSubject'| translate:
  {subject: ('customerComponent.attributes.address.street' | translate)}"
        formControlName="street"
        [value]="customer.facilityAddress.street" />
    </div>
    <!-- Facility Address Street | From -->

    <!-- Facility Address Postal Code | From -->
    <div class="form-field mb-2">
      <label htmlFor="facilityAddress.postalCode" class="block">
        {{ "customerComponent.attributes.address.postalCode" | translate}}*
      </label>
      <input
        id="facilityAddress.postalCode"
        pInputText
        type="text"
        class="w-full mt-2 block"
        [placeholder]="'general.form.enterSubject'| translate:
  {subject: ('customerComponent.attributes.address.postalCode' | translate)}"
        formControlName="postalCode"
        [value]="customer.facilityAddress.postalCode" />
    </div>
    <!-- Facility Postal Code | From -->

    <!-- Facility Address City | From -->
    <div class="form-field mb-2">
      <label htmlFor="facilityAddress.city" class="block">
        {{ "customerComponent.attributes.address.city" | translate}}*
      </label>
      <input
        id="facilityAddress.city"
        pInputText
        type="text"
        class="w-full mt-2 block"
        [placeholder]="'general.form.enterSubject'| translate:
  {subject: ('customerComponent.attributes.address.city' | translate)}"
        formControlName="city"
        [value]="customer.facilityAddress.city" />
    </div>
    <!-- Facility Address City | From -->

    <!-- Facility Address Country | From -->
    <div class="form-field mb-2">
      <label htmlFor="facilityAddress.country" class="block mb-2">
        {{ "customerComponent.attributes.address.country" | translate}}*
      </label>
      <p-dropdown
        formControlName="countryId"
        optionLabel="key"
        optionValue="id"
        dataKey="id"
        id="facilityAddress.country"
        styleClass="w-full"
        [options]="countries"
        [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('customerComponent.attributes.address.country' | translate)}">
        <ng-template let-country pTemplate="item">
          {{ ("countries."+ country.key) | translate }}
        </ng-template>
        <ng-template pTemplate="selectedItem">
          <span *ngIf="selectedFacilityAddressCountry !== undefined">
            {{ ("countries."+ selectedFacilityAddressCountry.key) | translate }}
          </span>
        </ng-template>
      </p-dropdown>
    </div>
    <!-- Facility Address City | Country -->
  </div>

  <div class="flex align-items-center gap-2 mt-4 mb-3">
    <p-inputSwitch
      id="isBillingAddressDifferent"
      [(ngModel)]="isBillingAddressDifferent"
      [ngModelOptions]="{standalone: true}"
      (ngModelChange)="onChangeIsBillingAddressDifferent($event)" />
    <label
      htmlFor="isBillingAddressDifferent"
      [ngStyle]="{ marginBottom: '4px'}">
      {{ 'customerComponent.form.isBillingAddressDifferent' | translate}}
    </label>
  </div>

  <h6
    *ngIf="isBillingAddressDifferent === true"
    class="uppercase text-primary font-normal">
    {{ 'customerComponent.form.subtiles.billingAddress' | translate}}
  </h6>

  <!-- Billing Recipient | From -->
  <div class="form-field mb-2" *ngIf="isBillingAddressDifferent === true">
    <label htmlFor="billingRecipient" class="block mb-2">
      {{ "customerComponent.attributes.billingRecipient" | translate}}
    </label>
    <input
      id="billingRecipient"
      pInputText
      formControlName="billingRecipient"
      type="text"
      class="w-full block"
      [placeholder]="'general.form.enterSubject'| translate:
              {subject: ('customerComponent.attributes.billingRecipient' | translate)}"
      [value]="customer.billingRecipient" />
  </div>
  <!-- Customer RecipientRecipient | From -->

  <!-- Billing Address Email | From -->
  <div class="form-field mb-2" *ngIf="isBillingAddressDifferent === true">
    <label htmlFor="billingAddressEmail" class="block mb-2">
      {{ "customerComponent.attributes.billingAddressEmail" | translate}}
    </label>
    <input
      id="billingAddressEmail"
      pInputText
      formControlName="billingAddressEmail"
      type="text"
      class="w-full block"
      [placeholder]="'general.form.enterSubject'| translate:
              {subject: ('customerComponent.attributes.billingAddressEmail' | translate)}"
      [value]="customer.billingAddressEmail" />
  </div>
  <!-- Customer Number Field | From -->

  <div
    formGroupName="billingAddress"
    *ngIf="isBillingAddressDifferent === true">
    <!-- Billing Address Street | From -->
    <div class="form-field mb-2">
      <label htmlFor="billingAddress.street" class="block">
        {{ "customerComponent.attributes.address.street" | translate}}*
      </label>
      <input
        id="billingAddress.street"
        pInputText
        type="text"
        class="w-full mt-2 block"
        [placeholder]="'general.form.enterSubject'| translate:
  {subject: ('customerComponent.attributes.address.street' | translate)}"
        formControlName="street"
        [value]="customer.billingAddress.street" />
    </div>
    <!-- Billing Address Street | From -->

    <!-- Billing Address Postal Code | From -->
    <div class="form-field mb-2">
      <label htmlFor="billingAddress.postalCode" class="block">
        {{ "customerComponent.attributes.address.postalCode" | translate}}*
      </label>
      <input
        id="billingAddress.postalCode"
        pInputText
        type="text"
        class="w-full mt-2 block"
        [placeholder]="'general.form.enterSubject'| translate:
  {subject: ('customerComponent.attributes.address.postalCode' | translate)}"
        formControlName="postalCode"
        [value]="customer.billingAddress.postalCode" />
    </div>
    <!-- Billing Postal Code | From -->

    <!-- Billing Address City | From -->
    <div class="form-field mb-2">
      <label htmlFor="billingAddress.city" class="block">
        {{ "customerComponent.attributes.address.city" | translate}}*
      </label>
      <input
        id="billingAddress.city"
        pInputText
        type="text"
        class="w-full mt-2 block"
        [placeholder]="'general.form.enterSubject'| translate:
  {subject: ('customerComponent.attributes.address.city' | translate)}"
        formControlName="city"
        [value]="customer.billingAddress.city" />
    </div>
    <!-- Billing Address City | From -->

    <!-- Billing Address Country | From -->
    <div class="form-field mb-2">
      <label htmlFor="billingAddress.country" class="block mb-2">
        {{ "customerComponent.attributes.address.country" | translate}}*
      </label>
      <p-dropdown
        formControlName="countryId"
        optionLabel="key"
        optionValue="id"
        dataKey="id"
        id="billingAddress.country"
        styleClass="w-full"
        [options]="countries"
        [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('customerComponent.attributes.address.country' | translate)}">
        <ng-template let-country pTemplate="item">
          {{ ("countries."+ country.key) | translate }}
        </ng-template>
        <ng-template pTemplate="selectedItem">
          <span *ngIf="selectedBillingAddressCountry !== undefined">
            {{ ("countries."+ selectedBillingAddressCountry.key) | translate }}
          </span>
        </ng-template>
      </p-dropdown>
    </div>
    <!-- Billing Address City | Country -->
  </div>
</form>
