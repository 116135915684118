import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { TableFilter } from 'src/app/models/TableFilter';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { environment } from 'src/environments/environment';

import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class TableFilterService extends GenericService<TableFilter> {
  constructor(
    http: HttpClient,
    messageCenterService: MessageCenterService,
    translate: TranslateService,
    private internalHttp: HttpClient
  ) {
    super(http, messageCenterService, translate);
    this.setContext('tableFilters');
  }

  findAllByTable(table: string): Observable<TableFilter[]> {
    return this.internalHttp.get<TableFilter[]>(
      `${environment.apiUrl}/${this.context}/findAll/${table}`
    );
  }
}
