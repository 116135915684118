import { ControlUnit } from 'src/app/models/ControlUnit';
import { Device } from 'src/app/models/Device';
import { HeatingEngineer } from 'src/app/models/HeatingEngineer';
import {
  ExtractCreateModel,
  ExtractUpdateModel
} from 'src/app/utils/types/extract-action-model';

import { Customer } from './Customer';

export const CommissionType = {
  HeatingEngineer: 'HeatingEngineer',
  NothaftHeiztechnik: 'NothaftHeiztechnik',
  Factory: 'Factory',
  Unknown: 'Unknown'
} as const;

export type CommissionType =
  (typeof CommissionType)[keyof typeof CommissionType];

export type CustomerDevice = {
  readonly id: number;

  isActive: boolean;
  isDeliveredByNothaft: boolean;

  commissionType: CommissionType;
  commissionDate: string | null;

  internalDeviceSerialNumber: string | null;
  externalDeviceSerialNumber: string | null;

  heatingEngineerId: number;
  heatingEngineer?: HeatingEngineer | undefined;

  controlUnitId: number | null;
  controlUnit?: ControlUnit | undefined;

  isGasSeparatorInstalled: boolean;

  note: string | null;

  customerId: number;

  deviceId: number;
  device?: Device | undefined;

  readonly createdAt: string;
  readonly createdById: number;

  readonly updatedAt: string;
  readonly updatedById: number;

  readonly deletedAt: string | null;
  readonly deletedById: number | null;
};

export type CustomerDeviceCreate = ExtractCreateModel<CustomerDevice>;

export type CustomerDeviceUpdate = ExtractUpdateModel<CustomerDevice>;

export type CustomerEditInterface =
  | CustomerDeviceCreate
  | (CustomerDeviceUpdate & { id: number });

export type DuplicateSerialNumberInfoDuplicate = {
  isDuplicate: true;

  customer: Customer;
  customerDeviceId: number;
  type: 'internal' | 'external';
};

export type DuplicateSerialNumberInfo =
  | DuplicateSerialNumberInfoDuplicate
  | {
      isDuplicate: false;
    };
