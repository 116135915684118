<app-customer-contact-person-table
  [customer]="customer"
  [customerContactPersons]="customerContactPersons"
  [countries]="countries"
  (handleCreateCustomerContactPerson)="handleCreateCustomerContactPerson()"
  (handleDeleteCustomerContactPerson)="handleDeleteCustomerContactPerson($event)"
  (handleViewCustomerContactPerson)="handleViewCustomerContactPerson($event)"
  (handleUpdateCustomerContactPerson)="handleUpdateCustomerContactPerson($event)"
  (handleChangeIsActive)="handleChangeIsActive($event.customerContactPerson, $event.value)"
  (handleChangeIsMainContact)="handleChangeIsMainContact($event.customerContactPerson, $event.value)"
  (changeCustomerContactPersons)="changeCustomerContactPersons.emit($event)" />

<!-- Create Sidebar -->
<p-sidebar
  [visible]="isSidebarVisible"
  position="right"
  styleClass="w-32rem scrollbar-gutter-stable"
  (visibleChange)="onSidebarVisibleChange($event)"
  (onHide)="onSidebarVisibleChange(false)">
  <!-- Header | Create Sidebar -->
  <ng-template pTemplate="header">
    <div class="bg-primary">
      <h4 class="mb-0 ml-4 text-white">
        {{ ( 'general.form.createSubject') | translate: {subject:
        ("customerContactPersonComponent.title" | translate)} }}
      </h4>
    </div>
  </ng-template>
  <!-- Body | Create Sidebar -->
  <ng-template pTemplate="content">
    <div body class="pt-4 ml-4">
      <app-customer-contact-person-form
        #form
        *ngIf="customerContactPersonToEdit"
        [customerContactPerson]="customerContactPersonToEdit"
        (formChanged)="onChangeForm()"
        [customerContactPersons]="customerContactPersons"
        [countries]="countries" />
    </div>
  </ng-template>
  <!-- Footer | Create Sidebar -->
  <ng-template pTemplate="footer">
    <ng-container>
      <app-logging
        *ngIf="loggingIdentifier !== null"
        #loggingComponent
        [model]="'CustomerContactPerson'"
        [identifier]="loggingIdentifier"
        [config]="config"
        [formatter]="loggingFormatter" />
    </ng-container>

    <div class="flex flex-wrap gap-2 justify-content-between mt-2">
      <button
        pButton
        pRipple
        type="button"
        [label]="'general.form.cancel' | translate"
        (click)="onSidebarVisibleChange(false)"
        class="flex-auto p-button-outlined"></button>
      <button
        *ngIf="$can('create')"
        pButton
        pRipple
        [label]="'general.form.save' | translate"
        class="flex-auto"
        [disabled]="isFormDisabled"
        (click)="onSubmit()"></button>
    </div>
  </ng-template>
</p-sidebar>
