<label pButton for="file-upload" class="custom-file-upload">
  <i class="fa fa-cloud-upload"></i> {{'general.form.chooseFile' | translate}}
</label>
<input
  pButton
  id="file-upload"
  type="file"
  (change)="fileChangeEvent($event)" />

<div
  [ngClass]="fileSizeInMB > 5 ? 'error text-center pt-4': 'text-center pt-4'">
  <div><small> {{'profile.maxFileSize' | translate}} </small></div>
  <div>
    <small
      >{{'profile.maxFileSizeActual' | translate}}
      <b>{{fileSizeInMB}}</b> Mb</small
    >
  </div>
  <i
    *ngIf="!cropperIsReady"
    class="pi pi-spin pi-spinner"
    style="font-size: 2rem"></i>
</div>

<div class="p-4">
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    [roundCropper]="true"
    format="png"
    output="base64"
    [imageBase64]="base64Image"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"></image-cropper>
</div>

<div class="flex justify-content-between">
  <button
    pButton
    pRipple
    [label]="'general.form.cancel' | translate"
    (click)="closeWithoutResult()"
    class="p-button-outlined p-1"></button>
  <button
    pButton
    pRipple
    [label]="'general.form.accept' | translate"
    (click)="closeWithResult()"
    [disabled]="!croppedImage || fileSizeInMB > 5"></button>
</div>
