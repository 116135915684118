// src/app/pipes/format-text.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatText'
})
export class FormatTextPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const urlPattern = /(https?:\/\/[^\s]+)/g;
    let formatted = value.replace(
      urlPattern,
      '<a href="$1" target="_blank">$1</a>'
    );

    formatted = formatted.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');

    formatted = formatted.replace(/(\r\n\r\n|\n\n|\r\r)/g, '<br><br>');

    formatted = formatted.replace(/(\r\n|\n|\r)/g, '<br>');

    return formatted;
  }
}
