/* eslint-disable max-lines */

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  catchError,
  first,
  firstValueFrom,
  of,
  throwError
} from 'rxjs';
import { CustomerCreateFormComponent } from 'src/app/components/admin/customer/components/customer-create-form/customer-create-form.component';
import { CustomerTableActionNewCorrespondenceComponent } from 'src/app/components/admin/customer/components/customer-table-action-new-correspondence/customer-table-action-new-correspondence.component';
import { CustomerTableActionNewTicketComponent } from 'src/app/components/admin/customer/components/customer-table-action-new-ticket/customer-table-action-new-ticket.component';
import {
  HandleIsActiveSwitchContext,
  HandleIsBlockedSwitchContext,
  handleSwitchChangeFactory
} from 'src/app/components/admin/customer/utils/handle-switch-change-factory';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { Country } from 'src/app/models/Country';

import { Customer, CustomerCreate } from 'src/app/models/customer/Customer';
import { CountryService } from 'src/app/services/api/country.service';
import {
  CustomerService,
  FindFacilityAddressResponse
} from 'src/app/services/api/customer/customer.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Severity } from 'src/app/types/misc/Severity';
import { AppAction } from 'src/config/authorization.config';

const handleChangeIsActive = handleSwitchChangeFactory(
  HandleIsActiveSwitchContext
);

const handleChangeIsBlocked = handleSwitchChangeFactory(
  HandleIsBlockedSwitchContext
);

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class CustomerComponent implements OnInit {
  @ViewChild('createForm')
  formComponent?: CustomerCreateFormComponent;

  @ViewChild('tableActionNewCorrespondence')
  tableActionNewCorrespondence?: CustomerTableActionNewCorrespondenceComponent;

  @ViewChild('tableActionNewTicket')
  tableActionNewTicket?: CustomerTableActionNewTicketComponent;

  isSidebarVisible = false;

  customerToCreate: CustomerCreate = this.customerFactory();

  countries: Country[] = [];

  customers: Customer[] = [];

  isLoading = false;

  constructor(
    public readonly translate: TranslateService,
    public readonly messageCenterService: MessageCenterService,
    private readonly countryService: CountryService,
    public readonly customerService: CustomerService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.countryService
      .findAll()
      .pipe(first())
      .subscribe((countries) => {
        this.countries = countries;
      });

    this.customerService
      .findAll()
      .pipe(first())
      .subscribe((customers) => {
        this.customers = customers;
        this.isLoading = false;
      });
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'Customer');
  }

  get isCreateFormDirty(): boolean {
    return this.formComponent?.formGroup?.dirty || false;
  }

  get createSubmitDisabled(): boolean {
    const form = this.formComponent?.formGroup;

    if (form) {
      return !form || !form.valid;
    }

    return true;
  }

  async onSidebarVisibleChange(value: boolean): Promise<void> {
    if (value === true || this.isCreateFormDirty === false) {
      this.isSidebarVisible = value;

      return;
    }

    const isVisible = await new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant('general.confirmUnsavedChanges.header'),
        this.translate.instant('general.confirmUnsavedChanges.message'),
        () => {
          resolve(false);
        },
        () => {
          resolve(true);
        }
      );
    });

    this.isSidebarVisible = isVisible;
  }

  canUseFacilityAddress(
    customer: CustomerCreate
  ): Observable<FindFacilityAddressResponse> {
    if (
      !customer.facilityAddress ||
      !customer.facilityAddress.city ||
      !customer.facilityAddress.countryId ||
      !customer.facilityAddress.postalCode ||
      !customer.facilityAddress.street
    ) {
      return of({ foundFacilityAddress: false });
    }

    return this.customerService.findFacilityAddress({
      city: customer.facilityAddress.city ?? '',
      countryId: customer.facilityAddress.countryId ?? 0,
      postalCode: customer.facilityAddress.postalCode ?? '',
      street: customer.facilityAddress.street ?? ''
    });
  }

  async onSubmit(): Promise<void> {
    if (!this.formComponent || this.createSubmitDisabled) {
      return;
    }

    const customer = this.formComponent.buildCustomer();

    const canUseFacilityAddress = await firstValueFrom(
      this.canUseFacilityAddress(customer)
    );

    if (canUseFacilityAddress.foundFacilityAddress === true) {
      this.messageCenterService.showToast(
        this.translate.instant(
          'customerComponent.actions.toasts.facilityAddressAlreadyInUse.summary'
        ),
        this.translate.instant(
          'customerComponent.actions.toasts.facilityAddressAlreadyInUse.detail',
          {
            customerNumber: canUseFacilityAddress.customer.customerNumber,
            name: canUseFacilityAddress.customer.name
          }
        ),
        'warn'
      );

      return;
    }

    this.customerService
      .create(customer)
      .pipe(
        catchError((error) => {
          this.showCrudToast(DataModificationMethod.Create, 'error', customer);

          return throwError(() => error);
        }),
        first() // Automatically unsubscribe after first value
      )
      .subscribe((createdCustomer) => {
        this.showCrudToast(
          DataModificationMethod.Create,
          'success',
          createdCustomer
        );

        this.customers.push(createdCustomer);
        this.customers.sort((a, b) => a.name.localeCompare(b.name));

        this.customers = [...this.customers];

        this.customerToCreate = this.customerFactory();
        this.formComponent?.formGroup?.reset();
        this.isSidebarVisible = false;
      });
  }

  handleCreateCustomer(): void {
    this.isSidebarVisible = true;
  }

  handleDeleteCustomer(customer: Customer): void {
    const translationContext = {
      name: customer.name,
      customerNumber: customer.customerNumber
    } as const;

    const executeDelete = () => {
      this.customerService
        .delete(customer.id)
        .pipe(
          catchError((error) => {
            this.showCrudToast(
              DataModificationMethod.Delete,
              'error',
              customer
            );

            return throwError(() => error);
          }),
          first() // Automatically unsubscribe after first value
        )
        .subscribe((deletedCustomer) => {
          this.showCrudToast(
            DataModificationMethod.Delete,
            'success',
            customer
          );
          this.customers = this.customers.filter(
            (c) => c.id !== deletedCustomer.id
          );
        });
    };

    this.messageCenterService.confirm(
      this.translate.instant(
        'customerComponent.actions.toasts.delete.confirm.header',
        translationContext
      ),
      this.translate.instant(
        'customerComponent.actions.toasts.delete.confirm.message',
        translationContext
      ),
      executeDelete,
      () => {
        this.messageCenterService.showToast(
          this.translate.instant(
            `customerComponent.actions.toasts.delete.info.summary`
          ),
          this.translate.instant(
            `customerComponent.actions.toasts.delete.info.detail`,
            translationContext
          ),
          'info'
        );
      }
    );
  }

  handleReloadCustomers(): void {
    this.isLoading = true;

    this.customerService.revalidateCache();

    this.customerService
      .findAll()
      .pipe(first())
      .subscribe((customers) => {
        this.customers = customers;
        this.isLoading = false;
      });
  }

  handleNewCorrespondence(customer: Customer): void {
    this.tableActionNewCorrespondence?.setVisible(customer);
  }

  handleNewTicket(customer: Customer): void {
    this.tableActionNewTicket?.setVisible(customer);
  }

  handleUpdateCustomer(customer: Customer): void {
    this.router.navigate(['customers', customer.id]);
  }

  handleViewCustomer(customer: Customer): void {
    this.router.navigate(['customers', customer.id]);
  }

  handleChangeIsActive(customer: Customer, value: boolean): void {
    return Reflect.apply(handleChangeIsActive, this, [customer, value]);
  }

  handleChangeIsBlocked(customer: Customer, value: boolean): void {
    return Reflect.apply(handleChangeIsBlocked, this, [customer, value]);
  }

  private customerFactory(): CustomerCreate {
    return {
      name: '',
      customerNumber: '',
      company: undefined,

      billingAddress: {
        city: '',
        countryId: 29,
        postalCode: '',
        street: ''
      },
      billingAddressEmail: undefined,
      billingRecipient: undefined,

      facilityAddress: {
        city: '',
        countryId: 29,
        postalCode: '',
        street: ''
      },

      facilityAddressType: 'EFH',

      contactPerson: {
        email: '',
        firstname: '',
        lastname: '',
        phoneNumberMobile: '',
        phoneNumberLandline: ''
      }
    } satisfies CustomerCreate;
  }

  public showCrudToast(
    method: DataModificationMethod | 'isActive' | 'isBlocked',
    severity: Severity,
    customer: Customer | CustomerCreate,
    context: Record<string, string> = {}
  ): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `customerComponent.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `customerComponent.actions.toasts.${method}.${severity}.detail`,
        {
          name: customer.name,
          customerNumber: customer.customerNumber,
          ...context
        }
      ),
      severity
    );
  }
}
