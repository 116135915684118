import { AppAction } from 'src/config/authorization.config';
import { TableActionSeverity } from '../types/misc/Severity';

export class GenericRowActionConfiguration {
  icon!: string;

  iconOnly!: boolean;

  tooltipTranslateKey!: string;

  identifier!: string;

  color!: string;

  textColor?: string;

  confirmRequired?: boolean;

  assetPath?: string;

  permissionAction?: AppAction | AppAction[] | undefined = undefined;

  severity: TableActionSeverity = 'primary';

  fullColor?: boolean;

  constructor(config?: Partial<GenericRowActionConfiguration>) {
    Object.assign(this, config);

    this.iconOnly = this.iconOnly ?? false;
    this.fullColor = this.fullColor ?? false;
  }
}
