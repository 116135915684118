import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';

import { customerCorrespondenceTableConfig } from 'src/app/components/admin/customer/views/components/customer-edit-correspondence/customer-edit-correspondences.config';
import { LoggingComponent } from 'src/app/components/misc/logging/logging.component';
import { FormComponent } from 'src/app/components/templates/crud/form/form.component';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { ActionClickedResponse } from 'src/app/models/ActionClickedResponse';
import { Customer } from 'src/app/models/customer/Customer';
import { CustomerCorrespondence } from 'src/app/models/customer/CustomerCorrespondence';
import { FileDto } from 'src/app/models/FileDto';
import { CustomerCorrespondenceService } from 'src/app/services/api/customer/customer-correspondence.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { DataService } from 'src/app/services/utils/data.service';
import { Severity } from 'src/app/types/misc/Severity';
import {
  LoggingFormatter,
  LoggingFormatterBuilder
} from 'src/app/utils/logging';

@Component({
  selector: 'app-customer-edit-correspondence',
  templateUrl: './customer-edit-correspondence.component.html',
  styleUrls: ['./customer-edit-correspondence.component.scss']
})
export class CustomerEditCorrespondenceComponent implements OnInit, OnChanges {
  @ContentChild('loggingTemplate', { static: true })
  loggingTemplate!: TemplateRef<unknown>;

  @ViewChild('loggingComponent') loggingComponent!: LoggingComponent;

  @ViewChild('form') formComponent?: FormComponent;

  @Input({ required: true }) customer!: Customer;

  @Input({ required: true }) correspondences: CustomerCorrespondence[] = [];

  @Output() correspondencesChange = new EventEmitter<
    CustomerCorrespondence[]
  >();

  config = customerCorrespondenceTableConfig();

  isSidebarVisible = false;

  editObject: CustomerCorrespondence = new CustomerCorrespondence();

  editSubmitDisabled = false;

  createSubmitDisabled = false;

  loggingFormatter: LoggingFormatter | null = null;

  constructor(
    private readonly translate: TranslateService,
    private readonly messageCenterService: MessageCenterService,

    private readonly customerCorrespondenceService: CustomerCorrespondenceService,
    private readonly dataService: DataService<CustomerCorrespondence>
  ) {}

  ngOnInit() {
    this.editSubmitDisabled = this.getEditSubmitDisabled();
    this.createSubmitDisabled = this.getCreateSubmitDisabled();

    this.editObject = new CustomerCorrespondence({
      customerId: this.customer.id
    });

    this.loggingFormatter = LoggingFormatterBuilder.new<{
      [K in keyof CustomerCorrespondence]: unknown;
    }>()
      .add('type', (value) =>
        this.translate.instant(
          `customerCorrespondenceComponent.table.columns.type.enum.${value}`
        )
      )
      .build();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['editObject']) {
      this.editSubmitDisabled = this.getEditSubmitDisabled();
      this.createSubmitDisabled = this.getCreateSubmitDisabled();
    }
  }

  async onSidebarVisibleChange(isVisible: boolean) {
    if (isVisible === true) {
      this.isSidebarVisible = true;

      return;
    }

    if (this.formComponent?.form?.dirty === true) {
      await new Promise<void>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant('general.confirmUnsavedChanges.header'),
          this.translate.instant('general.confirmUnsavedChanges.message'),
          () => {
            this.editObject = new CustomerCorrespondence({
              customerId: this.customer.id
            });
            this.formComponent?.form.reset();
            this.isSidebarVisible = false;
            resolve();
          },
          () => {
            resolve();
            this.isSidebarVisible = !isVisible;
          }
        );
      });

      return;
    }

    this.isSidebarVisible = isVisible;
  }

  onSubmit() {
    if (
      this.formComponent &&
      this.formComponent.form &&
      this.formComponent.form.valid
    ) {
      this.config.columns.forEach((col) => {
        if (col.formControl && this.editObject) {
          // @ts-expect-error Should be fine probably
          this.editObject[col.field] = this.formComponent?.form.get(
            col.field
          )?.value;
        }
      });
      this.formComponent.form.markAsUntouched();
      this.formComponent.form.markAsPristine();

      if (this.editObject.id) {
        this.updateCustomerCorrespondence(this.editObject.id, this.editObject);
      } else {
        this.createCustomerCorrespondence(this.editObject);
      }
    }
  }

  createCustomerCorrespondence(cc: CustomerCorrespondence): void {
    this.customerCorrespondenceService
      .createIncludingFiles(cc)
      .pipe(first())
      .subscribe((newCc) => {
        if (newCc) {
          this.correspondencesChange.emit(
            this.dataService.modifyList(
              this.correspondences,
              newCc,
              DataModificationMethod.Create
            )
          );

          if (this.isSidebarVisible) {
            this.isSidebarVisible = !this.isSidebarVisible;
          }
          this.showCrudToast(DataModificationMethod.Create, 'success', newCc);
        } else {
          this.showCrudToast(DataModificationMethod.Create, 'error', cc);
        }
      });
  }

  updateCustomerCorrespondence(id: number, cc: CustomerCorrespondence): void {
    if (cc.files && cc.files.length > 0) {
      cc.files.forEach((file) => {
        if (file.createdBy) {
          Reflect.deleteProperty(file, 'createdBy');
        }
      });
    }

    this.customerCorrespondenceService
      .editIncludingFiles(id, cc)
      .pipe(first())
      .subscribe((updatedCc) => {
        if (updatedCc) {
          const updatedList = [...this.correspondences];

          const index = updatedList.findIndex((x) => x.id === updatedCc.id);

          updatedList[index] = updatedCc;

          this.correspondencesChange.emit(updatedList);

          if (this.isSidebarVisible) {
            this.isSidebarVisible = !this.isSidebarVisible;
          }
          this.loggingComponent?.fetchLoggingView();
          this.showCrudToast(DataModificationMethod.Edit, 'success', updatedCc);
        } else {
          this.showCrudToast(DataModificationMethod.Edit, 'error', cc);
        }
      });
  }

  formChanged(): void {
    this.editSubmitDisabled = this.getEditSubmitDisabled();
    this.createSubmitDisabled = this.getCreateSubmitDisabled();
  }

  fileRemoved(file: File | FileDto): void {
    const files = this.formComponent?.form?.get('files')?.value;
    if (files.length > 0) {
      const index = files.indexOf(file);
      if (index !== -1) {
        files.splice(index, 1);
      }
      this.formComponent?.form?.get('files')?.setValue(files);
    }
  }

  executeTableAction(event: ActionClickedResponse) {
    switch (event.action.identifier) {
      case 'create':
        this.isSidebarVisible = true;
        this.editObject = new CustomerCorrespondence({
          customerId: this.customer.id
        });
        break;
      case 'edit':
        this.isSidebarVisible = true;
        this.editObject = event.object;
        break;
      case 'delete':
        this.delete(event.object);
        break;
      default:
        console.warn('Unknown action: ', event);
        break;
    }
  }

  async handleTableActionClicked(event: ActionClickedResponse) {
    if (event.action.confirmRequired) {
      await new Promise<boolean>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant(
            `${this.config.translationKey}.actions.toasts.delete.confirm.header`,
            { title: event.object.title }
          ),
          this.translate.instant(
            `${this.config.translationKey}.actions.toasts.delete.confirm.message`,
            { title: event.object.title }
          ),
          () => {
            this.executeTableAction(event);
          },
          () => {
            this.messageCenterService.showToast(
              this.translate.instant(
                `${this.config.translationKey}.actions.toasts.${event.action.identifier}.info.summary`
              ),
              this.translate.instant(
                `${this.config.translationKey}.actions.toasts.${event.action.identifier}.info.detail`,
                { title: event.object.title }
              ),
              'info'
            );

            resolve(false);
          }
        );
      });
    } else {
      this.executeTableAction(event);
    }
  }

  delete(data: CustomerCorrespondence) {
    if (data.id) {
      let severity: 'error' | 'success' = 'error';

      this.customerCorrespondenceService
        .delete(data.id)
        .pipe(first())
        .subscribe((customerCorrespondence: CustomerCorrespondence) => {
          if (customerCorrespondence) {
            severity = 'success';
            this.correspondencesChange.emit(
              this.dataService.modifyList(
                this.correspondences,
                data,
                DataModificationMethod.Delete
              )
            );
          }
          this.showCrudToast(DataModificationMethod.Delete, severity, data);
        });
    }
  }

  getEditSubmitDisabled(): boolean {
    const form = this.formComponent?.form;
    if (form) {
      return !form.valid;
    }

    return true;
  }

  getCreateSubmitDisabled(): boolean {
    const form = this.formComponent?.form;
    if (form) {
      return !form || !form.valid;
    }

    return true;
  }

  showCrudToast(
    method: DataModificationMethod,
    severity: Severity,
    cc: CustomerCorrespondence
  ): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `customerCorrespondenceComponent.actions.toasts.${method}.${severity}.summary`,
        { title: cc.title }
      ),
      this.translate.instant(
        `customerCorrespondenceComponent.actions.toasts.${method}.${severity}.detail`,
        { title: cc.title }
      ),
      severity
    );
  }

  showToast(severity: Severity, method: string): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `customerCorrespondenceComponent.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `customerCorrespondenceComponent.actions.toasts.${method}.${severity}.detail`
      ),
      severity
    );
  }
}
