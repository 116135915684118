<div>
  <span class="text-primary"
    >{{'ticketComponent.editForm.sections.customer.inner' | translate |
    uppercase}}</span
  >
  <span
    class="pi pi-window-maximize text-primary cursor-pointer ml-4"
    (click)="navigateToCustomers()"></span>

  <span
    *ngIf="!ticket.id"
    class="pi pi-arrow-right-arrow-left text-primary cursor-pointer ml-4"
    (click)="chooseCustomerClicked()"></span>
</div>
<div *ngIf="!ticket.customer">
  <div class="flex align-items-center mt-4" *ngIf="!selectedCustomer">
    <span
      (mousedown)="chooseCustomerClicked()"
      class="pi pi-plus-circle text-primary cursor-pointer text-2xl mr-2"></span>
    <span
      >{{'general.form.chooseSubject'| translate: {subject:
      ('ticketComponent.attributes.customer' | translate)} }}</span
    >
  </div>
  <div class="flex justify-content-between" *ngIf="selectedCustomer">
    <div class="grid mt-2">
      <div class="col-12 md:col-6 lg:col-4">
        <!-- Customer Number | Field -->
        <div class="form-field">
          <label htmlFor="customerNumber" class="block mb-2">
            {{ "ticketComponent.editForm.sections.customer.labels.number" |
            translate}}
          </label>
          <input
            [disabled]="true"
            id="customerNumber"
            pInputText
            type="text"
            class="w-full block"
            [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('ticketComponent.editForm.sections.customer.labels.number' | translate)}"
            [value]="selectedCustomer.customerNumber" />
        </div>
        <!-- Customer Number | Field -->
      </div>

      <div class="col-12 md:col-6 lg:col-4">
        <!-- Name | Field -->
        <div class="form-field">
          <label htmlFor="name" class="block mb-2">
            {{ "ticketComponent.editForm.sections.customer.labels.customer" |
            translate}}
          </label>
          <input
            [disabled]="true"
            id="name"
            pInputText
            type="text"
            class="w-full block"
            [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('ticketComponent.editForm.sections.customer.labels.customer' | translate)}"
            [value]="selectedCustomer.name" />
        </div>
        <!-- Name | Field -->
      </div>

      <div class="col-12 md:col-6 lg:col-4">
        <!-- facilityAddress | Field -->
        <div class="form-field">
          <label htmlFor="facilityAddress" class="block mb-2">
            {{ "ticketComponent.editForm.sections.customer.labels.address" |
            translate}}
          </label>
          <input
            [disabled]="true"
            id="facilityAddress"
            pInputText
            type="text"
            class="w-full block"
            tooltipPosition="top"
            [pTooltip]="formatAddress(selectedCustomer.facilityAddress)"
            [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('ticketComponent.editForm.sections.customer.labels.address' | translate)}"
            [value]="formatAddress(selectedCustomer.facilityAddress)" />
        </div>
        <!-- facilityAddress | Field -->
      </div>
    </div>
  </div>
</div>
<div *ngIf="ticket.customer">
  <div class="grid mt-2">
    <div class="col-12 md:col-6 lg:col-4">
      <!-- Customer Number | Field -->
      <div class="form-field">
        <label htmlFor="customerNumber" class="block mb-2">
          {{ "ticketComponent.editForm.sections.customer.labels.number" |
          translate}}
        </label>
        <input
          [disabled]="true"
          id="customerNumber"
          pInputText
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('ticketComponent.editForm.sections.customer.labels.number' | translate)}"
          [value]="ticket.customer.customerNumber" />
      </div>
      <!-- Customer Number | Field -->
    </div>

    <div class="col-12 md:col-6 lg:col-4">
      <!-- Name | Field -->
      <div class="form-field">
        <label htmlFor="name" class="block mb-2">
          {{ "ticketComponent.editForm.sections.customer.labels.customer" |
          translate}}
        </label>
        <input
          [disabled]="true"
          id="name"
          pInputText
          type="text"
          class="w-full block"
          [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('ticketComponent.editForm.sections.customer.labels.customer' | translate)}"
          [value]="ticket.customer.name" />
      </div>
      <!-- Name | Field -->
    </div>

    <div class="col-12 md:col-6 lg:col-4">
      <!-- facilityAddress | Field -->
      <div class="form-field">
        <label htmlFor="facilityAddress" class="block mb-2">
          {{ "ticketComponent.editForm.sections.customer.labels.address" |
          translate}}
        </label>
        <input
          [disabled]="true"
          id="facilityAddress"
          pInputText
          type="text"
          class="w-full block"
          tooltipPosition="top"
          [pTooltip]="formatAddress(ticket.customer.facilityAddress)"
          [placeholder]="'general.form.enterSubject'| translate:
        {subject: ('ticketComponent.editForm.sections.customer.labels.address' | translate)}"
          [value]="formatAddress(ticket.customer.facilityAddress)" />
      </div>
      <!-- facilityAddress | Field -->
    </div>
  </div>
</div>

<p-dialog
  [draggable]="false"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [(visible)]="customerModalVisible"
  (onHide)="hideCustomerModal()"
  [modal]="true"
  [style]="{ width: '75%' }">
  <!-- Dialog Header -->
  <ng-template pTemplate="header">
    <h2>
      {{'general.form.chooseSubject'| translate: {subject:
      ('ticketComponent.attributes.customer' | translate)} }}
    </h2>
  </ng-template>

  <app-customer-table
    [customers]="customers"
    [countries]="countries"
    [showEditAction]="false"
    [showDeleteAction]="false"
    [showCorrespondenceAction]="false"
    [showTicketAction]="false"
    [showCreateAction]="false"
    [showChooseAction]="true"
    [isLoading]="getCustomersIsLoading"
    (handleCustomerChosen)="onCustomerSelectionChanged($event)" />
  <ng-template pTemplate="footer">
    <div class="text-left">
      <button
        pButton
        pRipple
        type="button"
        [label]="'general.form.cancel' | translate"
        (click)="hideCustomerModal()"
        class="flex-auto p-button-outlined"></button>
    </div>
  </ng-template>
</p-dialog>
