/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */

import { catchError, first, throwError } from 'rxjs';
import { CustomerComponent } from 'src/app/components/admin/customer/customer.component';
import { Customer } from 'src/app/models/customer/Customer';

type StateContext = {
  action: string;
  actionExecuted: string;
  actionBefore: string;
};

type FactoryContext = {
  field: 'isActive' | 'isBlocked';

  onState: StateContext;
  ofState: StateContext;
};

export const HandleIsActiveSwitchContext = {
  field: 'isActive',

  onState: {
    action: 'activate',
    actionExecuted: 'activated',
    actionBefore: 'toActivate'
  },

  ofState: {
    action: 'deactivate',
    actionExecuted: 'deactivated',
    actionBefore: 'toDeactivated'
  }
} satisfies FactoryContext;

export const HandleIsBlockedSwitchContext = {
  field: 'isBlocked',

  onState: {
    action: 'block',
    actionExecuted: 'blocked',
    actionBefore: 'toBlock'
  },

  ofState: {
    action: 'unblock',
    actionExecuted: 'unblocked',
    actionBefore: 'toUnblock'
  }
} satisfies FactoryContext;

type AvailableContexts =
  | typeof HandleIsActiveSwitchContext
  | typeof HandleIsBlockedSwitchContext;

export const handleSwitchChangeFactory = (context: AvailableContexts) =>
  function execute(
    this: CustomerComponent,
    customer: Customer,
    value: boolean
  ) {
    const translationContext = {
      name: customer.name,
      customerNumber: customer.customerNumber,
      action: this.translate.instant(
        `customerComponent.actions.toasts.${context.field}.actions.${value ? context.onState.action : context.ofState.action}`
      ),
      result: this.translate.instant(
        `customerComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionExecuted : context.ofState.actionExecuted}`
      )
    } as const;

    const executeChange = () => {
      this.customerService
        .update(customer.id, { [context.field]: value })
        .pipe(
          catchError((error) => {
            this.showCrudToast(context.field, 'error', customer, {
              result: this.translate.instant(
                `customerComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionExecuted : context.ofState.actionExecuted}`
              )
            });

            return throwError(() => error);
          }),
          first() // Automatically unsubscribe after first value
        )
        .subscribe((updatedCustomer) => {
          this.showCrudToast(context.field, 'success', updatedCustomer, {
            result: this.translate.instant(
              `customerComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionExecuted : context.ofState.actionExecuted}`
            )
          });

          this.customers = this.customers.map((c) =>
            c.id === updatedCustomer.id ? updatedCustomer : c
          );
        });
    };

    this.messageCenterService.confirm(
      this.translate.instant(
        `customerComponent.actions.toasts.${context.field}.confirm.header`,
        translationContext
      ),
      this.translate.instant(
        `customerComponent.actions.toasts.${context.field}.confirm.message`,
        translationContext
      ),
      executeChange,
      () => {
        this.messageCenterService.showToast(
          this.translate.instant(
            `customerComponent.actions.toasts.${context.field}.info.summary`
          ),
          this.translate.instant(
            `customerComponent.actions.toasts.${context.field}.info.detail`,
            translationContext
          ),
          'info'
        );

        this.customers = this.customers.map((c) =>
          c.id === customer.id ? { ...customer, [context.field]: !value } : c
        );
      },
      this.translate.instant(
        `customerComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionBefore : context.ofState.actionBefore}`
      )
    );
  };
