import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WidgetComponent } from 'src/app/models/Widget';

@Component({
  selector: 'app-widget-wrapper',
  templateUrl: './widget-wrapper.component.html',
  styleUrl: './widget-wrapper.component.scss'
})
export class WidgetWrapperComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'small';

  @Input({ required: true }) widgetComponent!: WidgetComponent;

  @Input() footerTitle: string = '';

  @Input() widgetSelected = false;

  @Output() widgetSelectedChange = new EventEmitter<WidgetComponent>();

  /**
   * Emits an event when the selection state of the widget changes.
   * The event carries the current widget component.
   *
   * @returns {void}
   */
  selectionStateChanged(): void {
    this.widgetSelectedChange.emit(this.widgetComponent);
  }
}
