<div *ngIf="!preview" class="link w-full flex justify-content-between mt-2">
  <span class="text-xl font-bold"
    >{{ 'widgets.ticketEmergency.header' | translate }}
    ({{tickets.length}})</span
  >
  <span
    *ngIf="!editMode"
    [pTooltip]="'widgets.ticketEmergency.link' | translate"
    (click)="viewAllTickets()"
    class="pi pi-window-maximize cursor-pointer text-primary mr-2"></span>
</div>

<div
  *ngIf="tickets && tickets.length > 0"
  appendTo="body"
  echarts
  [options]="options"
  class="chart"></div>
