import * as moment from 'moment';
import { filterFactory } from './filter.factory';

const getFilterMoment = (filter: Date | string): moment.Moment => {
  const dottedDateRegex =
    /^(([1-2][0-9])|(0[1-9])|(3[0-1]))\.((1[0-2])|(0[1-9]))\.[0-9]{4}$/;

  if (filter instanceof Date) {
    return moment(filter);
  }

  if (typeof filter === 'string') {
    if (dottedDateRegex.test(filter)) {
      return moment(filter, 'DD.MM.YYYY');
    }

    return moment(filter);
  }

  return moment(filter);
};

export const dateFilter = filterFactory<
  Date | string | undefined | null,
  Date | string | Date[]
>((value, filterValue) => {
  if (value === undefined || value === null || value === '') {
    return false;
  }

  const date = getFilterMoment(value);
  let filter = filterValue;

  if (Array.isArray(filter)) {
    if (filter.length === 0) {
      return false;
    }

    if (filter[0] && filter[1]) {
      const start = moment(filter[0]);
      const end = moment(filter[1]);

      if (start.isSameOrBefore(date) && end.isSameOrAfter(date)) {
        return true;
      }

      return false;
    }

    if (!filter[0]) {
      // if is multi date picker (but only one date is selected)
      return false;
    }

    [filter] = filter;
  }

  const newFilter = getFilterMoment(filter);

  return date.format('DD.MM.YYYY').includes(newFilter.format('DD.MM.YYYY'));
});
