import {
  LogAttributeType,
  Logging,
  LoggingAttribute
} from 'src/app/models/Logging';

export type LoggingFormatterFn = (
  value: string,
  type: LogAttributeType,
  mode: 'new' | 'old',
  loggingAttribute: LoggingAttribute,
  log: Logging
) => string;

export type LoggingFormatter = Map<string, LoggingFormatterFn>;

export class LoggingFormatterBuilder<T extends Record<string, unknown>> {
  public static new<
    O extends Record<string, unknown>
  >(): LoggingFormatterBuilder<O> {
    return new LoggingFormatterBuilder<O>();
  }

  private constructor() {}

  private formatter: LoggingFormatter = new Map();

  public add(
    key: Extract<keyof T, string>,
    value: LoggingFormatterFn
  ): LoggingFormatterBuilder<T> {
    this.formatter.set(key, value);

    return this;
  }

  public build(): LoggingFormatter {
    return this.formatter;
  }
}

export const displayLoggingContext: LoggingFormatterFn = (
  _value,
  _type,
  mode,
  loggingAttribute
) => {
  if (mode === 'new') {
    return loggingAttribute.newContext ?? '';
  }

  return loggingAttribute.oldContext ?? '';
};
