import { Component, OnInit } from '@angular/core';

import { MyDayView } from 'src/app/models/Appointment';
import { AppointmentService } from 'src/app/services/api/appointment.service';

@Component({
  selector: 'app-my-day-overview',
  templateUrl: './my-day-overview.component.html',
  styleUrl: './my-day-overview.component.scss'
})
export class MyDayOverviewComponent implements OnInit {
  myDayView: MyDayView | null = null;

  constructor(private readonly appointmentService: AppointmentService) {}

  ngOnInit(): void {
    this.appointmentService.getMyDayView().subscribe((myDayView) => {
      this.myDayView = this.filterAppointments(myDayView);
    });
  }

  /**
   * Filters the appointments in the given MyDayView object by removing appointments without a ticket.
   * Do this here so appointment counter can still use appointments.length.
   *
   * Appointments without a ticket are considered to be internal appointments like holidays.
   * @param myDayView - The MyDayView object to filter.
   * @returns The filtered MyDayView object.
   */
  private filterAppointments(myDayView: MyDayView): MyDayView {
    myDayView.today.appointments = myDayView.today.appointments.filter(
      (appointment) => appointment.ticket != null
    );

    return myDayView;
  }
}
