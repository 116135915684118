/* eslint-disable quote-props */

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, shareReplay, tap, throwError } from 'rxjs';

import {
  Customer,
  CustomerCreate,
  CustomerUpdate
} from 'src/app/models/customer/Customer';
import { getHttpErrorTranslationKeys } from 'src/app/utils/errors/http-error';
import { environment } from 'src/environments/environment';

import { Address } from 'src/app/models/customer/Address';
import { MessageCenterService } from 'src/app/services/message-center.service';

export type FindFacilityAddressResponse =
  | {
      foundFacilityAddress: true;
      customer: Customer;
    }
  | {
      foundFacilityAddress: false;
    };

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private cache = new Map<number, Customer>();

  private isCacheReady = false;

  private customersResponse$: Observable<Customer[]> | null = null;

  constructor(
    private readonly http: HttpClient,
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService
  ) {}

  /**
   * Creates a new customer.
   *
   * @param customer - The customer object to be created.
   * @returns An Observable that emits the created customer.
   */
  public create(customer: CustomerCreate): Observable<Customer> {
    return this.http
      .post<Customer>(`${environment.apiUrl}/customers`, customer)
      .pipe(
        tap((createdCustomer) => {
          this.cache.set(createdCustomer.id, createdCustomer);
        })
      );
  }

  /**
   * Retrieves a customer by ID.
   *
   * @param id - The ID of the customer to retrieve.
   * @returns An Observable that emits the customer.
   */
  public findById(id: number): Observable<Customer> {
    // do not use cache to make sure we always get the latest data

    return this.http
      .get<Customer>(`${environment.apiUrl}/customers/${id}`)
      .pipe(
        tap((customer) => {
          this.cache.set(customer.id, customer);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  /**
   * Retrieves all customers.
   *
   * @returns An Observable that emits an array of customers.
   */
  public findAll(): Observable<Customer[]> {
    if (this.isCacheReady) {
      return new Observable((subscriber) => {
        const customers = Array.from(this.cache.values());

        subscriber.next(structuredClone(customers));
        subscriber.complete();
      });
    }

    if (this.customersResponse$ === null) {
      this.customersResponse$ = this.http
        .get<Customer[]>(`${environment.apiUrl}/customers`)
        .pipe(
          tap((customers) => {
            customers.forEach((customer) => {
              this.cache.set(customer.id, customer);
            });
            this.isCacheReady = true;
          }),
          catchError((error) => {
            this.customersResponse$ = null;

            return this.handleError(error);
          }),
          shareReplay(1)
        );
    }

    return this.customersResponse$;
  }

  /**
   * Updates an existing customer.
   *
   * @param id - The ID of the customer to update.
   * @param customer - The partial customer object with updated data.
   * @returns An Observable that emits the updated customer.
   */
  public update(id: number, customer: CustomerUpdate): Observable<Customer> {
    return this.http
      .patch<Customer>(`${environment.apiUrl}/customers/${id}`, customer)
      .pipe(
        tap((updatedCustomer) => {
          this.cache.set(updatedCustomer.id, updatedCustomer);
        })
      );
  }

  /**
   * Deletes a customer by ID.
   *
   * @param id - The ID of the customer to delete.
   * @returns An Observable that emits the updated customer.
   */
  public delete(id: number): Observable<Customer> {
    return this.http
      .delete<Customer>(`${environment.apiUrl}/customers/${id}`)
      .pipe(
        tap(() => {
          this.cache.delete(id);
        })
      );
  }

  /**
   * Finds a facility address by the given address.
   *
   * @param address - The address to search for.
   * @returns An Observable that emits the response.
   */
  public findFacilityAddress(
    address: Omit<Address, 'id'>,
    ignoreCustomerId: number | undefined = undefined
  ): Observable<FindFacilityAddressResponse> {
    const params: Record<string, string> = {
      street: address.street,
      city: address.city,
      'postal-code': address.postalCode,
      'country-id': address.countryId.toString()
    };

    if (ignoreCustomerId) {
      params['ignore-customer-id'] = ignoreCustomerId.toString();
    }

    return this.http
      .get<FindFacilityAddressResponse>(
        `${environment.apiUrl}/customers/find-facility-address`,
        { params }
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * Uploads files for a customer.
   *
   * @param customerId - The ID of the customer.
   * @param formData - The form data containing the files.
   * @returns An Observable that emits the updated customer.
   */
  public uploadFiles(
    customerId: number,
    formData: FormData
  ): Observable<Customer> {
    return this.http
      .post<Customer>(
        `${environment.apiUrl}/customers/${customerId}/file`,
        formData
      )
      .pipe(
        tap((updatedCustomer) => {
          this.cache.set(updatedCustomer.id, updatedCustomer);
        })
      );
  }

  /**
   * Deletes a file for a customer.
   *
   * @param customerId - The ID of the customer.
   * @param fileId - The ID of the file to delete.
   * @returns An Observable that emits the updated customer.
   */
  public deleteFile(customerId: number, fileId: number): Observable<Customer> {
    return this.http
      .delete<Customer>(
        `${environment.apiUrl}/customers/${customerId}/file/${fileId}`
      )
      .pipe(
        tap((updatedCustomer) => {
          this.cache.set(updatedCustomer.id, updatedCustomer);
        })
      );
  }

  public getNextCustomerNumber(): Observable<Pick<Customer, 'customerNumber'>> {
    return this.http.get<Pick<Customer, 'customerNumber'>>(
      `${environment.apiUrl}/customers/next-customer-number`
    );
  }

  public revalidateCache(): void {
    this.cache.clear();
    this.customersResponse$ = null;
    this.isCacheReady = false;
  }

  /**
   * Handles HTTP errors.
   *
   * @param error - The HttpErrorResponse object.
   * @returns An Observable that emits an error.
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    const errorKeys = getHttpErrorTranslationKeys(error);

    this.messageCenterService.showToast(
      this.translate.instant(errorKeys.summary, { status: error.status }),
      this.translate.instant(errorKeys.detail),
      'error'
    );

    return throwError(() => error);
  }
}
