import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {
  catchError,
  first,
  firstValueFrom,
  Subscription,
  throwError
} from 'rxjs';
import { CustomerDeviceFormComponent } from 'src/app/components/admin/customer/views/components/customer-edit-devices/components/customer-device-form/customer-device-form.component';
import {
  HandleIsActiveSwitchContext,
  handleSwitchChangeFactory
} from 'src/app/components/admin/customer/views/components/customer-edit-devices/utils/handle-switch-change-factory';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { Device } from 'src/app/models/Device';
import { DeviceManufacturer } from 'src/app/models/DeviceManufacturer';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { HeatingEngineer } from 'src/app/models/HeatingEngineer';
import { Customer } from 'src/app/models/customer/Customer';
import {
  CustomerDevice,
  CustomerDeviceCreate,
  CustomerDeviceUpdate,
  CustomerEditInterface,
  DuplicateSerialNumberInfo
} from 'src/app/models/customer/CustomerDevice';
import { CustomerDeviceService } from 'src/app/services/api/customer/customer-device.service';
import { DeviceService } from 'src/app/services/api/device.service';
import { HeatingEngineerService } from 'src/app/services/api/heating-engineer.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Severity } from 'src/app/types/misc/Severity';
import {
  displayLoggingContext,
  LoggingFormatter,
  LoggingFormatterBuilder
} from 'src/app/utils/logging';
import { AppAction } from 'src/config/authorization.config';

const handleChangeIsActive = handleSwitchChangeFactory(
  HandleIsActiveSwitchContext
);

@Component({
  selector: 'app-customer-edit-devices',
  templateUrl: './customer-edit-devices.component.html',
  styleUrls: ['./customer-edit-devices.component.scss']
})
export class CustomerEditDevicesComponent implements OnInit, OnDestroy {
  @ViewChild('form')
  form?: CustomerDeviceFormComponent;

  @Input({ required: true }) customer!: Customer;

  @Input({ required: true }) customerDevices: CustomerDevice[] = [];

  @Input({ required: true }) deviceManufacturers: DeviceManufacturer[] = [];

  @Input() selectedDevices: CustomerDevice[] = [];

  @Input() isTicketView = false;

  @Input() selectionEnabled = false;

  @Output() changeCustomerDevices = new EventEmitter<CustomerDevice[]>();

  @Output() selectedDevicesChange = new EventEmitter<CustomerDevice[]>();

  isFormDisabled = true;

  isFormDirty = false;

  isSidebarVisible = false;

  customerDeviceToEdit: CustomerEditInterface | null = null;

  devices: Device[] = [];

  heatingEngineers: HeatingEngineer[] = [];

  devicesSubscription: Subscription | null = null;

  heatingEngineersSubscription: Subscription | null = null;

  config = new GenericTableConfiguration({
    translationKey: 'customerDeviceComponent'
  });

  loggingFormatter: LoggingFormatter | null = null;

  constructor(
    public readonly translate: TranslateService,
    public readonly messageCenterService: MessageCenterService,
    private readonly deviceService: DeviceService,
    private readonly heatingEngineerService: HeatingEngineerService,
    public readonly customerDeviceService: CustomerDeviceService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.devicesSubscription = this.deviceService
      .findAll()
      .subscribe((devices) => {
        this.devices = devices;
      });

    this.heatingEngineersSubscription = this.heatingEngineerService
      .findAll()
      .subscribe((heatingEngineer) => {
        this.heatingEngineers = heatingEngineer;
      });

    this.onChangeForm();

    this.loggingFormatter = LoggingFormatterBuilder.new<CustomerDevice>()
      .add('controlUnitId', displayLoggingContext)
      .add('deviceId', displayLoggingContext)
      .add('commissionType', (value) =>
        this.translate.instant(
          `customerDeviceComponent.table.columns.commissionType.enum.${value}`
        )
      )
      .add('commissionDate', (value) => {
        if (!value || value === 'null') {
          return '';
        }

        return moment(value).format('DD.MM.YYYY');
      })
      .build();
  }

  ngOnDestroy(): void {
    this.devicesSubscription?.unsubscribe();
    this.heatingEngineersSubscription?.unsubscribe();
  }

  async onSubmit(): Promise<void> {
    if (
      this.customerDeviceToEdit === null ||
      !this.form ||
      this.isFormDisabled
    ) {
      return;
    }

    const customerDevice = this.form.buildCustomerDevice();

    if (!customerDevice) {
      return;
    }

    const manufacturerId = this.form.getCurrentManufacturerId();

    if (!manufacturerId) {
      console.error('No manufacturer selected');

      return;
    }

    // check if serial number is duplicate

    const isInternalSerialNumberSet = Boolean(
      customerDevice.internalDeviceSerialNumber
    );
    const isExternalSerialNumberSet = Boolean(
      customerDevice.externalDeviceSerialNumber
    );

    // if both serial numbers are set, check if they are the same
    if (isExternalSerialNumberSet && isInternalSerialNumberSet) {
      if (
        customerDevice.internalDeviceSerialNumber ===
        customerDevice.externalDeviceSerialNumber
      ) {
        this.form.alertInternalSerialNumberIsExternalSerialNumber();

        return;
      }
    }

    const { internal, external } =
      await this.checkDuplicateSerialNumber(customerDevice);

    if (internal.isDuplicate && external.isDuplicate) {
      if (internal.customer.id && external.customer.id) {
        this.form.alertBothDuplicateSerialNumber(
          internal,
          customerDevice.internalDeviceSerialNumber!, // we can assert because it gets checked in checkDuplicateSerialNumber
          customerDevice.externalDeviceSerialNumber! // we can assert because it gets checked in checkDuplicateSerialNumber
        );

        return;
      }
    }

    if (internal.isDuplicate || external.isDuplicate) {
      if (internal.isDuplicate) {
        this.form.alertDuplicateSerialNumber(
          customerDevice.internalDeviceSerialNumber!, // we can assert because it gets checked in checkDuplicateSerialNumber
          internal,
          'internal'
        );
      }

      if (external.isDuplicate) {
        this.form.alertDuplicateSerialNumber(
          customerDevice.externalDeviceSerialNumber!, // we can assert because it gets checked in checkDuplicateSerialNumber
          external,
          'external'
        );
      }

      return;
    }

    if ('id' in this.customerDeviceToEdit) {
      this.updateCustomerDevice(
        this.customerDeviceToEdit.id,
        customerDevice as CustomerDeviceUpdate // we can safely assert this type because we checked if 'id' is in this.customerDeviceToEdit
      );

      return;
    }

    // We can safely assert this type because we checked if 'id' is in this.customerDeviceToEdit
    this.createCustomerDevice(customerDevice as CustomerDeviceCreate);
  }

  async checkDuplicateSerialNumber(
    customerDevice: CustomerDeviceCreate | CustomerDeviceUpdate
  ): Promise<{
    internal: DuplicateSerialNumberInfo;
    external: DuplicateSerialNumberInfo;
  }> {
    const manufacturerId = this.form?.getCurrentManufacturerId();

    if (!manufacturerId) {
      console.error('No manufacturer selected');

      return {
        internal: {
          isDuplicate: false
        },
        external: {
          isDuplicate: false
        }
      };
    }

    const checkIfDuplicateSerialNumber = (
      serialNumber: string | null | undefined
    ): Promise<DuplicateSerialNumberInfo> => {
      if (!serialNumber) {
        return Promise.resolve({
          isDuplicate: false
        });
      }

      if (this.customerDeviceToEdit && 'id' in this.customerDeviceToEdit) {
        return firstValueFrom(
          this.customerDeviceService.findDuplicateSerialNumber(
            manufacturerId,
            serialNumber,
            this.customerDeviceToEdit?.id
          )
        );
      }

      return firstValueFrom(
        this.customerDeviceService.findDuplicateSerialNumber(
          manufacturerId,
          serialNumber
        )
      );
    };

    const [internal, external] = await Promise.all([
      checkIfDuplicateSerialNumber(customerDevice.internalDeviceSerialNumber),
      checkIfDuplicateSerialNumber(customerDevice.externalDeviceSerialNumber)
    ]);

    return {
      internal,
      external
    };
  }

  onChangeForm() {
    this.isFormDisabled =
      (!this.form?.formGroup?.valid || this.form?.formGroup?.disabled) ?? true;
    this.isFormDirty = this.form?.formGroup?.dirty ?? false;
  }

  get loggingIdentifier() {
    if (!this.customerDeviceToEdit) {
      return null;
    }

    if ('id' in this.customerDeviceToEdit) {
      return this.customerDeviceToEdit.id;
    }

    return null;
  }

  createCustomerDevice(customerDevice: CustomerDeviceCreate): void {
    this.customerDeviceService
      .create(customerDevice)
      .pipe(
        catchError((error) => {
          this.showCrudToast(
            DataModificationMethod.Create,
            'error',
            customerDevice
          );

          return throwError(() => error);
        }),
        first() // Automatically unsubscribe after first value
      )
      .subscribe((createdCustomerDevice) => {
        this.showCrudToast(
          DataModificationMethod.Create,
          'success',
          createdCustomerDevice
        );

        const newCustomerDevices = [
          ...this.customerDevices,
          createdCustomerDevice
        ];

        newCustomerDevices.sort((a, b) => a.deviceId - b.deviceId);

        this.changeCustomerDevices.emit(newCustomerDevices);

        this.customerDeviceToEdit = null;
        this.form?.formGroup?.reset();
        this.isSidebarVisible = false;
      });
  }

  updateCustomerDevice(id: number, customerDevice: CustomerDeviceUpdate): void {
    this.customerDeviceService
      .update(id, customerDevice)
      .pipe(
        catchError((error) => {
          this.showCrudToast(
            DataModificationMethod.Edit,
            'error',
            customerDevice
          );

          return throwError(() => error);
        }),
        first() // Automatically unsubscribe after first value
      )
      .subscribe((createdCustomerDevice) => {
        this.showCrudToast(
          DataModificationMethod.Edit,
          'success',
          createdCustomerDevice
        );

        const updatedCustomerDevices = this.customerDevices.map((c) =>
          c.id === id ? createdCustomerDevice : c
        );

        this.changeCustomerDevices.emit(updatedCustomerDevices);

        this.customerDeviceToEdit = null;
        this.form?.formGroup?.reset();
        this.isSidebarVisible = false;
      });
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'CustomerDevice');
  }

  handleCreateCustomerDevice(): void {
    this.isSidebarVisible = true;

    this.customerDeviceToEdit = {
      customerId: this.customer.id,

      controlUnitId: undefined,
      externalDeviceSerialNumber: undefined,
      internalDeviceSerialNumber: undefined,
      commissionDate: undefined,
      commissionType: 'NothaftHeiztechnik',
      deviceId: undefined,
      heatingEngineerId: undefined,

      isGasSeparatorInstalled: false,

      isDeliveredByNothaft: true,
      isActive: true,
      note: undefined
    } satisfies CustomerDeviceCreate;
  }

  handleDeleteCustomerDevice(customerDevice: CustomerDevice): void {
    const translationContext = {
      device: customerDevice.device?.title ?? '<Unknown>'
    } as const;

    const executeDelete = () => {
      this.customerDeviceService
        .delete(customerDevice.id)
        .pipe(
          catchError((error) => {
            this.showCrudToast(
              DataModificationMethod.Delete,
              'error',
              customerDevice
            );

            return throwError(() => error);
          }),
          first() // Automatically unsubscribe after first value
        )
        .subscribe((deletedCustomerDevice) => {
          this.showCrudToast(
            DataModificationMethod.Delete,
            'success',
            customerDevice
          );
          this.changeCustomerDevices.emit(
            this.customerDevices.filter(
              (c) => c.id !== deletedCustomerDevice.id
            )
          );
        });
    };
    this.messageCenterService.confirm(
      this.translate.instant(
        'customerDeviceComponent.actions.toasts.delete.confirm.header',
        translationContext
      ),
      this.translate.instant(
        'customerDeviceComponent.actions.toasts.delete.confirm.message',
        translationContext
      ),
      executeDelete,
      () => {
        this.messageCenterService.showToast(
          this.translate.instant(
            `customerDeviceComponent.actions.toasts.delete.info.summary`
          ),
          this.translate.instant(
            `customerDeviceComponent.actions.toasts.delete.info.detail`,
            translationContext
          ),
          'info'
        );
      }
    );
  }

  handleUpdateCustomerDevice(customerDevice: CustomerDevice): void {
    this.isSidebarVisible = true;

    this.customerDeviceToEdit = structuredClone(customerDevice);
  }

  handleViewCustomerDevice(customerDevice: CustomerDevice): void {
    this.isSidebarVisible = true;

    this.customerDeviceToEdit = structuredClone(customerDevice);
  }

  handleChangeIsActive(customerDevice: CustomerDevice, value: boolean): void {
    return Reflect.apply(handleChangeIsActive, this, [customerDevice, value]);
  }

  async onSidebarVisibleChange(value: boolean): Promise<void> {
    if (value === true || this.isFormDirty === false) {
      this.isSidebarVisible = value;

      return;
    }

    const isVisible = await new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant('general.confirmUnsavedChanges.header'),
        this.translate.instant('general.confirmUnsavedChanges.message'),
        () => {
          resolve(false);
          this.form?.formGroup?.reset();
        },
        () => {
          resolve(true);
        }
      );
    });

    this.isSidebarVisible = isVisible;
  }

  public showCrudToast(
    method: DataModificationMethod | 'isActive',
    severity: Severity,
    customer: CustomerDevice | CustomerDeviceCreate | CustomerDeviceUpdate,
    translationContext?: Record<string, string>
  ): void {
    let title = customer.device?.title;

    if (!title && customer.deviceId) {
      title = this.devices.find((d) => d.id === customer.deviceId)?.title;
    }

    if (!title) {
      title = '<Unknown>';
    }

    this.messageCenterService.showToast(
      this.translate.instant(
        `customerDeviceComponent.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `customerDeviceComponent.actions.toasts.${method}.${severity}.detail`,
        {
          device: title,
          ...translationContext
        }
      ),
      severity
    );
  }
}
