import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Country } from 'src/app/models/Country';
import { Ticket } from 'src/app/models/Ticket';
import { Address } from 'src/app/models/customer/Address';
import { Customer } from 'src/app/models/customer/Customer';

@Component({
  selector: 'app-ticket-customer',
  templateUrl: './ticket-customer.component.html',
  styleUrls: ['./ticket-customer.component.scss']
})
export class TicketCustomerComponent implements OnInit {
  @Input() formGroupName!: string;

  @Input() ticket!: Ticket;

  @Input() customers: Customer[] = [];

  @Input() countries: Country[] = [];

  @Input() getCustomersIsLoading = true;

  @Output() ticketChange = new EventEmitter<Ticket>();

  @Output() customerChanged = new EventEmitter<Customer>();

  form!: FormGroup;

  customerModalVisible = false;

  selectedCustomer: Customer | null = null;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    if (this.ticket.customer) {
      this.selectedCustomer = this.ticket.customer;
    }
  }

  onTicketChanged(): void {
    this.ticket.customer = this.selectedCustomer;
    this.ticketChange.emit(this.ticket);
  }

  formatAddress(address: Address): string {
    const country = this.countries.find((c) => c.id === address.countryId);

    if (country) {
      const countryTranslation = this.translate.instant(
        `countries.${country.key}`
      );

      return `${address.street}, ${address.postalCode} ${address.city}, ${countryTranslation}`;
    }

    return `${address.street}, ${address.postalCode} ${address.city}`;
  }

  navigateToCustomers(): void {
    if (this.selectedCustomer) {
      this.router.navigate([`customers/${this.selectedCustomer.id}`]);
    } else {
      this.router.navigate(['customers']);
    }
  }

  onCustomerSelectionChanged(customer: Customer): void {
    this.form.get('customer')?.setValue(customer);
    this.selectedCustomer = customer;
    this.hideCustomerModal();
    this.onTicketChanged();
    this.customerChanged.emit(customer);
  }

  chooseCustomerClicked(): void {
    this.customerModalVisible = true;
  }

  public hideCustomerModal(): void {
    this.customerModalVisible = false;
  }
}
