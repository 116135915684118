import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Device } from 'src/app/models/Device';
import { GenericService } from 'src/app/services/api/generic.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { environment } from 'src/environments/environment';

const CONTEXT = 'devices';

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends GenericService<Device> {
  constructor(
    http: HttpClient,
    messageCenterService: MessageCenterService,
    translate: TranslateService,
    private internalHttp: HttpClient
  ) {
    super(http, messageCenterService, translate);
    this.setContext(CONTEXT);
  }

  public uploadFiles(deviceId: number, formData: FormData): Observable<Device> {
    return this.internalHttp.post<Device>(
      `${environment.apiUrl}/${CONTEXT}/${deviceId}/file`,
      formData
    );
  }

  public deleteFile(deviceId: number, fileId: number): Observable<Device> {
    return this.internalHttp.delete<Device>(
      `${environment.apiUrl}/${CONTEXT}/${deviceId}/file/${fileId}`
    );
  }
}
