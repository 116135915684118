import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoComplete, AutoCompleteSelectEvent } from 'primeng/autocomplete';

import { GenericColumnConfiguration } from 'src/app/models/GenericColumnConfiguration';

type AutoCompleteCompleteEvent = {
  originalEvent: Event;
  query: string;
};

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent implements ControlValueAccessor, OnInit {
  @ViewChild('autocomplete') autocomplete!: AutoComplete;

  @Input({ required: true }) columnConfiguration!: GenericColumnConfiguration;

  @Input() id?: string | undefined = undefined;

  @Input() name?: string | undefined = undefined;

  @Input() placeholder?: string | undefined = undefined;

  @Input() defaultValue?: Record<string, unknown> | undefined = undefined;

  ngOnInit(): void {
    const refKey = this.columnConfiguration.referringKey;
    const value = this.defaultValue;

    if (refKey && value && typeof value === 'object' && refKey in value) {
      this.value = String(value[refKey]);

      setTimeout(() => {
        this.autocomplete.updateModel(String(value[refKey]));
      }, 50); // Delay to ensure the model is updated
    }
  }

  value: string | null = null;

  onChange: (value: string | null) => void = () => {};

  onTouched: () => void = () => {};

  filteredOptions: unknown[] = [];

  isDisabled = false;

  keydownEventListener = null;

  filter(event: AutoCompleteCompleteEvent): void {
    this.handleInputChange(event.query);

    if (
      !this.columnConfiguration.options ||
      !this.columnConfiguration.referringKey
    ) {
      console.warn(
        '[AutocompleteComponent] No options or referringKey provided'
      );

      return;
    }

    if (!event.query) {
      this.filteredOptions = [...this.columnConfiguration.options];

      return;
    }

    const filtered: unknown[] = [];

    const options = this.columnConfiguration.options ?? [];

    for (let i = 0; i < options.length; i++) {
      const value = String(
        options[i][this.columnConfiguration.referringKey]
      ).toLowerCase();

      if (value.startsWith(event.query.toLowerCase())) {
        filtered.push(options[i]);
      }
    }

    this.filteredOptions = filtered;
  }

  writeValue(value: string | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  handleOptionSelect(event: AutoCompleteSelectEvent): void {
    if (!this.columnConfiguration.referringKey) {
      console.warn(
        '[AutocompleteComponent] No referringKey provided for column configuration'
      );

      return;
    }

    this.handleInputChange(event.value[this.columnConfiguration.referringKey]);
  }

  handleInputChange(input: string): void {
    this.value = input;
    this.onChange(this.value);

    if (!input) {
      this.onTouched();
    }
  }

  handleFocus(): void {
    const inputEl = this.autocomplete.inputEL
      ?.nativeElement as HTMLInputElement;
    inputEl.addEventListener('keydown', this.handleKeydown);
  }

  handleBlur(): void {
    const inputEl = this.autocomplete.inputEL
      ?.nativeElement as HTMLInputElement;

    inputEl.removeEventListener('keydown', this.handleKeydown);
  }

  // Note: This needs to be a arrow function to be able to access the class context via `this`
  handleKeydown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      this.autocomplete.hide();
    }
  };
}
