<app-crud
  *ngIf="config"
  [@fadeAnimation]
  [config]="config"
  [objectsList]="deviceList"
  [sidebarVisible]="sidebarVisible"
  [separatePageVisible]="separatePageVisible"
  [tableVisible]="tableVisible"
  [editMethod]="editMethod"
  [createMethod]="createMethod"
  [editObject]="editDevice"
  [totalRecords]="totalRecords"
  (sidebarVisibleChange)="sidebarVisibleChange($event)"
  (separatePageVisibleChange)="separatePageVisibleChange($event)"
  (tableVisibleChange)="tableVisibleChange($event)"
  (cellChanged)="onCellChanged($any($event))"
  (objectChanged)="deviceChanged($event)"
  (rowActionClicked)="rowActionClicked($event)"
  (lazyLoadTableEventFired)="lazyLoadTableEventFired($event)">
  <ng-template #loggingTemplate>
    <app-logging
      #loggingComponent
      *ngIf="editDevice && editDevice.id"
      [model]="'Device'"
      [identifier]="editDevice.id"
      [config]="config"
      [formatter]="loggingFormatter" />
  </ng-template>
</app-crud>
