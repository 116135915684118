import { Ticket, TicketCategory } from 'src/app/models/Ticket';

export const getBackgroundColor = (ticket: Ticket): string => {
  switch (ticket.ticketCategoryType) {
    case TicketCategory.Commissioning:
      return '#60B254';
    case TicketCategory.Emergency:
      return '#C26262';
    case TicketCategory.Maintenance:
      return '#9B5FC4';
    case TicketCategory.SGC:
      return '#f264f7';
    case TicketCategory.Malfunction:
      return '#C2A74D';
    case TicketCategory.Task:
      return '#658DC8';
    default:
      return '#000';
  }
};

export const getIcon = (ticket: Ticket): string => {
  switch (ticket.ticketCategoryType) {
    case TicketCategory.Commissioning:
      return 'pi pi-wrench';
    case TicketCategory.Emergency:
      return 'pi pi-times';
    case TicketCategory.Maintenance:
      return 'pi pi-cog';
    case TicketCategory.Malfunction:
      return 'pi pi-exclamation-triangle';
    case TicketCategory.Task:
      return 'pi pi-check-square';
    default:
      return '';
  }
};
