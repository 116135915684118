import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableActionSeverity } from 'src/app/types/misc/Severity';

@Component({
  selector: 'app-table-action',
  templateUrl: './table-action.component.html',
  styleUrls: ['./table-action.component.scss']
})
export class TableActionComponent {
  @Input() severity: TableActionSeverity = 'primary';

  @Input() tooltip?: string;

  @Input() tooltipPosition: string = 'right';

  @Input() icon: string = '';

  @Output() actionClicked = new EventEmitter<void>();

  clicked(): void {
    this.actionClicked.emit();
  }
}
