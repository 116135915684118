/* eslint-disable max-lines */
import { animate, style, transition, trigger } from '@angular/animations';
import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { Subscription } from 'rxjs';
import { LoggingComponent } from 'src/app/components/misc/logging/logging.component';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { ActionClickedResponse } from 'src/app/models/ActionClickedResponse';
import { CellChangedResponse } from 'src/app/models/CellChangedResponse';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { LazyListDto } from 'src/app/models/LazyListDto';
import { User, UserRole } from 'src/app/models/User';
import { UserService } from 'src/app/services/api/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataService } from 'src/app/services/utils/data.service';
import { EditMethod } from 'src/app/types/misc/EditMethod';
import {
  LoggingFormatter,
  LoggingFormatterBuilder
} from 'src/app/utils/logging';
import { MessageCenterService } from '../../../services/message-center.service';
import { Severity } from '../../../types/misc/Severity';
import { createUserManagementTableConfig } from './users.config';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class UsersComponent implements OnInit, OnDestroy {
  @ViewChild('loggingComponent') loggingComponent!: LoggingComponent;

  config!: GenericTableConfiguration;

  userList: User[] = [];

  totalRecords!: number;

  editUser?: User | null;

  editMethod: EditMethod = 'sidebar';

  createMethod: EditMethod = 'sidebar';

  sidebarVisible = false;

  separatePageVisible = false;

  tableVisible = true;

  userSubscriptions: Subscription = new Subscription();

  totalRecordsSubscription: Subscription = new Subscription();

  lazyLoadTableEvent?: TableLazyLoadEvent;

  loggingFormatter: LoggingFormatter | null = null;

  constructor(
    private messageCenterService: MessageCenterService,
    private translate: TranslateService,
    private userService: UserService,
    private dataService: DataService<User>,
    private clipboard: Clipboard,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.config = createUserManagementTableConfig(
      Object.values(UserRole).filter((x) => x !== UserRole.TechnicalAdmin),
      'CustomerService'
    );
    if (!this.config.lazyLoad) {
      this.userSubscriptions.add(
        this.userService.findAll().subscribe((users: User[]) => {
          this.userList = users;
        })
      );
    }

    this.loggingFormatter = LoggingFormatterBuilder.new<{
      [K in keyof User]: unknown;
    }>()
      .add('role', (value) =>
        this.translate.instant(
          `userManagementComponent.table.columns.role.roles.${value}`
        )
      )
      .build();
  }

  ngOnDestroy(): void {
    if (this.userSubscriptions) {
      this.userSubscriptions.unsubscribe();
    }
    if (this.totalRecordsSubscription) {
      this.totalRecordsSubscription.unsubscribe();
    }
  }

  lazyLoadTableEventFired(event: TableLazyLoadEvent): void {
    if (this.config.lazyLoad) {
      this.lazyLoadTableEvent = event;
      this.userSubscriptions.add(
        this.userService
          .lazyLoad(event)
          .subscribe((lazyUserListDto: LazyListDto<User>) => {
            this.userList = lazyUserListDto.objects;
            this.totalRecords = lazyUserListDto.totalRecords;
          })
      );
    }
  }

  userChanged(user: User): void {
    if (user.id) {
      this.updateUser(user.id, user);
    } else {
      const { email } = user;

      if (email) {
        this.userSubscriptions.add(
          this.userService
            .findByEmail(email)
            .subscribe(async (existingUser) => {
              if (existingUser) {
                if (existingUser.deletedAt) {
                  const shouldReactivate =
                    await this.confirmUserReactivation(existingUser);
                  if (shouldReactivate && existingUser.id) {
                    this.reactivateAndModifyUserList(existingUser.id);
                  }
                } else {
                  this.userAlreadyExists(existingUser);
                }
              } else {
                this.createUser(user);
              }
            })
        );
      }
    }
  }

  userAlreadyExists(existingUser: User): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant(
          'userManagementComponent.form.alreadyExists.header'
        ),
        this.translate.instant(
          'userManagementComponent.form.alreadyExists.message',
          { email: existingUser.email }
        ),
        () => resolve(true),
        () => resolve(false)
      );
    });
  }

  confirmUserReactivation(existingUser: User): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant(
          'userManagementComponent.form.reactivateUser.header',
          { email: existingUser.email }
        ),
        this.translate.instant(
          'userManagementComponent.form.reactivateUser.message',
          { email: existingUser.email }
        ),
        () => resolve(true),
        () => resolve(false)
      );
    });
  }

  reactivateAndModifyUserList(userId: number): void {
    this.userSubscriptions.add(
      this.userService.reactivateUser(userId).subscribe((reactivatedUser) => {
        if (reactivatedUser) {
          this.userList = this.dataService.modifyList(
            this.userList,
            reactivatedUser,
            DataModificationMethod.Create
          );
        }
      })
    );
  }

  createUser(user: User): void {
    this.userSubscriptions.add(
      this.userService.create(user).subscribe((newUser) => {
        if (newUser) {
          this.userList = this.dataService.modifyList(
            this.userList,
            newUser,
            DataModificationMethod.Create
          );
          if (this.sidebarVisible) {
            this.sidebarVisible = !this.sidebarVisible;
          }
          this.showCrudToast(
            DataModificationMethod.Create,
            'success',
            newUser.username
          );
        } else {
          this.showCrudToast(
            DataModificationMethod.Create,
            'error',
            user.username
          );
        }
      })
    );
  }

  onCellChanged(response: CellChangedResponse): void {
    const user = new User(response.object);

    if (user.id) {
      this.updateUser(user.id, user);
    }
  }

  updateUser(id: number, user: User): void {
    this.userSubscriptions.add(
      this.userService.edit(id, user).subscribe((updatedUser) => {
        if (updatedUser) {
          this.userList = this.dataService.modifyList(
            this.userList,
            updatedUser,
            DataModificationMethod.Edit
          );
          if (this.sidebarVisible) {
            this.sidebarVisible = !this.sidebarVisible;
          }
          this.reloadData();
          this.loggingComponent?.fetchLoggingView();
          this.showCrudToast(
            DataModificationMethod.Edit,
            'success',
            updatedUser.username
          );
        } else {
          this.showCrudToast(
            DataModificationMethod.Edit,
            'error',
            user.username
          );
        }
      })
    );
  }

  rowActionClicked(response: ActionClickedResponse): void {
    switch (response.action.identifier) {
      case 'create':
        this.create();
        break;
      case 'edit':
        this.edit(response.object);
        break;
      case 'delete':
        this.delete(response.object);
        break;
      case 'copyLink':
        this.generatePasswordLink(response.object);
        break;
      case 'sendInvitation':
        this.sendInvitation(response.object);
        break;
      default:
        break;
    }
  }

  generatePasswordLink(data: User): void {
    if (data.email) {
      this.userSubscriptions.add(
        this.authService
          .generatePasswordLink(data.email)
          .subscribe((response) => {
            if (response) {
              this.clipboard.copy(response.link);
              this.showToast('success', 'copyLink');
            } else {
              this.showToast('error', 'copyLink');
            }
          })
      );
    }
  }

  sendInvitation(data: User): void {
    if (data.email) {
      this.userSubscriptions.add(
        this.authService
          .generatePasswordLinkWeb(data.email)
          .subscribe((response) => {
            if (response) {
              this.showToast('success', 'invitation');
            } else {
              this.showToast('error', 'invitation');
            }
          })
      );
    }
  }

  edit(data: User): void {
    if (this.editMethod === 'sidebar') {
      this.sidebarVisible = this.editMethod === 'sidebar';
    } else {
      this.separatePageVisible = this.editMethod === 'page';
      this.tableVisible = false;
    }
    this.editUser = data;
  }

  create(): void {
    if (this.createMethod === 'sidebar') {
      this.sidebarVisible = this.createMethod === 'sidebar';
    } else {
      this.separatePageVisible = this.createMethod === 'page';
      this.tableVisible = false;
    }
    this.editUser = new User();
  }

  delete(data: User): void {
    if (data.id) {
      let severity: 'error' | 'success' = 'error';
      this.userSubscriptions.add(
        this.userService.delete(data.id).subscribe((deletedUser: User) => {
          if (deletedUser) {
            severity = 'success';
            this.userList = this.dataService.modifyList(
              this.userList,
              data,
              DataModificationMethod.Delete
            );
            this.reloadData();
          }
          this.showCrudToast(
            DataModificationMethod.Delete,
            severity,
            data.username
          );
        })
      );
    }
  }

  sidebarVisibleChange(visible: boolean): void {
    this.sidebarVisible = visible;
    if (this.editUser && !this.sidebarVisible) {
      this.editUser = null;
    }
  }

  separatePageVisibleChange(visible: boolean): void {
    this.separatePageVisible = visible;
    if (this.editUser && !this.separatePageVisible) {
      this.editUser = null;
    }
  }

  tableVisibleChange(visible: boolean): void {
    this.tableVisible = visible;
  }

  reloadData(): void {
    if (this.config.lazyLoad && this.lazyLoadTableEvent) {
      this.lazyLoadTableEventFired(this.lazyLoadTableEvent);
    }
  }

  showCrudToast(
    method: DataModificationMethod,
    severity: Severity,
    username: string | undefined
  ): void {
    const translateParam = username ? username : '';
    this.messageCenterService.showToast(
      this.translate.instant(
        `userManagementComponent.actions.toasts.${method}.${severity}.summary`,
        { username: translateParam }
      ),
      this.translate.instant(
        `userManagementComponent.actions.toasts.${method}.${severity}.detail`,
        { username: translateParam }
      ),
      severity
    );
  }

  showToast(severity: Severity, method: string): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `userManagementComponent.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `userManagementComponent.actions.toasts.${method}.${severity}.detail`
      ),
      severity
    );
  }
}
