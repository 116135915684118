/* eslint-disable max-lines-per-function */
import { Validators } from '@angular/forms';
import { GenericColumnConfiguration } from 'src/app/models/GenericColumnConfiguration';
import { GenericRowActionConfiguration } from 'src/app/models/GenericRowActionConfiguration';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';

// eslint-disable-next-line require-jsdoc
function filterTableConfig(): GenericTableConfiguration {
  return new GenericTableConfiguration({
    table: 'filter',
    showGridLines: false,
    showStripedRows: false,
    size: 'sm',
    showAsCard: false,
    translationKey: 'filterComponent',
    showPdfExportAction: false,
    showCsvExportAction: false,
    showExcelExportAction: false,
    lazyLoad: false,
    columns: [
      new GenericColumnConfiguration({
        type: 'string',
        field: 'filterName',
        isFilterable: false,
        isSortable: false,
        formControl: {
          validators: [Validators.required]
        }
      })
    ],
    rowActions: [
      new GenericRowActionConfiguration({
        icon: 'pi-check-circle',
        tooltipTranslateKey: '',
        identifier: 'applyFilter',
        iconOnly: true,
        confirmRequired: false
      }),
      new GenericRowActionConfiguration({
        icon: 'pi-trash',
        tooltipTranslateKey: '',
        identifier: 'delete',
        iconOnly: true,
        confirmRequired: true,
        color: '#FF0000'
      })
    ]
  });
}
export { filterTableConfig };
