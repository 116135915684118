import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-password-guidelines',
  templateUrl: './password-guidelines.component.html',
  styleUrls: ['./password-guidelines.component.scss']
})
export class PasswordGuidelinesComponent {
  @Input() showHeader = false;

  @Input() showFooter = false;

  environment = environment;
}
