import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyDayOverviewComponent } from 'src/app/components/admin/my-day-overview/my-day-overview.component';
import { ErrorViewComponent } from 'src/app/components/misc/error-view/error-view.component';
import { CalendarOverviewComponent } from './components/admin/calendar-overview/calendar-overview.component';
import { CustomerComponent } from './components/admin/customer/customer.component';
import { CustomerEditComponent } from './components/admin/customer/views/customer-edit.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { DeviceComponent } from './components/admin/device/device.component';
import { HeatingEngineerComponent } from './components/admin/heating-engineer/heating-engineer.component';
import { TicketEditComponent } from './components/admin/ticket/components/ticket-edit/ticket-edit.component';
import { TicketComponent } from './components/admin/ticket/ticket.component';
import { UsersComponent } from './components/admin/users/users.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { LoginComponent } from './components/auth/login/login.component';
import { NewPasswordComponent } from './components/auth/newpassword/newpassword.component';
import { VerificationComponent } from './components/auth/verification/verification.component';
import { HomeComponent } from './components/templates/home/home.component';
import { authGuard } from './guards/auth.guard';
import { canDeactivateGuard } from './guards/can-deactivate.guard';
import { loginGuard } from './guards/login.guard';
import { roleGuard } from './guards/role.guard';
import { AppLayoutComponent } from './layout/app.layout.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginGuard]
  },
  {
    path: 'newpassword/:token',
    component: NewPasswordComponent
  },
  {
    path: 'verification',
    component: VerificationComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'customers',
        component: CustomerComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'customers/:id',
        component: CustomerEditComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'heating-engineers',
        component: HeatingEngineerComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'devices',
        component: DeviceComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'tickets',
        component: TicketComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'tickets/:id',
        component: TicketEditComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'tickets/:id/:scrollTo',
        component: TicketEditComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'calendar',
        component: CalendarOverviewComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'my-day',
        component: MyDayOverviewComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [authGuard, roleGuard],
        canDeactivate: [canDeactivateGuard]
      }
    ]
  },
  {
    path: 'error',
    component: ErrorViewComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
