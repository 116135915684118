/* eslint-disable require-jsdoc */
/* eslint-disable max-lines-per-function */

import { Validators } from '@angular/forms';
import { Country } from 'src/app/models/Country';
import { GenericActionConfiguration } from 'src/app/models/GenericActionConfiguration';
import { GenericColumnConfiguration } from 'src/app/models/GenericColumnConfiguration';
import { GenericRowActionConfiguration } from 'src/app/models/GenericRowActionConfiguration';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';

function heatingEngineerTableConfig(
  countries: Country[]
): GenericTableConfiguration {
  return new GenericTableConfiguration({
    permissionSubject: 'HeatingEngineer',
    table: 'heating-engineer',
    showGridLines: false,
    showStripedRows: false,
    translationKey: 'heatingEngineerComponent',
    showPdfExportAction: false,
    showCsvExportAction: false,
    showExcelExportAction: false,
    lazyLoad: true,
    columns: [
      new GenericColumnConfiguration({
        type: 'boolean',
        field: 'isBlocked',
        isFilterable: true,
        isSortable: false,
        booleanStyle: 'icon',
        frozenLeft: true,
        isCreateField: false,
        isEditField: false,
        showTableHeader: false,
        booleanIsOpposite: true
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'heatingEngineerNumber',
        frozenLeft: true,
        formControl: {
          validators: [Validators.required]
        },
        forceSingleLine: true
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'name',
        isClickable: true,
        frozenLeft: true,
        formControl: {
          validators: [Validators.required]
        },
        forceSingleLine: true
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'address',
        isCreateField: false,
        isEditField: false,
        cutOffWithTooltip: true,
        minWidth: 300
      }),
      new GenericColumnConfiguration({
        type: 'telephone',
        field: 'mobilePhoneNumber',
        formControl: {
          validators: []
        },
        forceSingleLine: true
      }),
      new GenericColumnConfiguration({
        type: 'telephone',
        field: 'landlinePhoneNumber',
        formControl: {
          validators: []
        },
        forceSingleLine: true
      }),
      new GenericColumnConfiguration({
        type: 'email',
        field: 'email',
        formControl: {
          validators: [Validators.email]
        },
        forceSingleLine: true
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'street',
        isCreateField: true,
        isEditField: true,
        isTableField: false,
        formControl: {
          validators: []
        }
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'city',
        isCreateField: true,
        isEditField: true,
        isTableField: false,
        formControl: {
          validators: []
        }
      }),
      new GenericColumnConfiguration({
        type: 'string',
        field: 'postalCode',
        isCreateField: true,
        isEditField: true,
        isTableField: false,
        formControl: {
          validators: []
        }
      }),

      new GenericColumnConfiguration({
        type: 'dropdown',
        field: 'country',

        options: countries,
        optionLabel: 'key',
        translatePath: 'countries',
        showFilter: true,

        isCreateField: true,
        isEditField: true,
        isTableField: false,

        formControl: {
          validators: []
        }
      }),
      new GenericColumnConfiguration({
        type: 'number',
        field: 'deviceCommissioningCount',
        isCreateField: false,
        isEditField: false,
        isTableField: true,
        isSortable: true,
        isFilterable: false
      }),
      new GenericColumnConfiguration({
        type: 'longText',
        cutOffWithTooltip: 200,
        field: 'note',
        formControl: {
          validators: []
        }
      }),
      new GenericColumnConfiguration({
        type: 'boolean',
        field: 'isActive',
        isFilterable: true,
        booleanStyle: 'switch',
        formControl: {},
        isCreateField: false,
        permissionAction: 'update'
      }),
      new GenericColumnConfiguration({
        type: 'boolean',
        field: 'isBlocked',
        isFilterable: true,
        booleanStyle: 'switch',
        formControl: {},
        isCreateField: false,
        isTableField: false,
        permissionAction: 'update'
      })
    ],
    rowActions: [
      new GenericRowActionConfiguration({
        icon: 'pi-pencil',
        tooltipTranslateKey: 'heatingEngineerComponent.actions.tooltips.edit',
        identifier: 'edit',
        permissionAction: 'update',
        iconOnly: false,
        severity: 'success'
      }),
      new GenericRowActionConfiguration({
        icon: 'pi-trash',
        tooltipTranslateKey: 'heatingEngineerComponent.actions.tooltips.delete',
        identifier: 'delete',
        permissionAction: 'delete',
        iconOnly: false,
        severity: 'danger',
        confirmRequired: true
      })
    ],
    topRightAction: new GenericActionConfiguration({
      identifier: 'create',
      iconOnly: false,
      icon: 'pi-plus-circle',
      label: [
        'general.form.createSubject',
        'heatingEngineerComponent.form.title'
      ],
      permissionAction: 'create'
    })
  });
}

export { heatingEngineerTableConfig };
