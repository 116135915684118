<app-ticket-table
  *ngIf="tickets && !createOnly"
  [@fadeAnimation]
  [tickets]="tickets"
  [countries]="countries"
  [customers]="customers"
  [standaloneCard]="standaloneCard"
  [showCustomerColumn]="showCustomerColumn"
  [showCreateButton]="$can('create')"
  [showUpdateButton]="$can('update')"
  (handleCreateTicket)="handleCreateTicket()"
  (handleUpdateTicket)="handleUpdateTicket($event)"></app-ticket-table>

<button
  *ngIf="createOnly && showCreateButton && $can('create')"
  pButton
  pRipple
  type="button"
  icon="pi pi-plus-circle"
  [label]="'ticketComponent.newTicket' | translate"
  class="flex-auto p-button-outlined"
  (click)="handleCreateTicket()"></button>

<!-- Create Sidebar -->
<p-sidebar
  [visible]="isSidebarVisible"
  position="right"
  appendTo="body"
  styleClass="w-32rem scrollbar-gutter-stable"
  (visibleChange)="onSidebarVisibleChange($event)"
  (onHide)="onSidebarVisibleChange(false)">
  <!-- Header | Create Sidebar -->
  <ng-template pTemplate="header">
    <div class="bg-primary flex justify-content-between">
      <h4 class="mb-0 ml-4 text-white">
        {{ ( 'general.form.createSubject') | translate: {subject:
        ("ticketComponent.title" | translate)} }}
      </h4>
      <span
        class="pi pi-window-maximize text-white cursor-pointer text-3xl ml-4"
        (click)="openTicketDetail()"></span>
    </div>
  </ng-template>
  <!-- Body | Create Sidebar -->
  <ng-template pTemplate="content">
    <div body class="pt-4 ml-4">
      <app-ticket-create-form
        #createForm
        [ticket]="ticketToCreate"
        [countries]="countries"
        [customers]="customers"
        [getCustomersIsLoading]="getCustomersIsLoading"></app-ticket-create-form>
    </div>
  </ng-template>
  <!-- Footer | Create Sidebar -->
  <ng-template pTemplate="footer">
    <div class="flex flex-wrap gap-2 justify-content-between mt-2">
      <button
        pButton
        pRipple
        type="button"
        [label]="'general.form.cancel' | translate"
        (click)="onSidebarVisibleChange(false)"
        class="flex-auto p-button-outlined"></button>
      <button
        pButton
        pRipple
        [label]="'general.form.save' | translate"
        class="flex-auto"
        [disabled]="createSubmitDisabled"
        (click)="onSubmit()"></button>
    </div>
  </ng-template>
</p-sidebar>
