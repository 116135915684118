<ng-container *ngIf="highestSlotIndex > 0" #formContainer>
  <form [formGroup]="form" [ngClass]="{'separate-page': isInSeparatePage}">
    <div class="form-fields">
      <ng-container *ngFor="let i of previousNumbersArray;">
        <div
          class="form-field-empty"
          *ngIf="!isNumberInArrayAttribute(i)"></div>
        <ng-container *ngFor="let col of config.columns">
          <div
            *ngIf="(col.formControl && col.formSeparatePageIndex === i &&
            ((col.isCreateField && !this.editObject.id) ||
            (col.isEditField && this.editObject.id))) || highestSlotIndex === 0"
            class="form-field">
            <div class="mb-2">
              <label [htmlFor]="col.field">
                {{ config.translationKey + ".table.columns." + col.field +
                ".title" | translate}}<ng-container *ngIf="isRequired(col)"
                  >*</ng-container
                >
              </label>
              <div
                class="pt-2"
                [ngClass]="col.type === 'boolean' ? 'pt-2' : ''">
                <input
                  *ngIf="col.type === 'string' || col.type === 'email' || col.type === 'telephone'"
                  type="text"
                  pInputText
                  class="w-full"
                  [id]="col.field"
                  [placeholder]="'general.form.enterSubject'| translate:
                  {subject: (config.translationKey + '.table.columns.' + col.field + '.placeholder' | translate)}"
                  [formControlName]="col.field"
                  [value]="editObject ? editObject[col.field] : ''" />

                <textarea
                  rows="5"
                  cols="30"
                  *ngIf="col.type === 'longText'"
                  type="text"
                  pInputText
                  class="w-full"
                  [id]="col.field"
                  [placeholder]="'general.form.enterSubject'| translate:
                  {subject: (config.translationKey + '.table.columns.' + col.field + '.placeholder' | translate)}"
                  [formControlName]="col.field"
                  [value]="editObject ? editObject[col.field] : ''"></textarea>

                <p-calendar
                  *ngIf="col.type === 'date'"
                  [firstDayOfWeek]="1"
                  [formControlName]="col.field">
                </p-calendar>

                <p-inputSwitch
                  *ngIf="col.type === 'boolean'"
                  [formControlName]="col.field"></p-inputSwitch>

                <p-inputNumber
                  *ngIf="col.type === 'number'"
                  [formControlName]="col.field"></p-inputNumber>

                <p-inputNumber
                  *ngIf="col.type === 'currency' || col.type === 'decimal'"
                  [mode]="col.type"
                  [currency]=""
                  [currencyDisplay]=""
                  [locale]=""
                  [formControlName]="col.field"></p-inputNumber>

                <p-dropdown
                  *ngIf="col.type === 'dropdown'"
                  autoWidth="false"
                  [style]="{'width':'100%'}"
                  [formControlName]="col.field"
                  [options]="col.options"
                  [optionLabel]="col.optionLabel"></p-dropdown>

                <p-dropdown
                  *ngIf="col.type === 'enum'"
                  [formControlName]="col.field"
                  autoWidth="false"
                  [style]="{'width':'100%'}"
                  [options]="col.options">
                  <ng-template let-value pTemplate="item">
                    {{ (col.translatePath + '.' + value) | translate }}
                  </ng-template>
                  <ng-template pTemplate="selectedItem" let-value>
                    <span *ngIf="value">
                      {{ (col.translatePath + '.' + value) | translate }}
                    </span>
                  </ng-template>
                </p-dropdown>

                <app-file-upload *ngIf="col.type === 'files'"></app-file-upload>
              </div>
              <div class="p-error">
                <div
                  *ngIf="form.controls[col.field].touched && form.controls[col.field].errors?.['required']">
                  {{ 'general.form.validation.error' | translate: { subject: (
                  config.translationKey + ".table.columns." + col.field +
                  ".title" | translate)} }}
                </div>
                <div
                  *ngIf="form.controls[col.field].touched && form.controls[col.field].errors?.['email']">
                  {{ 'general.form.validation.email' | translate: { subject: (
                  config.translationKey + ".table.columns." + col.field +
                  ".title" | translate)} }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="highestSlotIndex === 0" #formContainer>
  <form [formGroup]="form" [ngClass]="{'separate-page': isInSeparatePage}">
    <div class="form-fields">
      <ng-container *ngFor="let col of config.columns">
        <div
          *ngIf="col.formControl &&
        ((col.isCreateField && !this.editObject.id) ||
          (col.isEditField && this.editObject.id))"
          class="form-field">
          <div class="mb-2">
            <label [htmlFor]="col.field">
              {{ config.translationKey + ".table.columns." + col.field +
              ".title" | translate}}<ng-container *ngIf="isRequired(col)"
                >*</ng-container
              >
            </label>
            <div class="pt-2" [ngClass]="col.type === 'boolean' ? 'pt-2' : ''">
              <app-autocomplete
                *ngIf="col.type === 'autocomplete'"
                [formControlName]="col.field"
                [id]="col.field"
                [placeholder]="'general.form.enterSubject'| translate:
        {subject: (config.translationKey + '.table.columns.' + col.field + '.placeholder' | translate)}"
                [columnConfiguration]="col"
                [defaultValue]="editObject[col.field]" />

              <input
                *ngIf="col.type === 'string' || col.type === 'email' || col.type === 'telephone'"
                type="text"
                pInputText
                class="w-full"
                [id]="col.field"
                [placeholder]="'general.form.enterSubject'| translate:
        {subject: (config.translationKey + '.table.columns.' + col.field + '.placeholder' | translate)}"
                [formControlName]="col.field"
                [value]="editObject ? editObject[col.field] : ''" />

              <textarea
                rows="5"
                cols="30"
                *ngIf="col.type === 'longText'"
                type="text"
                pInputText
                class="w-full"
                [id]="col.field"
                [placeholder]="'general.form.enterSubject'| translate:
        {subject: (config.translationKey + '.table.columns.' + col.field + '.placeholder' | translate)}"
                [formControlName]="col.field"
                [value]="editObject ? editObject[col.field] : ''"></textarea>

              <p-calendar
                *ngIf="col.type === 'date'"
                [id]="col.field"
                [firstDayOfWeek]="1"
                [formControlName]="col.field">
              </p-calendar>

              <p-inputSwitch
                *ngIf="col.type === 'boolean'"
                [id]="col.field"
                [formControlName]="col.field"></p-inputSwitch>

              <p-inputNumber
                *ngIf="col.type === 'number'"
                [id]="col.field"
                [formControlName]="col.field"></p-inputNumber>

              <p-inputNumber
                *ngIf="col.type === 'currency' || col.type === 'decimal'"
                [id]="col.field"
                [mode]="col.type"
                [currency]=""
                [currencyDisplay]=""
                [locale]=""
                [formControlName]="col.field"></p-inputNumber>

              <p-dropdown
                *ngIf="col.type === 'dropdown' && !col.translatePath"
                [id]="col.field"
                [filter]="col.dropDownFilter"
                [filterBy]="col.optionLabel"
                autoWidth="false"
                [style]="{'width':'100%'}"
                [formControlName]="col.field"
                [options]="col.options"
                [optionLabel]="col.optionLabel"
                [showClear]="true"
                [placeholder]="'general.choose'| translate"
                dataKey="id"></p-dropdown>

              <p-dropdown
                *ngIf="col.type === 'dropdown' && col.translatePath"
                [id]="col.field"
                [filter]="col.dropDownFilter"
                [filterBy]="col.optionLabel"
                autoWidth="false"
                [style]="{'width':'100%'}"
                [formControlName]="col.field"
                [options]="col.options"
                optionLabel="label"
                [showClear]="true"
                [placeholder]="'general.choose'| translate"
                dataKey="id">
                <ng-template let-value pTemplate="item">
                  {{ (col.translatePath + '.' + (col.optionLabel ?
                  value[col.optionLabel] : value)) | translate }}
                </ng-template>
                <ng-template pTemplate="selectedItem" let-value>
                  <span *ngIf="value">
                    {{ (col.translatePath + '.' + (col.optionLabel ?
                    value[col.optionLabel] : value)) | translate }}
                  </span>
                </ng-template>
              </p-dropdown>

              <p-dropdown
                *ngIf="col.type === 'enum'"
                [id]="col.field"
                [filter]="col.dropDownFilter"
                [filterBy]="col.optionLabel"
                [formControlName]="col.field"
                autoWidth="false"
                [style]="{'width':'100%'}"
                [showClear]="true"
                [placeholder]="'general.choose'| translate"
                [options]="col.options">
                <ng-template
                  *ngIf="col.translatePath"
                  let-value
                  pTemplate="item">
                  {{ (col.translatePath + '.' + value) | translate }}
                </ng-template>
                <ng-template
                  *ngIf="col.translatePath"
                  pTemplate="selectedItem"
                  let-value>
                  <span *ngIf="value">
                    {{ (col.translatePath + '.' + value) | translate }}
                  </span>
                </ng-template>
              </p-dropdown>

              <app-file-upload
                *ngIf="col.type === 'files'"
                (fileRemoved)="fileRemoved($event)"
                (choose)="filesChosen($event, col.field)"></app-file-upload>

              <div
                class="pt-4"
                *ngIf="config.fileDownloadContext && col.type === 'files' && editObject[col.field] && editObject[col.field].length > 0">
                <div class="pb-2">
                  {{'general.alreadyUploadedFiles' | translate}}
                </div>
                <div>
                  <app-files
                    [filesList]="editObject[col.field]"
                    [context]="config.fileDownloadContext"
                    (fileRemoved)="fileRemoved($event)"
                    (choose)="filesChosen($event, col.field)"></app-files>
                </div>
              </div>
            </div>
            <div class="p-error">
              <div
                *ngIf="form.controls[col.field].touched && form.controls[col.field].errors?.['required']">
                {{ 'general.form.validation.error' | translate: { subject: (
                config.translationKey + ".table.columns." + col.field + ".title"
                | translate)} }}
              </div>
              <div
                *ngIf="form.controls[col.field].touched && form.controls[col.field].errors?.['email']">
                {{ 'general.form.validation.email' | translate: { subject: (
                config.translationKey + ".table.columns." + col.field + ".title"
                | translate)} }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</ng-container>
