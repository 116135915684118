/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */

import { catchError, first, throwError } from 'rxjs';
import { CustomerEditContactPersonComponent } from 'src/app/components/admin/customer/views/components/customer-edit-contact-person/customer-edit-contact-person.component';
import { CustomerContactPerson } from 'src/app/models/customer/CustomerContactPerson';

type StateContext = {
  action: string;
  actionExecuted: string;
  actionBefore: string;
};

type FactoryContext = {
  field: 'isActive';

  onState: StateContext;
  ofState: StateContext;
};

export const HandleIsActiveSwitchContext = {
  field: 'isActive',

  onState: {
    action: 'activate',
    actionExecuted: 'activated',
    actionBefore: 'toActivate'
  },

  ofState: {
    action: 'deactivate',
    actionExecuted: 'deactivated',
    actionBefore: 'toDeactivated'
  }
} satisfies FactoryContext;

type AvailableContexts = typeof HandleIsActiveSwitchContext;

/**
 * Handles the change event of a switch component.
 * @param context - The available contexts for the switch component.
 * @returns A function that executes the switch change logic.
 */
export const handleSwitchChangeFactory = (context: AvailableContexts) =>
  function execute(
    this: CustomerEditContactPersonComponent,
    customerContactPerson: CustomerContactPerson,
    value: boolean
  ) {
    const translationContext = {
      name:
        customerContactPerson.firstname === null
          ? customerContactPerson.lastname
          : `${customerContactPerson.firstname} ${customerContactPerson.lastname}`,
      action: this.translate.instant(
        `customerContactPersonComponent.actions.toasts.${context.field}.actions.${value ? context.onState.action : context.ofState.action}`
      ),
      result: this.translate.instant(
        `customerContactPersonComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionExecuted : context.ofState.actionExecuted}`
      )
    } as const;

    const executeChange = () => {
      this.customerContactPersonService
        .update(customerContactPerson.id, { [context.field]: value })
        .pipe(
          catchError((error) => {
            this.showCrudToast(context.field, 'error', customerContactPerson, {
              result: this.translate.instant(
                `customerContactPersonComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionExecuted : context.ofState.actionExecuted}`
              )
            });

            return throwError(() => error);
          }),
          first() // Automatically unsubscribe after first value
        )
        .subscribe((updatedCustomerContactPerson) => {
          this.showCrudToast(
            context.field,
            'success',
            updatedCustomerContactPerson,
            {
              result: this.translate.instant(
                `customerContactPersonComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionExecuted : context.ofState.actionExecuted}`
              )
            }
          );

          this.changeCustomerContactPersons.emit(
            this.customerContactPersons.map((x) =>
              x.id === updatedCustomerContactPerson.id
                ? updatedCustomerContactPerson
                : x
            )
          );
        });
    };

    this.messageCenterService.confirm(
      this.translate.instant(
        `customerContactPersonComponent.actions.toasts.${context.field}.confirm.header`,
        translationContext
      ),
      this.translate.instant(
        `customerContactPersonComponent.actions.toasts.${context.field}.confirm.message`,
        translationContext
      ),
      executeChange,
      () => {
        this.messageCenterService.showToast(
          this.translate.instant(
            `customerContactPersonComponent.actions.toasts.${context.field}.info.summary`
          ),
          this.translate.instant(
            `customerContactPersonComponent.actions.toasts.${context.field}.info.detail`,
            translationContext
          ),
          'info'
        );

        this.changeCustomerContactPersons.emit(
          this.customerContactPersons.map((x) =>
            x.id === customerContactPerson.id
              ? { ...customerContactPerson, [context.field]: !value }
              : x
          )
        );
      },
      this.translate.instant(
        `customerContactPersonComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionBefore : context.ofState.actionBefore}`
      )
    );
  };
