<p *ngIf="!ticket.customer" class="text-center text-gray-700 mt-5 font-bold">
  {{'ticketComponent.noCustomerSelected' | translate}}
</p>

<app-customer-device-table
  *ngIf="ticket.customer"
  [customer]="ticket.customer"
  [customerDevices]="customerDevices"
  [showEditIcon]="false"
  [isTicketView]="true"
  (handleDeleteCustomerDevice)="customerDeviceRemoved($event)"
  (handleCreateCustomerDevice)="selectCustomerDevice()" />

<p-dialog
  [draggable]="false"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [(visible)]="customerDeviceModalVisible"
  (onHide)="hideCustomerDeviceModal()"
  [modal]="true"
  [style]="{ width: '75%' }">
  <!-- Dialog Header -->
  <ng-template pTemplate="header">
    <h2>
      {{'general.form.chooseSubject'| translate: {subject:
      ('ticketComponent.attributes.devices' | translate)} }}
    </h2>
  </ng-template>

  <app-customer-edit-devices
    *ngIf="ticket.customer"
    [customer]="ticket.customer"
    [customerDevices]="devices"
    [deviceManufacturers]="deviceManufacturers"
    (changeCustomerDevices)="devices = $event;"
    [selectionEnabled]="true"
    [(selectedDevices)]="customerDevices"
    (selectedDevicesChange)="devicesChanged($event)" />

  <ng-template pTemplate="footer">
    <div class="flex justify-content-between">
      <div class="text-left">
        <button
          pButton
          pRipple
          type="button"
          [label]="'general.form.cancel' | translate"
          (click)="hideCustomerDeviceModal()"
          class="flex-auto p-button-outlined"></button>
      </div>
      <div>
        <button
          pButton
          pRipple
          type="button"
          [label]="'general.form.take' | translate"
          (click)="saveDevices()"
          class="flex-auto"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>
