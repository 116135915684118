<button
  *ngIf="environment.config.showConfig"
  class="p-link ml-4 mr-2 text-primary"
  type="button"
  (click)="onConfigButtonClick()">
  <i class="pi pi-cog" style="font-size: 1.5rem; padding: 0.5rem"></i>
</button>

<p-sidebar
  *ngIf="environment.config.showConfig"
  [(visible)]="visible"
  position="right"
  [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="layout-config-sidebar w-18rem">
  <ng-template pTemplate="content">
    <div class="p-2 mt-2">
      <ng-container *ngIf="environment.config.showThemes">
        <h5>{{'general.configSidebar.themes' | translate}}</h5>
        <div class="flex flex-wrap row-gap-3">
          <div class="w-3" *ngFor="let theme of componentThemes">
            <button
              type="button"
              class="cursor-pointer p-link w-2rem h-2rem border-circle flex-shrink-0"
              (click)="changeTheme(theme.name)"
              [ngStyle]="{'background-color': theme.color}"></button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!minimal">
        <ng-container *ngIf="environment.config.showMenuType">
          <h5>{{'general.configSidebar.menuType.header' | translate}}</h5>
          <div class="field-radiobutton">
            <p-radioButton
              name="menuMode"
              value="static"
              [(ngModel)]="menuMode"
              inputId="mode1"></p-radioButton>
            <label for="mode1"
              >{{'general.configSidebar.menuType.static' | translate}}</label
            >
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              name="menuMode"
              value="overlay"
              [(ngModel)]="menuMode"
              inputId="mode2"></p-radioButton>
            <label for="mode2"
              >{{'general.configSidebar.menuType.overlay' | translate}}</label
            >
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              name="menuMode"
              value="slim"
              [(ngModel)]="menuMode"
              inputId="mode3"></p-radioButton>
            <label for="mode3"
              >{{'general.configSidebar.menuType.slim' | translate}}</label
            >
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              name="menuMode"
              value="horizontal"
              [(ngModel)]="menuMode"
              inputId="mode4"></p-radioButton>
            <label for="mode4"
              >{{'general.configSidebar.menuType.horizontal' |
              translate}}</label
            >
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              name="menuMode"
              value="reveal"
              [(ngModel)]="menuMode"
              inputId="mode5"></p-radioButton>
            <label for="mode5"
              >{{'general.configSidebar.menuType.reveal' | translate}}</label
            >
          </div>
        </ng-container>
        <ng-container *ngIf="environment.config.showMenuTheme">
          <h5>{{'general.configSidebar.menuTheme.header' | translate}}</h5>
          <div class="field-radiobutton">
            <p-radioButton
              name="menuTheme"
              value="colorScheme"
              [(ngModel)]="menuTheme"
              inputId="menutheme-colorscheme"></p-radioButton>
            <label for="menutheme-colorscheme"
              >{{'general.configSidebar.menuTheme.colorScheme' |
              translate}}</label
            >
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              name="menuTheme"
              value="primaryColor"
              [(ngModel)]="menuTheme"
              inputId="menutheme-primarycolor"></p-radioButton>
            <label for="menutheme-primarycolor"
              >{{'general.configSidebar.menuTheme.primary' | translate}}</label
            >
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              name="menuTheme"
              value="transparent"
              [(ngModel)]="menuTheme"
              inputId="menutheme-transparent"></p-radioButton>
            <label for="menutheme-transparent"
              >{{'general.configSidebar.menuTheme.transparent' |
              translate}}</label
            >
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="environment.config.showColorScheme">
        <h5>{{'general.configSidebar.colorScheme.header' | translate}}</h5>
        <div class="field-radiobutton">
          <p-radioButton
            name="colorScheme"
            value="light"
            [(ngModel)]="colorScheme"
            inputId="mode-light"></p-radioButton>
          <label for="mode-light"
            >{{'general.configSidebar.colorScheme.light' | translate}}</label
          >
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            name="colorScheme"
            value="dim"
            [(ngModel)]="colorScheme"
            inputId="mode-dim"></p-radioButton>
          <label for="mode-dim"
            >{{'general.configSidebar.colorScheme.dim' | translate}}</label
          >
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            name="colorScheme"
            value="dark"
            [(ngModel)]="colorScheme"
            inputId="mode-dark"></p-radioButton>
          <label for="mode-dark"
            >{{'general.configSidebar.colorScheme.dark' | translate}}</label
          >
        </div>
      </ng-container>
      <ng-container *ngIf="!minimal">
        <ng-container *ngIf="environment.config.showInputStyle">
          <h5>{{'general.configSidebar.inputStyle.header' | translate}}</h5>
          <div class="flex">
            <div class="field-radiobutton flex-1">
              <p-radioButton
                name="inputStyle"
                value="outlined"
                [(ngModel)]="inputStyle"
                inputId="outlined_input"></p-radioButton>
              <label for="outlined_input"
                >{{'general.configSidebar.inputStyle.outlined' |
                translate}}</label
              >
            </div>
            <div class="field-radiobutton flex-1">
              <p-radioButton
                name="inputStyle"
                value="filled"
                [(ngModel)]="inputStyle"
                inputId="filled_input"></p-radioButton>
              <label for="filled_input"
                >{{'general.configSidebar.inputStyle.filled' |
                translate}}</label
              >
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="environment.config.showRippleEffect">
          <h5>{{'general.configSidebar.ripple' | translate}}</h5>
          <p-inputSwitch [(ngModel)]="ripple"></p-inputSwitch>
        </ng-container>
        <ng-container *ngIf="environment.config.showScale">
          <h5>{{'general.configSidebar.scale' | translate}}</h5>
          <div class="flex align-items-center">
            <button
              icon="pi pi-minus"
              type="button"
              pButton
              (click)="decrementScale()"
              class="p-button-text p-button-rounded w-2rem h-2rem mr-2"
              [disabled]="scale === scales[0]"></button>
            <div class="flex gap-2 align-items-center">
              <i
                class="pi pi-circle-fill text-300"
                *ngFor="let s of scales"
                [ngClass]="{'text-primary-500': s === scale}"></i>
            </div>
            <button
              icon="pi pi-plus"
              type="button"
              pButton
              (click)="incrementScale()"
              class="p-button-text p-button-rounded w-2rem h-2rem ml-2"
              [disabled]="scale === scales[scales.length - 1]"></button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</p-sidebar>
