<ng-container *ngIf="user" class="px-8">
  <div class="text-center">
    <div class="flex align-items-center justify-content-center">
      <p-avatar
        *ngIf="hasAvatar"
        [image]="userAvatarUrl"
        shape="circle"
        class="user-avatar"
        [style]="{ 'background-color': 'transparent' }"></p-avatar>
      <p-avatar
        *ngIf="!hasAvatar"
        [label]="userAvatarLabel"
        class="user-avatar"
        shape="circle"></p-avatar>
    </div>

    <div>
      <h4>{{user.firstname}} {{user.lastname}}</h4>
    </div>
  </div>

  <hr />

  <div class="flex flex-column gap-1 pb-2">
    <small for="email">{{'profile.email' | translate}}</small>
    <input pInputText id="email" name="email" [value]="user.email" disabled />
  </div>

  <div class="flex flex-column gap-1 pb-2">
    <small for="role">{{'profile.role' | translate}}</small>
    <input
      *ngIf="user.role"
      pInputText
      id="role"
      name="role"
      disabled
      [value]="('userManagementComponent.table.columns.role.roles.' + user.role )| translate" />
  </div>

  <div class="flex flex-column gap-1 pb-2">
    <small for="username">{{'profile.username' | translate}}</small>
    <input
      pInputText
      id="username"
      name="username"
      [ngModel]="user.username"
      disabled />
  </div>

  <div class="flex flex-column gap-1 pb-2">
    <small for="firstname">{{'profile.firstname' | translate}}</small>
    <input
      pInputText
      id="firstname"
      name="firstname"
      [ngModel]="user.firstname"
      disabled />
  </div>

  <div class="flex flex-column gap-1 pb-2">
    <small for="lastname">{{'profile.lastname' | translate}}</small>
    <input
      pInputText
      id="lastname"
      name="lastname"
      [ngModel]="user.lastname"
      disabled />
  </div>
</ng-container>
