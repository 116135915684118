<div class="user-avatar" (click)="userOverlay.toggle($event)">
  <p-avatar
    *ngIf="hasAvatar"
    [image]="userAvatarUrl"
    shape="circle"
    [style]="{ 'background-color': 'transparent' }"></p-avatar>
  <p-avatar
    *ngIf="!hasAvatar"
    [label]="userAvatarLabel"
    shape="circle"></p-avatar>
</div>

<p-overlayPanel #userOverlay>
  <app-user-profile-view
    *ngIf="userOverlay.overlayVisible"
    [user]="user"></app-user-profile-view>
</p-overlayPanel>
