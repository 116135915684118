<div class="flex gap-2">
  <div>
    <app-files
      #filesComponent
      [showFilterBar]="true"
      [filesList]="files"
      [delegatePreview]="true"
      context="tickets"
      [showUploadBtn]="$can('update') && ticketId !== 0"
      [showRemoveBtn]="$can('delete')"
      [showCustomButton]="$can('update') && ticket.customer ? true : false"
      [showUploadComponent]="$can('update')"
      [customButtonIcon]="'pi pi-link'"
      (customUploadButtonClicked)="handleCustomerDocumentsLinkClicked()"
      (upload)="handleDeviceUpload($event)"
      (choose)="handleFileChoose($event)"
      (fileRemoved)="handleFileRemove($event)"
      (preview)="handlePreview($event)"></app-files>
  </div>
  <div
    class="flex align-items-center justify-content-center max-w-full w-full preview-container">
    <ng-container [ngSwitch]="true">
      <div *ngIf="filePreview?.file?.previewUrl" class="preview-closeable">
        <button
          pButton
          pRipple
          icon="pi pi-times"
          (click)="dismissPreview()"></button>
      </div>
      <ng-container *ngSwitchCase="filePreview === null">
        <!-- Display nothing -->
      </ng-container>

      <ng-container *ngSwitchCase="filePreview?.type === 'image'">
        <img
          [src]="filePreview?.file?.previewUrl"
          alt="preview"
          class="max-w-full" />
      </ng-container>

      <ng-container *ngSwitchCase="filePreview?.type === 'pdf'">
        <ngx-doc-viewer
          *ngIf="filePreview?.file?.previewUrl"
          [url]="filePreview!.file.previewUrl!"
          [viewer]="'pdf'"
          style="width: 100%; height: 100vh"></ngx-doc-viewer>
      </ng-container>

      <ng-container *ngSwitchCase="filePreview?.type === 'msg'">
        <app-msg-viewer
          [file]="filePreview!.file"
          (modalHidden)="filePreview = null"></app-msg-viewer>
      </ng-container>
    </ng-container>
  </div>
</div>

<p-dialog
  *ngIf="ticket.customer"
  [draggable]="false"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [(visible)]="customerDocumentsModalVisible"
  (onHide)="hideCustomerModal()"
  [modal]="true"
  [style]="{ width: '75%' }">
  <!-- Dialog Header -->
  <ng-template pTemplate="header">
    <h2>
      {{'general.form.chooseSubject'| translate: {subject:
      ('ticketComponent.attributes.customerDocuments' | translate)} }}
    </h2>
  </ng-template>

  <app-customer-edit-documents
    *ngIf="customerDocumentsModalVisible"
    [customer]="ticket.customer"
    (selectedFilesChange)="fileSelectionChanged($event)"
    selectionMode="multiple"
    [delegatePreview]="false"
    [(selectedFiles)]="selectedCustomerFiles"></app-customer-edit-documents>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="text-left">
        <button
          pButton
          pRipple
          type="button"
          [label]="'general.form.cancel' | translate"
          (click)="hideCustomerModal()"
          class="flex-auto p-button-outlined"></button>
      </div>
      <div
        class="text-right"
        *ngIf="ticket.customer && customerDocumentsModalVisible">
        <button
          pButton
          pRipple
          type="button"
          [label]="'general.form.take' | translate"
          (click)="takeCustomerDocuments()"
          class="flex-auto"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>
