<div class="card-body card-relative">
  <p-fileUpload
    #uploader
    name="files"
    (onProgress)="load()"
    (onSelect)="onSelect($event)"
    (onBeforeUpload)="uploadHandler($event)"
    [multiple]="true"
    [showUploadButton]="showUploadButton"
    [showCancelButton]="showCancelButton"
    [accept]="allowedFileTypes"
    [maxFileSize]="maxFileSize">
    <ng-template
      pTemplate="header"
      let-files
      let-chooseCallback="chooseCallback"
      let-clearCallback="clearCallback"
      let-uploadCallback="uploadCallback">
      <div
        class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
        <div class="flex gap-2">
          <p-button
            (onClick)="onChoose($event, chooseCallback)"
            icon="pi pi-plus"
            iconPos="left"
            [label]="'primeng.choose' | translate" />
          <p-button
            *ngIf="showUploadButton"
            (onClick)="uploadHandler(uploadCallback)"
            icon="pi pi-upload"
            iconPos="left"
            [label]="'primeng.upload' | translate"
            [disabled]="!files || files.length === 0" />
          <p-button
            *ngIf="showCancelButton"
            (onClick)="clearCallback()"
            icon="pi pi-times"
            iconPos="left"
            [label]="'primeng.cancel' | translate"
            [disabled]="!files || files.length === 0" />
          <ng-content> </ng-content>
        </div>
      </div>
    </ng-template>
    <ng-template let-file pTemplate="file">
      <div class="file-container flex gap-2 mb-2">
        <div class="file-icon">
          <!-- PDF File Icon -->
          <i
            *ngIf="file.type === FileTypes.PDF"
            class="pi pi-file-pdf"
            style="font-size: 2rem"></i>

          <!-- Word File Icon -->
          <i
            *ngIf="file.type === FileTypes.DOC || file.type === FileTypes.DOCX"
            class="pi pi-file-word"
            style="font-size: 2rem"></i>

          <!-- Excel File Icon -->
          <i
            *ngIf="file.type === FileTypes.XLS || file.type === FileTypes.XLSX"
            class="pi pi-file-excel"
            style="font-size: 2rem"></i>

          <!-- Image File Icon -->
          <i
            *ngIf="file.type === FileTypes.JPG ||
              file.type === FileTypes.PNG ||
              file.type === FileTypes.GIF ||
              file.type === FileTypes.SVG"
            class="pi pi-image"
            style="font-size: 2rem"></i>

          <!-- Default File Icon -->
          <i
            *ngIf="![
              FileTypes.PDF,
              FileTypes.DOC ,
              FileTypes.DOCX,
              FileTypes.XLS,
              FileTypes.XLSX,
              FileTypes.JPG,
              FileTypes.PNG,
              FileTypes.GIF,
              FileTypes.SVG
            ].includes(file.type)"
            class="pi pi-file"
            style="font-size: 2rem"></i>
        </div>
        <div class="file-name">{{ file.name }}</div>
        <div class="file-size">{{file.size | fileSize}}</div>
        <div class="remove-file">
          <i
            class="pi pi-trash icon"
            [pTooltip]="'general.form.remove' | translate"
            tooltipPosition="left"
            (click)="removeFile(file, uploader)"></i>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="content" let-files *ngIf="showDragAndDropArea">
      <div
        [ngClass]="size === 'small' ? '': 'p-8'"
        class="dropArea text-center"
        *ngIf="files.length === 0">
        <div class="title">
          <h5 class="text-primary">{{ titleTranslationKey | translate}}</h5>
        </div>
        <div class="subtitle">
          <p>{{subtitleTranslationKey | translate}}</p>
        </div>
      </div>
    </ng-template>
  </p-fileUpload>
  <div *ngIf="showSpinner" class="progress">
    <div class="spinner-background"></div>
    <div class="progress-content">
      <div class="text-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </div>
  </div>
</div>
