<div class="flex justify-content-between">
  <div>
    <div class="text-2xl flex gap-2">
      <span *ngIf="ticket.ticketNumber">{{ticket.ticketNumber}}</span
      ><span *ngIf="!ticket.ticketNumber"
        >{{'ticketComponent.newTicket' | translate}}</span
      ><span *ngIf="ticket.subject">|</span><span>{{ticket.subject}}</span>
    </div>
    <div class="flex gap-2 mt-4">
      <div>
        <app-ticket-chip
          *ngIf="ticket.ticketCategoryType"
          [label]="ticket.ticketCategoryType"></app-ticket-chip>
      </div>
      <div>
        <app-ticket-chip
          *ngIf="ticket.ticketStatusType"
          [label]="ticket.ticketStatusType"></app-ticket-chip>
      </div>
    </div>
  </div>

  <div class="flex flex-column align-items-end gap-3">
    <div class="flex align-items-end">
      <a
        class="p-button p-button-outlined"
        style="min-width: 12rem"
        (click)="returnToOverviewClicked()">
        {{'general.back' | translate}}
      </a>
    </div>

    <app-logging
      *ngIf="ticket.id"
      #loggingComponent
      [identifier]="ticket.id"
      [model]="'Ticket'"
      [config]="config"
      [formatter]="loggingFormatter" />
  </div>
</div>
