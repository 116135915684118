import { AppSubject } from 'src/config/authorization.config';
import { environment } from 'src/environments/environment';
import { GenericTableSize } from '../types/misc/GenericTableTypes/GenericTableSize';
import { RowSelectionType } from '../types/misc/GenericTableTypes/RowSelectionTypes';
import { GenericActionConfiguration } from './GenericActionConfiguration';
import { GenericColumnConfiguration } from './GenericColumnConfiguration';
import { GenericRowActionConfiguration } from './GenericRowActionConfiguration';

export class GenericTableConfiguration {
  table!: string;

  size?: GenericTableSize = 'md';

  defaultSortField?: string;

  defaultSortOrder: 1 | -1 = 1;

  showGridLines?: boolean;

  showStripedRows?: boolean;

  showGlobalFilter?: boolean;

  rowsPerPageOptions?: number[];

  initialRowsCount?: number;

  translationKey!: string;

  columns!: GenericColumnConfiguration[];

  rowActions!: GenericRowActionConfiguration[];

  rowActionWidthRem?: number;

  topRightAction?: GenericActionConfiguration;

  bottomRightAction?: GenericActionConfiguration;

  bottomLeftAction?: GenericActionConfiguration;

  showCsvExportAction?: boolean;

  showExcelExportAction?: boolean;

  showPdfExportAction?: boolean;

  rowSelectionMode?: RowSelectionType;

  showSummary?: boolean;

  lazyLoad!: boolean;

  useBuiltInService!: boolean;

  showAsCard!: boolean;

  showFilterConfiguration!: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableStyle: any;

  fileDownloadContext?: string;

  permissionSubject?: AppSubject;

  constructor(config?: Partial<GenericTableConfiguration>) {
    Object.assign(this, config);

    if (!this.rowsPerPageOptions) {
      this.rowsPerPageOptions =
        environment.tableConfiguration.rowsPerPageOptions;
    }

    if (!this.initialRowsCount) {
      // eslint-disable-next-line prefer-destructuring
      this.initialRowsCount = this.rowsPerPageOptions[0];
    }

    this.showGridLines = this.showGridLines ?? false;

    this.showStripedRows = this.showStripedRows ?? false;

    this.showGlobalFilter = this.showGlobalFilter ?? true;

    this.showSummary = this.showSummary ?? false;

    this.lazyLoad = this.lazyLoad ?? false;

    this.rowActions = this.rowActions ?? [];

    this.useBuiltInService = this.useBuiltInService ?? true;

    this.showAsCard = this.showAsCard ?? true;

    this.tableStyle = this.tableStyle ?? { 'min-width': '50rem' };

    this.showFilterConfiguration = this.showFilterConfiguration ?? true;
  }
}
