import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Table } from 'primeng/table';
import { first, map, Observable } from 'rxjs';

import { FilterComponent } from 'src/app/components/misc/generic-table/filter/filter.component';
import { FormType, HiveForm } from 'src/app/models/HiveForm';
import { TableFilter } from 'src/app/models/TableFilter';
import { Ticket } from 'src/app/models/Ticket';
import { PdfService } from 'src/app/services/api/pdf.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-table',
  templateUrl: './form-table.component.html',
  styleUrls: ['./form-table.component.scss']
})
export class FormTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('formTable') public formTable?: Table;

  @ViewChild('appFilter') appFilter?: FilterComponent;

  @Input({ required: true }) ticket!: Ticket;

  @Input() showTechnicianColumn = true;

  @Input() showCreatedAtColumn = false;

  @Input() showStatusColumn = true;

  @Input() showCompletedAtColumn = true;

  @Input() deadlineEditable = false;

  @Input() showEditAction = true;

  @Input() showPdfAction = true;

  @Input() showDeleteAction = true;

  @Input() showCreateAction = true;

  @Input() showGlobalSearch = true;

  @Input() selectionDisabled = true;

  @Input() showSelectedFormsOnly = false;

  @Input() smallTable = false;

  @Input() isSelectable = false;

  @Input() selectedForms: HiveForm[] = [];

  @Input() currentAppointmentId: number | null = null;

  @Input() showTechnicianCalendar = true;

  @Output() handleCreateForm = new EventEmitter<void>();

  @Output() handleUpdateForm = new EventEmitter<HiveForm>();

  @Output() handleDeleteForm = new EventEmitter<HiveForm>();

  @Output() selectedFormsChange = new EventEmitter<HiveForm[]>();

  @Output() deadlineChange = new EventEmitter<HiveForm>();

  forms: HiveForm[] = [];

  isLoading = false;

  tableConfig = environment.tableConfiguration;

  apiUrl = environment.apiUrl;

  isFilterApplied = false;

  activeFilter: number | null = null;

  displayFilterOverlay = false;

  formTypes = Object.values(FormType);

  filterExpanded = true;

  /**
   * URL to the file that should be viewed in the modal
   */
  fileToView: string | null = null;

  /**
   * Whether the file modal is visible
   */
  isFileModalVisible = false;

  /**
   * Cache for storing file URLs to prevent multiple downloads
   */
  fileCache: Map<number, string> = new Map();

  statuses = [
    {
      label: this.translate.instant('formComponent.statuses.completed'),
      value: true
    },
    {
      label: this.translate.instant('formComponent.statuses.open'),
      value: false
    }
  ];

  constructor(
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly pdfService: PdfService
  ) {
    this.route.url.subscribe((url) => {
      url.forEach((segment) => {
        if (segment.path === 'ticket') {
          this.showTechnicianColumn = true;
        }
      });
    });
  }

  ngOnInit() {
    this.filterExpanded = !this.smallTable;

    if (this.ticket.forms && !this.showSelectedFormsOnly) {
      this.forms = this.ticket.forms;
    }

    if (this.ticket.forms && this.selectedForms && this.showSelectedFormsOnly) {
      this.forms = this.selectedForms;
    }

    this.forms = this.forms.map((form) => ({
      ...form,
      key: this.getKey(form),
      showTechnicianCalendar: this.showCalendar(form),
      status:
        form.installationIdmLuftFormId !== null ||
        form.installationIdmSwFormId !== null ||
        form.sgcMaintenanceIdmFormId !== null ||
        form.serviceReportFormId !== null
    }));
  }

  getKey(form: HiveForm): string {
    if (form.id) {
      return form.id.toString();
    }

    return `${form.id || 'new'}-${form.customerDevice?.device?.title || 'unknown'}-${form.type || 'unknown'}-${form.createdAt || 'unknown'}`;
  }

  ngOnDestroy(): void {
    // Revoke all object URLs to prevent memory leaks
    this.fileCache.forEach((url) => {
      URL.revokeObjectURL(url);
    });
    this.fileCache.clear();
  }

  showCalendar(form: HiveForm): string | null {
    let retVal = '';
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    if (form.appointments && form.appointments.length > 0) {
      form.appointments.forEach((appointment) => {
        if (appointment.id !== Number(this.currentAppointmentId)) {
          const startDate = new Date(appointment.start).toLocaleString(
            'de-DE',
            options
          );

          retVal += `${startDate} - ${appointment.title}\n`;
        }
      });
    }

    return retVal;
  }

  displayDate(date: Date | string | null | undefined): string {
    if (!date) {
      return '';
    }

    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedForms']) {
      this.selectedForms = this.selectedForms.map((form) => ({
        ...form,
        key: this.getKey(form),
        showTechnicianCalendar: this.showCalendar(form)
      }));
    }
    if (changes['showSelectedFormsOnly']) {
      if (this.ticket.forms && !this.showSelectedFormsOnly) {
        this.forms = this.ticket.forms;
        this.forms = this.forms.map((form) => ({
          ...form,
          key: this.getKey(form),
          showTechnicianCalendar: this.showCalendar(form)
        }));
      }

      if (
        this.ticket.forms &&
        this.selectedForms &&
        this.showSelectedFormsOnly
      ) {
        this.forms = this.selectedForms;
        this.forms = this.forms.map((form) => ({
          ...form,
          key: this.getKey(form),
          showTechnicianCalendar: this.showCalendar(form)
        }));
      }
    }
  }

  createForm(): void {
    this.handleCreateForm.emit();
  }

  async editForm(form: HiveForm): Promise<void> {
    if (form.deadline) {
      form.deadline = new Date(form.deadline);
    }
    const now = new Date();
    if (form.appointments && form.appointments.length > 0) {
      const appointmentStart = new Date(form.appointments[0]?.start);

      if (appointmentStart <= now) {
        const update = await new Promise<boolean>((resolve) => {
          this.messageCenterService.confirm(
            this.translate.instant(
              'calendarComponent.appointment.editAppointment.editWarning.title'
            ),
            this.translate.instant(
              'calendarComponent.appointment.editAppointment.editWarning.summary'
            ),
            () => {
              resolve(true);
            },
            () => {
              resolve(false);
            }
          );
        });
        if (update) {
          this.handleUpdateForm.emit(form);
        }
      } else {
        this.handleUpdateForm.emit(form);
      }
    } else {
      this.handleUpdateForm.emit(form);
    }
  }

  private fetchPdf(formId: number): Observable<string> {
    const cached = this.fileCache.get(formId);

    if (cached) {
      return new Observable((observer) => {
        observer.next(cached);
        observer.complete();
      });
    }

    return this.pdfService.downloadFormPdf(formId).pipe(
      first(),
      map((data: ArrayBuffer) => {
        const blob = new Blob([data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        this.fileCache.set(formId, url);

        return url;
      })
    );
  }

  /**
   * Downloads the PDF of the given form
   */
  downloadPdf(form: HiveForm): void {
    if (!form.id) {
      return;
    }

    const ticketType = this.translate.instant(
      `ticketComponent.ticketChips.${this.ticket.ticketCategoryType}`
    );
    const manufacturer = form.customerDevice?.device?.manufacturer?.name || '';
    const fileName = `${ticketType}`;
    const customerName = this.ticket.customer?.name || '';
    const workDate =
      moment(form.appointments?.[0]?.end).format('DDMMYYYY') ||
      moment(new Date()).format('DDMMYYYY');

    // downloadUrl function to download the PDF
    const downloadUrl = (url: string) => {
      const a = document.createElement('a');
      a.href = url;
      a.download = `${workDate}_${fileName}_${manufacturer}_${customerName}_${form.type}_${form.id}_ticket_${this.ticket.ticketNumber}.pdf`;
      a.click();
    };

    // Download the PDF and cache the URL
    this.fetchPdf(form.id).subscribe((url: string) => {
      downloadUrl(url);
    });
  }

  /**
   * Opens the PDF viewer for the given form
   */
  viewPdf(form: HiveForm): void {
    if (!form.id) {
      return;
    }

    // Show the modal
    this.isFileModalVisible = true;

    // Download the PDF and cache the URL
    this.fetchPdf(form.id).subscribe((url: string) => {
      this.fileToView = url;
    });
  }

  hidePdfViewer(): void {
    if (this.fileToView) {
      this.fileToView = null;
    }

    this.isFileModalVisible = false;
  }

  async deleteForm(form: HiveForm): Promise<void> {
    const deleteForm = await this.deleteConfirmation(form);
    if (deleteForm) {
      this.forms = this.forms.filter(
        (formToDelete) => formToDelete.id !== form.id
      );
      this.handleDeleteForm.emit(form);
    }
  }

  deleteConfirmation(form: HiveForm): Promise<boolean> {
    let formTypeTranslation = this.translate.instant(
      `formComponent.attributes.formTypes.${form.type}`
    );

    formTypeTranslation =
      formTypeTranslation && formTypeTranslation.length > 0
        ? formTypeTranslation
        : '';

    let device = form.customerDevice?.device?.title;
    device = device && device.length > 0 ? device : '';

    return new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant('formComponent.form.delete.header'),
        this.translate.instant('formComponent.form.delete.message', {
          device,
          form: formTypeTranslation
        }),
        () => resolve(true),
        () => resolve(false)
      );
    });
  }

  filterApplied(filter: TableFilter): void {
    this.activeFilter = filter.id;

    if (this.formTable) {
      this.formTable.filters = structuredClone(
        filter.filterData
      ) as Table['filters'];
      this.formTable._filter();
    }
  }

  clearFilters(): void {
    if (this.formTable) {
      this.formTable.clearFilterValues();
      this.formTable.reset();
      localStorage.removeItem('form-table-memory');
    }

    this.activeFilter = null;
  }

  saveFilterClicked(filterName: string): void {
    if (!this.formTable) {
      throw new Error('Table element not found');
    }

    const tableFilter = new TableFilter();

    tableFilter.filterData = structuredClone(this.formTable?.filters);
    tableFilter.table = 'ticket';
    tableFilter.filterName = filterName;

    this.appFilter?.createFilter(tableFilter);
  }

  onSelectedFormsChange(event: HiveForm[]): void {
    this.selectedForms = event;
    this.selectedFormsChange.emit(this.selectedForms);
  }

  onDeadlineChange(deadline: Date, form: HiveForm): void {
    form.deadline = deadline;
    this.deadlineChange.emit(form);
  }
}
