import { GridsterItem } from 'angular-gridster2';

export enum WidgetComponent {
  TicketCommissioning = 'app-widget-ticket-commissioning',
  TicketEmergency = 'app-widget-ticket-emergency',
  TicketMaintenance = 'app-widget-ticket-maintenance',
  TicketTask = 'app-widget-ticket-task',
  TicketsAll = 'app-widget-tickets-all',
  TicketMalfunction = 'app-widget-ticket-malfunction',
  Empty = 'app-widget-empty',
  Ticket = 'app-tickets'
}

export type Widget = {
  id?: number;
  item: GridsterItem;
  component: WidgetComponent;
};
