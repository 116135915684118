/* eslint-disable max-lines */

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { Observable, first, of } from 'rxjs';

import { Country } from 'src/app/models/Country';
import {
  Customer,
  CustomerUpdate,
  FacilityAddressType
} from 'src/app/models/customer/Customer';
import {
  CustomerService,
  FindFacilityAddressResponse
} from 'src/app/services/api/customer/customer.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormChangeDetectionService } from 'src/app/services/form-change-detection.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { AppAction } from 'src/config/authorization.config';

type Option = {
  value: string;
  label: string;
};

const DEFAULT_STRING_VALIDATORS = [
  Validators.required,
  Validators.minLength(2),
  Validators.maxLength(255)
];

@Component({
  selector: 'app-customer-edit-base-data',
  templateUrl: './customer-edit-base-data.component.html',
  styleUrls: ['./customer-edit-base-data.component.scss']
})
export class CustomerEditBaseDataComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input({ required: true }) customer!: Customer;

  @Input({ required: true }) countries: Country[] = [];

  @Output() handleUpdateCustomer: EventEmitter<CustomerUpdate> =
    new EventEmitter();

  formGroup: FormGroup | null = null;

  isBillingAddressDifferent = false;

  facilityAddressTypeOptions: Option[] = [];

  constructor(
    public readonly translate: TranslateService,
    private readonly authService: AuthService,
    private readonly customerService: CustomerService,
    public readonly messageCenterService: MessageCenterService,
    private readonly formChangeDetectionService: FormChangeDetectionService
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(this.customer.name, {
        validators: DEFAULT_STRING_VALIDATORS
      }),
      customerNumber: new FormControl(this.customer.customerNumber, {
        validators: DEFAULT_STRING_VALIDATORS
      }),
      company: new FormControl(this.customer.company),

      isActive: new FormControl(this.customer.isActive),
      isBlocked: new FormControl(this.customer.isBlocked),

      billingAddress: new FormGroup({
        street: new FormControl(this.customer.billingAddress.street, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        city: new FormControl(this.customer.billingAddress.city, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        postalCode: new FormControl(this.customer.billingAddress.postalCode, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        countryId: new FormControl(this.customer.billingAddress.countryId, {
          validators: [Validators.required]
        })
      }),

      billingAddressEmail: new FormControl(this.customer.billingAddressEmail, {
        validators: Validators.email
      }),
      billingRecipient: new FormControl(this.customer.billingRecipient),

      facilityAddress: new FormGroup({
        street: new FormControl(this.customer.facilityAddress.street, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        city: new FormControl(this.customer.facilityAddress.city, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        postalCode: new FormControl(this.customer.facilityAddress.postalCode, {
          validators: DEFAULT_STRING_VALIDATORS
        }),
        countryId: new FormControl(this.customer.facilityAddress.countryId, {
          validators: [Validators.required]
        })
      }),

      facilityAddressType: new FormControl(this.customer.facilityAddressType, {
        validators: DEFAULT_STRING_VALIDATORS
      })
    });

    this.formGroup.get('customerNumber')?.disable();

    this.facilityAddressTypeOptions = Object.entries(FacilityAddressType).map(
      ([key, value]) => ({
        value: key,
        label: this.translate.instant(
          `customerComponent.facilityAddressType.${value}`
        )
      })
    );

    this.isBillingAddressDifferent =
      this.customer.facilityAddress.id !== this.customer.billingAddress.id;

    if (this.$can('update') === false) {
      this.formGroup.disable();
    }

    this.formChangeDetectionService.addFormToFormArray(this.formGroup);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customer']) {
      this.ngOnInit();
    }
  }

  ngOnDestroy(): void {
    if (this.formGroup) {
      this.formChangeDetectionService.removeForm(this.formGroup);
    }
  }

  onChangeUseFacilityAddressAsBillingAddress(value: boolean): void {
    if (!this.formGroup) {
      return;
    }

    // @ts-expect-error Its not properly typed but the values are there
    const controls = this.formGroup.controls.billingAddress.controls as Record<
      string,
      AbstractControl
    >;

    const controlsBilling =
      // @ts-expect-error Its not properly typed but the values are there
      this.formGroup.controls.facilityAddress.controls as Record<
        string,
        AbstractControl
      >;

    if (value === false) {
      controls['street'].clearValidators();
      controls['city'].clearValidators();
      controls['postalCode'].clearValidators();
      controls['countryId'].clearValidators();

      this.formGroup.markAsDirty();
    } else {
      controls['street'].setValidators(DEFAULT_STRING_VALIDATORS);
      controls['city'].setValidators(DEFAULT_STRING_VALIDATORS);
      controls['postalCode'].setValidators(DEFAULT_STRING_VALIDATORS);
      controls['countryId'].setValidators(Validators.required);

      controls['street'].setValue(controlsBilling['street'].value);
      controls['city'].setValue(controlsBilling['city'].value);
      controls['postalCode'].setValue(controlsBilling['postalCode'].value);
      controls['countryId'].setValue(controlsBilling['countryId'].value);
    }

    controls['street'].updateValueAndValidity();
    controls['city'].updateValueAndValidity();
    controls['postalCode'].updateValueAndValidity();
    controls['countryId'].updateValueAndValidity();
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'Customer');
  }

  get isSubmitDisabled(): boolean {
    if (!this.formGroup) {
      return true;
    }

    return this.formGroup.invalid || this.formGroup.pristine;
  }

  get selectedBillingAddressCountry(): Country | undefined {
    if (!this.formGroup) {
      return undefined;
    }

    const countryId = this.formGroup.get('billingAddress.countryId')?.value;

    if (!countryId) {
      return undefined;
    }

    return this.countries.find((country) => country.id === countryId);
  }

  get selectedFacilityAddressCountry(): Country | undefined {
    if (!this.formGroup) {
      return undefined;
    }

    const countryId = this.formGroup.get('facilityAddress.countryId')?.value;

    if (!countryId) {
      return undefined;
    }

    return this.countries.find((country) => country.id === countryId);
  }

  onSubmit(): void {
    const customer = this.buildCustomer();

    this.canUseFacilityAddress(customer)
      .pipe(first())
      .subscribe((response) => {
        if (response.foundFacilityAddress) {
          this.alertFacilityAddressAlreadyUsed(response.customer);

          return;
        }

        this.handleUpdateCustomer.emit(customer);
      });
  }

  onChangeIsBlocked(event: InputSwitchChangeEvent): void {
    this.formGroup?.get('isBlocked')?.setValue(!event.checked);

    const translationContext = {
      name: this.customer.name,
      customerNumber: this.customer.customerNumber,
      action: this.translate.instant(
        `customerComponent.actions.toasts.isBlocked.actions.${event.checked ? 'block' : 'unblock'}`
      )
    } as const;

    this.messageCenterService.confirm(
      this.translate.instant(
        `customerComponent.actions.toasts.isBlocked.confirm.header`,
        translationContext
      ),
      this.translate.instant(
        `customerComponent.actions.toasts.isBlocked.confirm.message`,
        translationContext
      ),
      () => {
        this.formGroup?.get('isBlocked')?.setValue(event.checked);
      },
      () => {}
    );
  }

  onChangeIsActive(event: InputSwitchChangeEvent): void {
    this.formGroup?.get('isActive')?.setValue(!event.checked);

    const translationContext = {
      name: this.customer.name,
      customerNumber: this.customer.customerNumber,
      action: this.translate.instant(
        `customerComponent.actions.toasts.isActive.actions.${event.checked ? 'activate' : 'deactivate'}`
      )
    } as const;

    this.messageCenterService.confirm(
      this.translate.instant(
        `customerComponent.actions.toasts.isActive.confirm.header`,
        translationContext
      ),
      this.translate.instant(
        `customerComponent.actions.toasts.isActive.confirm.message`,
        translationContext
      ),
      () => {
        this.formGroup?.get('isActive')?.setValue(event.checked);
      },
      () => {}
    );
  }

  private alertFacilityAddressAlreadyUsed(byCustomer: Customer): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        'customerComponent.actions.toasts.facilityAddressAlreadyInUse.summary'
      ),
      this.translate.instant(
        'customerComponent.actions.toasts.facilityAddressAlreadyInUse.detail',
        {
          customerNumber: byCustomer.customerNumber,
          name: byCustomer.name
        }
      ),
      'warn'
    );
  }

  private canUseFacilityAddress(
    customer: CustomerUpdate
  ): Observable<FindFacilityAddressResponse> {
    if (
      !customer.facilityAddress ||
      !customer.facilityAddress.city ||
      !customer.facilityAddress.countryId ||
      !customer.facilityAddress.postalCode ||
      !customer.facilityAddress.street
    ) {
      return of({ foundFacilityAddress: false });
    }

    return this.customerService.findFacilityAddress(
      {
        city: customer.facilityAddress.city ?? '',
        countryId: customer.facilityAddress.countryId ?? 0,
        postalCode: customer.facilityAddress.postalCode ?? '',
        street: customer.facilityAddress.street ?? ''
      },
      this.customer.id
    );
  }

  private buildCustomer(): CustomerUpdate {
    const facilityAddress = {
      street: this.formGroup?.get('facilityAddress.street')?.value,
      city: this.formGroup?.get('facilityAddress.city')?.value,
      postalCode: this.formGroup?.get('facilityAddress.postalCode')?.value,
      countryId: this.formGroup?.get('facilityAddress.countryId')?.value
    };

    return {
      name: this.formGroup?.get('name')?.value,
      customerNumber: this.formGroup?.get('customerNumber')?.value,
      company: this.formGroup?.get('company')?.value,

      isActive: this.formGroup?.get('isActive')?.value,
      isBlocked: this.formGroup?.get('isBlocked')?.value,

      facilityAddress: {
        street: this.formGroup?.get('facilityAddress.street')?.value,
        city: this.formGroup?.get('facilityAddress.city')?.value,
        postalCode: this.formGroup?.get('facilityAddress.postalCode')?.value,
        countryId: this.formGroup?.get('facilityAddress.countryId')?.value
      },
      facilityAddressType: this.formGroup?.get('facilityAddressType')?.value,

      billingAddress: this.isBillingAddressDifferent
        ? {
            street: this.formGroup?.get('billingAddress.street')?.value,
            city: this.formGroup?.get('billingAddress.city')?.value,
            postalCode: this.formGroup?.get('billingAddress.postalCode')?.value,
            countryId: this.formGroup?.get('billingAddress.countryId')?.value
          }
        : facilityAddress,
      billingAddressEmail: this.formGroup?.get('billingAddressEmail')?.value,
      billingRecipient: this.formGroup?.get('billingRecipient')?.value
    };
  }
}
