<img
  src="../../../assets/images/login.png"
  class="fixed left-0 top-0 background" />

<div
  [ngClass]="[
        'min-h-screen flex',
        style === 'right'
          ? 'pl-5 justify-content-end align-items-right'
          : style === 'left'
          ? 'pr-5 justify-content-start align-items-left'
          : 'px-5 justify-content-center align-items-center'
      ]">
  <div
    [ngClass]="[
          'border-1 surface-border surface-card py-7 px-4 md:px-7 z-1 side-panel-width',
          style === 'right' || style === 'left' ? '' : 'border-round'
        ]">
    <div class="logo">
      <img
        class="hive-logo max-w-20rem"
        src="../../../assets/images/logo.png"
        alt="logo" />
      <img
        class="hive-logo-dark max-w-20rem"
        src="../../../assets/images/logo-dark.png"
        alt="logo" />
    </div>
    <div
      [ngClass]="
            style === 'right' || style === 'left' ? 'mb-4 mt-4' : 'mb-4 mt-4'
          ">
      <ng-content select=".header-content"></ng-content>
    </div>
    <ng-content select=".body-content"></ng-content>
  </div>
</div>
