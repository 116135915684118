import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, catchError } from 'rxjs';

import { Ticket, TicketCreate } from 'src/app/models/Ticket';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { environment } from 'src/environments/environment';

import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService extends GenericService<Ticket> {
  private ticketSource = new BehaviorSubject<Ticket | TicketCreate | null>(
    null
  );

  currentTicket = this.ticketSource.asObservable();

  constructor(
    http: HttpClient,
    messageCenterService: MessageCenterService,
    translate: TranslateService,
    private internalHttp: HttpClient
  ) {
    super(http, messageCenterService, translate);
    this.setContext('tickets');
  }

  changeTicket(ticket: Ticket | TicketCreate | null) {
    this.ticketSource.next(ticket);
  }

  /**
   * Creates a new ticket.
   * @returns An Observable that emits the created ticket.
   */
  public override create(ticket: TicketCreate): Observable<Ticket> {
    return this.internalHttp.post<Ticket>(
      `${environment.apiUrl}/tickets`,
      ticket
    );
  }

  /**
   * Retrieves a ticket by ID.
   *
   * @param id - The ID of the ticket to retrieve.
   * @returns An Observable that emits the ticket.
   */
  public findById(id: number): Observable<Ticket> {
    return this.internalHttp
      .get<Ticket>(`${environment.apiUrl}/tickets/${id}`)
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * Retrieves a ticket by ID.
   *
   * @param id - The ID of the ticket to retrieve.
   * @returns An Observable that emits the ticket.
   */
  public findAllByCustomerId(customerId: number): Observable<Ticket[]> {
    return this.internalHttp
      .get<
        Ticket[]
      >(`${environment.apiUrl}/tickets/find-by-customer-id/${customerId}`)
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * Uploads files for a ticket.
   *
   * @param ticketId - The ID of the ticket.
   * @param formData - The form data containing the files.
   * @returns An Observable that emits the updated ticket.
   */
  public uploadFiles(ticketId: number, formData: FormData): Observable<Ticket> {
    return this.internalHttp.post<Ticket>(
      `${environment.apiUrl}/tickets/${ticketId}/file`,
      formData
    );
  }

  /**
   * Deletes a file for a ticket.
   *
   * @param ticketId - The ID of the ticket.
   * @param fileId - The ID of the file to delete.
   * @returns An Observable that emits the updated ticket.
   */
  public deleteFile(ticketId: number, fileId: number): Observable<Ticket> {
    return this.internalHttp.delete<Ticket>(
      `${environment.apiUrl}/tickets/${ticketId}/file/${fileId}`
    );
  }
}
