import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { Subscription } from 'rxjs';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { ActionClickedResponse } from 'src/app/models/ActionClickedResponse';
import { CellChangedResponse } from 'src/app/models/CellChangedResponse';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { LazyListDto } from 'src/app/models/LazyListDto';
import { TableFilter } from 'src/app/models/TableFilter';
import { TableFilterService } from 'src/app/services/api/filter.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { DataService } from 'src/app/services/utils/data.service';
import { EditMethod } from 'src/app/types/misc/EditMethod';
import { Severity } from 'src/app/types/misc/Severity';
import { filterTableConfig } from './filter.config';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class FilterComponent implements OnInit, OnDestroy {
  config!: GenericTableConfiguration;

  filterList: TableFilter[] = [];

  totalRecords!: number;

  editFilter?: TableFilter | null;

  editMethod: EditMethod = 'page';

  createMethod: EditMethod = 'page';

  sidebarVisible = false;

  separatePageVisible = false;

  tableVisible = true;

  filterSubscription: Subscription = new Subscription();

  totalRecordsSubscription: Subscription = new Subscription();

  lazyLoadTableEvent?: TableLazyLoadEvent;

  @Input() table = '';

  @Output() filterApplied = new EventEmitter<TableFilter>();

  constructor(
    private messageCenterService: MessageCenterService,
    private translate: TranslateService,
    private filterService: TableFilterService,
    private dataService: DataService<TableFilter>
  ) {
    this.config = filterTableConfig();
  }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData() {
    this.filterSubscription = this.filterService
      .findAllByTable(this.table)
      .subscribe((filters: TableFilter[]) => {
        this.filterList = filters;
      });
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
    if (this.totalRecordsSubscription) {
      this.totalRecordsSubscription.unsubscribe();
    }
  }

  lazyLoadTableEventFired(event: TableLazyLoadEvent): void {
    if (this.config.lazyLoad) {
      this.lazyLoadTableEvent = event;
      this.filterSubscription = this.filterService
        .lazyLoad(event)
        .subscribe((lazyFilterListDto: LazyListDto<TableFilter>) => {
          this.filterList = lazyFilterListDto.objects;
          this.totalRecords = lazyFilterListDto.totalRecords;
        });
    }
  }

  filterChanged(filter: TableFilter): void {
    if (filter.id) {
      this.updateFilter(filter.id, filter);
    } else {
      this.createFilter(filter);
    }
  }

  createFilter(filter: TableFilter): void {
    this.filterService.create(filter).subscribe((newFilter) => {
      if (newFilter) {
        this.filterList = this.dataService.modifyList(
          this.filterList,
          newFilter,
          DataModificationMethod.Create
        );
        if (this.sidebarVisible) {
          this.sidebarVisible = !this.sidebarVisible;
        }
        this.showCrudToast(DataModificationMethod.Create, 'success');
      } else {
        this.showCrudToast(DataModificationMethod.Create, 'error');
      }
    });
  }

  applyFilter(filter: TableFilter) {
    this.filterApplied.emit(filter);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCellChanged(_response: CellChangedResponse): void {
    // ToDo: implement if needed
  }

  updateFilter(id: number, filter: TableFilter): void {
    this.filterService.edit(id, filter).subscribe((updatedFilter) => {
      if (updatedFilter) {
        this.filterList = this.dataService.modifyList(
          this.filterList,
          updatedFilter,
          DataModificationMethod.Edit
        );
        if (this.sidebarVisible) {
          this.sidebarVisible = !this.sidebarVisible;
        }
        this.showCrudToast(DataModificationMethod.Edit, 'success');
      } else {
        this.showCrudToast(DataModificationMethod.Edit, 'error');
      }
    });
  }

  onSelectionChanged(selectedFilters: TableFilter[]): void {
    // eslint-disable-next-line no-console
    console.log(selectedFilters);
  }

  rowActionClicked(response: ActionClickedResponse): void {
    switch (response.action.identifier) {
      case 'delete':
        this.delete(response.object);
        break;
      case 'applyFilter':
        this.applyFilter(response.object);
        break;
      default:
        break;
    }
  }

  edit(data: TableFilter): void {
    if (this.editMethod === 'sidebar') {
      this.sidebarVisible = this.editMethod === 'sidebar';
    } else {
      this.separatePageVisible = this.editMethod === 'page';
      this.tableVisible = false;
    }
    this.editFilter = data;
  }

  create(): void {
    if (this.createMethod === 'sidebar') {
      this.sidebarVisible = this.createMethod === 'sidebar';
    } else {
      this.separatePageVisible = this.createMethod === 'page';
      this.tableVisible = false;
    }
    this.editFilter = new TableFilter();
  }

  delete(data: TableFilter): void {
    if (data.id) {
      let severity: 'error' | 'success' = 'error';
      this.filterService
        .delete(data.id)
        .subscribe((deletedFilter: TableFilter) => {
          if (deletedFilter) {
            severity = 'success';
            this.filterList = this.dataService.modifyList(
              this.filterList,
              data,
              DataModificationMethod.Delete
            );
            this.reloadData();
          }
          this.showCrudToast(DataModificationMethod.Delete, severity);
        });
    }
  }

  sidebarVisibleChange(visible: boolean): void {
    this.sidebarVisible = visible;
    if (this.editFilter && !this.sidebarVisible) {
      this.editFilter = null;
    }
  }

  separatePageVisibleChange(visible: boolean): void {
    this.separatePageVisible = visible;
    if (this.editFilter && !this.separatePageVisible) {
      this.editFilter = null;
    }
  }

  tableVisibleChange(visible: boolean): void {
    this.tableVisible = visible;
  }

  reloadLazyData(): void {
    if (this.config.lazyLoad && this.lazyLoadTableEvent) {
      this.lazyLoadTableEventFired(this.lazyLoadTableEvent);
    }
  }

  showCrudToast(method: DataModificationMethod, severity: Severity): void {
    // ToDo: add keys
    this.messageCenterService.showToast(
      this.translate.instant(
        `filterComponent.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `filterComponent.actions.toasts.${method}.${severity}.detail`
      ),
      severity
    );
  }
}
