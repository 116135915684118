import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { LoginDto } from 'src/app/models/LoginDto';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm!: FormGroup;

  loginSubscription: Subscription = new Subscription();

  blockedAt?: Date;

  loginMethod = environment.loginMethod;

  constructor(
    private formBuilder: FormBuilder,
    private messageCenterService: MessageCenterService,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', this.loginMethod === 'standard' ? Validators.required : '']
    });
  }

  ngOnDestroy(): void {
    this.loginSubscription.unsubscribe();
  }

  onSubmit() {
    if (this.loginMethod === 'standard') {
      if (this.loginForm && this.loginForm.valid) {
        const email = this.loginForm.get('email')?.value;
        const password = this.loginForm.get('password')?.value;
        const loginDto = new LoginDto(email, password);

        this.loginSubscription = this.authService.login(loginDto).subscribe({
          next: () => {
            this.authService.showLoginToast('success');
            this.router.navigate(['/']);
          },
          error: () => this.authService.showLoginToast('error')
        });
      } else {
        this.messageCenterService.showToast(
          this.translate.instant('loginComponent.toasts.error.summary'),
          this.translate.instant('loginComponent.toasts.error.detail'),
          'error'
        );
      }
    } else {
      this.useVerfication();
    }
  }

  useVerfication() {
    if (this.loginForm && this.loginForm.valid) {
      const email = this.loginForm.get('email')?.value;

      this.authService.generateCodeForLogin(email).subscribe((response) => {
        if (response) {
          const parameter = { email };
          this.router.navigate(['/verification'], { state: parameter });
        } else {
          this.messageCenterService.showToast(
            this.translate.instant('loginComponent.toasts.error.summary'),
            this.translate.instant('loginComponent.toasts.error.detail'),
            'error'
          );
          this.authService.getBlockedAtByEmail(email).subscribe((blockedAt) => {
            this.blockedAt = new Date(blockedAt);
          });
        }
      });
    }
  }
}
