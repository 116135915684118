import { DeviceManufacturer } from 'src/app/models/DeviceManufacturer';
import { FileDto } from './FileDto';

export class Device {
  id!: number;

  title!: string;

  manufacturerId!: number;

  manufacturer!: DeviceManufacturer;

  hasGasSeparatorCheck: boolean = false;

  status!: boolean;

  createdById?: number;

  files?: FileDto[];

  constructor(config?: Partial<Device>) {
    Object.assign(this, config);
  }
}
