import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription, first } from 'rxjs';

import { LoginDto } from 'src/app/models/LoginDto';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/api/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormChangeDetectionService } from 'src/app/services/form-change-detection.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { PasswordValidationService } from 'src/app/services/utils/password-validation.service';
import { Severity } from 'src/app/types/misc/Severity';
import { environment } from 'src/environments/environment';
import { blobToBase64 } from 'src/utils/blob-to-base64';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  user: User | null = null;

  profileForm: FormGroup;

  subscriptions: Subscription = new Subscription();

  isLoading = false;

  passwordChangeVisible = false;

  passwordMediumRegEx = '';

  passwordStrongRegEx = '';

  passwordWeakRegEx = '';

  newPasswordForm!: FormGroup;

  // undefined represents the initial state of the profile picture
  profilePictureBase64: string | null | undefined = undefined;

  didProfilePictureChanged = false;

  trimSubscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageCenterService: MessageCenterService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private formChangeDetectionService: FormChangeDetectionService,
    private passwordValidationService: PasswordValidationService
  ) {
    this.profileForm = this.fb.group({
      firstname: this.fb.control('', [Validators.required]),
      lastname: this.fb.control('', [Validators.required]),
      username: ['']
    });
    this.formChangeDetectionService.addFormToFormArray(this.profileForm);

    this.trimValuesBeforeValidation();
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.subscriptions.add(
      this.authService.user$.subscribe((user) => {
        this.user = user;

        if (user) {
          this.profileForm.setValue({
            firstname: user.firstname,
            lastname: user.lastname,
            username: user.username
          });

          if (user.id && user.profilePictureId) {
            this.userService
              .getProfilePicture(user.id)
              .pipe(first())
              .subscribe((blob) => {
                blobToBase64(blob)
                  .pipe(first())
                  .subscribe((base64) => {
                    this.profilePictureBase64 = base64;
                    this.isLoading = false;
                  });
              });
          } else {
            this.profilePictureBase64 = null;
          }
        }

        this.isLoading = false;
      })
    );

    this.passwordMediumRegEx = environment.passwordStrength.medium
      ? this.passwordValidationService.getPasswordRegex('medium')
      : '';

    this.passwordStrongRegEx = environment.passwordStrength.strong
      ? this.passwordValidationService.getPasswordRegex('strong')
      : '';

    this.passwordWeakRegEx = environment.passwordStrength.weak
      ? this.passwordValidationService.getPasswordRegex('weak')
      : '';

    const pattern = new RegExp(this.getPasswordPattern().substring(1));

    this.newPasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      password2: [
        '',
        [
          Validators.required,
          Validators.pattern(pattern) // Custom validator for matching passwords
        ]
      ],
      password3: [
        '',
        [
          Validators.required,
          this.passwordsMatchValidator.bind(this) // Custom validator for matching passwords
        ]
      ]
    });
  }

  ngOnDestroy(): void {
    if (this.profileForm) {
      this.formChangeDetectionService.removeForm(this.profileForm);
    }
    this.subscriptions.unsubscribe();

    this.trimSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  trimValuesBeforeValidation(): void {
    Object.keys(this.profileForm.controls).forEach((key) => {
      const control = this.profileForm.get(key);
      if (!control) {
        return;
      }

      const subscription = control.valueChanges.subscribe((value) => {
        if (typeof value === 'string') {
          const trimmedValue = value.trim();

          if (trimmedValue === '') {
            control.setValue(null, { emitEvent: false });

            return;
          }

          if (trimmedValue !== value) {
            control.setValue(trimmedValue, { emitEvent: false });
          }
        }
      });

      this.trimSubscriptions.push(subscription);
    });
  }

  hasUserChanged(): boolean {
    return this.profileForm.dirty;
  }

  save() {
    if (!this.user || !this.user.id) {
      return;
    }

    this.user.firstname = this.profileForm.get('firstname')?.value;
    this.user.lastname = this.profileForm.get('lastname')?.value;

    this.subscriptions.add(
      this.userService
        .edit(this.user.id, {
          firstname: this.profileForm.get('firstname')?.value ?? null,
          lastname: this.profileForm.get('lastname')?.value ?? null,
          username: this.profileForm.get('username')?.value ?? null
        })
        .subscribe({
          next: (updatedUser) => {
            if (this.profilePictureBase64 && this.didProfilePictureChanged) {
              this.userService
                .updateProfilePicture(this.profilePictureBase64)
                .pipe(first())
                .subscribe((updatedProfileUser) => {
                  this.user = updatedProfileUser;

                  this.showToast('success', 'edit');
                  this.authService.setUser(this.user);
                  this.close();
                });

              return;
            }

            this.user = updatedUser;

            this.showToast('success', 'edit');
            this.authService.setUser(this.user);
            this.close();
          },
          error: () => {
            this.showToast('error', 'edit');
          }
        })
    );
  }

  profilePictureChanged(profilePicture: string): void {
    if (this.user) {
      this.profileForm.markAsDirty();
      this.profilePictureBase64 = profilePicture;
      this.didProfilePictureChanged = true;
    }
  }

  showToast(severity: Severity, method: string): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `profile.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `profile.actions.toasts.${method}.${severity}.detail`
      ),
      severity
    );
  }

  close() {
    this.ref.close();

    if (this.user) {
      this.profileForm.reset({
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        username: this.user.username
      });
    }
  }

  async closeWindow() {
    if (this.profileForm.dirty) {
      await new Promise<boolean>((resolve) => {
        this.messageCenterService.confirm(
          this.translate.instant('general.confirmUnsavedChanges.header'),
          this.translate.instant('general.confirmUnsavedChanges.message'),
          () => {
            this.ref.close();
          },
          () => {
            resolve(false);
          }
        );
      });
    }
  }

  changePassword() {
    this.passwordChangeVisible = true;
  }

  getPasswordPattern(): string {
    let passwordPattern = '';

    switch (environment.passwordValidAt) {
      case 'weak':
        passwordPattern = this.passwordWeakRegEx;
        break;

      case 'medium':
        passwordPattern = this.passwordMediumRegEx;
        break;

      case 'strong':
        passwordPattern = this.passwordStrongRegEx;
        break;

      default:
        break;
    }

    return passwordPattern;
  }

  passwordsMatchValidator(): { [key: string]: boolean } | null {
    const password = this.newPasswordForm?.controls['password']?.value;
    const password2 = this.newPasswordForm?.controls['password2']?.value;
    const password3 = this.newPasswordForm?.controls['password3']?.value;

    if (password && password2 && password3 && password2 !== password3) {
      return { passwordsNotMatch: true };
    }

    return null;
  }

  onPasswordChange(): void {
    if (this.newPasswordForm && this.newPasswordForm.valid) {
      const email = this.user?.email || '';
      const password = this.newPasswordForm?.controls['password']?.value;
      const credentials = new LoginDto(email, password);
      this.authService.login(credentials).subscribe({
        next: (response) => {
          if (response) {
            const password2 = this.newPasswordForm.get('password2')?.value;
            this.authService
              .setPassword(password2, response.accessToken)
              .subscribe({
                next: () => {
                  this.showToast('success', 'changePassword');
                  this.passwordChangeVisible = false;
                },
                error: () => {
                  this.showToast('error', 'changePassword');
                }
              });
          }
        },
        error: () => {
          this.showToast('error', 'changePassword');
        }
      });
    }
  }

  resetForm(): void {
    this.newPasswordForm.controls['password'].setValue('');
    this.newPasswordForm.controls['password2'].setValue('');
    this.newPasswordForm.controls['password3'].setValue('');
  }
}
