/* eslint-disable max-lines */
/* eslint-disable id-length */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  RendererFactory2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription, forkJoin } from 'rxjs';
import { FileUploadComponent } from 'src/app/components/misc/file-upload/file-upload.component';
import { FileDto } from 'src/app/models/FileDto';
import { User } from 'src/app/models/User';
import { GenericService } from 'src/app/services/api/generic.service';
import { FileTypes } from 'src/app/types/misc/FileTypes';
import { FileDownloader } from 'src/app/utils/other/download-helper';

type ViewType = 'pdf' | 'office';
type SortOption = 'asc' | 'desc' | 'none';

type PreviewEvent = {
  file: FileDto;
  type: 'pdf' | 'image' | 'msg';
};

type FileSelectionMode = 'multiple' | 'none';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnDestroy, OnInit, OnChanges {
  @Input() filesList!: FileDto[];

  @Input() context!: string;

  @Input() uploadIncluded = true;

  @Input() showFilterBar = false;

  @Input() showUploadComponent = false;

  @Input() showDragAndDropArea = false;

  @Input() delegatePreview = false;

  @Input() showUploadBtn = true;

  @Input() showRemoveBtn = true;

  @Input() emitRemoveOnly = false;

  @Input() selectedFiles: FileDto[] = [];

  @Input() selectionMode: FileSelectionMode = 'none';

  @Input() showCustomButton = false;

  @Input() maxHeight: number | undefined = undefined;

  @Input() customButtonIcon = '';

  @Output() customUploadButtonClicked = new EventEmitter<void>();

  @Output() fileRemoved = new EventEmitter<FileDto>();

  @Output() choose = new EventEmitter<File[]>();

  @Output() upload = new EventEmitter<File[]>();

  @Output() preview = new EventEmitter<PreviewEvent>();

  @Output() selectedFilesChange = new EventEmitter<FileDto[]>();

  @ViewChild('fileUploadComponent') fileUploadComponent?: FileUploadComponent;

  @ViewChild('op') overlayPanel?: OverlayPanel | undefined;

  filesListShadow!: FileDto[];

  fileToView: FileDto | null = null;

  FileTypes = FileTypes;

  isLoading = true;

  msgFile: FileDto | null = null;

  subscriptions = new Subscription();

  viewType: ViewType = 'pdf';

  visible = false;

  searchTerm = '';

  fileNameFilter = '';

  createdAtFilter: Date[] = [];

  createdByFilter!: User;

  createdByOptions: User[] = [];

  fileNameSort: SortOption = 'none';

  createdAtSort: SortOption = 'none';

  createdBySort: SortOption = 'none';

  private fileDownloader: FileDownloader;

  /**
   * Constructor for the FilesComponent.
   * @param {GenericService<'Files'>} genericService - The generic service for file operations.
   * @param {RendererFactory2} rendererFactory - The renderer factory.
   */
  constructor(
    private genericService: GenericService<'Files'>,
    private rendererFactory: RendererFactory2
  ) {
    this.fileDownloader = new FileDownloader(this.rendererFactory);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['filesList'] &&
      changes['filesList'].previousValue !== undefined
    ) {
      this.remount();
    }
  }

  ngOnInit(): void {
    this.loadFiles();
  }

  ngOnDestroy(): void {
    this.unloadFiles();
    this.subscriptions.unsubscribe();
  }

  public remount() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();

    this.unloadFiles();

    if (this.fileUploadComponent) {
      this.fileUploadComponent.reset();
    }

    this.isLoading = true;
    this.overlayPanel?.hide();

    this.loadFiles();
  }

  loadFiles(): void {
    this.filesListShadow = this.filesList;

    // Filter files that have an ID
    const filesWithId = this.filesList.filter((file) => file.id);

    if (filesWithId.length > 0) {
      const requests = filesWithId.map((file) =>
        this.genericService.getFile(file.id, this.context)
      );

      this.createdByOptions = Array.from(
        new Set(
          filesWithId.map((file) => JSON.stringify(file.createdBy as User))
        )
      ).map((user) => JSON.parse(user));

      this.subscriptions.add(
        forkJoin(requests).subscribe({
          next: (responses) => {
            responses.forEach((data, index) => {
              const fileIndex = this.filesList.findIndex(
                (file) => file.id === filesWithId[index].id
              );
              if (fileIndex !== -1) {
                this.filesList[fileIndex].buffer = data;
                this.filesList[fileIndex].previewUrl =
                  this.fileDownloader.createBlobUrl(
                    data,
                    this.filesList[fileIndex].mimetype
                  );
              }
            });
            this.isLoading = false;
          },
          error: (error) => {
            console.error('Error:', error);
            this.isLoading = false;
          }
        })
      );
    } else {
      this.isLoading = false;
    }
  }

  _isFileSelected(event: FileDto): boolean {
    return this.selectedFiles.includes(event);
  }

  _fileSelectionChanged(file: FileDto) {
    file.selected = !file.selected;
    if (file.selected) {
      this.selectedFiles.push(file);
    } else {
      this.selectedFiles = this.selectedFiles.filter((f) => f !== file);
    }
    this.selectedFilesChange.emit(this.selectedFiles);
  }

  unloadFiles(): void {
    if (this.filesList && this.filesList.length > 0) {
      this.filesList.forEach((file) => {
        if (file.previewUrl) {
          this.fileDownloader.revokeBlobUrl(file.previewUrl);
        }
      });
    }
  }

  /**
   * Removes the specified file from the uploader.
   * @param {FileDto} file - The file to remove.
   * @returns {void}
   */
  removeFile(file: FileDto): void {
    // Remove the specified file from the uploader
    const index = this.filesList.indexOf(file);
    if (index !== -1) {
      this.filesList[index].deletedAt = new Date();
      this.fileRemoved.emit(this.filesList[index]);
    }
  }

  /**
   * Downloads the specified file.
   * @param {FileDto} file - The file to download.
   * @returns {void}
   */
  download(file: FileDto): void {
    if (file.buffer) {
      this.fileDownloader.downloadFile(
        file.buffer,
        file.originalname,
        file.mimetype
      );
    }
  }

  /**
   * Opens the file preview for the specified file.
   * @param {FileDto} file - The file to preview.
   * @param {ViewType} viewType - The type of view to use for the preview.
   * @returns {void}
   */
  openFilePreview(file: FileDto, viewType: ViewType): void {
    this.viewType = viewType;
    this.fileToView = file;
    this.visible = true;
  }

  /**
   * Shows the preview for the specified .msg file.
   * @param {FileDto} file - The file to show the preview for.
   * @returns {void}
   */
  showMsgPreview(file: FileDto): void {
    this.msgFile = file;
  }

  /**
   * Clears the date filter and resets the files list to its original state.
   * @returns {void}
   */
  clearDateFilter(): void {
    this.createdAtFilter = [];
    this.filesList = this.filesListShadow.slice(0, this.filesListShadow.length);
  }

  /**
   * Sorts the files list by the 'createdAt' property.
   * @param {SortOption} sortType - The type of sort ('asc', 'desc', 'none').
   * @returns {void}
   */
  sortFilesByDate(sortType: SortOption): void {
    this.createdAtSort = sortType;
    if (sortType === 'asc') {
      this.filesList = this.filesList.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    } else if (sortType === 'desc') {
      this.filesList = this.filesList.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    } else if (sortType === 'none') {
      this.filesList = this.filesListShadow.slice(
        0,
        this.filesListShadow.length
      );
    }
  }

  /**
   * Clears the filename filter and resets the files list to its original state.
   * @returns {void}
   */
  clearFilenameFilter(): void {
    this.fileNameFilter = '';
    this.filesList = this.filesListShadow.slice(0, this.filesListShadow.length);
  }

  /**
   * Sorts the files list by the 'filename' property.
   * @param {SortOption} sortType - The type of sort ('asc', 'desc', 'none').
   * @returns {void}
   */
  sortFilesByName(sortType: SortOption): void {
    this.fileNameSort = sortType;
    if (sortType === 'asc') {
      this.filesList = this.filesList.sort((a, b) =>
        a.filename.localeCompare(b.filename)
      );
    } else if (sortType === 'desc') {
      this.filesList = this.filesList.sort((a, b) =>
        b.filename.localeCompare(a.filename)
      );
    } else if (sortType === 'none') {
      this.filesList = this.filesListShadow.slice(
        0,
        this.filesListShadow.length
      );
    }
  }

  /**
   * Clears the 'createdBy' filter and resets the files list to its original state.
   * @returns {void}
   */
  clearCreatedByFilter(): void {
    this.createdByFilter = new User();
    this.filesList = this.filesListShadow.slice(0, this.filesListShadow.length);
  }

  /**
   * Sorts the files list by the 'firstname' property of the 'createdBy' object.
   * @param {SortOption} sortType - The type of sort ('asc', 'desc', 'none').
   * @returns {void}
   */
  sortFilesByUser(sortType: SortOption): void {
    this.createdBySort = sortType;
    if (sortType === 'asc') {
      this.filesList = this.filesList.sort((a, b) =>
        (a.createdBy?.firstname ?? '').localeCompare(
          b.createdBy?.firstname ?? ''
        )
      );
    } else if (sortType === 'desc') {
      this.filesList = this.filesList.sort((a, b) =>
        (b.createdBy?.firstname ?? '').localeCompare(
          a.createdBy?.firstname ?? ''
        )
      );
    } else if (sortType === 'none') {
      this.filesList = this.filesListShadow.slice(
        0,
        this.filesListShadow.length
      );
    }
  }

  /**
   * Handles the select event.
   * @param {File[]} event - The select event.
   * @returns {void}
   */
  onUpload(event: File[]): void {
    this.upload.emit(event);
  }

  /**
   * Handles the select event.
   * @param {File[]} event - The select event.
   * @returns {void}
   */
  onSelect(event: File[]): void {
    this.choose.emit(event);
  }
}

export { type PreviewEvent as FilePreviewEvent };
