import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { AppointmentDto } from 'src/app/models/Appointment';
import { HiveForm } from 'src/app/models/HiveForm';
import { getBackgroundColor, getIcon } from 'src/utils/ticket-helper';
import { isFormCompleted } from 'src/utils/is-form-completed';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';

@Component({
  selector: 'app-appointment-my-day',
  templateUrl: './appointment-my-day.component.html',
  styleUrl: './appointment-my-day.component.scss'
})
export class AppointmentMyDayComponent implements OnInit {
  @Input({ required: true }) appointment!: AppointmentDto;

  @Output() handleSwitchView = new EventEmitter<AppointmentDto>();

  completedForms: HiveForm[] = [];

  totalForms: number = 0;

  actionItems: MenuItem[] | undefined;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    const translationsPath = 'myDayComponent.actions';
    this.translate
      .get([
        `${translationsPath}.open`,
        `${translationsPath}.showTicket`,
        `${translationsPath}.showCustomer`,
        `${translationsPath}.callCustomerLandline`,
        `${translationsPath}.callCustomerMobile`
      ])
      .pipe(first())
      .subscribe((translations) => {
        this.actionItems = [
          {
            label: translations[`${translationsPath}.open`],
            icon: 'pi pi-external-link',
            command: () => this.switchView()
          },
          {
            label: translations[`${translationsPath}.showTicket`],
            icon: 'pi pi-ticket',
            routerLink: ['/tickets/', this.appointment.ticket?.id]
          },
          {
            label: translations[`${translationsPath}.showCustomer`],
            icon: 'pi pi-user',
            routerLink: ['/customers/', this.appointment.ticket?.customerId]
          }
        ];

        if (
          this.appointment.ticket?.customerContactPerson?.phoneNumberLandline
        ) {
          this.actionItems.push({
            label: translations[`${translationsPath}.callCustomerLandline`],
            icon: 'pi pi-phone',
            command: () => {
              window.location.href = `tel:${this.appointment.ticket?.customerContactPerson?.phoneNumberLandline}`;
            }
          });
        }
        if (this.appointment.ticket?.customerContactPerson?.phoneNumberMobile) {
          this.actionItems.push({
            label: translations[`${translationsPath}.callCustomerMobile`],
            icon: 'pi pi-mobile',
            command: () => {
              window.location.href = `tel:${this.appointment.ticket?.customerContactPerson?.phoneNumberMobile}`;
            }
          });
        }
      });
    this.totalForms = this.appointment.forms?.length || 0;
    if (this.appointment.forms) {
      for (const form of this.appointment.forms) {
        if (isFormCompleted(form)) {
          this.completedForms.push(form);
        }
      }
    }
  }

  checkAllFormsCompleted(): string {
    if (this.completedForms.length === this.totalForms) {
      return 'completed';
    }

    return '';
  }

  formatTime(date: string): string {
    return moment(date).format('HH:mm');
  }

  handleAppointmentClick(event: MouseEvent): void {
    // Check if the clicked element is the user avatar
    const target = event.target as HTMLElement;

    // Do not open detail view when clicking on user avatar
    if (target.closest('.user-avatar')) {
      return;
    }

    this.switchView();
  }

  switchView() {
    this.handleSwitchView.emit(this.appointment);
  }

  onMenuButtonClick(event: MouseEvent, menu: Menu) {
    // Don't open detail view when clicking on menu button.
    event.stopPropagation();
    // Open menu
    menu.toggle(event);
  }

  get backgroundColor(): string {
    if (!this.appointment.ticket) {
      return '';
    }

    return getBackgroundColor(this.appointment.ticket);
  }

  get icon(): string {
    if (!this.appointment.ticket) {
      return '';
    }

    return getIcon(this.appointment.ticket);
  }
}
