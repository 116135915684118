/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot
} from '@angular/router';
import { FormChangeDetectionService } from '../services/form-change-detection.service';
import { inject } from '@angular/core';
import { MessageCenterService } from '../services/message-center.service';
import { TranslateService } from '@ngx-translate/core';

export const canDeactivateGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  if (inject(FormChangeDetectionService).formArray.dirty) {
    const result = await new Promise<boolean>((resolve) => {
      const translate = inject(TranslateService);
      inject(MessageCenterService).confirm(
        translate.instant('general.confirmUnsavedChanges.header'),
        translate.instant('general.confirmUnsavedChanges.message'),
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
    });

    return result;
  }

  return true;
};
