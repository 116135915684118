/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */

import { catchError, first, throwError } from 'rxjs';
import { CustomerEditDevicesComponent } from 'src/app/components/admin/customer/views/components/customer-edit-devices/customer-edit-devices.component';
import { CustomerDevice } from 'src/app/models/customer/CustomerDevice';

type StateContext = {
  action: string;
  actionExecuted: string;
  actionBefore: string;
};

type FactoryContext = {
  field: 'isActive';

  onState: StateContext;
  ofState: StateContext;
};

export const HandleIsActiveSwitchContext = {
  field: 'isActive',

  onState: {
    action: 'activate',
    actionExecuted: 'activated',
    actionBefore: 'toActivate'
  },

  ofState: {
    action: 'deactivate',
    actionExecuted: 'deactivated',
    actionBefore: 'toDeactivated'
  }
} satisfies FactoryContext;

type AvailableContexts = typeof HandleIsActiveSwitchContext;

/**
 * Handles the change event of a switch component.
 * @param context - The available contexts for the switch component.
 * @returns A function that executes the switch change logic.
 */
export const handleSwitchChangeFactory = (context: AvailableContexts) =>
  function execute(
    this: CustomerEditDevicesComponent,
    customerDevice: CustomerDevice,
    value: boolean
  ) {
    const translationContext = {
      device: customerDevice.device?.title ?? '<Unknown>',
      action: this.translate.instant(
        `customerDeviceComponent.actions.toasts.${context.field}.actions.${value ? context.onState.action : context.ofState.action}`
      ),
      result: this.translate.instant(
        `customerDeviceComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionExecuted : context.ofState.actionExecuted}`
      )
    } as const;

    const executeChange = () => {
      this.customerDeviceService
        .update(customerDevice.id, { [context.field]: value })
        .pipe(
          catchError((error) => {
            this.showCrudToast(context.field, 'error', customerDevice, {
              result: this.translate.instant(
                `customerDeviceComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionExecuted : context.ofState.actionExecuted}`
              )
            });

            return throwError(() => error);
          }),
          first() // Automatically unsubscribe after first value
        )
        .subscribe((updatedCustomerDevice) => {
          this.showCrudToast(context.field, 'success', updatedCustomerDevice, {
            result: this.translate.instant(
              `customerDeviceComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionExecuted : context.ofState.actionExecuted}`
            )
          });

          this.changeCustomerDevices.emit(
            this.customerDevices.map((x) =>
              x.id === updatedCustomerDevice.id ? updatedCustomerDevice : x
            )
          );
        });
    };

    this.messageCenterService.confirm(
      this.translate.instant(
        `customerDeviceComponent.actions.toasts.${context.field}.confirm.header`,
        translationContext
      ),
      this.translate.instant(
        `customerDeviceComponent.actions.toasts.${context.field}.confirm.message`,
        translationContext
      ),
      executeChange,
      () => {
        this.messageCenterService.showToast(
          this.translate.instant(
            `customerDeviceComponent.actions.toasts.${context.field}.info.summary`
          ),
          this.translate.instant(
            `customerDeviceComponent.actions.toasts.${context.field}.info.detail`,
            translationContext
          ),
          'info'
        );

        this.changeCustomerDevices.emit(
          this.customerDevices.map((x) =>
            x.id === customerDevice.id
              ? { ...customerDevice, [context.field]: !value }
              : x
          )
        );
      },
      this.translate.instant(
        `customerDeviceComponent.actions.toasts.${context.field}.actions.${value ? context.onState.actionBefore : context.ofState.actionBefore}`
      )
    );
  };
