<ng-container *ngIf="showHeader">
  <h6>{{ 'passwordGuidelinesComponent.title' | translate }}</h6>
</ng-container>
<ng-container *ngIf="showFooter">
  <p-divider></p-divider>
  <p class="mt-2">{{ 'passwordGuidelinesComponent.guidelines' | translate }}</p>
  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
    <li
      *ngIf="environment.passwordValidAt === 'weak' && environment.passwordStrength.weak.minCharacters > 0">
      {{ 'passwordGuidelinesComponent.rules.minCharacters' | translate: {count:
      environment.passwordStrength.weak.minCharacters} }}
    </li>
    <li
      *ngIf="environment.passwordValidAt === 'medium' && environment.passwordStrength.medium.minCharacters > 0">
      {{ 'passwordGuidelinesComponent.rules.minCharacters' | translate: {count:
      environment.passwordStrength.medium.minCharacters} }}
    </li>
    <li
      *ngIf="environment.passwordValidAt === 'strong' && environment.passwordStrength.strong.minCharacters > 0">
      {{ 'passwordGuidelinesComponent.rules.minCharacters' | translate: {count:
      environment.passwordStrength.strong.minCharacters} }}
    </li>
    <li
      *ngIf="environment.passwordValidAt === 'medium' && environment.passwordStrength.medium.oneLowerCase">
      {{ 'passwordGuidelinesComponent.rules.oneLowerCase' | translate }}
    </li>
    <li
      *ngIf="environment.passwordValidAt === 'medium' && environment.passwordStrength.medium.oneUpperCase">
      {{ 'passwordGuidelinesComponent.rules.oneUpperCase' | translate }}
    </li>
    <li
      *ngIf="environment.passwordValidAt === 'strong' && environment.passwordStrength.strong.oneLowerCase">
      {{ 'passwordGuidelinesComponent.rules.oneLowerCase' | translate }}
    </li>
    <li
      *ngIf="environment.passwordValidAt === 'strong' && environment.passwordStrength.strong.oneUpperCase">
      {{ 'passwordGuidelinesComponent.rules.oneUpperCase' | translate }}
    </li>
    <li
      *ngIf="environment.passwordValidAt === 'strong' && environment.passwordStrength.strong.oneNumeric">
      {{ 'passwordGuidelinesComponent.rules.oneNumeric' | translate }}
    </li>
    <li
      *ngIf="environment.passwordValidAt === 'strong' && environment.passwordStrength.strong.oneSpecial">
      {{ 'passwordGuidelinesComponent.rules.oneSpecial' | translate }}
    </li>
  </ul>
</ng-container>
