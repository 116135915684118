<!-- Device Manufacturer -->
<div class="form-field mb-3">
  <label htmlFor="deviceManufacturerId" class="block mb-2">
    {{ "customerDeviceComponent.attributes.deviceManufacturerId" | translate}}*
  </label>
  <p-dropdown
    [(ngModel)]="selectedDeviceManufacturer"
    id="deviceManufacturerId"
    autoWidth="false"
    [style]="{'width':'100%'}"
    [filter]="true"
    filterBy="name"
    [options]="deviceManufacturers"
    optionLabel="name"
    [placeholder]="'general.choose'| translate"
    dataKey="id"
    (ngModelChange)="onChangeDeviceManufacturer($event)" />
</div>
<!-- Manufacturer -->

<form
  #form
  *ngIf="formGroup !== null && customerDevice"
  [formGroup]="formGroup"
  class="form-fields">
  <!-- Device Field | From -->
  <div class="form-field mb-3">
    <label htmlFor="deviceId" class="block mb-2">
      {{ "customerDeviceComponent.attributes.deviceId" | translate}}*
    </label>
    <p-dropdown
      id="deviceId"
      autoWidth="false"
      [style]="{'width':'100%'}"
      [filter]="true"
      filterBy="title"
      formControlName="deviceId"
      [options]="deviceOptions"
      optionLabel="title"
      [placeholder]="'general.choose'| translate"
      dataKey="id"
      optionValue="id" />
  </div>
  <!-- Device Field | From -->

  <!-- Heating Engineer Field | From -->
  <div class="form-field mb-3">
    <label htmlFor="heatingEngineerId" class="block mb-2">
      {{ "customerDeviceComponent.attributes.heatingEngineerId" | translate}}*
    </label>
    <p-dropdown
      id="heatingEngineerId"
      autoWidth="false"
      [style]="{'width':'100%'}"
      [filter]="true"
      filterBy="name"
      formControlName="heatingEngineerId"
      [options]="heatingEngineerOptions"
      optionLabel="name"
      [placeholder]="'general.choose'| translate"
      dataKey="id"
      optionValue="id">
      <ng-template let-option pTemplate="item">
        <span
          class="dropdown-option"
          [ngStyle]="{
          maxWidth: '340px',
        }">
          {{option.name}}
        </span>
      </ng-template>
    </p-dropdown>
  </div>
  <!-- Heating Engineer | From -->

  <!-- is Delivered By Nothaft | From -->
  <div class="form-field mb-2 pb-2 pt-2 flex align-items-center gap-2">
    <p-inputSwitch
      id="isDeliveredByNothaft"
      formControlName="isDeliveredByNothaft"></p-inputSwitch>
    <label htmlFor="isDeliveredByNothaft" class="block">
      {{ "customerDeviceComponent.attributes.isDeliveredByNothaft" | translate}}
    </label>
  </div>
  <!-- is Delivered By Nothaft | From -->

  <!-- Commission Type | From -->
  <div class="form-field mb-3">
    <label htmlFor="commissionType" class="block mb-2">
      {{ "customerDeviceComponent.attributes.commissionType" | translate}}*
    </label>
    <p-dropdown
      id="commissionType"
      autoWidth="false"
      [style]="{'width':'100%'}"
      formControlName="commissionType"
      [options]="commissionTypeOptions"
      optionLabel="label"
      optionValue="value"
      [placeholder]="'general.choose'| translate"
      dataKey="id">
    </p-dropdown>
  </div>
  <!-- Commission Field | From -->

  <!-- Commissioning Date Field | From -->
  <div class="form-field mb-3">
    <label htmlFor="commissionDate" class="block mb-2">
      {{ "customerDeviceComponent.attributes.commissionDate" | translate}}
    </label>
    <p-calendar
      id="commissionDate"
      formControlName="commissionDate"
      class="w-full block"
      [style]="{'width':'100%'}"
      dateFormat="dd.mm.yy"
      [firstDayOfWeek]="1"
      [placeholder]="'general.choose'| translate" />
  </div>
  <!--  Commissioning Date Field | From -->

  <!-- Is Active Field | From -->
  <div class="form-field mb-2 pb-2 pt-2 flex align-items-center gap-2">
    <p-inputSwitch id="isActive" formControlName="isActive"></p-inputSwitch>
    <label htmlFor="isActive" class="block">
      {{ "customerDeviceComponent.attributes.isActive" | translate}}
    </label>
  </div>
  <!-- Is Active Field | From -->

  <!-- Note Field | From -->
  <div class="form-field mb-2">
    <label htmlFor="note" class="block mb-2">
      {{ "customerDeviceComponent.attributes.note" | translate}}
    </label>
    <textarea
      id="note"
      rows="5"
      cols="30"
      type="text"
      pInputText
      formControlName="note"
      class="w-full block"
      [placeholder]="'general.form.enterSubject'| translate:
    {subject: ('customerDeviceComponent.attributes.note' | translate)}"
      [value]="customerDevice.note"></textarea>
  </div>
  <!-- Note Field | From -->

  <h6 class="uppercase text-primary font-normal">
    {{ 'customerDeviceComponent.form.subTitels.deviceInformation' | translate}}
  </h6>

  <!-- Control Unit Field | From -->
  <div *ngIf="controlUnitOptions" class="form-field mb-3">
    <label htmlFor="controlUnitId" class="block mb-2">
      {{ "customerDeviceComponent.attributes.controlUnitId" | translate}}*
    </label>
    <p-dropdown
      id="controlUnitId"
      autoWidth="false"
      [style]="{'width':'100%'}"
      [filter]="true"
      filterBy="name"
      scrollHeight="250px"
      formControlName="controlUnitId"
      [options]="controlUnitOptions"
      optionLabel="name"
      [placeholder]="'general.choose'| translate"
      dataKey="id"
      optionValue="id">
    </p-dropdown>
  </div>
  <!-- Control Unit Field | From -->

  <!-- Internal Device Serial Number Field | From -->
  <div class="form-field mb-3">
    <label htmlFor="internalDeviceSerialNumber" class="block mb-2">
      {{ "customerDeviceComponent.attributes.internalDeviceSerialNumber" |
      translate}}
    </label>

    <input
      id="internalDeviceSerialNumber"
      type="text"
      pInputText
      class="w-full"
      [placeholder]="'general.form.enterSubject'| translate:
  {subject: ('customerDeviceComponent.attributes.internalDeviceSerialNumber' | translate)}"
      formControlName="internalDeviceSerialNumber"
      [value]="customerDevice.internalDeviceSerialNumber" />
  </div>
  <!-- Internal Device Serial Number Field | From -->

  <!-- External Device Serial Number Field | From -->
  <div class="form-field mb-3">
    <label htmlFor="externalDeviceSerialNumber" class="block mb-2">
      {{ "customerDeviceComponent.attributes.externalDeviceSerialNumber" |
      translate}}
    </label>

    <input
      id="externalDeviceSerialNumber"
      type="text"
      pInputText
      class="w-full"
      [placeholder]="'general.form.enterSubject'| translate:
  {subject: ('customerDeviceComponent.attributes.externalDeviceSerialNumber' | translate)}"
      formControlName="externalDeviceSerialNumber"
      [value]="customerDevice.externalDeviceSerialNumber" />
  </div>
  <!-- External Device Serial Number Field | From -->

  <!-- is Gas Separator Installed Field | From -->
  <div
    *ngIf="selectedDevice?.hasGasSeparatorCheck === true"
    class="form-field mb-3">
    <label htmlFor="isGasSeparatorInstalled" class="block mb-2">
      {{ "customerDeviceComponent.attributes.isGasSeparatorInstalled" |
      translate}}
    </label>

    <p-dropdown
      id="isGasSeparatorInstalled"
      autoWidth="false"
      [style]="{'width':'100%'}"
      formControlName="isGasSeparatorInstalled"
      [options]="gasSeparatorInstalledOptions"
      optionLabel="label"
      [placeholder]="'general.choose'| translate"
      dataKey="value"
      appendTo="body"
      optionValue="value">
    </p-dropdown>
  </div>
  <!-- is Gas Separator Installed Field | From -->
</form>
