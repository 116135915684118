import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileDto } from '../models/FileDto';
import { MsgData } from '../models/MsgData';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private http: HttpClient) {}

  findOne(id: number): Observable<FileDto> {
    return this.http.get<FileDto>(`${environment.apiUrl}/files/${id}`);
  }

  getFileAsBase64(path: string): Observable<string> {
    return this.http
      .get(path, { responseType: 'blob' })
      .pipe(switchMap((blob) => this.convertBlobToBase64(blob)));
  }

  parseMsg(id: number): Observable<MsgData> {
    return this.http.get<MsgData>(
      `${environment.apiUrl}/devices/parse-msg/${id}`
    );
  }

  parseEml(id: number): Observable<MsgData> {
    return this.http.get<MsgData>(
      `${environment.apiUrl}/devices/parse-eml/${id}`
    );
  }

  private convertBlobToBase64(blob: Blob): Observable<string> {
    return from(
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      })
    );
  }
}
