import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggingComponent } from 'src/app/components/misc/logging/logging.component';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { Ticket } from 'src/app/models/Ticket';
import {
  displayLoggingContext,
  LoggingFormatter,
  LoggingFormatterBuilder
} from 'src/app/utils/logging';

@Component({
  selector: 'app-ticket-header',
  templateUrl: './ticket-header.component.html',
  styleUrls: ['./ticket-header.component.scss']
})
export class TicketHeaderComponent {
  @ViewChild('loggingComponent')
  loggingComponent?: LoggingComponent;

  @Input() ticket!: Ticket;

  @Output() returnToOverview = new EventEmitter<void>();

  config = new GenericTableConfiguration({
    translationKey: 'ticketComponent'
  });

  loggingFormatter: LoggingFormatter;

  constructor(private readonly translate: TranslateService) {
    this.loggingFormatter = LoggingFormatterBuilder.new<Ticket>()
      .add('editedById', displayLoggingContext)
      .add('customerId', displayLoggingContext)
      .add('customerContactPersonId', displayLoggingContext)
      .add('ticketCategoryType', (value) =>
        this.translate.instant(`ticketComponent.ticketChips.${value}`)
      )
      .add('ticketStatusType', (value) =>
        this.translate.instant(`ticketComponent.ticketChips.${value}`)
      )
      .add('reoccurringInterval', (value) =>
        this.translate.instant(
          `ticketComponent.attributes.intervalOptions.${value}`
        )
      )
      .build();
  }

  returnToOverviewClicked(): void {
    this.returnToOverview.emit();
  }

  public reloadLogging(): void {
    this.loggingComponent?.fetchLoggingView();
  }
}
