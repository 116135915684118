import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EChartsOption } from 'echarts';
import { Ticket, TicketCategory, TicketStatus } from 'src/app/models/Ticket';
import { TicketService } from 'src/app/services/api/ticket.service';

@Component({
  selector: 'app-widget-ticket-commissioning',
  templateUrl: './widget-ticket-commissioning.component.html',
  styleUrl: './widget-ticket-commissioning.component.scss'
})
export class WidgetTicketCommissioningComponent implements OnInit, OnChanges {
  @Input() preview = false;

  @Input() editMode = false;

  @Input() tickets: Ticket[] = [];

  @Input() colorScheme: 'dim' | 'light' | 'dark' = 'light';

  options!: EChartsOption;

  constructor(
    public readonly ticketService: TicketService,
    private readonly translate: TranslateService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.filterTickets();
    this.generateChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tickets']) {
      this.filterTickets();
      this.generateChartData();
    }
    if (changes['colorScheme']) {
      this.generateChartData();
    }
  }

  /**
   * Filters the tickets to include only those that belong to the Commissioning category.
   *
   * @returns {void}
   */
  filterTickets(): void {
    this.tickets = this.tickets.filter(
      (ticket) => ticket.ticketCategoryType === TicketCategory.Commissioning
    );
  }

  /**
   * Generates chart data for ticket commissioning statuses.
   * This method sets up the data and options for a bar chart.
   *
   * @returns { void }
   */
  generateChartData(): void {
    this.options = {
      tooltip: {
        trigger: 'item',
        appendToBody: true,
        position: 'right'
      },
      legend: {
        top: '5%',
        left: 'left',
        orient: 'vertical',
        type: 'scroll',
        textStyle: {
          color:
            this.colorScheme === 'dark' || this.colorScheme === 'dim'
              ? '#fff'
              : '#000'
        }
      },
      series: [
        {
          name: this.translate.instant('widgets.ticketCommissioning.count'),
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['65%', '50%'],
          avoidLabelOverlap: true,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
            label: {
              show: true,
              fontSize: 12,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Planning
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Planning'
              ),
              itemStyle: {
                color: 'rgba(69, 168, 198, 0.8)',
                borderColor: 'rgb(69, 168, 198)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Open
              ).length,
              name: this.translate.instant('widgets.ticketTask.labels.Open'),
              itemStyle: {
                color: 'rgba(0, 144, 255, 0.8)',
                borderColor: 'rgb(0, 144, 255)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.InProgress
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.InProgress'
              ),
              itemStyle: {
                color: 'rgba(255, 187, 0, 0.8)',
                borderColor: 'rgb(255, 187, 0)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Completed
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Completed'
              ),
              itemStyle: {
                color: 'rgba(12, 167, 136, 0.8)',
                borderColor: 'rgb(12, 167, 136)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Cancelled
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Cancelled'
              ),
              itemStyle: {
                color: 'rgba(244, 67, 54, 0.8)',
                borderColor: 'rgb(244, 67, 54)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Done
              ).length,
              name: this.translate.instant('widgets.ticketTask.labels.Done'),
              itemStyle: {
                color: 'rgba(143, 208, 78, 0.8)',
                borderColor: 'rgb(143, 208, 78)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Complaint
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Complaint'
              ),
              itemStyle: {
                color: 'rgba(148, 18, 171, 0.8)',
                borderColor: 'rgb(148, 18, 171)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Complained
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Complained'
              ),
              itemStyle: {
                color: 'rgba(160, 108, 233, 0.8)',
                borderColor: 'rgb(160, 108, 233)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Goodwill
              ).length,
              name: this.translate.instant(
                'widgets.ticketTask.labels.Goodwill'
              ),
              itemStyle: {
                color: 'rgba(23, 121, 194, 0.8)',
                borderColor: 'rgb(23, 121, 194)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Paused
              ).length,
              name: this.translate.instant('widgets.ticketTask.labels.Paused'),
              itemStyle: {
                color: 'rgba(190, 134, 163, 0.8)',
                borderColor: 'rgb(190, 134, 163)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Settle
              ).length,
              name: this.translate.instant('widgets.ticketTask.labels.Settle'),
              itemStyle: {
                color: 'rgba(125, 156, 100, 0.8)',
                borderColor: 'rgb(125, 156, 100)'
              }
            },
            {
              value: this.tickets.filter(
                (ticket) => ticket.ticketStatusType === TicketStatus.Settled
              ).length,
              name: this.translate.instant('widgets.ticketTask.labels.Settled'),
              itemStyle: {
                color: 'rgba(144, 208, 78, 0.8)',
                borderColor: 'rgb(144, 208, 78)'
              }
            }
          ]
        }
      ]
    };
  }

  /**
   * Navigates to the 'tickets' route to view all tickets.
   */
  viewAllTickets(): void {
    // Use the router to navigate to the 'tickets' route
    this.router.navigate(['tickets']);
  }
}
