import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MsgViewerComponent } from 'src/app/components/misc/file-upload/files/msg-viewer/msg-viewer.component';
import { FileDto } from 'src/app/models/FileDto';

@Component({
  selector: 'app-msg-viewer-modal',
  templateUrl: './msg-viewer-modal.component.html',
  styleUrls: ['./msg-viewer-modal.component.scss']
})
export class MsgViewerModalComponent {
  @Input({ required: true }) file!: FileDto;

  @Output() modalHidden = new EventEmitter<void>();

  @ViewChild('msgViewer') msgViewer!: MsgViewerComponent;

  msgModalVisible = true;

  /**
   * Hides the modal and emits a 'modalHidden' event.
   * @returns {void}
   */
  public hide(): void {
    this.msgModalVisible = false;
    this.modalHidden.emit();
  }

  get msgData() {
    return this.msgViewer?.msgData;
  }

  get htmlData() {
    return this.msgViewer?.htmlData;
  }

  get plainData() {
    return this.msgViewer?.plainData;
  }
}
