import { HttpErrorResponse } from '@angular/common/http';

// TODO: Fix translations - In the translation file, are not all possible status codes defined
// define the translation keys for the error messages OR handle the error messages in a different way

export const getHttpErrorTranslationKeys = (error: HttpErrorResponse) => {
  if (error.status >= 400 && error.status < 600) {
    return {
      summary: `errorMessages.${error.status}.summary`,
      detail: `errorMessages.${error.status}.detail`
    } as const;
  }

  return {
    summary: 'errorMessages.generic.summary',
    detail: 'errorMessages.generic.detail'
  } as const;
};
