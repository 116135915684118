import { Appointment } from './Appointment';
import { CustomerDevice } from './customer/CustomerDevice';
import { User } from './User';

// eslint-disable-next-line no-shadow
export enum FormType {
  // Installation | IDM
  InstallationIdmLuft = 'InstallationIdmLuft',
  InstallationIdmSw = 'InstallationIdmSw',

  // SGC Maintenance | IDM
  SgcMaintenanceIdm = 'SgcMaintenanceIdm',

  ServiceReport = 'ServiceReport'
}

export class HiveForm {
  readonly id?: number;

  type?: FormType;

  deadline?: Date;

  technicianId?: number;

  technician?: User;

  customerDeviceId?: number;

  customerDevice?: CustomerDevice;

  createdAt?: Date;

  appointments?: Appointment[];

  installationIdmLuftFormId?: number;

  installationIdmSwFormId?: number;

  sgcMaintenanceIdmFormId?: number;

  serviceReportFormId?: number;

  readonly createdById?: number;

  createdBy?: User;

  readonly updatedAt?: Date;

  readonly updatedById?: number;

  updatedBy?: User;

  readonly deletedAt?: Date | null;

  readonly deletedById?: number | null;

  deletedBy?: User;
}
