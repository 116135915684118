import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Country } from 'src/app/models/Country';
import {
  CustomerContactPerson,
  CustomerContactPersonCreate,
  CustomerContactPersonEditInterface,
  CustomerContactPersonType,
  CustomerContactPersonUpdate
} from 'src/app/models/customer/CustomerContactPerson';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormChangeDetectionService } from 'src/app/services/form-change-detection.service';
import { AppAction } from 'src/config/authorization.config';

const DEFAULT_STRING_VALIDATORS = [
  Validators.required,
  Validators.minLength(2),
  Validators.maxLength(255)
];

@Component({
  selector: 'app-customer-contact-person-form',
  templateUrl: './customer-contact-person-form.component.html',
  styleUrls: ['./customer-contact-person-form.component.scss']
})
export class CustomerContactPersonFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input({ required: true })
  customerContactPerson!: CustomerContactPersonEditInterface;

  @Input({ required: true }) customerContactPersons: CustomerContactPerson[] =
    [];

  @Input({ required: true }) countries: Country[] = [];

  @Output() formChanged = new EventEmitter<void>();

  formGroup: FormGroup | null = null;

  typeOptions = Object.values(CustomerContactPersonType);

  formSubscription: Subscription | null = null;

  constructor(
    private readonly authService: AuthService,
    private formChangeDetectionService: FormChangeDetectionService
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      type: new FormControl(this.customerContactPerson.type, {
        validators: [Validators.required]
      }),

      firstname: new FormControl(this.customerContactPerson.firstname, {
        validators: [Validators.maxLength(255)]
      }),

      lastname: new FormControl(this.customerContactPerson.lastname, {
        validators: DEFAULT_STRING_VALIDATORS
      }),

      phoneNumberMobile: new FormControl(
        this.customerContactPerson.phoneNumberMobile,
        {
          validators: []
        }
      ),

      phoneNumberLandline: new FormControl(
        this.customerContactPerson.phoneNumberLandline,
        {
          validators: []
        }
      ),

      email: new FormControl(this.customerContactPerson.email, {
        validators: [Validators.email]
      }),

      street: new FormControl(this.customerContactPerson.street, {
        validators: [Validators.maxLength(255)]
      }),

      postalCode: new FormControl(this.customerContactPerson.postalCode, {
        validators: [Validators.maxLength(255)]
      }),

      city: new FormControl(this.customerContactPerson.city, {
        validators: [Validators.maxLength(255)]
      }),

      countryId: new FormControl(this.customerContactPerson.countryId, {
        validators: []
      }),

      isMainContact: new FormControl(this.customerContactPerson.isMainContact, {
        validators: [Validators.required]
      }),

      isActive: new FormControl(this.customerContactPerson.isActive, {
        validators: [Validators.required]
      }),

      note: new FormControl(this.customerContactPerson.note, {
        validators: [Validators.maxLength(255)]
      })
    });

    if (this.$can('update') === false) {
      this.formGroup.disable();
    }
    if (this.customerContactPerson.isMainContact === true) {
      this.formGroup.get('isMainContact')?.disable();
      this.formGroup.get('isActive')?.disable();
    }
    this.formChangeDetectionService.addFormToFormArray(this.formGroup);

    this.formSubscription = this.formGroup.valueChanges.subscribe((changes) => {
      if (changes) {
        this.formChanged.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerContactPerson']) {
      this.formGroup?.patchValue(this.customerContactPerson);
    }
  }

  ngOnDestroy(): void {
    if (this.formGroup) {
      this.formChangeDetectionService.removeForm(this.formGroup);
    }

    this.formSubscription?.unsubscribe();
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'CustomerContactPerson');
  }

  public buildCustomerContactPerson():
    | CustomerContactPersonCreate
    | CustomerContactPersonUpdate
    | null {
    return {
      customerId: this.customerContactPerson.customerId,

      type: this.formGroup?.get('type')?.value,
      firstname: this.formGroup?.get('firstname')?.value,
      lastname: this.formGroup?.get('lastname')?.value,

      phoneNumberMobile: this.formGroup?.get('phoneNumberMobile')?.value,
      phoneNumberLandline: this.formGroup?.get('phoneNumberLandline')?.value,
      email: this.formGroup?.get('email')?.value,

      street: this.formGroup?.get('street')?.value,
      postalCode: this.formGroup?.get('postalCode')?.value,
      city: this.formGroup?.get('city')?.value,
      countryId: this.formGroup?.get('countryId')?.value,

      isMainContact: this.formGroup?.get('isMainContact')?.value,
      isActive: this.formGroup?.get('isActive')?.value,
      note: this.formGroup?.get('note')?.value
    };
  }
}
