<app-logged-out-layout>
  <ng-container class="header-content">
    <div class="text-900 text-xl font-bold mb-2">
      {{ 'newPasswordComponent.title' | translate }}
    </div>
    <span class="text-600 font-medium"
      >{{ 'newPasswordComponent.subtitle' | translate }}</span
    >
  </ng-container>

  <ng-container class="body-content">
    <form [formGroup]="newPasswordForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column">
        <span class="p-input-icon-right w-full mb-4">
          <p-password
            id="password"
            formControlName="password"
            [feedback]="true"
            [toggleMask]="true"
            inputStyleClass="w-full"
            [strongRegex]="passwordStrongRegEx"
            [mediumRegex]="passwordMediumRegEx"
            styleClass="w-full p-password p-component p-inputwrapper p-input-icon-right"
            [placeholder]="'general.form.enterSubject' | translate: {subject: ('newPasswordComponent.form.password.placeholder' | translate)}">
            <ng-template pTemplate="header">
              <app-password-guidelines
                [showHeader]="true"></app-password-guidelines>
            </ng-template>
            <ng-template pTemplate="footer">
              <app-password-guidelines
                [showFooter]="true"></app-password-guidelines>
            </ng-template>
          </p-password>
        </span>
        <span class="p-input-icon-right w-full mb-4">
          <p-password
            id="password2"
            formControlName="password2"
            [feedback]="true"
            [toggleMask]="true"
            inputStyleClass="w-full"
            [strongRegex]="passwordStrongRegEx"
            [mediumRegex]="passwordMediumRegEx"
            styleClass="w-full p-password p-component p-inputwrapper p-input-icon-right"
            [placeholder]="'general.form.enterSubject' | translate: {subject: ('newPasswordComponent.form.password2.placeholder' | translate)}">
            <ng-template pTemplate="header">
              <app-password-guidelines
                [showHeader]="true"></app-password-guidelines>
            </ng-template>
            <ng-template pTemplate="footer">
              <app-password-guidelines
                [showFooter]="true"></app-password-guidelines>
            </ng-template>
          </p-password>
        </span>
        <div class="flex flex-wrap gap-2 justify-content-between">
          <button
            pButton
            pRipple
            type="button"
            [label]="'general.form.cancel' | translate"
            class="flex-auto p-button-outlined"
            [routerLink]="['/login']"></button>
          <button
            pButton
            pRipple
            [label]="'general.form.submit' | translate"
            class="flex-auto"
            type="submit"
            [disabled]="!newPasswordForm.valid"></button>
        </div>
      </div>
    </form>
  </ng-container>
</app-logged-out-layout>
